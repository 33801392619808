import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';

import './index.css';
import '../../static/css/animate.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";


class TermsandConditions extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };

    render() {
        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec profile-edit-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                <div className='privacy-form'>
                                    <h3>Privacy Policy</h3>
                                    <h4>Nifty NFTs Sales</h4>
                                    <p>Aliquam faucibus, odio nec commodo aliquam, neque felis placerat dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed condimentum est. Mauris arcu odio, vestibulum quis dapibus sit amet, finibus id turpis. Aliquam semper fringilla semper. Sed nec velit sit amet dolor pulvinar feugiat. Suspendisse blandit, nulla sed interdum egestas, nibh ex maximus arcu, non posuere sem nulla in augue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas mattis sapien vel sollicitudin blandit. Donec nec porttitor eros. Praesent blandit, erat non vehicula rutrum, mauris orci scelerisque urna, rutrum malesuada odio magna at felis. Fusce convallis elit in risus tincidunt ullamcorper. Aliquam maximus dolor turpis, nec commodo libero mattis ut.</p>
                                    <h4>Conditions</h4>
                                    <p>Vestibulum blandit viverra convallis. Pellentesque ligula urna, fermentum ut semper in, tincidunt nec dui. Morbi mauris lacus, consequat eget justo in, semper gravida enim. Donec ultrices varius ligula. Ut non pretium augue. Etiam non rutrum metus. In varius sit amet lorem tempus sagittis. Cras sed maximus enim, vel ultricies tortor. Pellentesque consectetur tellus ornare felis porta dapibus. Etiam eget viverra ipsum, ac posuere massa. Nam vitae tempor metus. Donec rhoncus, metus ac convallis ultricies, turpis augue vestibulum elit, ut pharetra orci urna quis lectus. Praesent in libero non enim tincidunt imperdiet nec vitae urna. Morbi elementum non enim eget aliquet. Vivamus fermentum quam magna, ac cursus neque pulvinar feugiat. Nulla facilisi.</p>
                                    <ul className='bulet-list'>
                                        <li>Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac.</li>
                                        <li>Ut sit amet erat nec nibh rhoncus varius in non lorem. Donec interdum, lectus in convallis pulvinar, enim elit porta sapien, vel finibus erat felis sed neque.</li>
                                        <li>Etiam aliquet neque sagittis erat tincidunt aliquam.</li>
                                        <li>Vestibulum at neque hendrerit, mollis dolor at, blandit justo. Integer ac interdum purus.</li>
                                        <li>In placerat lorem non quam pulvinar molestie ac eget lacus. Proin mollis lobortis porttitor.</li>
                                        <li>Nam in fringilla felis. Nunc non magna maximus, pulvinar justo ut, pulvinar lacus.</li>
                                        <li>Vivamus semper ex vel felis lobortis, nec feugiat erat pulvinar.</li>
                                        <li>Cras eu sem sed tellus sodales pellentesque nec id libero.</li>
                                        <li>Vestibulum tincidunt, ipsum vitae finibus tempus, orci mi iaculis lacus, id faucibus erat Leo ac nisl.</li>
                                        <li>Nullam vel pulvinar nisi, ac fringilla urna.</li>
                                    </ul>
                                    <p>Aliquam in bibendum mauris. Sed vitae erat vel velit blandit pharetra vitae nec ante. Cras at est augue. Cras ut interdum elit. Ut malesuada a urna sit amet blandit. <br />Nullam nunc lorem, aliquam at eros laoreet, feugiat bibendum ligula. Aenean congue, massa id aliquet semper, ligula ante tristique nulla, quis posuere dui purus vel urna.<br />Nullam varius, magna nec egestas convallis, orci ex tempus quam, id finibus arcu ipsum fringilla purus.<br />Aenean dapibus suscipit eleifend. Aliquam vel ipsum eu lorem hendrerit iaculis vitae ut lorem. Suspendisse ullamcorper dolor faucibus sem auctor consequat.<br />Ut luctus posuere auctor. Sed non molestie metus.</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div >
        );
    }
};

const mapDispatchToProps = {  };

const mapStateToProps = ({ Auth }) => {
  let { lightMode } = Auth;
  return { lightMode };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsandConditions);