import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';

import './index.css';
import Navbar from '../../components/navbar';
import { toggleLoader } from "../../store/actions/Auth";
import { getSingleCollection, toggleSingleCollection } from "../../store/actions/Collection";

class CollectionDetails extends Component {

    constructor(props) {
        super(props);
        const query = require('url').parse(document.location.href, true);
        const collectionId = query.path.split('/')[--query.path.split('/').length];
        this.state = {
            logo: '',
            collectionLogo: '',
            banner: '',
            collectionBanner: '',
            collectionName: '',
            slug: '',
            description: '',
            category: '',
            websiteUrl: '',
            blockchain: '',
            contractAddress: '',

            categoryArray: ['Art', 'Gaming', 'Music', 'photography', 'Sports'],
        };
        props.getSingleCollection(collectionId);
        props.toggleSingleCollection(true);
    };

    componentDidMount() {
    };

    copied = () => EventBus.publish("info", 'Address copied');


    render() {
        let { lightMode, isSingleCollection, singleCollection } = this.props;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />

                {isSingleCollection
                    ? <div className="single-collection-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                    : <>
                        {/* BANNER IMAGE */}
                        <section className='banner-area collection-details-banner'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='banner-img'>
                                            {singleCollection['banner']
                                                ? <img src={singleCollection['banner']['url']} alt='' />
                                                : <img src={require('../../static/images/manage_account_bg.png')} alt='' />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* LOGO IMAGE */}
                        <section className='profile-img-area'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-12'>
                                        <div className='img-proflie'>
                                            {singleCollection['logo']
                                                ? <img src={singleCollection['logo']['url']} alt='' />
                                                : <img src={require('../../static/images/profile-img-edit.webp')} alt='' />
                                            }
                                        </div>
                                    </div>
                                    <div className='col-lg-9 col-md-12'>
                                        <div className='followers-sec'>
                                            <div className='followers-block'>
                                                <h5>{singleCollection['totalVolume'] ? singleCollection['totalVolume'] : '--'} ETH</h5>
                                                <p>Total Volume</p>
                                            </div>
                                            <div className='followers-block'>
                                                <h5>{singleCollection['floorPrice'] ? singleCollection['floorPrice'] : '--'} ETH</h5>
                                                <p>Floor Price</p>
                                            </div>
                                            <div className='followers-block'>
                                                <h5>{singleCollection['bestOffer'] ? singleCollection['bestOffer'] : '--'} ETH</h5>
                                                <p>Best Offer</p>
                                            </div>
                                            <div className='followers-block'>
                                                <h5>{singleCollection['listed'] ? singleCollection['listed'] : '--'} %</h5>
                                                <p>Listed</p>
                                            </div>
                                            <div className='followers-block'>
                                                <h5>{singleCollection['owners'] ? singleCollection['owners'] : '--'}</h5>
                                                <p>Owners</p>
                                            </div>
                                            <div className='followers-block'>
                                                <h5>{singleCollection['uniqueOwners'] ? singleCollection['uniqueOwners'] : '--'} %</h5>
                                                <p>Unique Owners</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className='profile-detail'>
                                            <div className='name-area'>
                                                <h4>{singleCollection['collectionName']}
                                                    <i className="icon">
                                                        {singleCollection['isVerified'] &&
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="22.153" height="23.078" viewBox="0 0 22.153 23.078">
                                                                <path id="Vector" d="M21.653,9.681l-1.506-1.75a2.454,2.454,0,0,1-.52-1.4V4.653A2.146,2.146,0,0,0,17.49,2.516H15.607a2.484,2.484,0,0,1-1.4-.52L12.463.49a2.231,2.231,0,0,0-2.79,0L7.934,2.007a2.554,2.554,0,0,1-1.4.509H4.623A2.146,2.146,0,0,0,2.486,4.653V6.547a2.52,2.52,0,0,1-.509,1.384L.482,9.692a2.23,2.23,0,0,0,0,2.768L1.977,14.22A2.52,2.52,0,0,1,2.486,15.6V17.5a2.146,2.146,0,0,0,2.137,2.137H6.539a2.484,2.484,0,0,1,1.4.52l1.75,1.506a2.231,2.231,0,0,0,2.79,0l1.75-1.506a2.454,2.454,0,0,1,1.4-.52H17.5A2.146,2.146,0,0,0,19.638,17.5V15.616a2.484,2.484,0,0,1,.52-1.4l1.506-1.75A2.246,2.246,0,0,0,21.653,9.681Zm-5.979-.7-5.348,5.348a.829.829,0,0,1-1.174,0l-2.68-2.68a.83.83,0,0,1,1.174-1.174l2.093,2.093L14.5,7.809a.83.83,0,0,1,1.174,1.174Z" transform="translate(0.008 0.926)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H22.153V22.153H0Z" transform="translate(22.153 22.153) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        }
                                                    </i>
                                                </h4>
                                            </div>
                                            <p className='profile-name'>
                                                {singleCollection['slug']}
                                                <CopyToClipboard onCopy={this.copied} text={singleCollection['contractAddress']}>
                                                    <button className='icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                            <g id="Group_2524" data-name="Group 2524" transform="translate(-710 -1)">
                                                                <path id="Path_4010" data-name="Path 4010" d="M718.988,9.947a3.635,3.635,0,0,0,5.481.393l2.181-2.181A3.634,3.634,0,0,0,721.51,3.02l-1.25,1.243" transform="translate(-2.669)" fill="none" stroke="#2d85ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                <path id="Path_4011" data-name="Path 4011" d="M719.681,10.425a3.633,3.633,0,0,0-5.48-.392l-2.181,2.181a3.634,3.634,0,0,0,5.139,5.139l1.242-1.243" transform="translate(0 -2.372)" fill="none" stroke="#2d85ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </CopyToClipboard>
                                            </p>
                                            <div className='profile-adress'>
                                                <p>Address: {singleCollection['contractAddress']}<br />
                                                    {singleCollection['description']}
                                                    {/* <span>...more</span> */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* SORT */}
                        <section className='sort-by-sec nft-tabs-sec '>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='filter-btn col-lg-6 col-md-4 col'>
                                        <Link to="#" className='sort-btn-left'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13">
                                                <g id="Group_2369" data-name="Group 2369" transform="translate(-4780 -1464)">
                                                    <rect id="Rectangle_773" data-name="Rectangle 773" width="20" height="3" transform="translate(4780 1464)" />
                                                    <rect id="Rectangle_774" data-name="Rectangle 774" width="13" height="3" transform="translate(4784 1469)" />
                                                    <rect id="Rectangle_775" data-name="Rectangle 775" width="4" height="3" transform="translate(4788 1474)" />
                                                </g>
                                            </svg>
                                        </Link>
                                    </div>
                                    <div className='sort-area col-lg-6 col-md-8 col'>
                                        <div className='sortby-inner'>
                                            <div className='sortby-dropdrown dropdown'>
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className='icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.512" height="16.512" viewBox="0 0 16.512 16.512">
                                                            <g id="vuesax_bold_setting-4" data-name="vuesax/bold/setting-4" opacity="0.4">
                                                                <g id="setting-4">
                                                                    <path id="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321A.479.479,0,0,1,4.8.482.479.479,0,0,1,4.321.963Z" transform="translate(10.334 4.252)" fill="#292d32" />
                                                                    <path id="Vector-2" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.482.482,0,0,1,0,.963Z" transform="translate(1.376 4.252)" fill="#292d32" />
                                                                    <path id="Vector-3" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(4.259 2.016)" fill="#292d32" />
                                                                    <path id="Vector-4" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.479.479,0,0,1,.482.482A.479.479,0,0,1,3.041.963Z" transform="translate(11.614 11.29)" fill="#292d32" />
                                                                    <path id="Vector-5" data-name="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321a.482.482,0,1,1,0,.963Z" transform="translate(1.376 11.29)" fill="#292d32" />
                                                                    <path id="Vector-6" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(6.818 9.061)" fill="#292d32" />
                                                                    <path id="Vector-7" data-name="Vector" d="M0,0H16.512V16.512H0Z" transform="translate(16.512 16.512) rotate(180)" fill="none" opacity="0" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </i>
                                                    Sort by
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" href="#">Sort by</a>
                                                    <a class="dropdown-item" href="#">Sort by</a>
                                                    <a class="dropdown-item" href="#">Sort by</a>
                                                </div>
                                            </div>
                                            <div className='right-sort-btn'>
                                                <button className='sort-btn'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="vuesax_bold_grid-2" data-name="vuesax/bold/grid-2" transform="translate(-748 -188)">
                                                            <g id="grid-2">
                                                                <path id="Vector" d="M9.25,0V9.25H5.81C2.17,9.25,0,7.08,0,3.44V0Z" transform="translate(750 200.75)" fill="#292d32" />
                                                                <path id="Vector-2" data-name="Vector" d="M9.25,5.81V9.25H0V0H3.44C7.08,0,9.25,2.17,9.25,5.81Z" transform="translate(760.75 190)" fill="#292d32" />
                                                                <path id="Vector-3" data-name="Vector" d="M9.25,0V9.25H0V5.81C0,2.17,2.17,0,5.81,0Z" transform="translate(750 190)" fill="#292d32" />
                                                                <path id="Vector-4" data-name="Vector" d="M9.25,0V3.44c0,3.64-2.17,5.81-5.81,5.81H0V0Z" transform="translate(760.75 200.75)" fill="#292d32" />
                                                                <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(772 212) rotate(180)" fill="none" opacity="0" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                                <button className='sort-btn'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="vuesax_bold_grid-1" data-name="vuesax/bold/grid-1" transform="translate(-437 -268)" opacity="0.5">
                                                            <g id="grid-1" transform="translate(9 16)">
                                                                <path id="Vector" d="M5.75,0V5.8H0C0,2.19,2.15.02,5.75,0Z" transform="translate(430 254)" fill="#292d32" />
                                                                <path id="Vector-2" data-name="Vector" d="M5.75,5.8H0V0C3.6.02,5.75,2.19,5.75,5.8Z" transform="translate(444.25 254)" fill="#292d32" />
                                                                <path id="Vector-3" data-name="Vector" d="M5.75,0C5.71,3.55,3.57,5.68,0,5.7V0Z" transform="translate(444.25 268.3)" fill="#292d32" />
                                                                <path id="Vector-4" data-name="Vector" d="M5.75,0V5.7C2.18,5.68.04,3.55,0,0Z" transform="translate(430 268.3)" fill="#292d32" />
                                                                <path id="Vector-5" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(430 261.3)" fill="#292d32" />
                                                                <path id="Vector-6" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(444.25 261.3)" fill="#292d32" />
                                                                <path id="Vector-7" data-name="Vector" d="M0,0H5.5V5.5H0Z" transform="translate(437.25 261.3)" fill="#292d32" />
                                                                <path id="Vector-8" data-name="Vector" d="M0,0H5.5V5.8H0Z" transform="translate(437.25 254)" fill="#292d32" />
                                                                <path id="Vector-9" data-name="Vector" d="M0,0H5.5V5.7H0Z" transform="translate(437.25 268.3)" fill="#292d32" />
                                                                <path id="Vector-10" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(452 276) rotate(180)" fill="none" opacity="0" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* POST's */}
                        <section className='nfts-sec collection-details-page'>
                            <div className='auto-container'>
                                <div className='row'>
                                    {singleCollection['posts'] && singleCollection['posts'].length > 0
                                        ? <>
                                            <div className='col-12'>
                                                <p>{singleCollection['posts'].length} posts</p>
                                                <h4>NFTs</h4>
                                            </div>
                                            {singleCollection['posts'].map(post => {
                                                return (
                                                    <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
                                                        <div className='nfts-block'>
                                                            <div className='img-box'>
                                                                {post['data']['format'] == 'jpg' || post['data']['format'] == 'jpeg' || post['data']['format'] == 'png'
                                                                    || post['data']['format'] == 'pnm' || post['data']['format'] == 'bmp' || post['data']['format'] == 'svg'
                                                                    ? <img src={post['data']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                    : post['data']['format'] == 'mp4' || post['data']['format'] == 'webm'
                                                                        ? <video width="500" autoPlay loop>
                                                                            <source src={post['data']['url']} type="video/mp4" />
                                                                        </video>
                                                                        : post['data']['format'] == 'mp3' || post['data']['format'] == 'mpeg' || post['data']['format'] == 'ogg'
                                                                            ? <audio controls>
                                                                                <source src={post['data']['url']} type="audio/mp3" />
                                                                            </audio>
                                                                            : <p className="text-center py-4">No Preview!</p>
                                                                }
                                                                {/* <div className='overlabox'>
                                                                    <button className='fav-heart'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                                            <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                                        </svg>
                                                                    </button>
                                                                </div> */}
                                                            </div>
                                                            <div className='text-box'>
                                                                <p>{post['description']}</p>
                                                                {post['owner'] &&
                                                                    <div className='user-detail'>
                                                                        <div className='img-box'><img src={post['owner']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' /></div>
                                                                        <div className='detail-box'><p>{post['owner']['name']}</p></div>
                                                                    </div>
                                                                }
                                                                {(post['forSale'] || post['isSponsored']) &&
                                                                    <div className='btn-area'>
                                                                        {post['forSale'] &&
                                                                            <button className='btn-style-one'>15 ETH</button>
                                                                        }
                                                                        {post['isSponsored'] &&
                                                                            <button className='btn-style-two'>Sponsored</button>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })



                                            }
                                        </>
                                        : <div className='col-12 d-flex justify-content-center'>
                                            <h4>No NFTs Posted</h4>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>


                        {/* Click flitter to show filtters defults hidden */}

                        {/* <section className='marketplace-filter'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-12'>
                                        <div className='siderbar-filter'>
                                            <nav className='navbar navbar-expand-lg sidenav' data-mdb-hidden="false" id="sidebar">
                                                <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                                    <ul class="list-unstyled components" id="navbar" >
                                                        <li>
                                                            <a href="#status" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Status</a>
                                                            <div class="collapse submenu-area" id="status" aria-expanded="true">
                                                                <ul class="submenu list-unstyled components">
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Buy Now</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>On Auction</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>New</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a href="#price" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Price</a>
                                                            <div class="collapse submenu-area" id="price" aria-expanded="true">
                                                                <ul class="submenu list-unstyled components">
                                                                    <li>
                                                                        <div className='group-from-inputs'>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value="USD"
                                                                                label="Age"
                                                                                variant="outlined"
                                                                            // onChange={handleChange}
                                                                            >
                                                                                <MenuItem>USD</MenuItem>
                                                                                <MenuItem>GBP</MenuItem>
                                                                                <MenuItem>PKR</MenuItem>
                                                                            </Select>
                                                                            <TextField id="min-basic" placeholder="Min" variant="outlined" />
                                                                            <span>To</span>
                                                                            <TextField id="max-basic" placeholder="Max" variant="outlined" />
                                                                        </div>
                                                                        <div className='group-from'>
                                                                            <button className='btn-style-one'>Apply</button>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a href="#collection" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Collections</a>
                                                            <div class="collapse submenu-area" id="collection" aria-expanded="true">
                                                                <ul class="submenu list-unstyled components">
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable><span className='icon'><img src={require('../../static/images/collection-1.png')} alt='' /></span>ShitBeast</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable><span className='icon'><img src={require('../../static/images/collection-2.png')} alt='' /></span>Just Ape</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable><span className='icon'><img src={require('../../static/images/collection-3.png')} alt='' /></span>Bored Ape Yacht</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable><span className='icon'><img src={require('../../static/images/collection-4.png')} alt='' /></span>Goblintown.wtf</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a href="#item-quantity" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Item quantity</a>
                                                            <div class="collapse submenu-area" id="item-quantity" aria-expanded="true">
                                                                <ul class="submenu list-unstyled components">
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>All items</lable>
                                                                            <Radio />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Single items</lable>
                                                                            <Radio />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Bundles</lable>
                                                                            <Radio />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a href="#category" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Category</a>
                                                            <div class="collapse submenu-area" id="category" aria-expanded="true">
                                                                <ul class="submenu list-unstyled components">
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Collectibles</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Art</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Music</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Domain Names</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Photography</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Trading Cards</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Virtual World</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>Utility</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a href="#onsale" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">On sale in</a>
                                                            <div class="collapse submenu-area" id="onsale" aria-expanded="true">
                                                                <ul class="submenu list-unstyled components">
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>ETH</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>WETH</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>APE</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>ASH</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>BAT</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>CUBE</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>DAI</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable>GALA</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <a href="#chains" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Chains</a>
                                                            <div class="collapse submenu-area" id="chains" aria-expanded="true">
                                                                <ul class="submenu list-unstyled components">
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable><span className='icon'><img src={require('../../static/images/chains-1.png')} alt='' /></span>Ethereum</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable><span className='icon'><img src={require('../../static/images/chains-2.png')} alt='' /></span>Klatyn</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable><span className='icon'><img src={require('../../static/images/chains-3.png')} alt='' /></span>Polygon</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='group-from'>
                                                                            <lable><span className='icon'><img src={require('../../static/images/chains-4.png')} alt='' /></span>Solana</lable>
                                                                            <Checkbox defaultChecked />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className='col-lg-9 col-md-12'>
                                        <section className='nfts-sec collection-details-page'>
                                            <div className='row'>
                                                {singleCollection['posts'] && singleCollection['posts'].length > 0
                                                    ? <>
                                                        <div className='col-12'>
                                                            <p>{singleCollection['posts'].length} posts</p>
                                                            <h4>NFTs</h4>
                                                        </div>
                                                        {singleCollection['posts'].map(post => {
                                                            return (
                                                                <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                                                                    <div className='nfts-block'>
                                                                        <div className='img-box'>
                                                                            {post['data']['format'] == 'jpg' || post['data']['format'] == 'jpeg' || post['data']['format'] == 'png'
                                                                                || post['data']['format'] == 'pnm' || post['data']['format'] == 'bmp' || post['data']['format'] == 'svg'
                                                                                ? <img src={post['data']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                : post['data']['format'] == 'mp4' || post['data']['format'] == 'webm'
                                                                                    ? <video width="500" autoPlay loop>
                                                                                        <source src={post['data']['url']} type="video/mp4" />
                                                                                    </video>
                                                                                    : post['data']['format'] == 'mp3' || post['data']['format'] == 'mpeg' || post['data']['format'] == 'ogg'
                                                                                        ? <audio controls>
                                                                                            <source src={post['data']['url']} type="audio/mp3" />
                                                                                        </audio>
                                                                                        : <p className="text-center py-4">No Preview!</p>
                                                                            }
                                                                            <div className='overlabox'>
                                                                    <button className='fav-heart'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                                            <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                        </div>
                                                                        <div className='text-box'>
                                                                            <p>{post['description']}</p>
                                                                            {post['owner'] &&
                                                                                <div className='user-detail'>
                                                                                    <div className='img-box'><img src={post['owner']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' /></div>
                                                                                    <div className='detail-box'><p>{post['owner']['name']}</p></div>
                                                                                </div>
                                                                            }
                                                                            {(post['forSale'] || post['isSponsored']) &&
                                                                                <div className='btn-area'>
                                                                                    {post['forSale'] &&
                                                                                        <button className='btn-style-one'>15 ETH</button>
                                                                                    }
                                                                                    {post['isSponsored'] &&
                                                                                        <button className='btn-style-two'>Sponsored</button>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })



                                                        }
                                                    </>
                                                    : <div className='col-12 d-flex justify-content-center'>
                                                        <h4>No NFTs Posted</h4>
                                                    </div>
                                                }
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                    </>
                }
            </div>
        );
    }
}
const mapDispatchToProps = { getSingleCollection, toggleSingleCollection, toggleLoader };

const mapStateToProps = ({ Auth, Collection }) => {
    let { lightMode, } = Auth;
    let { singleCollection, isSingleCollection } = Collection;
    return { lightMode, singleCollection, isSingleCollection };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDetails);