import { eventChannel } from 'redux-saga';
import { take, call, put, } from 'redux-saga/effects';

import { socket } from '../config';
import {
    setAllActiveFriends, setAllChatHistory, allChatHistoryLoader, setSingleChatHistory, singleChatHistoryLoader,
    sendChatLoader, chatMessageSent, chatMessageReceived, setActiveStatus
} from '../actions/Chat';

const userID = localStorage.getItem('userID');

const createSocket = socket => eventChannel((emit) => {

    //********** CHAT SOCKETS **********//

    socket.on(`active:friends:${userID}`, (data) => {
        emit(setAllActiveFriends(data));
    });

    socket.on(`is:Online:${userID}`, (data) => {
        emit(setActiveStatus(data));
    });

    socket.on(`chat:history:all:${userID}`, (data) => {
        emit(setAllChatHistory(data));
        emit(allChatHistoryLoader(false));
    });

    socket.on(`chat:history:single:${userID}`, (data) => {
        emit(setSingleChatHistory(data['history']));
        emit(singleChatHistoryLoader(false));
    });

    socket.on(`chat:message:sent:${userID}`, (data) => {
        emit(sendChatLoader(false));
        emit(chatMessageSent(data['messageObj']));
    });

    socket.on(`chat:message:received:${userID}`, (data) => {
        emit(chatMessageReceived(data['messageObj']));
    });


    return () => {
        // socket.off(`all_prices`, () => emit());
    }
});

export default function* listenSockets() {
    const socketChannel = yield call(createSocket, socket);
    while (true) {
        let action = yield take(socketChannel);
        if (action) yield put(action);
    }
};
