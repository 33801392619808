import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomeScreen from './HomeScreen';
import Messaging from './Messaging';
import Profile from './Profile';
import PostDetail from './PostDetail';
import DiscoverScreen from './DiscoverScreen';
import Marketplace from './Marketplace';
import MarketplaceFilter from './MarketplaceFilter';
import UploadPost from './UploadPost';
import Analytics from './Analytics';
import SponsorNfts from './SponsorNfts';
import SponsoredDetails from './SponsoredDetails';
import Privacy from './Privacy';
import ContactUs from './ContactUs';
import ManageAccount from './ManageAccount';
import MyWallet from './MyWallet';
import AddCard from './AddCard';
import PrivacyPolicy from './PrivacyPolicy';
import TermsandConditions from './TermsandConditions';
import BlockedAccounts from './BlockedAccounts';
import DeleteAccount from './DeleteAccount';
import CreateNewPassword from './CreateNewPassword';
import Forget from './Forget';
import ResetPass from './ResetPass';
import Login from './Login';
import SignupBasic from './Signup/SignupBasic.js';
import SplashScreen from './SplashScreen';
import Verification from './Verification';
import CreateNewCollection from './CreateNewCollection';
import MyCollections from './MyCollections';
import EditCollection from './EditCollection';
import CollectionDetails from './CollectionDetails';
import CreateNotableDrop from './CreateNotableDrop';
import NotableDropsDetails from './NotableDropsDetails';
import NotableDropsDetails3D from './NotableDropsDetails3D';
import PlaceaBid from './PlaceaBid';
import Loader from '../components/loader';
import PrivateRoute from "../store/PrivateRoute";
import { logout } from '../store/actions/Auth';

import '../static/css/style.css';
import 'cropperjs/dist/cropper.css';
import '../static/css/animate.css';
import 'jquery/dist/jquery.min.js';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";
import 'owl.carousel/dist/assets/owl.carousel.css';

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };


  render() {

    return (
      <div>
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => <HomeScreen {...props} />} />
            <Route exact path='/homeScreen' component={props => <HomeScreen {...props} />} />
            <Route exact path='/discoverScreen' component={props => <DiscoverScreen {...props} />} />
            <Route exact path='/marketplace' component={props => <Marketplace {...props} />} />
            <Route exact path='/marketplaceFilter' component={props => <MarketplaceFilter {...props} />} />
            <Route exact path='/addCard' component={props => <AddCard {...props} />} />
            <Route exact path='/privacyPolicy' component={props => <PrivacyPolicy {...props} />} />
            <Route exact path='/termsAndConditions' component={props => <TermsandConditions {...props} />} />
            <Route exact path='/analytics' component={props => <Analytics {...props} />} />
            <Route exact path='/sponsorNFTs' component={props => <SponsorNfts {...props} />} />
            <Route exact path='/sponsoredDetails' component={props => <SponsoredDetails {...props} />} />
            <Route exact path='/privacy' component={props => <Privacy {...props} />} />
            <Route exact path='/contactUs' component={props => <ContactUs {...props} />} />
            <Route exact path='/createnotabledrop' component={props => <CreateNotableDrop {...props} />} />
            <Route exact path='/notabledropsdetails/:id' component={props => <NotableDropsDetails {...props} />} />
            <Route exact path='/notabledropsdetails3d' component={props => <NotableDropsDetails3D {...props} />} />
            <Route exact path='/placeabid' component={props => <PlaceaBid {...props} />} />

            <PrivateRoute exact path='/messaging/:id' component={props => <Messaging {...props} />} />
            <PrivateRoute exact path='/profile/:id' component={props => <Profile {...props} />} />
            <PrivateRoute exact path='/postDetail/:id' component={props => <PostDetail {...props} />} />
            <PrivateRoute exact path='/uploadPosts' component={props => <UploadPost {...props} />} />
            <PrivateRoute exact path='/manageAccount' component={props => <ManageAccount {...props} />} />
            <PrivateRoute exact path='/myWallet' component={props => <MyWallet {...props} />} />
            <PrivateRoute exact path='/blockedAccounts' component={props => <BlockedAccounts {...props} />} />
            <PrivateRoute exact path='/deleteAccount' component={props => <DeleteAccount {...props} />} />
            <PrivateRoute exact path='/createNewPassword' component={props => <CreateNewPassword {...props} />} />
            <PrivateRoute exact path='/forget' component={props => <Forget {...props} />} />
            <PrivateRoute exact path='/login' component={props => <Login {...props} />} />
            <PrivateRoute exact path='/resetPass' component={props => <ResetPass {...props} />} />
            <PrivateRoute exact path='/signUp' component={props => <SignupBasic {...props} />} />
            <PrivateRoute exact path='/verification' component={props => <Verification {...props} />} />
            <PrivateRoute exact path='/splashScreen' component={props => <SplashScreen {...props} />} />
            <PrivateRoute exact path='/createNewCollection' component={props => <CreateNewCollection {...props} />} />
            <PrivateRoute exact path='/myCollections' component={props => <MyCollections {...props} />} />
            <PrivateRoute exact path='/editCollection/:id' component={props => <EditCollection {...props} />} />
            <PrivateRoute exact path='/collectionDetails/:id' component={props => <CollectionDetails {...props} />} />
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout,
};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(App);