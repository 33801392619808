import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";
import { getSingleCollection, toggleSingleCollection, editCollection } from "../../store/actions/Collection";

class EditCollection extends Component {

    constructor(props) {
        super(props);
        const query = require('url').parse(document.location.href, true);
        const collectionId = query.path.split('/')[--query.path.split('/').length];
        this.state = {
            logo: '',
            collectionLogo: '',
            banner: '',
            collectionBanner: '',
            collectionName: '',
            slug: '',
            description: '',
            category: '',
            websiteUrl: '',
            blockchain: '',
            contractAddress: '',

            categoryArray: ['Art', 'Gaming', 'Music', 'photography', 'Sports'],
        };
        props.getSingleCollection(collectionId);
        props.toggleSingleCollection(true);
    };

    async componentWillReceiveProps({ singleCollection }) {
        if (singleCollection) {
            if (singleCollection['logo']) this.setState({ collectionLogo: singleCollection['logo']['url'] });
            if (singleCollection['banner']) this.setState({ collectionBanner: singleCollection['banner']['url'] });
            if (singleCollection['collectionName']) this.setState({ collectionName: singleCollection['collectionName'] });
            if (singleCollection['slug']) this.setState({ slug: singleCollection['slug'] });
            if (singleCollection['description']) this.setState({ description: singleCollection['description'] });
            if (singleCollection['category']) this.setState({ category: singleCollection['category'] });
            if (singleCollection['websiteUrl']) this.setState({ websiteUrl: singleCollection['websiteUrl'] });
            if (singleCollection['blockchain']) this.setState({ blockchain: singleCollection['blockchain'] });
            if (singleCollection['contractAddress']) this.setState({ contractAddress: singleCollection['contractAddress'] });
        }
    }

    setLogoImage = async (e) => {
        let { logo, collectionLogo } = this.state;
        if (e.currentTarget.files[0]) {
            collectionLogo = URL.createObjectURL(e.currentTarget.files[0]);
            let res = await fetch(collectionLogo);
            let blob = await res.blob();
            logo = new File([blob], "Collection Logo", { type: "image/png" });
            if (logo['size'] <= 5000000) this.setState({ logo, collectionLogo });
            else EventBus.publish("error", "File Size is more then 5MB");
        }
    };

    setBannerImage = async (e) => {
        let { banner, collectionBanner } = this.state;
        if (e.currentTarget.files[0]) {
            collectionBanner = URL.createObjectURL(e.currentTarget.files[0]);
            let res = await fetch(collectionBanner);
            let blob = await res.blob();
            banner = new File([blob], "Collection Banner", { type: "image/png" });
            if (banner['size'] <= 5000000) this.setState({ banner, collectionBanner });
            else EventBus.publish("error", "File Size is more then 5MB");
        }
    };

    handleFormChange = ({ target }) => this.setState({ [target.name]: target.value });

    handleUpateCollection = async () => {
        try {
            let { publicAddress, singleCollection } = this.props;
            let { logo, banner, collectionName, description, slug, category, websiteUrl, blockchain, contractAddress } = this.state;

            if (collectionName == '') {
                EventBus.publish('error', `Please Enter the Collection Name`);
                return;
            };

            if (slug == '') {
                EventBus.publish('error', `Please Enter the Collection Slug`);
                return;
            };

            let data = new FormData();

            data.append('logo', logo);
            data.append('banner', banner);
            data.append('collectionName', collectionName);
            data.append('description', description);
            data.append('slug', slug);
            data.append('category', category);
            data.append('websiteUrl', websiteUrl);
            data.append('blockchain', blockchain);
            data.append('payoutAddress', publicAddress);
            data.append('contractAddress', contractAddress);

            this.props.toggleLoader({ message: 'Editing Collection...', status: true });
            this.props.editCollection({ data, id: singleCollection['_id'] })

        } catch (e) {
            console.log('********Error = ', e);
            this.props.toggleLoader({ message: 'Collection not edited...', status: false });
            EventBus.publish('error', `Unable to Edit Collection`);
        };
    };

    render() {

        let { lightMode, isSingleCollection } = this.props;
        let { collectionLogo, collectionBanner, collectionName, slug, description, category, websiteUrl, categoryArray, } = this.state;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec editcollection-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='left-sidebar-area col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                {isSingleCollection
                                    ? <div className="single-collection-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                    : <div className='contact-form create-collection-page edit-collection-page'>
                                        <h2>Edit Collections</h2>
                                        <h4>Edit Logo</h4>
                                        <div className='img-upload-area'>
                                            <div className='img-box'>
                                                <img src={collectionLogo || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                <div className='ovalybox'>
                                                    <div className='input-file'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                            <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                        </svg>
                                                        <input
                                                            type='file'
                                                            name='avatar'
                                                            id='contained-button-file'
                                                            className='custom-file-input'
                                                            accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                            onChange={this.setLogoImage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-box'>
                                                <h5>Select a file or drag and drop here</h5>
                                                <p>JPG , PNG or SVG file size no more then 1MB</p>
                                                <div className='input-file'>
                                                    <input
                                                        type='file'
                                                        name='avatar'
                                                        id='contained-button-file'
                                                        className='custom-file-input'
                                                        accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                        onChange={this.setLogoImage}
                                                    />
                                                    <span className='btn-style-two'>Select File</span>
                                                </div>
                                            </div>
                                        </div>
                                        <h4>Edit Banner</h4>
                                        <div className='nft-upload-btn-area'>
                                            <div className='upload-btn'>
                                                <div className='input-area input-after-img'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                        <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                    </svg>
                                                    <img className='banner-img-show' src={collectionBanner || require('../../static/images/manage_account_bg.png')} alt='' />
                                                    <input
                                                        type='file'
                                                        id='contained-button-file'
                                                        className='custom-file-input'
                                                        accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                        onChange={this.setBannerImage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-area'>
                                            <ValidatorForm className="validatorForm" >
                                                <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields" item xs={12}>
                                                        <label>Name*</label>
                                                        <CustomTextField
                                                            fullWidth
                                                            type="text"
                                                            margin="dense"
                                                            variant="outlined"
                                                            name="collectionName"
                                                            value={collectionName}
                                                            className="MyTextField"
                                                            placeholder="Type collection name"
                                                            onChange={this.handleFormChange}
                                                            validators={['required']}
                                                            errorMessages={['Please add collection name']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields" item xs={12}>
                                                        <label>Symbol*</label>
                                                        <CustomTextField
                                                            fullWidth
                                                            type="text"
                                                            margin="dense"
                                                            name="slug"
                                                            value={slug}
                                                            variant="outlined"
                                                            className="MyTextField"
                                                            placeholder="Symbol of collection"
                                                            onChange={this.handleFormChange}
                                                            validators={['required']}
                                                            errorMessages={['Please add collection slug']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields" item xs={12}>
                                                        <label>Description</label>
                                                        <CustomTextField
                                                            fullWidth
                                                            multiline
                                                            type="text"
                                                            margin="dense"
                                                            name="description"
                                                            variant="outlined"
                                                            className="MyTextField"
                                                            value={description}
                                                            onChange={this.handleFormChange}
                                                            placeholder="Here is the description of your collection"
                                                        />
                                                        <span className='crechter-counter'>0/100 Max</span>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields" item xs={12}>
                                                        <label>Category & Tag</label>
                                                        <Select
                                                            name="category"
                                                            value={category}
                                                            variant="outlined"
                                                            onChange={this.handleFormChange}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            {categoryArray.map(category => {
                                                                return (
                                                                    <MenuItem value={category}>
                                                                        <lable>{category}</lable>
                                                                    </MenuItem>
                                                                )
                                                            })
                                                            }
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields" item xs={12}>
                                                        <label>Social Links</label>
                                                        <CustomTextField
                                                            fullWidth
                                                            type="url"
                                                            margin="dense"
                                                            name="websiteUrl"
                                                            variant="outlined"
                                                            value={websiteUrl}
                                                            className="MyTextField"
                                                            placeholder="http://.yuorsite.io"
                                                            onChange={this.handleFormChange}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <div className='group-form btn-group'>
                                                    <button onClick={this.handleUpateCollection} className='btn-style-one'>Update</button>
                                                </div>
                                            </ValidatorForm>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}
const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);


const mapDispatchToProps = { getSingleCollection, toggleSingleCollection, editCollection, toggleLoader };

const mapStateToProps = ({ Auth, Collection }) => {
    let { lightMode, } = Auth;
    let { singleCollection, isSingleCollection } = Collection;
    return { lightMode, singleCollection, isSingleCollection };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCollection);