import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { HashLink } from 'react-router-hash-link';
import { styled } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import { switchNetwork } from '../../store/web3';
import { TokenABI } from '../../store/contract/index';
import { toggleLoader } from "../../store/actions/Auth";
import { getMyCollections } from "../../store/actions/Collection";
import { chainId, hostName, networkName } from '../../store/config';
import { uploadPost, uploadPostLoader } from "../../store/actions/Post";

class UploadPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            collection: {},
            allowComments: true,
            privacy: 'Public',
            nft: '',
            imageNFT: '',
            videoNFT: '',
            audioNFT: '',
        };
        props.getMyCollections();
    };

    componentWillReceiveProps({ isNFTUplaoded }) {
        if (!isNFTUplaoded) {
            this.setState({ name: '', description: '', collection: {}, allowComments: true, privacy: 'Public', nft: '', imageNFT: '', videoNFT: '', audioNFT: '' });
        }
    };

    setFileNFT = async (e) => {
        this.setState({ nft: '', imageNFT: '', videoNFT: '', audioNFT: '' });

        let { nft } = this.state;
        if (e.currentTarget.files[0]) {
            let type = e.currentTarget.files[0]['type'];
            let fileNFT = window.URL.createObjectURL(e.currentTarget.files[0]);

            let res = await fetch(fileNFT);
            let blob = await res.blob();
            nft = new File([blob], "NFT File", { type });
            if (nft['size'] <= 10000000) {
                if (type.includes('image')) this.setState({ nft, imageNFT: fileNFT });
                else if (type.includes('video')) this.setState({ nft, videoNFT: fileNFT });
                else this.setState({ nft, audioNFT: fileNFT });
            }
            else EventBus.publish("error", "File Size is more then 10MB");
        }
    };

    handleFormChange = async ({ target }) => {
        if (target.name == 'blockchain') {
            if (target.value == 'Goerli') {
                let chainId = '0x5';
                let hostName = 'goerli';
                let networkName = 'Goerli test network';
                await switchNetwork({ chainId, hostName, networkName });
            }
            else await switchNetwork({ chainId, hostName, networkName });
        }

        this.setState({ [target.name]: target.value });
    };

    handleCreateNFT = async () => {
        try {
            let { publicAddress, history } = this.props;
            let { name, description, collection, allowComments, privacy, nft, imageNFT, videoNFT, audioNFT, } = this.state;

            const Token = await new web3.eth.Contract(TokenABI, collection['contractAddress']);
            const mintAmount = 1;

            if (imageNFT == '' && videoNFT == '' && audioNFT == '') {
                EventBus.publish('error', `Please select the NFT`);
                return;
            };

            if (name == '') {
                EventBus.publish('error', `Please enter the NFT name`);
                return;
            };

            if (collection == {}) {
                EventBus.publish('error', `Please select the collection`);
                return;
            };

            const balance = await web3.eth.getBalance(publicAddress);
            if (balance == 0) {
                EventBus.publish('error', `Your have insufficient balance`);
                return;
            };

            let data = new FormData();
            data.append('nft', nft);
            data.append('name', name);
            data.append('description', description);
            data.append('collectionId', collection['_id']);
            data.append('allowComments', allowComments);
            data.append('privacy', privacy);

            /** Create Collection **/
            this.props.toggleLoader({ message: 'Creating New NFT...', status: true });
            await web3.eth.sendTransaction({
                from: publicAddress,
                value: 0,
                to: collection['contractAddress'],
                data: Token.methods.mint(publicAddress, mintAmount).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    this.props.uploadPost({ data, history });
                    this.props.uploadPostLoader(true);
                })
        } catch (e) {
            console.log('********Error = ', e);
            this.props.toggleLoader({ message: 'NFT not Created...', status: false });
            EventBus.publish('error', `Unable to Create NFT`);
        };
    };

    render() {

        let { lightMode, myCollections } = this.props;
        let { name, description, collection, allowComments, privacy, imageNFT, videoNFT, audioNFT } = this.state;

        return (
            <div className={`nfity-page niftyupload-nft ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='nft-upload-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sec-title'>
                                    <h2>Create New NFT</h2>
                                    <p>Lets upload a new items.</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                <div className='nft-upload-btn-area'>
                                    <div className='upload-btn'>
                                        <div className='image-area'>
                                            <img src={require('../../static/images/nft-upload.png')} alt='' />
                                        </div>
                                        <div className='input-area'>
                                            {imageNFT == '' && videoNFT == '' && audioNFT == ''
                                                ? <>
                                                    <img src={require('../../static/images/upload-ion.png')} alt='' />
                                                    <div className='content-area'>
                                                        <h5>Image, Video or Audio</h5>
                                                        <p>File types supported: JPG, PNG, GIF<br />
                                                            SVG, MP4, WEBM, MP3, OGG<br />
                                                            Max size: 10 MB</p>
                                                        <Link className='ordrag-area' to="#">Or drag and drop a file</Link>
                                                    </div>
                                                    <button class="btn-style-one">Select file</button>
                                                    <input
                                                        type='file'
                                                        accept="audio/*,video/*,image/*"
                                                        className='custom-file-input'
                                                        name='avatar'
                                                        id='contained-button-file'
                                                        onChange={this.setFileNFT}
                                                    />
                                                </>
                                                : <>
                                                    {imageNFT &&
                                                        <div className='input-area input-after-img'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                                <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                            </svg>
                                                            <img className='img-show' src={imageNFT} alt='' />
                                                            <input
                                                                type='file'
                                                                id='contained-button-file'
                                                                className='custom-file-input'
                                                                accept="audio/*,video/*,image/*"
                                                                onChange={this.setFileNFT}
                                                            />
                                                        </div>
                                                    }
                                                    {videoNFT &&
                                                        <div className='input-area input-after-img'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                                <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                            </svg>
                                                            <video width="400" autoPlay loop>
                                                                <source src={videoNFT} type="video/mp4" />
                                                            </video>
                                                            <input
                                                                type='file'
                                                                id='contained-button-file'
                                                                className='custom-file-input'
                                                                accept="audio/*,video/*,image/*"
                                                                onChange={this.setFileNFT}
                                                            />
                                                        </div>
                                                    }
                                                    {audioNFT &&
                                                        <div className='input-area input-after-img'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                                <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                            </svg>
                                                            <audio controls>
                                                                <source src={audioNFT} type="audio/mp3" />
                                                            </audio>
                                                            <input
                                                                type='file'
                                                                id='contained-button-file'
                                                                className='custom-file-input'
                                                                accept="audio/*,video/*,image/*"
                                                                onChange={this.setFileNFT}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-12'>
                                <div className='form-area'>
                                    <ValidatorForm className="validatorForm" >
                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <label>Name</label>
                                                <CustomTextField
                                                    fullWidth
                                                    name="name"
                                                    type="text"
                                                    value={name}
                                                    margin="dense"
                                                    variant="outlined"
                                                    className="MyTextField"
                                                    placeholder="Type name here…"
                                                    onChange={this.handleFormChange}
                                                    validators={['required']}
                                                    errorMessages={['Please add Your Name']}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <label>Description</label>
                                                <CustomTextField
                                                    fullWidth
                                                    multiline
                                                    type="text"
                                                    margin="dense"
                                                    variant="outlined"
                                                    name="description"
                                                    value={description}
                                                    className="MyTextField"
                                                    onChange={this.handleFormChange}
                                                    placeholder="Write something about your NFT…"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <label>Collections</label>
                                                {myCollections.length > 0
                                                    ? <Select
                                                        displayEmpty
                                                        name="collection"
                                                        className='collection-dropdown'
                                                        variant="outlined"
                                                        onChange={this.handleFormChange}
                                                        value={collection['collectionName']}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {myCollections.map(collection => {
                                                            return (
                                                                <MenuItem value={collection}>
                                                                    <lable>{collection['collectionName']}</lable>
                                                                </MenuItem>
                                                            )
                                                        })
                                                        }
                                                    </Select>
                                                    : <div className='allow-comments'>
                                                        <p>You don't have any collection</p>
                                                    </div>
                                                }
                                            </Grid>
                                        </Grid>
                                        {myCollections.length > 0 &&
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Blockchain</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="text"
                                                        margin="dense"
                                                        disabled={true}
                                                        value={collection['blockchain']}
                                                        variant="outlined"
                                                        className="MyTextField"
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <HashLink className='hashlinks-prod'>#Hashtags</HashLink>
                                                <HashLink className='hashlinks-prod'>@Friends</HashLink>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <div className='allow-comments'>
                                                    <p>Allow Comments</p>
                                                    <AntSwitch
                                                        checked={allowComments}
                                                        onChange={() => this.setState({ allowComments: !allowComments })}
                                                        inputProps={{ 'aria-label': 'ant design' }} />
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <label>Privacy Settings</label>
                                                <Select
                                                    name="privacy"
                                                    value={privacy}
                                                    variant="outlined"
                                                    onChange={this.handleFormChange}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="Public">Public</MenuItem>
                                                    <MenuItem value="Private">Private</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        {myCollections.length > 0
                                            ? <div className='group-form btn-group'>
                                                {/* <button className='btn-style-two'>Draft</button> */}
                                                <button onClick={this.handleCreateNFT} className='btn-style-one'>Post</button>
                                            </div>
                                            : <div className='d-flex flex-column align-items-center'>
                                                <p>Create a collection to create new NFT</p>
                                                <Link className="btn-style-one" to={`/createNewCollection`}>Create Collections</Link>
                                            </div>
                                        }
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}
const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const mapDispatchToProps = { toggleLoader, getMyCollections, uploadPost, uploadPostLoader };

const mapStateToProps = ({ Auth, Collection, Post }) => {
    let { isNFTUplaoded } = Post;
    let { myCollections } = Collection;
    let { lightMode, publicAddress } = Auth;
    return { lightMode, publicAddress, myCollections, isNFTUplaoded };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPost);