import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";
import { createNotableDrop, notableDropLoader } from "../../store/actions/Drop";

class CreateNotableDrop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: '',
            logoImage: '',
            file: '',
            imageNotableDrop: '',
            videoNotableDrop: '',
            audioNotableDrop: '',
            projectName: '',
            description: '',
            discordUrl: '',
            twitterUrl: '',
            websiteUrl: '',
            fromDate: new Date(Date.now()),
            toDate: new Date(Date.now() + (3600 * 1000 * 24)),
        };
    };

    componentWillReceiveProps({ isNotableDrop }) {
        if (!isNotableDrop) this.setState({
            logo: '', logoImage: '', file: '', imageNotableDrop: '', videoNotableDrop: '', audioNotableDrop: '', projectName: '',
            description: '', discordUrl: '', twitterUrl: '', websiteUrl: '', fromDate: new Date(Date.now()), toDate: new Date(Date.now() + (3600 * 1000 * 24))
        })
    };

    setNotableDropBanner = async (e) => {
        this.setState({ file: '', imageNotableDrop: '', videoNotableDrop: '', audioNotableDrop: '' });

        let { file } = this.state;
        if (e.currentTarget.files[0]) {
            let type = e.currentTarget.files[0]['type'];
            let fileNotableDrop = URL.createObjectURL(e.currentTarget.files[0]);
            let res = await fetch(fileNotableDrop);
            let blob = await res.blob();
            file = new File([blob], "file", { type });
            if (file['size'] <= 10000000) {
                if (type.includes('image')) this.setState({ file, imageNotableDrop: fileNotableDrop });
                else if (type.includes('video')) this.setState({ file, videoNotableDrop: fileNotableDrop });
                else this.setState({ file, audioNotableDrop: fileNotableDrop });
            }
            else EventBus.publish("error", "File Size is more then 10MB");
        }
    };

    setNotableDroplogo = async (e) => {
        let { logo, logoImage } = this.state;
        if (e.currentTarget.files[0]) {
            logoImage = URL.createObjectURL(e.currentTarget.files[0]);
            let res = await fetch(logoImage);
            let blob = await res.blob();
            logo = new File([blob], "logo", { type: "image/png" });
            if (logo['size'] <= 5000000) this.setState({ logo, logoImage });
            else EventBus.publish("error", "File Size is more then 5MB");
        }
    };

    handleFormChange = async ({ target }) => this.setState({ [target.name]: target.value });

    postNotableDrop = () => {
        let { file, imageNotableDrop, videoNotableDrop, audioNotableDrop, logo, projectName, description, discordUrl, twitterUrl, websiteUrl, fromDate, toDate } = this.state;

        if (imageNotableDrop == '' && videoNotableDrop == '' && audioNotableDrop == '') {
            EventBus.publish('error', `Please select the file`);
            return;
        };

        if (projectName == '') {
            EventBus.publish('error', `Please enter the project name`);
            return;
        };

        if (fromDate > toDate) {
            EventBus.publish('error', `From Date can't be greater then To Date`);
            return;
        };

        let data = new FormData();
        data.append('file', file);
        data.append('logo', logo);
        data.append('projectName', projectName);
        data.append('description', description);
        data.append('discordUrl', discordUrl);
        data.append('twitterUrl', twitterUrl);
        data.append('websiteUrl', websiteUrl);
        data.append('fromDate', fromDate);
        data.append('toDate', toDate);

        this.props.toggleLoader({ message: 'Creating Notable Drop...', status: true });
        this.props.notableDropLoader(true);
        this.props.createNotableDrop(data);
    };

    render() {

        let { lightMode } = this.props;
        let { imageNotableDrop, videoNotableDrop, audioNotableDrop, logoImage, projectName, description, discordUrl, twitterUrl,
            websiteUrl, fromDate, toDate } = this.state;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec notbale-drop-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='left-sidebar-area col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                <div className='contact-form create-collection-page'>
                                    <h2>Create Notable Drop</h2>
                                    <p>Lets upload a new items.</p>

                                    <div className='nft-upload-btn-area'>
                                        <div className='upload-btn'>
                                            <div className='image-area'>
                                                <img src={require('../../static/images/nft-upload.png')} alt='' />
                                            </div>
                                            {imageNotableDrop == '' && videoNotableDrop == '' && audioNotableDrop == ''
                                                ? <div className='input-area'>
                                                    <img src={require('../../static/images/upload-ion.png')} alt='' />
                                                    <div className='content-area'>
                                                        <h5>Image, Video or Audio</h5>
                                                        <p>File types supported: JPG, PNG, GIF<br />
                                                            MP4, WEBM, MP3, WAV. Max size: 10 MB</p>
                                                        <Link className='ordrag-area' to="#">Or drag and drop a file</Link>
                                                    </div>
                                                    <button className="btn-style-one">Select file</button>
                                                    <input
                                                        type='file'
                                                        id='contained-button-file'
                                                        className='custom-file-input'
                                                        accept="audio/*,video/*,image/*"
                                                        onChange={this.setNotableDropBanner}
                                                    />
                                                </div>
                                                : <>
                                                    {imageNotableDrop &&
                                                        <div className='input-area input-after-img'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                                <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                            </svg>
                                                            <img className='banner-img' src={imageNotableDrop} alt='' />
                                                            <input
                                                                type='file'
                                                                id='contained-button-file'
                                                                className='custom-file-input'
                                                                accept="audio/*,video/*,image/*"
                                                                onChange={this.setNotableDropBanner}
                                                            />
                                                        </div>
                                                    }
                                                    {videoNotableDrop &&
                                                        <div className='input-area input-after-img'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                                <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                            </svg>
                                                            <video width="700" autoPlay loop>
                                                                <source src={videoNotableDrop} type="video/mp4" />
                                                            </video>
                                                            <input
                                                                type='file'
                                                                id='contained-button-file'
                                                                className='custom-file-input'
                                                                accept="audio/*,video/*,image/*"
                                                                onChange={this.setNotableDropBanner}
                                                            />
                                                        </div>
                                                    }
                                                    {audioNotableDrop &&
                                                        <div className='input-area input-after-img'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                                <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                            </svg>
                                                            <audio controls>
                                                                <source src={audioNotableDrop} type="audio/mp3" />
                                                            </audio>
                                                            <input
                                                                type='file'
                                                                id='contained-button-file'
                                                                className='custom-file-input'
                                                                accept="audio/*,video/*,image/*"
                                                                onChange={this.setNotableDropBanner}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className='add-btn'>
                                        <button className="btn-style-one">Add New</button>
                                    </div>
                                    <h4>Upload Logo</h4>
                                    < div className='img-upload-area'>
                                        <div className='img-box'>
                                            {logoImage == ''
                                                ? <img src={require('../../static/images/place-img.png')} alt='' />
                                                : <img className='logo-img' src={logoImage} alt='' />
                                            }
                                        </div>
                                        <div className='text-box'>
                                            <h5>Select a file or drag and drop here</h5>
                                            <p>JPG , PNG or SVG file size no more then 1MB</p>
                                            <div className='input-file'>
                                                <input
                                                    type='file'
                                                    name='avatar'
                                                    id='contained-button-file'
                                                    className='custom-file-input'
                                                    accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                    onChange={this.setNotableDroplogo}
                                                />
                                                <span className='btn-style-two'>Select File</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-area'>
                                        <ValidatorForm className="validatorForm" onSubmit={this.postNotableDrop}>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Project Name</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="text"
                                                        margin="dense"
                                                        variant="outlined"
                                                        name="projectName"
                                                        value={projectName}
                                                        className="MyTextField"
                                                        placeholder="Type name here…"
                                                        onChange={this.handleFormChange}
                                                        validators={['required']}
                                                        errorMessages={['Please add project name']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input textarea-group" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Description</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        multiline
                                                        type="text"
                                                        margin="dense"
                                                        name="description"
                                                        variant="outlined"
                                                        className="MyTextField"
                                                        value={description}
                                                        onChange={this.handleFormChange}
                                                        placeholder="Here is the description of your notable drop"
                                                    />
                                                    <span className='crechter-counter'>0/100 Max</span>
                                                </Grid>
                                            </Grid>

                                            <Grid className="input-fields" item xs={12}>
                                                <label>From Date</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        fullWidth
                                                        className='MyTextField'
                                                        name="fromDate"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        format="dd/MM/yyyy"
                                                        value={fromDate}
                                                        onChange={(date) => this.setState({ fromDate: date })}
                                                        KeyboardButtonProps={{ 'aria-label': 'Date of Birth' }}
                                                    />
                                                    <span className='icon-date'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19">
                                                            <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,17.219A1.8,1.8,0,0,0,1.821,19H15.179A1.8,1.8,0,0,0,17,17.219V7.125H0ZM12.143,9.945A.452.452,0,0,1,12.6,9.5h1.518a.452.452,0,0,1,.455.445V11.43a.452.452,0,0,1-.455.445H12.6a.452.452,0,0,1-.455-.445Zm0,4.75a.452.452,0,0,1,.455-.445h1.518a.452.452,0,0,1,.455.445V16.18a.452.452,0,0,1-.455.445H12.6a.452.452,0,0,1-.455-.445ZM7.286,9.945A.452.452,0,0,1,7.741,9.5H9.259a.452.452,0,0,1,.455.445V11.43a.452.452,0,0,1-.455.445H7.741a.452.452,0,0,1-.455-.445Zm0,4.75a.452.452,0,0,1,.455-.445H9.259a.452.452,0,0,1,.455.445V16.18a.452.452,0,0,1-.455.445H7.741a.452.452,0,0,1-.455-.445ZM2.429,9.945A.452.452,0,0,1,2.884,9.5H4.4a.452.452,0,0,1,.455.445V11.43a.452.452,0,0,1-.455.445H2.884a.452.452,0,0,1-.455-.445Zm0,4.75a.452.452,0,0,1,.455-.445H4.4a.452.452,0,0,1,.455.445V16.18a.452.452,0,0,1-.455.445H2.884a.452.452,0,0,1-.455-.445Zm12.75-12.32H13.357V.594A.6.6,0,0,0,12.75,0H11.536a.6.6,0,0,0-.607.594V2.375H6.071V.594A.6.6,0,0,0,5.464,0H4.25a.6.6,0,0,0-.607.594V2.375H1.821A1.8,1.8,0,0,0,0,4.156V5.938H17V4.156A1.8,1.8,0,0,0,15.179,2.375Z" fill="#707070" />
                                                        </svg>
                                                    </span>
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid className="input-fields" item xs={12}>
                                                <label>To Date</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        fullWidth
                                                        className='MyTextField'
                                                        name="toDate"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        format="dd/MM/yyyy"
                                                        value={toDate}
                                                        onChange={(date) => this.setState({ toDate: date })}
                                                        KeyboardButtonProps={{ 'aria-label': 'Date of Birth' }}
                                                    />
                                                    <span className='icon-date'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19">
                                                            <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,17.219A1.8,1.8,0,0,0,1.821,19H15.179A1.8,1.8,0,0,0,17,17.219V7.125H0ZM12.143,9.945A.452.452,0,0,1,12.6,9.5h1.518a.452.452,0,0,1,.455.445V11.43a.452.452,0,0,1-.455.445H12.6a.452.452,0,0,1-.455-.445Zm0,4.75a.452.452,0,0,1,.455-.445h1.518a.452.452,0,0,1,.455.445V16.18a.452.452,0,0,1-.455.445H12.6a.452.452,0,0,1-.455-.445ZM7.286,9.945A.452.452,0,0,1,7.741,9.5H9.259a.452.452,0,0,1,.455.445V11.43a.452.452,0,0,1-.455.445H7.741a.452.452,0,0,1-.455-.445Zm0,4.75a.452.452,0,0,1,.455-.445H9.259a.452.452,0,0,1,.455.445V16.18a.452.452,0,0,1-.455.445H7.741a.452.452,0,0,1-.455-.445ZM2.429,9.945A.452.452,0,0,1,2.884,9.5H4.4a.452.452,0,0,1,.455.445V11.43a.452.452,0,0,1-.455.445H2.884a.452.452,0,0,1-.455-.445Zm0,4.75a.452.452,0,0,1,.455-.445H4.4a.452.452,0,0,1,.455.445V16.18a.452.452,0,0,1-.455.445H2.884a.452.452,0,0,1-.455-.445Zm12.75-12.32H13.357V.594A.6.6,0,0,0,12.75,0H11.536a.6.6,0,0,0-.607.594V2.375H6.071V.594A.6.6,0,0,0,5.464,0H4.25a.6.6,0,0,0-.607.594V2.375H1.821A1.8,1.8,0,0,0,0,4.156V5.938H17V4.156A1.8,1.8,0,0,0,15.179,2.375Z" fill="#707070" />
                                                        </svg>
                                                    </span>
                                                </MuiPickersUtilsProvider>
                                            </Grid>

                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Discord URL</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="url"
                                                        margin="dense"
                                                        name="discordUrl"
                                                        variant="outlined"
                                                        value={discordUrl}
                                                        className="MyTextField"
                                                        placeholder="Type discord url here"
                                                        onChange={this.handleFormChange}
                                                    />
                                                    <span className='icon-url'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <path id="Icon_awesome-link" data-name="Icon awesome-link" d="M11.482,6.518a5.341,5.341,0,0,1,.013,7.544l-.013.013L9.12,16.437A5.344,5.344,0,0,1,1.563,8.88l1.3-1.3a.562.562,0,0,1,.96.373A6.478,6.478,0,0,0,4.167,9.8a.566.566,0,0,1-.133.584l-.46.46a2.531,2.531,0,1,0,3.557,3.6l2.363-2.362a2.531,2.531,0,0,0,0-3.58,2.634,2.634,0,0,0-.364-.3.564.564,0,0,1-.244-.443A1.4,1.4,0,0,1,9.3,6.714l.74-.74a.565.565,0,0,1,.724-.061,5.361,5.361,0,0,1,.721.6Zm4.955-4.955a5.35,5.35,0,0,0-7.557,0L6.517,3.925,6.5,3.938a5.345,5.345,0,0,0,.734,8.149.565.565,0,0,0,.724-.061l.74-.74a1.4,1.4,0,0,0,.411-1.048.564.564,0,0,0-.244-.443,2.633,2.633,0,0,1-.364-.3,2.531,2.531,0,0,1,0-3.58l2.363-2.362a2.531,2.531,0,1,1,3.557,3.6l-.46.46a.566.566,0,0,0-.133.584,6.478,6.478,0,0,1,.341,1.853.562.562,0,0,0,.96.373l1.3-1.3a5.349,5.349,0,0,0,0-7.557Z" transform="translate(0 0)" fill="#707070" />
                                                        </svg>
                                                    </span>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Twitter URL</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="url"
                                                        margin="dense"
                                                        name="twitterUrl"
                                                        variant="outlined"
                                                        value={twitterUrl}
                                                        className="MyTextField"
                                                        placeholder="Type twitter url here"
                                                        onChange={this.handleFormChange}
                                                    />
                                                    <span className='icon-url'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <path id="Icon_awesome-link" data-name="Icon awesome-link" d="M11.482,6.518a5.341,5.341,0,0,1,.013,7.544l-.013.013L9.12,16.437A5.344,5.344,0,0,1,1.563,8.88l1.3-1.3a.562.562,0,0,1,.96.373A6.478,6.478,0,0,0,4.167,9.8a.566.566,0,0,1-.133.584l-.46.46a2.531,2.531,0,1,0,3.557,3.6l2.363-2.362a2.531,2.531,0,0,0,0-3.58,2.634,2.634,0,0,0-.364-.3.564.564,0,0,1-.244-.443A1.4,1.4,0,0,1,9.3,6.714l.74-.74a.565.565,0,0,1,.724-.061,5.361,5.361,0,0,1,.721.6Zm4.955-4.955a5.35,5.35,0,0,0-7.557,0L6.517,3.925,6.5,3.938a5.345,5.345,0,0,0,.734,8.149.565.565,0,0,0,.724-.061l.74-.74a1.4,1.4,0,0,0,.411-1.048.564.564,0,0,0-.244-.443,2.633,2.633,0,0,1-.364-.3,2.531,2.531,0,0,1,0-3.58l2.363-2.362a2.531,2.531,0,1,1,3.557,3.6l-.46.46a.566.566,0,0,0-.133.584,6.478,6.478,0,0,1,.341,1.853.562.562,0,0,0,.96.373l1.3-1.3a5.349,5.349,0,0,0,0-7.557Z" transform="translate(0 0)" fill="#707070" />
                                                        </svg>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Website URL</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="url"
                                                        margin="dense"
                                                        name="websiteUrl"
                                                        variant="outlined"
                                                        value={websiteUrl}
                                                        className="MyTextField"
                                                        placeholder="Type website url here"
                                                        onChange={this.handleFormChange}
                                                    />
                                                    <span className='icon-url'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <path id="Icon_awesome-link" data-name="Icon awesome-link" d="M11.482,6.518a5.341,5.341,0,0,1,.013,7.544l-.013.013L9.12,16.437A5.344,5.344,0,0,1,1.563,8.88l1.3-1.3a.562.562,0,0,1,.96.373A6.478,6.478,0,0,0,4.167,9.8a.566.566,0,0,1-.133.584l-.46.46a2.531,2.531,0,1,0,3.557,3.6l2.363-2.362a2.531,2.531,0,0,0,0-3.58,2.634,2.634,0,0,0-.364-.3.564.564,0,0,1-.244-.443A1.4,1.4,0,0,1,9.3,6.714l.74-.74a.565.565,0,0,1,.724-.061,5.361,5.361,0,0,1,.721.6Zm4.955-4.955a5.35,5.35,0,0,0-7.557,0L6.517,3.925,6.5,3.938a5.345,5.345,0,0,0,.734,8.149.565.565,0,0,0,.724-.061l.74-.74a1.4,1.4,0,0,0,.411-1.048.564.564,0,0,0-.244-.443,2.633,2.633,0,0,1-.364-.3,2.531,2.531,0,0,1,0-3.58l2.363-2.362a2.531,2.531,0,1,1,3.557,3.6l-.46.46a.566.566,0,0,0-.133.584,6.478,6.478,0,0,1,.341,1.853.562.562,0,0,0,.96.373l1.3-1.3a5.349,5.349,0,0,0,0-7.557Z" transform="translate(0 0)" fill="#707070" />
                                                        </svg>
                                                    </span>
                                                </Grid>
                                            </Grid>

                                            <div className='group-form btn-group'>
                                                {/* <button className='btn-style-two'>Draft</button> */}
                                                <button type='submit' className='btn-style-one'>Post</button>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        );
    }
}
const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const mapDispatchToProps = { toggleLoader, createNotableDrop, notableDropLoader };

const mapStateToProps = ({ Auth, Drop }) => {
    let { isNotableDrop, } = Drop;
    let { lightMode, publicAddress, } = Auth;
    return { lightMode, publicAddress, isNotableDrop, };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotableDrop);