import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import './index.css';
import { chainId, hostName, networkName } from '../../store/config';
import { web3, loginTorus, logoutTours } from '../../store/web3';
import { getAllChatHistory, allChatHistoryLoader } from '../../store/actions/Chat';
import { getNonce, getSearchUser, setSearchUser, searchUserLoader, login, logout, toggleLoader, getProfilesDetails } from '../../store/actions/Auth';


class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            address: '',
            isLogoutModal: false,
        };
        props.getProfilesDetails();
        props.getAllChatHistory();
        props.allChatHistoryLoader(true);
    };

    async componentDidMount() {
        let { publicAddress } = this.props;
        if (publicAddress) await loginTorus();
    };

    componentDidUpdate(prevProps, prevState) {
        let { userNonce } = this.props;
        if (prevProps['userNonce'] !== userNonce) {
            this.handelLogin(userNonce);
        }
    };

    componentWillReceiveProps({ auth, publicAddress }) {
        if (publicAddress) this.setState({ address: publicAddress });
        if (!auth) this.props.toggleLoader({ message: 'Logging Out...', status: false });
    };

    handleNonce = async () => {
        this.props.toggleLoader({ message: 'Logging In...', status: true });
        await loginTorus();
        web3.eth.getAccounts().then((accounts) => {
            this.props.getNonce(accounts?.[0]);
            this.setState({ address: accounts?.[0] });
        }).catch((error) => {
            this.props.toggleLoader({ message: 'Logging In...', status: false });
            console.log("********error::", error);
        });
    };

    handelLogin = async (nonce) => {
        let { address } = this.state;
        const signature = await web3.eth.personal.sign(web3.utils.utf8ToHex(`Nifty,${nonce.toString()}`), address);
        if (signature) {
            this.props.toggleLoader({ message: 'Signing In...', status: true });
            let data = await { publicAddress: address, signature };
            this.props.login(data);
        }
    };

    handleLogout = async () => {
        this.props.toggleLoader({ message: 'Logging Out...', status: true });
        await logoutTours();
        this.setState({ address: '' });
        this.props.logout();
        this.toggleLogoutModal();
        EventBus.publish("success", "Wallet logout!!!");
    };

    handleChange = (e) => {
        let { search } = this.state;
        this.props.setSearchUser([]);
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (search.length >= 2) {
                this.props.getSearchUser(search);
                this.props.searchUserLoader(true);
            }
        });
    };

    copied = () => EventBus.publish("info", 'Address Copied');
    toggleLogoutModal = () => this.setState({ isLogoutModal: !this.state.isLogoutModal });

    render() {

        let { isLogoutModal, search } = this.state;
        let { auth, publicAddress, userID, searchData, isUserSeach, chatHistory, totalUnreadCount, lightMode } = this.props;

        return (
            <div className="nfity-nav">
                <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                    <div className='container-fluid'>
                        <div className="inner-container">
                            <Link className='navbar-brand light' to='/'><img src={require('../../static/images/logo.png')} alt='' /></Link>
                            <Link className='navbar-brand dark' to='/'><img src={require('../../static/images/logo-white.png')} alt='' /></Link>
                            <div className="searchbox">
                                <div className="group-form">
                                    <div className="icon-search">
                                        <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14.998" viewBox="0 0 14 14.998">
                                                <path id="search" d="M14.771,13.661,11.32,9.948a6.162,6.162,0,0,0,1.374-3.893A5.964,5.964,0,0,0,6.839,0,5.964,5.964,0,0,0,.984,6.055a5.964,5.964,0,0,0,5.855,6.055,5.661,5.661,0,0,0,3.354-1.1l3.477,3.741a.746.746,0,0,0,1.08.022A.81.81,0,0,0,14.771,13.661ZM6.839,1.58a4.408,4.408,0,0,1,4.327,4.476,4.408,4.408,0,0,1-4.327,4.476A4.408,4.408,0,0,1,2.511,6.055,4.408,4.408,0,0,1,6.839,1.58Z" transform="translate(-0.984)" opacity="0.4" />
                                            </svg>
                                        </button>
                                    </div>
                                    <input className="searchbox" type="text" placeholder="Search" name="search" value={search} onChange={this.handleChange} />
                                    {search.length > 0 &&
                                        <div className="search-show">
                                            <div className="inner-search">
                                                {isUserSeach
                                                    ? <div className="d-flex justify-content-center mb-3">
                                                        <i className="fa fa-spinner fa-spin fa-fw"></i>
                                                    </div>
                                                    : <>
                                                        {searchData && searchData.length > 0 && search.length > 2
                                                            ? <>
                                                                {searchData.map(data => {

                                                                    return (
                                                                        <div className="search-block">
                                                                            <a href={`/profile/${data['_id']}`}>
                                                                                {data['profilePicture'] &&
                                                                                    <div className="icon">
                                                                                        <img src={data['profilePicture']} alt='' />
                                                                                    </div>
                                                                                }
                                                                                <div className="text-box">
                                                                                    <div className="left-area">
                                                                                        <h4>{data['name']}</h4>
                                                                                        <p>{data['username']}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    );
                                                                })
                                                                }
                                                            </>
                                                            : <div className="search-block">
                                                                <div className="text-box">
                                                                    <div className="left-area">
                                                                        {search.length > 2 && searchData.length == 0 &&
                                                                            <h4>No User Found!!!</h4>
                                                                        }
                                                                        {search.length <= 2 &&
                                                                            <p>Type atleast Three words...</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='nav-links' id='navbarSupportedContent'>
                                <ul className='navbar-nav sidenav-menu'>
                                    <li className='nav-item'>
                                        <Link className='nav-link' to='/'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.996" height="20.998" viewBox="0 0 20.996 20.998">
                                                <path id="home" d="M20.431,9.133h0L11.864.566a1.933,1.933,0,0,0-2.734,0L.569,9.127l-.009.009a1.932,1.932,0,0,0,1.286,3.293l.06,0h.341v6.3A2.265,2.265,0,0,0,4.511,21H7.862a.615.615,0,0,0,.615-.615V15.441a1.033,1.033,0,0,1,1.032-1.032h1.977a1.033,1.033,0,0,1,1.032,1.032v4.942a.615.615,0,0,0,.615.615h3.351a2.265,2.265,0,0,0,2.263-2.262v-6.3h.317a1.933,1.933,0,0,0,1.368-3.3Zm0,0" transform="translate(0)" fill="#707070" />
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link' to='/Marketplace'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23.625" height="21" viewBox="0 0 23.625 21">
                                                <g id="Group_2094" data-name="Group 2094" transform="translate(284 -762)">
                                                    <path id="marketplace_tab_bar" d="M10.837,11.518l1.038-6.891h-5.4L4.219,10.534a2.07,2.07,0,0,0-.106.656c0,1.449,1.509,2.625,3.374,2.625a3.2,3.2,0,0,0,3.35-2.3Zm5.089,2.3c1.864,0,3.374-1.176,3.374-2.625,0-.054,0-.108-.007-.159l-.668-6.4h-5.4l-.669,6.4c0,.054-.005.108-.005.164,0,1.449,1.511,2.625,3.374,2.625Zm6.563,1.373v5.19H9.363V15.2a5.523,5.523,0,0,1-1.876.326,5.412,5.412,0,0,1-.749-.064V23.79a1.842,1.842,0,0,0,1.835,1.838h14.7a1.844,1.844,0,0,0,1.838-1.838V15.458a5.639,5.639,0,0,1-.749.064A5.454,5.454,0,0,1,22.488,15.188Zm5.145-4.654L25.375,4.627h-5.4l1.037,6.88a3.194,3.194,0,0,0,3.351,2.307c1.864,0,3.374-1.176,3.374-2.625A2.108,2.108,0,0,0,27.633,10.534Z" transform="translate(-288.113 757.373)" fill="#707070" />
                                                </g>
                                            </svg>
                                        </Link>
                                    </li>
                                    {auth &&
                                        <>
                                            <li className='nav-item'>
                                                <Link className='nav-link' to='#'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.99" height="21" viewBox="0 0 20.99 21">
                                                        <path id="Vector" d="M14.9,0H6.1C2.278,0,0,2.278,0,6.1v8.789C0,18.721,2.278,21,6.1,21h8.789c3.822,0,6.1-2.279,6.1-6.1V6.1C21,2.278,18.721,0,14.9,0Zm-.2,11.287H11.287V14.7a.787.787,0,1,1-1.575,0V11.287H6.3a.787.787,0,1,1,0-1.575H9.712V6.3a.787.787,0,1,1,1.575,0V9.712H14.7a.787.787,0,1,1,0,1.575Z" fill="#707070" />
                                                    </svg>
                                                </Link>
                                                <div className="addnft-dropdpwon">
                                                    <ul>
                                                        <li>
                                                            <Link className="btn-add-nft" to='/createNewCollection'>
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.073" height="14.08" viewBox="0 0 14.073 14.08">
                                                                        <path id="Vector" d="M9.99,0H4.09A3.757,3.757,0,0,0,0,4.09V9.983a3.759,3.759,0,0,0,4.09,4.1H9.983a3.757,3.757,0,0,0,4.09-4.09V4.09A3.749,3.749,0,0,0,9.99,0ZM9.856,7.568H7.568V9.856a.528.528,0,0,1-1.056,0V7.568H4.224a.528.528,0,1,1,0-1.056H6.512V4.224a.528.528,0,1,1,1.056,0V6.512H9.856a.528.528,0,0,1,0,1.056Z" />
                                                                    </svg>
                                                                </i>
                                                                Add New Collection
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="btn-add-nft" to="/uploadPosts">
                                                                <i className="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.073" height="14.08" viewBox="0 0 14.073 14.08">
                                                                        <path id="Vector" d="M9.99,0H4.09A3.757,3.757,0,0,0,0,4.09V9.983a3.759,3.759,0,0,0,4.09,4.1H9.983a3.757,3.757,0,0,0,4.09-4.09V4.09A3.749,3.749,0,0,0,9.99,0ZM9.856,7.568H7.568V9.856a.528.528,0,0,1-1.056,0V7.568H4.224a.528.528,0,1,1,0-1.056H6.512V4.224a.528.528,0,1,1,1.056,0V6.512H9.856a.528.528,0,0,1,0,1.056Z" />
                                                                    </svg>
                                                                </i>
                                                                Add New NFTs
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className='nav-item'>
                                                <Link className='nav-link' to='#' onClick={() => this.props.getAllChatHistory()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.01" height="21" viewBox="0 0 21.01 21">
                                                        <path id="Vector" d="M15.758,0H5.253A5.243,5.243,0,0,0,0,5.232v7.333A5.243,5.243,0,0,0,5.253,17.8H6.828a1.208,1.208,0,0,1,.84.42l1.576,2.091a1.493,1.493,0,0,0,2.521,0l1.576-2.091a1.065,1.065,0,0,1,.84-.42h1.576a5.243,5.243,0,0,0,5.253-5.232V5.232A5.243,5.243,0,0,0,15.758,0Zm-4.2,12.344h-6.3a.788.788,0,0,1,0-1.576h6.3a.788.788,0,1,1,0,1.576Zm4.2-5.253H5.253a.788.788,0,0,1,0-1.576H15.758a.788.788,0,1,1,0,1.576Z" fill="#707070" />
                                                    </svg>
                                                    {totalUnreadCount > 0 &&
                                                        <div className='total-unread'><span>{totalUnreadCount}</span></div>
                                                    }
                                                </Link>
                                                <div className="inbox-dropdpwon">
                                                    <div className="top-heading">
                                                        <h5>Inbox</h5>
                                                    </div>
                                                    <div className="inbox-detail">
                                                        <p className="date">Messages</p>
                                                        {/* <h5 className="time-date">Today</h5> */}
                                                        {chatHistory.length > 0
                                                            ? <>
                                                                {chatHistory.map(chat => {
                                                                    let sentTime = new Date(chat['sentAt']).getTime();
                                                                    let currentTime = new Date().getTime();
                                                                    let milisec = (currentTime - sentTime);

                                                                    let days = moment.duration(milisec).days();
                                                                    let hours = moment.duration(milisec).hours();
                                                                    let minutes = moment.duration(milisec).minutes();
                                                                    let seconds = moment.duration(milisec).seconds();

                                                                    return (
                                                                        <a className='text-dark' href={`/messaging/${chat['friendData']['_id']}`}>
                                                                            <div className="inbox-block">
                                                                                <div className="left-area">
                                                                                    <div className={`img-box ${chat['friendData']['isOnline'] && 'active'}`}>
                                                                                        <img src={(chat['friendData'] && chat['friendData']['profilePicture']) || require('../../static/images/camera.png')} alt='' />
                                                                                    </div>
                                                                                    <div className="text-box">
                                                                                        {chat['friendData'] &&
                                                                                            <h5>{chat['friendData']['name']}</h5>
                                                                                        }
                                                                                        <p>messaged {chat['type'] == "sender" ? 'you' : 'recieved'}.</p>
                                                                                        {days > 0
                                                                                            ? <p>{days}d ago</p>
                                                                                            : hours > 0
                                                                                                ? <p>{hours}h ago</p>
                                                                                                : minutes > 0
                                                                                                    ? <p>{minutes}m ago</p>
                                                                                                    : seconds > 0
                                                                                                        ? <p>{seconds}s ago</p>
                                                                                                        : 'now'
                                                                                        }
                                                                                        {chat['messageType'] == "Text"
                                                                                            ? <span className={`massage ${chat['unreadCount'] > 0 && 'font-weight-bold'}`}>{chat['lastMessage']}</span>
                                                                                            : <span className={`massage ${chat['unreadCount'] > 0 && 'font-weight-bold'}`}>{chat['lastData']['name'].substring(0, 10) + '...'}</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-area">
                                                                                    <Link className="btn-style-two" to="#">Friends</Link>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    )
                                                                })
                                                                }
                                                            </>
                                                            : <div className="d-flex justify-content-center"><p>No Messages!</p></div>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='nav-item notfication-dropdown'>
                                                <Link className='nav-link' to='#'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.634" height="21" viewBox="0 0 16.634 21">
                                                        <g id="Group_3103" data-name="Group 3103" transform="translate(-1323.365 -25)">
                                                            <g id="Group_3102" data-name="Group 3102" transform="translate(1323.365 25)">
                                                                <path id="Vector" d="M15.781,13.114l-1.05-1.743a3.9,3.9,0,0,1-.42-1.554V7.161a6.2,6.2,0,0,0-3.539-5.6A3.072,3.072,0,0,0,8.063,0a3.109,3.109,0,0,0-2.72,1.6A6.225,6.225,0,0,0,1.879,7.161V9.818a3.807,3.807,0,0,1-.42,1.543L.4,13.114a2.454,2.454,0,0,0,1.375,3.707A19.638,19.638,0,0,0,8.095,17.85a19.918,19.918,0,0,0,6.321-1.018,2.532,2.532,0,0,0,1.365-3.717Z" fill="#707070" />
                                                                <path id="Vector-2" data-name="Vector" d="M5.932.011A3.165,3.165,0,0,1,2.961,2.1,3.113,3.113,0,0,1,.735,1.166,2.9,2.9,0,0,1,0,0C.136.021.273.031.42.052c.241.031.494.063.745.084.6.052,1.208.084,1.817.084S4.179.189,4.767.136c.22-.021.441-.031.651-.063Z" transform="translate(5.113 18.9)" fill="#707070" />
                                                            </g>
                                                            <g id="Group_2301" data-name="Group 2301" transform="translate(1333.409 25.405)">
                                                                <g id="Group_2300" data-name="Group 2300" transform="translate(0)">
                                                                    <g id="Group_2299" data-name="Group 2299" transform="translate(0 0)">
                                                                        <g id="Ellipse_364" data-name="Ellipse 364" transform="translate(0)" fill="#f20000" stroke="#fff" stroke-width="0.5">
                                                                            <circle cx="3.295" cy="3.295" r="3.295" stroke="none" />
                                                                            <circle cx="3.295" cy="3.295" r="3.045" fill="none" />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </Link>
                                                <div className="inbox-dropdpwon">
                                                    <div className="top-heading">
                                                        <h5>Notifications</h5>
                                                    </div>
                                                    <div className="inbox-detail">
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a className='text-dark'>
                                                            <div className="inbox-block">
                                                                <div className="left-area">
                                                                    <div className="img-box">
                                                                        <img src={require('../../static/images/camera.png')} alt='' />
                                                                    </div>
                                                                    <div className="text-box">
                                                                        <h5>User</h5>
                                                                        <p>message you</p>
                                                                        <p>2 minutes ago</p>
                                                                        <span className="massage">Testing Notfication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    }
                                    {publicAddress == '' || publicAddress == null || publicAddress == undefined
                                        ? <li className='nav-item'>
                                            <button onClick={this.handleNonce} className="login-btn btn-style-two">Login</button>
                                        </li>
                                        : <li className='nav-item'>
                                            <button onClick={this.toggleLogoutModal} className="login-btn btn-style-two">
                                                {publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)}
                                            </button>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div >
                    </div >
                </nav>

                {/* --------------- LOGOUT MODAL--------------- */}
                <Modal isOpen={isLogoutModal} toggle={this.toggleLogoutModal} className={`main-modal delete-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalHeader toggle={this.toggleLogoutModal}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="row">
                                <div className="col-12">
                                    <div className='icon-splash'>
                                        <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                    </div>
                                    <h2>LOGOUT</h2>
                                    <h5>Are you sure want to logout your account?<br />
                                        Before logging out you can copy address. </h5>
                                </div>
                                <div className="modalfooter">
                                    <CopyToClipboard onCopy={this.copied} text={publicAddress}>
                                        <button className="btn-style-two">Copy Address</button>
                                    </CopyToClipboard>
                                    <button className="btn-style-one" onClick={this.handleLogout}>Logout</button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }
}

const mapDispatchToProps = {
    getNonce, getSearchUser, setSearchUser, searchUserLoader, login, logout, toggleLoader, getAllChatHistory, allChatHistoryLoader, getProfilesDetails
};

const mapStateToProps = ({ Auth, Chat }) => {
    let { chatHistory, totalUnreadCount } = Chat;
    let { lightMode, auth, userNonce, publicAddress, userID, searchData, isUserSeach, } = Auth;
    return { lightMode, auth, userNonce, publicAddress, userID, searchData, isUserSeach, chatHistory, totalUnreadCount }
};


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);