import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import { toggleLoader } from "../../store/actions/Auth";


class HashtagExplore extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {
        let { } = this.state;

        return (
            // <div className="nfity-page" onWheel={this.onScroll}>
            <div>
                <div className='auto-container'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-12'>
                            <div className='user-accounts'>
                                <div className='hashtag-user'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/hashtag-user.jpg')} alt='' />
                                    </div>
                                    <div className='text-box'>
                                        <div className='profile-detail'>
                                            <div className='name-area'>
                                                <h4>#Topnifty</h4>
                                                <div className='sortby-dropdrown dropdown'>
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.893" height="4.723" viewBox="0 0 18.893 4.723">
                                                            <g id="details_more" transform="translate(-355 -167.599)">
                                                                <g id="Group_58" data-name="Group 58" transform="translate(373.893 167.599) rotate(90)">
                                                                    <path id="Path_9" data-name="Path 9" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 0)" />
                                                                    <path id="Path_10" data-name="Path 10" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.361,2.361,0,0,1,3.34,0" transform="translate(0 7.085)" />
                                                                    <path id="Path_11" data-name="Path 11" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 14.17)" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a class="dropdown-item" href="#">
                                                            <i className='icon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.19" height="14.188" viewBox="0 0 14.19 14.188">
                                                                    <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M18.245,4.535,4.679,10.448a.311.311,0,0,0,.011.565l3.67,2.073a.592.592,0,0,0,.676-.067l7.236-6.238c.048-.041.163-.118.207-.074s-.026.159-.067.207l-6.26,7.051a.59.59,0,0,0-.059.706l2.4,3.847a.312.312,0,0,0,.562-.007l5.61-13.567A.311.311,0,0,0,18.245,4.535Z" transform="translate(-4.503 -4.503)" />
                                                                </svg>
                                                            </i>
                                                            Send Message
                                                        </a>
                                                        <a class="dropdown-item" href="#">
                                                            <i className='icon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.242" height="17.091" viewBox="0 0 14.242 17.091">
                                                                    <path id="Icon_ionic-md-flag" data-name="Icon ionic-md-flag" d="M20.1,5.355a17.519,17.519,0,0,1-2.208.187A17.779,17.779,0,0,1,14.267,5a18.514,18.514,0,0,0-3.743-.5c-2.608,0-3.494.539-3.587.6l-.187.129V21.591H8.886V13.66a13.5,13.5,0,0,1,1.642-.089,18.013,18.013,0,0,1,3.552.69,19.089,19.089,0,0,0,3.868.512,17.664,17.664,0,0,0,2.154-.178c.334-.04.623-.076.89-.12V5.221C20.77,5.266,20.436,5.314,20.1,5.355Z" transform="translate(-6.75 -4.5)" />
                                                                </svg>
                                                            </i>
                                                            Report
                                                        </a>
                                                        <a class="dropdown-item" href="#">
                                                            <i className='icon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.492" height="14.492" viewBox="0 0 14.492 14.492">
                                                                    <path id="Icon_material-block" data-name="Icon material-block" d="M10.246,3a7.246,7.246,0,1,0,7.246,7.246A7.249,7.249,0,0,0,10.246,3Zm-5.8,7.246a5.8,5.8,0,0,1,5.8-5.8A5.726,5.726,0,0,1,13.8,5.674L5.674,13.8A5.726,5.726,0,0,1,4.449,10.246Zm5.8,5.8A5.726,5.726,0,0,1,6.7,14.818L14.818,6.7a5.726,5.726,0,0,1,1.225,3.551A5.8,5.8,0,0,1,10.246,16.043Z" transform="translate(-3 -3)" />
                                                                </svg>
                                                            </i>
                                                            Block
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>34,545 posts</p>
                                            <Link className='btn-style-one' to="#">Follow</Link>
                                        </div>
                                    </div>
                                    <div className='items-detail'>
                                        <div className='items-block'>
                                            <p>55</p>
                                            <span>Items</span>
                                        </div>
                                        <div className='items-block'>
                                            <p>3.4K</p>
                                            <span>Owners</span>
                                        </div>
                                        <div className='items-block'>
                                            <p>3.4K</p>
                                            <span>Floor price</span>
                                        </div>
                                        <div className='items-block'>
                                            <p>
                                                <span className='icon'>
                                                    <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                </span>
                                                56.5K
                                            </p>
                                            <span>Items</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='row clearfix'>
                        <div className='col-12'>
                            <h4>Top Posts</h4>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-1.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-2.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-3.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-4.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-5.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-6.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-7.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-8.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-9.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-10.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-11.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-12.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-13.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-14.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-15.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-16.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-17.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-18.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-19.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-20.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-21.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-22.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-23.jpg')} alt='' />
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-sm-6'>
                            <div className='nft-img'>
                                <img src={require('../../static/images/nft-collection/hashtag-nft-24.jpg')} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}


export default connect()(HashtagExplore);