export const getAllDrops = () => ({
    type: 'GET_ALL_DROPS',
});

export const setAllDrops = (data) => ({
    type: 'SET_ALL_DROPS',
    payload: data,
});

export const allDropsLoader = (data) => ({
    type: 'ALL_DROPS_LOADER',
    payload: data,
});

export const getSingleDrop = (data) => ({
    type: 'GET_SINGLE_DROP',
    payload: data,
});

export const setSingleDrop = (data) => ({
    type: 'SET_SINGLE_DROP',
    payload: data,
});

export const singleDropLoader = (data) => ({
    type: 'SINGLE_DROP_LOADER',
    payload: data,
});


export const createNotableDrop = (data) => ({
    type: 'CREATE_NOTABLE_DROP',
    payload: data,
});

export const notableDropLoader = (data) => ({
    type: 'NOTABLE_DROP_LOADER',
    payload: data,
});
