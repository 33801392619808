import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';

import './index.css';
import '../../static/css/animate.css';
import 'react-circular-progressbar/dist/styles.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";

class AddCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };

    render() {
        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec profile-edit-page add-card-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                <div className='my-wallet-box'>
                                    <div className='top-heading'>
                                        <h3>Add New Card</h3>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-12'>
                                            <div className='wallet-block style-two'>
                                                <div className='top-card-name'>
                                                    <div className='left-img'><img src={require('../../static/images/mastercard.png')} alt='' /></div>
                                                    <div className='right-img'><img src={require('../../static/images/symbols.png')} alt='' /></div>
                                                </div>
                                                <ul className='card-detail'>
                                                    <li>****</li>
                                                    <li>****</li>
                                                    <li>****</li>
                                                    <li>****</li>
                                                </ul>
                                                <div className='bottom-footer'>
                                                    <p>Name</p>
                                                    <p>Expiry</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-area'>
                                        <ValidatorForm className="validatorForm" >
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Card Holder Name</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        className="MyTextField"
                                                        autoComplete='off'
                                                        placeholder="John Smith"
                                                        name="Name"
                                                        type="text"
                                                        // value={email}
                                                        variant="outlined"
                                                        margin="dense"
                                                        // onKeyPress={this.keyPressed}
                                                        // onChange={this.handleFormChange}
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Please add Your Card Holder Name', 'Card Holder Name is not valid']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Card Number</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        className="MyTextField"
                                                        autoComplete='off'
                                                        placeholder="1234 7654 6678 9876"
                                                        name="cardNumber"
                                                        type="text"
                                                        // value={email}
                                                        variant="outlined"
                                                        margin="dense"
                                                        // onKeyPress={this.keyPressed}
                                                        // onChange={this.handleFormChange}
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Please add Your CVC', 'CVC is not valid']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={6}>
                                                    <label>Expiry Date</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <span className='icon-date'><img src={require('../../static/images/calender_sponsor.png')} alt='' /></span>
                                                        <DatePicker
                                                            fullWidth
                                                            variant="outlined"
                                                            className='MyTextField'
                                                            name="dob"
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            placeholder="From"
                                                            format="MM/dd/yyyy"
                                                            // value={dob}
                                                            // onChange={(date) => this.setDateOfBirth(date)}
                                                            KeyboardButtonProps={{ 'aria-label': 'From' }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid className="input-fields cvc-field" item xs={6}>
                                                    <label>CVC</label>
                                                    <span className='icon-date'><img src={require('../../static/images/calender_sponsor.png')} alt='' /></span>
                                                    <CustomTextField
                                                        fullWidth
                                                        className="MyTextField"
                                                        autoComplete='off'
                                                        placeholder="456"
                                                        name="cvc"
                                                        type="text"
                                                        // value={email}
                                                        variant="outlined"
                                                        margin="dense"
                                                        // onKeyPress={this.keyPressed}
                                                        // onChange={this.handleFormChange}
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Please add Your Card Number', 'Card Number is not valid']}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <Checkbox />
                                                    <label>Security save card and details</label>
                                                </Grid>
                                            </Grid>
                                            <div className='group-form start-group'>
                                                <button className='btn-style-one'>Add Card</button>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div >
        );
    }
}

const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCard);