import io from 'socket.io-client';

/* -- set app title --*/
const AppTitle = 'FRONTEND NIFTY';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://nifty.metawarriors.world';
const production = 'https://nifty.metawarriors.world';
const development = 'https://nifty.metawarriors.world';

let SocketUrl, opensea, token = localStorage.getItem('token');
let env = AppMode[0] || 'development', networkId = '', chainId = '', hostName = '', networkName = '', message = '', explorer = '';

switch (AppMode[0]) {

  case 'development':
    networkId = 5;
    chainId = '0x61';
    hostName = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
    networkName = 'Binance Smart Chain Testnet';
    SocketUrl = development;
    explorer = 'https://cronos.org/explorer/testnet3'
    message = 'Please switch your network to Cronos testnet';
    opensea = 'https://testnets.opensea.io/'
    break;

  case 'production':
    networkId = 5;
    chainId = '0x61';
    hostName = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
    networkName = 'Binance Smart Chain Testnet';
    SocketUrl = production;
    message = 'Please switch your network to Ethereum Mainnet';
    explorer = 'https://etherscan.io'
    opensea = 'https://opensea.io/'
    break;

  case 'testing':
    networkId = 5;
    chainId = '0x61';
    hostName = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
    networkName = 'Binance Smart Chain Testnet';
    SocketUrl = testing;
    message = 'Please switch your network to Cronos testnet';
    explorer = 'https://cronos.org/explorer/testnet3'
    opensea = 'https://testnets.opensea.io/'
    break;

  default:
    networkId = 5;
    chainId = '0x61';
    hostName = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
    networkName = 'Binance Smart Chain Testnet';
    SocketUrl = 'http://192.168.50.149:4004';
    message = 'Please switch your network to Cronos testnet';
    explorer = 'https://cronos.org/explorer/testnet3'
    opensea = 'https://testnets.opensea.io/'
};

let socket = io(`${SocketUrl}/auth`, { query: { token } });
let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, socket, opensea, networkId, chainId, hostName, networkName, message, explorer, env };