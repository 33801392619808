import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import { styled } from '@material-ui/core/styles';

import './index.css';
import { logoutTours } from '../../store/web3';
import { toggleDarkMode, getProfilesDetails, toggleLoader, logout } from "../../store/actions/Auth";

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = { addClass: false }
        this.state = {
        };
    };

    componentDidUpdate(prevProps, prevState) {
        let { auth } = this.props;
        if (prevProps['auth'] !== auth) {
            this.props.getProfilesDetails();
        }
    };

    handleLogout = async () => {
        this.props.toggleLoader({ message: 'Logging Out...', status: true });
        await logoutTours();
        this.setState({ address: '' });
        this.props.logout();
        EventBus.publish("success", "Wallet logout!!!");
    };

    toggle() {
        this.setState({ addClass: !this.state.addClass });
    }


    render() {
        let boxClass = ["sidebar-list"];
        if (this.state.addClass) {
            boxClass.push('loadmore');
        }
        let pathname = window.location.pathname;
        let { auth, userID, profileDetails, lightMode } = this.props;

        return (
            <div>
                <div className='siderbar-inner'>
                    {auth &&
                        <div className='profile-detail'>
                            {profileDetails['profilePicture'] &&
                                <div className='img-box'>
                                    <img src={profileDetails['profilePicture']} alt='' />
                                </div>
                            }
                            <Link className="sidebar-nav-item" to={`/profile/${userID}`}>
                                {profileDetails['firstName'] &&
                                    <h4>{profileDetails['firstName']}</h4>
                                }
                            </Link>
                        </div>
                    }
                    <div className="siderbar-menu">
                        <ul className={boxClass.join(' ')}>
                            {auth &&
                                <>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/manageAccount') && 'active'}`} to="/manageAccount">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.535" height="21.85" viewBox="0 0 18.535 21.85">
                                                    <g id="Group_2258" data-name="Group 2258" transform="translate(-120.417 -178.991)">
                                                        <path id="Path_3675" data-name="Path 3675" d="M158,430.46a.956.956,0,0,1-.6-1.031c.031-1.13-.006-2.262.052-3.39a6.638,6.638,0,0,1,1.264-3.5,7.243,7.243,0,0,1,3.653-2.741,7.533,7.533,0,0,1,2.429-.38q1.867-.013,3.733,0a7.225,7.225,0,0,1,5.707,2.631,6.663,6.663,0,0,1,1.591,3.589c.089.773.073,1.56.092,2.34.012.483-.016.968.009,1.45a.943.943,0,0,1-.6,1.031Z" transform="translate(-36.983 -229.62)" fill="#707070" />
                                                        <path id="Path_3676" data-name="Path 3676" d="M225.446,265.785a5.116,5.116,0,1,1,5.119-5.113A5.136,5.136,0,0,1,225.446,265.785Z" transform="translate(-95.764 -76.562)" fill="#707070" />
                                                    </g>
                                                </svg>
                                            </i>
                                            Manage account
                                            {/* <i className='icon-arrow'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13">
                                                    <path id="Settings_arrow" data-name="Settings arrow" d="M13.361,12.692l4.633-4.915a.966.966,0,0,0,0-1.312.843.843,0,0,0-1.239,0l-5.249,5.569a.969.969,0,0,0-.026,1.281l5.271,5.608a.844.844,0,0,0,1.239,0,.966.966,0,0,0,0-1.312Z" transform="translate(18.251 19.194) rotate(180)" fill="#9f9f9f" />
                                                </svg>
                                            </i> */}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/MyCollections') && 'active'}`} to="/MyCollections">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
                                                    <path id="Icon_material-collections" data-name="Icon material-collections" d="M23,16.3V4.9A1.958,1.958,0,0,0,21,3H9A1.958,1.958,0,0,0,7,4.9V16.3a1.958,1.958,0,0,0,2,1.9H21A1.958,1.958,0,0,0,23,16.3ZM12,12.5l2.03,2.574L17,11.55l4,4.75H9ZM3,6.8V20.1A1.958,1.958,0,0,0,5,22H19V20.1H5V6.8Z" transform="translate(-3 -3)" fill="#707070" />
                                                </svg>
                                            </i>
                                            My Collections
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/CreateNewCollection') && 'active'}`} to="/CreateNewCollection">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19.002" height="15" viewBox="0 0 19.002 15">
                                                    <path id="Icon_material-create-new-folder" data-name="Icon material-create-new-folder" d="M20.1,7.875H12.5L10.6,6H4.9A1.873,1.873,0,0,0,3.01,7.875L3,19.125A1.881,1.881,0,0,0,4.9,21H20.1A1.881,1.881,0,0,0,22,19.125V9.75A1.881,1.881,0,0,0,20.1,7.875Zm-.95,7.5H16.3v2.813H14.4V15.375h-2.85V13.5H14.4V10.688h1.9V13.5h2.85Z" transform="translate(-3 -6)" fill="#707070" />
                                                </svg>
                                            </i>
                                            Create Collections
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/CreateNotableDrop') && 'active'}`} to="/CreateNotableDrop">
                                            <i className='icon'>
                                                <svg id="Group_3258" data-name="Group 3258" xmlns="http://www.w3.org/2000/svg" width="21" height="21.161" viewBox="0 0 21 21.161">
                                                    <path id="Path_4278" data-name="Path 4278" d="M80.486,89.163l-2.137-1.877c-1.743-1.529-3.478-3.067-5.236-4.58a1.942,1.942,0,0,1-.686-1.347,6.366,6.366,0,0,1,3.131-6.393,10.386,10.386,0,0,1,4.057-1.488,3.5,3.5,0,0,1,.749-.087c-.176.147-.322.266-.464.389a4.674,4.674,0,0,0-1.564,3.1,13.247,13.247,0,0,0,.388,4.543.376.376,0,0,1,.013.236c-.084-.1-.154-.185-.228-.267a3.707,3.707,0,0,0-3.063-1.356,2.081,2.081,0,0,0-1.549.724,1.372,1.372,0,0,0,.085,1.884c1.31,1.271,2.728,2.423,4.087,3.64,1.039.93,2.1,1.84,3.14,2.764a.355.355,0,0,0,.374.1c-.187-.512-.373-1.023-.561-1.533q-.954-2.587-1.909-5.175c-.054-.146-.157-.307.034-.429a.448.448,0,0,1,.545.179.81.81,0,0,1,.08.164q1.216,3.3,2.427,6.594c.058.158.127.231.309.218a7.01,7.01,0,0,1,.79,0,.223.223,0,0,0,.261-.179Q84.772,85.678,86,82.374A.513.513,0,0,1,86.361,82c.286-.068.431.072.338.347-.12.354-.258.7-.388,1.052q-1.032,2.791-2.063,5.582c-.02.055-.035.113-.052.167.185.071.292-.031.412-.136q3.3-2.9,6.6-5.793c.25-.219.484-.452.712-.691a1.327,1.327,0,0,0,.094-1.542,1.95,1.95,0,0,0-1.684-.939A3.717,3.717,0,0,0,87.177,81.5c-.04.046-.078.093-.12.137-.007.007-.029,0-.065,0a14.031,14.031,0,0,0,.3-1.422,10.7,10.7,0,0,0,.074-3.738,4.658,4.658,0,0,0-1.8-2.949l-.189-.144a5.53,5.53,0,0,1,1.079.141,9.9,9.9,0,0,1,4.324,1.841,6.325,6.325,0,0,1,2.372,3.361,6.8,6.8,0,0,1,.12,2.977,1.506,1.506,0,0,1-.514.9q-3.643,3.19-7.278,6.388c-.052.045-.1.092-.193.176.2,0,.355,0,.512,0a.786.786,0,0,1,.815.794q.011,1.651,0,3.3a.79.79,0,0,1-.859.8q-2,0-4,0c-.566,0-1.132,0-1.7,0a.8.8,0,0,1-.882-.867q0-1.571,0-3.141a.8.8,0,0,1,.905-.888Z" transform="translate(-72.38 -72.899)" fill="#707070" />
                                                    <path id="Path_4279" data-name="Path 4279" d="M128.37,78.852a3.191,3.191,0,0,0-3.11-2.228,3.247,3.247,0,0,0-3.4,2.223,18.433,18.433,0,0,1-.657-3.129,6.825,6.825,0,0,1,.3-2.995,6.674,6.674,0,0,1,1-1.877,2.323,2.323,0,0,1,1.756-.967,9.357,9.357,0,0,1,1.974.035,2.6,2.6,0,0,1,1.782,1.342,6.845,6.845,0,0,1,.963,4.915A21.017,21.017,0,0,1,128.37,78.852Z" transform="translate(-114.62 -69.844)" fill="#707070" />
                                                </svg>
                                            </i>
                                            Add Notable Drops
                                        </Link>
                                    </li>
                                </>
                            }
                            {auth &&
                                <>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/sponsorNFTs') && 'active'}`} to="/sponsorNFTs">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.846" height="18.849" viewBox="0 0 18.846 18.849">
                                                    <g id="Group_2259" data-name="Group 2259" transform="translate(-82.131 -319.62)">
                                                        <path id="Path_3700" data-name="Path 3700" d="M9.778,328.127l1.313-1.371L4.335,320l-2.911,2.854a4.853,4.853,0,0,0,0,6.814,4.845,4.845,0,0,0,6.814,0l.318-.318,1.464,1.464a.865.865,0,0,0,1.223-1.223Z" transform="translate(82.104 7.401)" fill="#707070" />
                                                        <path id="Path_3701" data-name="Path 3701" d="M102.871,195.4,92.727,185.254a.865.865,0,0,0-1.432.338l-1.863,5.59,7.512,7.512,5.59-1.863A.865.865,0,0,0,102.871,195.4Z" transform="translate(-2.148 134.619)" fill="#707070" />
                                                    </g>
                                                </svg>
                                            </i>
                                            Sponsor your NFT
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/myWallet') && 'active'}`} to="/myWallet">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.846" height="15.584" viewBox="0 0 18.846 15.584">
                                                    <path id="Path_3681" data-name="Path 3681" d="M13.772,49.382h5.074V47.026a2.721,2.721,0,0,0-2.718-2.718H2.718A2.721,2.721,0,0,0,0,47.026V57.174a2.721,2.721,0,0,0,2.718,2.718H16.128a2.721,2.721,0,0,0,2.718-2.718V54.818H13.772a2.718,2.718,0,0,1,0-5.436Z" transform="translate(0 -44.308)" fill="#707070" />
                                                </svg>
                                            </i>
                                            Crypto wallet
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/analytics') && 'active'}`} to="/analytics">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.846" height="18.851" viewBox="0 0 18.846 18.851">
                                                    <g id="Group_2262" data-name="Group 2262" transform="translate(0)">
                                                        <g id="Group_2261" data-name="Group 2261">
                                                            <path id="Path_3683" data-name="Path 3683" d="M12.044,169.3q0-4.322,0-8.644a.682.682,0,0,1,.782-.784h3.869a.669.669,0,0,1,.733.727q0,8.7,0,17.392a.67.67,0,0,1-.729.731h-3.89a.678.678,0,0,1-.765-.759q0-4.332,0-8.665Z" transform="translate(-5.313 -159.87)" fill="#707070" />
                                                            <path id="Path_3684" data-name="Path 3684" d="M131.863,262.474q0-2.986,0-5.971a.666.666,0,0,1,.746-.754H136.5a.671.671,0,0,1,.748.753q0,5.982,0,11.964a.67.67,0,0,1-.736.744H132.6a.669.669,0,0,1-.736-.744Q131.863,265.47,131.863,262.474Z" transform="translate(-118.4 -250.362)" fill="#707070" />
                                                            <path id="Path_3685" data-name="Path 3685" d="M-107.746,355.6q0-1.65,0-3.3a.668.668,0,0,1,.74-.741h3.868a.681.681,0,0,1,.777.768q0,3.28,0,6.559a.674.674,0,0,1-.751.751H-107a.664.664,0,0,1-.742-.736q0-1.65,0-3.3Z" transform="translate(107.746 -340.792)" fill="#707070" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </i>
                                            Analytics
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="sidebar-nav-item" to="#">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.846" height="18.847" viewBox="0 0 18.846 18.847">
                                                    <path id="Path_3686" data-name="Path 3686" d="M633.431-83.674a8.121,8.121,0,0,1-.96-.29A3.193,3.193,0,0,1,630.59-87.3c.017-.132-.061-.154-.139-.2L625.6-90.15c-.338-.185-.682-.361-1.013-.558-.135-.08-.2-.05-.3.054a3.4,3.4,0,0,1-3.377.99,3.38,3.38,0,0,1-2.573-2.471,3.52,3.52,0,0,1,2.916-4.457,3.454,3.454,0,0,1,3.061,1.086.168.168,0,0,0,.245.04q2.956-1.618,5.916-3.227c.077-.042.135-.075.119-.192a3.227,3.227,0,0,1,2.243-3.483,3.237,3.237,0,0,1,4.169,2.6.9.9,0,0,0,.036.122v.736a.936.936,0,0,0-.036.122,3.253,3.253,0,0,1-2.467,2.668,3.244,3.244,0,0,1-3.434-1.365c-.033-.049-.068-.1-.1-.141a.34.34,0,0,0-.051.015q-2.9,1.583-5.8,3.163c-.108.059-.108.114-.072.217a3.335,3.335,0,0,1,.013,2.216c-.055.163-.022.229.123.308q2.293,1.24,4.58,2.492l1.208.656c.044-.065.077-.116.112-.165a3.131,3.131,0,0,1,3.243-1.375,3.113,3.113,0,0,1,2.55,2.266c.058.179.089.367.133.551v.736a1.08,1.08,0,0,0-.036.122,3.173,3.173,0,0,1-2.008,2.52,6.507,6.507,0,0,1-.826.229Z" transform="translate(-618.191 102.52)" fill="#707070" />
                                                </svg>
                                            </i>
                                            Share your profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/privacy') && 'active'}`} to="/privacy">
                                            <i class="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g id="vuesax_bold_eye-slash" data-name="vuesax/bold/eye-slash" transform="translate(-172 -188)"><path id="Vector" d="M4.88.84.84,4.88A2.9,2.9,0,0,1,0,2.86,2.866,2.866,0,0,1,2.86,0,2.9,2.9,0,0,1,4.88.84Z" transform="translate(181.14 197.14)" fill="#707070"></path><path id="Vector-2" data-name="Vector" d="M3.03.17A2.866,2.866,0,0,1,.17,3.03.5.5,0,0,1,0,3.01L3.01,0A.5.5,0,0,1,3.03.17Z" transform="translate(183.83 199.83)" fill="#707070"></path><g id="Group_2322" data-name="Group 2322"><path id="Vector-3" data-name="Vector" d="M13.213,1.671a14.129,14.129,0,0,0-.92-1.29A1,1,0,0,0,10.8.291l-3,3a3.922,3.922,0,0,1,.06,2.21,4.021,4.021,0,0,1-2.9,2.9,3.922,3.922,0,0,1-2.21-.06L.293,10.8a1,1,0,0,0,.33,1.64,9.221,9.221,0,0,0,3.32.62,9.682,9.682,0,0,0,5.09-1.49,13.427,13.427,0,0,0,4.23-4.34A5.78,5.78,0,0,0,13.213,1.671Z" transform="translate(180.057 195.51)" fill="#707070"></path><path id="Vector-4" data-name="Vector" d="M16.25,2.32,12.86,5.71A3.986,3.986,0,0,0,10,4.53a4.045,4.045,0,0,0-2.86,6.9L3.76,14.82H3.75a14.283,14.283,0,0,1-3-3.41,5.771,5.771,0,0,1,0-5.69A13.354,13.354,0,0,1,4.91,1.49,9.774,9.774,0,0,1,10,0,9.936,9.936,0,0,1,16.25,2.32Z" transform="translate(174 191.43)" fill="#707070"></path><path id="Vector-5" data-name="Vector" d="M19.765.225a.773.773,0,0,0-1.09,0L.225,18.685a.773.773,0,0,0,0,1.09.791.791,0,0,0,.54.22.758.758,0,0,0,.54-.23L19.765,1.3A.743.743,0,0,0,19.765.225Z" transform="translate(174.005 190.005)" fill="#707070"></path></g><path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(196 212) rotate(180)" fill="none" opacity="0"></path></g></svg>
                                            </i>
                                            Privacy
                                        </Link>
                                    </li>
                                </>
                            }
                            <li>
                                <Link className={`sidebar-nav-item ${pathname.match('/termsAndConditions') && 'active'}`} to="/termsAndConditions">
                                    <i className='icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="25" viewBox="0 0 19 25">
                                            <g id="Group_2269" data-name="Group 2269" transform="translate(-125 -622.997)">
                                                <g id="term_conditions" transform="translate(125 622.997)">
                                                    <path id="Path_3687" data-name="Path 3687" d="M74.105,7.324A1.709,1.709,0,0,1,72.4,5.615V0H63.679A2.686,2.686,0,0,0,61,2.686V22.314A2.686,2.686,0,0,0,63.679,25H77.321A2.686,2.686,0,0,0,80,22.314V7.324Zm-9.11,10.254h3.543a.732.732,0,0,1,0,1.465H64.995a.732.732,0,0,1,0-1.465ZM64.264,14.4a.732.732,0,0,1,.731-.732H75.713a.732.732,0,0,1,0,1.465H64.995A.732.732,0,0,1,64.264,14.4ZM75.713,9.766a.732.732,0,0,1,0,1.465H64.995a.732.732,0,0,1,0-1.465Z" transform="translate(-61)" fill="#707070" />
                                                    <path id="Path_3688" data-name="Path 3688" d="M325,11.477a.242.242,0,0,0,.242.242h5.524a2.651,2.651,0,0,0-.508-.664L325.6,6.647a2.665,2.665,0,0,0-.6-.426v5.256Z" transform="translate(-312.089 -5.92)" fill="#707070" />
                                                </g>
                                            </g>
                                        </svg>
                                    </i>
                                    Terms and conditions
                                </Link>
                            </li>
                            <li>
                                <Link className={`sidebar-nav-item ${pathname.match('/privacyPolicy') && 'active'}`} to="/privacyPolicy">
                                    <i className='icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="25" viewBox="0 0 19 25">
                                            <g id="Group_2264" data-name="Group 2264" transform="translate(-121.132 -553.478)">
                                                <path id="Path_3689" data-name="Path 3689" d="M702.982,461.6v1.655c0,1.252-.005,2.5,0,3.756,0,.686.26.93.949.931,1.767,0,3.534,0,5.352,0,.009.2.023.368.023.54,0,5.024-.028,10.048.015,15.072a2.865,2.865,0,0,1-3.059,3.044c-4.346-.062-8.694-.021-13.041-.022a2.676,2.676,0,0,1-2.893-2.878q-.014-9.609,0-19.218a2.675,2.675,0,0,1,2.889-2.882h9.762Zm2.432,13.148c0-.813.01-1.626,0-2.439-.008-.543-.25-.829-.73-.851a2.413,2.413,0,0,1-2.2-1.429.879.879,0,0,0-.892-.532c-1.119.014-2.239.029-3.357-.005a1.089,1.089,0,0,0-1.157.673,2.272,2.272,0,0,1-1.937,1.283c-.714.071-.912.258-.915.971-.006,1.3-.016,2.6,0,3.9a7.518,7.518,0,0,0,5.066,7.181,1.734,1.734,0,0,0,1.065,0A7.428,7.428,0,0,0,705,478.845,11.69,11.69,0,0,0,705.414,474.751Z" transform="translate(-569.192 91.875)" fill="#707070" />
                                                <path id="Path_3690" data-name="Path 3690" d="M835.173,467.939l4.182,4.175h-4.182Z" transform="translate(-699.854 86.153)" fill="#707070" />
                                                <path id="Path_3691" data-name="Path 3691" d="M753.413,559.19c-.11,1.74.217,3.474-.264,5.159a5.755,5.755,0,0,1-3.471,3.876,1.078,1.078,0,0,1-.767,0,5.723,5.723,0,0,1-3.67-5.055c-.1-1.309-.018-2.632-.018-3.981a4.053,4.053,0,0,0,2.5-1.725.5.5,0,0,1,.361-.143c.8-.013,1.607-.015,2.411,0a.543.543,0,0,1,.389.18A4.03,4.03,0,0,0,753.413,559.19Zm-4.837,4.064c-.293-.32-.528-.605-.794-.86a.7.7,0,0,0-1.031-.054.719.719,0,0,0,.016,1.071q.629.664,1.292,1.3a.7.7,0,0,0,1.071.025q1.381-1.343,2.722-2.726a.687.687,0,0,0-.024-1.031.7.7,0,0,0-1,.018c-.122.1-.231.223-.344.337Z" transform="translate(-618.668 5.606)" fill="#707070" />
                                            </g>
                                        </svg>
                                    </i>
                                    Privacy policy
                                </Link>
                            </li>
                            <li>
                                <Link className={`sidebar-nav-item ${pathname.match('/contactUs') && 'active'}`} to="/contactUs">
                                    <i className='icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.846" height="19.992" viewBox="0 0 18.846 19.992">
                                            <g id="Group_2265" data-name="Group 2265" transform="translate(-119.488 -592.788)">
                                                <path id="Path_3692" data-name="Path 3692" d="M1387.919,289.169q.111-.853.222-1.706a7.371,7.371,0,0,1,.109-.774,1.318,1.318,0,0,0-.14-.907c-.02-.043-.043-.086-.06-.131a.429.429,0,0,1,.425-.639h.968a.443.443,0,0,1,.407.612.267.267,0,0,1-.035.074,1.406,1.406,0,0,0-.173,1.188c.115.676.182,1.359.271,2.039a.777.777,0,0,0,.052.2,40.558,40.558,0,0,0,1.47-4.621c.353.076.7.14,1.042.225,1.4.351,2.8.711,4.2,1.064a1.514,1.514,0,0,1,1.057.926,7.865,7.865,0,0,1,.569,2.62c.029.424.045.85.075,1.274a.284.284,0,0,1-.2.334c-.624.2-1.243.428-1.875.6a22.047,22.047,0,0,1-4.2.671,38.9,38.9,0,0,1-4.794.078,26.146,26.146,0,0,1-6.883-1.113c-.241-.077-.474-.181-.711-.27a.258.258,0,0,1-.177-.29c.052-.759.067-1.524.167-2.277a6.933,6.933,0,0,1,.4-1.5,1.561,1.561,0,0,1,1.135-1.01c1.661-.416,3.319-.846,4.979-1.272.134-.034.2-.011.25.142.442,1.448.893,2.893,1.343,4.338a.906.906,0,0,0,.061.126Z" transform="translate(-1260.052 320.437)" fill="#707070" />
                                                <path id="Path_3693" data-name="Path 3693" d="M1528.882,39.252a6.1,6.1,0,0,1-1.2,2.386,2.637,2.637,0,0,1-1.292.943,2.356,2.356,0,0,1-2.331-.544,5.943,5.943,0,0,1-1.589-2.833,7.691,7.691,0,0,1-.316-3.03,3.05,3.05,0,0,1,2.718-2.863,4.125,4.125,0,0,1,2.961.46,2.916,2.916,0,0,1,1.453,2.469,13.388,13.388,0,0,1-.189,2.062.241.241,0,0,1-.13.157,8.564,8.564,0,0,1-2.592.6.372.372,0,0,1-.243-.112.782.782,0,0,0-.611-.24c-.239.014-.48,0-.721.005a.76.76,0,1,0-.052,1.518,5.435,5.435,0,0,0,.984-.017.719.719,0,0,0,.4-.252.532.532,0,0,1,.392-.21A9.751,9.751,0,0,0,1528.882,39.252Z" transform="translate(-1396.746 561.351)" fill="#707070" />
                                                <path id="Path_3694" data-name="Path 3694" d="M1472.19-10.039a5.128,5.128,0,0,1,4.494,2.614,9.688,9.688,0,0,1,.581,1.29c.048.115.094.2.228.226a.394.394,0,0,1,.328.325,3.591,3.591,0,0,1-.137,2.454c-.155.355-.24.412-.626.392-.192-.01-.382-.034-.574-.05-.217-.018-.286-.1-.261-.323.1-.831.194-1.662.284-2.494a.861.861,0,0,0-.025-.365,4.382,4.382,0,0,0-3.313-3.121,4.411,4.411,0,0,0-5.323,3,1.624,1.624,0,0,0-.047.77c.1.762.181,1.525.266,2.289.018.164-.062.257-.235.268-.239.016-.479.032-.717.061a.391.391,0,0,1-.446-.286,10.746,10.746,0,0,1-.339-1.18,3.044,3.044,0,0,1,.131-1.461.325.325,0,0,1,.293-.275c.139-.013.191-.1.232-.221a6.122,6.122,0,0,1,1.093-2.059,5.1,5.1,0,0,1,3.967-1.859Z" transform="translate(-1343.218 602.828)" fill="#707070" />
                                            </g>
                                        </svg>
                                    </i>
                                    Contact us
                                </Link>
                            </li>
                            <li>
                                <Link className="sidebar-nav-item" to="#">
                                    <i className='icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.846" height="18.882" viewBox="0 0 18.846 18.882">
                                            <path id="Path_3680" data-name="Path 3680" d="M352.823,178.68a9.711,9.711,0,0,1-3.34-18.824.639.639,0,0,1,.711.113.631.631,0,0,1,.151.721,8.182,8.182,0,0,0-.422,3.706,8.426,8.426,0,0,0,11.115,7.035.657.657,0,0,1,.744.128.639.639,0,0,1,.116.743,9.53,9.53,0,0,1-7.366,6.177C353.971,178.591,353.393,178.615,352.823,178.68Z" transform="translate(-343.118 -159.798)" fill="#707070" />
                                        </svg>
                                    </i>
                                    Dark mode
                                    <i className='icon-arrow'>
                                        <AntSwitch checked={lightMode} onClick={() => this.props.toggleDarkMode(!lightMode)} />
                                    </i>
                                </Link>
                            </li>
                            {auth &&
                                <>
                                    <li>
                                        <Link className="sidebar-nav-item" to="/" onClick={this.handleLogout}>
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                                    <g id="Group_2267" data-name="Group 2267" transform="translate(-118.988 -671.409)">
                                                        <path id="Path_3697" data-name="Path 3697" d="M11.779,10.3a.788.788,0,0,0-.785.792v3.167a.789.789,0,0,1-.785.792H7.852V3.171a1.6,1.6,0,0,0-1.069-1.5L6.55,1.587h3.658a.789.789,0,0,1,.785.792V4.754a.785.785,0,1,0,1.57,0V2.379A2.368,2.368,0,0,0,10.208,0H1.767a.612.612,0,0,0-.084.017C1.645.018,1.609,0,1.571,0A1.578,1.578,0,0,0,0,1.587v14.25a1.6,1.6,0,0,0,1.069,1.5L5.8,18.93A1.613,1.613,0,0,0,6.282,19a1.578,1.578,0,0,0,1.57-1.583v-.792h2.356a2.368,2.368,0,0,0,2.356-2.375V11.087A.788.788,0,0,0,11.779,10.3Zm0,0" transform="translate(118.988 671.405)" fill="#707070" />
                                                        <path id="Path_3698" data-name="Path 3698" d="M285.74,110.037,282.6,106.9a.785.785,0,0,0-1.34.555v2.356h-3.141a.785.785,0,1,0,0,1.57h3.141v2.356a.785.785,0,0,0,1.34.555l3.141-3.141A.784.784,0,0,0,285.74,110.037Zm0,0" transform="translate(-147.982 568.725)" fill="#707070" />
                                                    </g>
                                                </svg>
                                            </i>
                                            Log out
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/deleteAccount') && 'active'}`} to="/deleteAccount">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24">
                                                    <g id="Group_2266" data-name="Group 2266" transform="translate(-120.66 -632.102)">
                                                        <path id="Path_3695" data-name="Path 3695" d="M65.173,2.9H61.154V2.175A2.183,2.183,0,0,0,58.962,0H56.038a2.183,2.183,0,0,0-2.192,2.175V2.9H49.827A1.82,1.82,0,0,0,48,4.711v1.45a.728.728,0,0,0,.731.725H66.269A.728.728,0,0,0,67,6.161V4.711A1.82,1.82,0,0,0,65.173,2.9Zm-9.865-.725a.729.729,0,0,1,.731-.725h2.923a.729.729,0,0,1,.731.725V2.9H55.308Z" transform="translate(72.66 632.102)" fill="#707070" />
                                                        <path id="Path_3696" data-name="Path 3696" d="M73.593,184a.227.227,0,0,0-.228.237l.6,12.551a2.183,2.183,0,0,0,2.192,2.071H87.27a2.183,2.183,0,0,0,2.192-2.071l.6-12.551a.227.227,0,0,0-.228-.237Zm11.049,1.812a.732.732,0,0,1,1.463,0v9.423a.732.732,0,0,1-1.463,0Zm-3.658,0a.732.732,0,0,1,1.463,0v9.423a.732.732,0,0,1-1.463,0Zm-3.658,0a.732.732,0,0,1,1.463,0v9.423a.732.732,0,0,1-1.463,0Z" transform="translate(48.445 457.243)" fill="#707070" />
                                                    </g>
                                                </svg>
                                            </i>
                                            Delete your account
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`sidebar-nav-item ${pathname.match('/BlockedAccounts') && 'active'}`} to="/BlockedAccounts">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.212" height="20.188" viewBox="0 0 14.212 20.188">
                                                    <g id="Group_2617" data-name="Group 2617" transform="translate(-144.96 -209)">
                                                        <path id="Vector" d="M4.795,0a4.79,4.79,0,0,0-.121,9.579.814.814,0,0,1,.222,0h.071A4.791,4.791,0,0,0,4.795,0Z" transform="translate(147.271 209)" fill="#707070" />
                                                        <path id="Vector-2" data-name="Vector" d="M12.234,1.408a10.022,10.022,0,0,0-10.245,0A3.984,3.984,0,0,0,0,4.668a3.951,3.951,0,0,0,1.978,3.24A9.326,9.326,0,0,0,7.106,9.332a9.326,9.326,0,0,0,5.128-1.423,3.982,3.982,0,0,0,1.978-3.26A3.974,3.974,0,0,0,12.234,1.408ZM9.064,5.557a.762.762,0,0,1,0,1.07.755.755,0,0,1-1.07,0l-.888-.888-.888.888a.755.755,0,0,1-1.07,0,.762.762,0,0,1,0-1.07l.888-.888L5.148,3.78a.757.757,0,0,1,1.07-1.07l.888.888.888-.888a.757.757,0,0,1,1.07,1.07l-.888.888Z" transform="translate(144.96 219.856)" fill="#707070" />
                                                    </g>
                                                </svg>
                                            </i>
                                            Blocked account
                                        </Link>
                                    </li>
                                </>
                            }
                        </ul>
                        {auth &&
                            <>
                                <button className="sidebar-nav-item loadmore-btn" onClick={this.toggle.bind(this)}>
                                    <i className='icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13">
                                            <path id="Settings_arrow" data-name="Settings arrow" d="M13.361,12.692l4.633-4.915a.966.966,0,0,0,0-1.312.843.843,0,0,0-1.239,0l-5.249,5.569a.969.969,0,0,0-.026,1.281l5.271,5.608a.844.844,0,0,0,1.239,0,.966.966,0,0,0,0-1.312Z" transform="translate(18.251 19.194) rotate(180)" fill="#9f9f9f" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13">
                                            <path id="Settings_arrow" data-name="Settings arrow" d="M13.361,12.692l4.633-4.915a.966.966,0,0,0,0-1.312.843.843,0,0,0-1.239,0l-5.249,5.569a.969.969,0,0,0-.026,1.281l5.271,5.608a.844.844,0,0,0,1.239,0,.966.966,0,0,0,0-1.312Z" transform="translate(18.251 19.194) rotate(180)" fill="#9f9f9f" />
                                        </svg>
                                    </i>
                                    {this.state.addClass ? "See Less" : "See More"}
                                </button>
                            </>
                        }
                    </div>

                </div>
            </div >
        );
    }
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const mapDispatchToProps = {
    toggleDarkMode, getProfilesDetails, toggleLoader, logout
};

const mapStateToProps = ({ Auth }) => {
    let { auth, userID, profileDetails, lightMode } = Auth;
    return { auth, userID, profileDetails, lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
