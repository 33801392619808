import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import Switch from '@material-ui/core/Switch';
import { styled } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

import './index.css';
import '../../static/css/animate.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";


class Privacy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShareModal: false,
        };
    };

    render() {
        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec privacy-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-4 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-8 col-md-12'>
                                <div className='privacy-form'>
                                    <h3>Privacy</h3>
                                    <div className='privacy-block'>
                                        <div className='left-content-area'>
                                            <div className='icon'>
                                                <img src={require("../../static/images/lock.svg")} alt="" />
                                            </div>
                                            <p>Private account</p>
                                        </div>
                                        <div className='right-area'>
                                            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                                        </div>
                                    </div>
                                    <div className='privacy-block'>
                                        <div className='left-content-area'>
                                            <div className='icon'>
                                                <img src={require("../../static/images/comment.svg")} alt="" />
                                            </div>
                                            <p>Comment</p>
                                        </div>
                                        <div className='right-area'>
                                            <div className='sortby-dropdrown dropdown'>
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Everyone
                                                    <i className='icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="6.021" height="11.181" viewBox="0 0 6.021 11.181">
                                                            <path id="Settings_arrow" data-name="Settings arrow" d="M13.066,11.783,17.05,7.555a.831.831,0,0,0,0-1.128.725.725,0,0,0-1.066,0l-4.515,4.79a.833.833,0,0,0-.022,1.1l4.533,4.823a.726.726,0,0,0,1.066,0,.831.831,0,0,0,0-1.128Z" transform="translate(17.271 17.375) rotate(180)" fill="#9f9f9f" />
                                                        </svg>
                                                    </i>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <h5>Comments</h5>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio checked />
                                                            <lable>Everyone</lable>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio />
                                                            <lable>People You Follow</lable>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio />
                                                            <lable>No One</lable>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='privacy-block'>
                                        <div className='left-content-area'>
                                            <div className='icon'>
                                                <img src={require("../../static/images/mention.svg")} alt="" />
                                            </div>
                                            <p>Mention</p>
                                        </div>
                                        <div className='right-area'>
                                            <div className='sortby-dropdrown dropdown'>
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Everyone
                                                    <i className='icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="6.021" height="11.181" viewBox="0 0 6.021 11.181">
                                                            <path id="Settings_arrow" data-name="Settings arrow" d="M13.066,11.783,17.05,7.555a.831.831,0,0,0,0-1.128.725.725,0,0,0-1.066,0l-4.515,4.79a.833.833,0,0,0-.022,1.1l4.533,4.823a.726.726,0,0,0,1.066,0,.831.831,0,0,0,0-1.128Z" transform="translate(17.271 17.375) rotate(180)" fill="#9f9f9f" />
                                                        </svg>
                                                    </i>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <h5>Comments</h5>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio checked />
                                                            <lable>Everyone</lable>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio />
                                                            <lable>People You Follow</lable>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio />
                                                            <lable>No One</lable>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='privacy-block'>
                                        <div className='left-content-area'>
                                            <div className='icon'>
                                                <img src={require("../../static/images/tag.svg")} alt="" />
                                            </div>
                                            <p>Tags</p>
                                        </div>
                                        <div className='right-area'>
                                            <div className='sortby-dropdrown dropdown'>
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Everyone
                                                    <i className='icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="6.021" height="11.181" viewBox="0 0 6.021 11.181">
                                                            <path id="Settings_arrow" data-name="Settings arrow" d="M13.066,11.783,17.05,7.555a.831.831,0,0,0,0-1.128.725.725,0,0,0-1.066,0l-4.515,4.79a.833.833,0,0,0-.022,1.1l4.533,4.823a.726.726,0,0,0,1.066,0,.831.831,0,0,0,0-1.128Z" transform="translate(17.271 17.375) rotate(180)" fill="#9f9f9f" />
                                                        </svg>
                                                    </i>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <h5>Comments</h5>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio checked />
                                                            <lable>Everyone</lable>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio />
                                                            <lable>People You Follow</lable>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio />
                                                            <lable>No One</lable>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='privacy-block last-block'>
                                        <div className='left-content-area'>
                                            <div className='icon'>
                                                <img src={require("../../static/images/block.svg")} alt="" />
                                            </div>
                                            <p>Block account</p>
                                        </div>
                                        <div className='right-area'>
                                            <div className='sortby-dropdrown dropdown'>
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Everyone
                                                    <i className='icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="6.021" height="11.181" viewBox="0 0 6.021 11.181">
                                                            <path id="Settings_arrow" data-name="Settings arrow" d="M13.066,11.783,17.05,7.555a.831.831,0,0,0,0-1.128.725.725,0,0,0-1.066,0l-4.515,4.79a.833.833,0,0,0-.022,1.1l4.533,4.823a.726.726,0,0,0,1.066,0,.831.831,0,0,0,0-1.128Z" transform="translate(17.271 17.375) rotate(180)" fill="#9f9f9f" />
                                                        </svg>
                                                    </i>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <h5>Comments</h5>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio checked />
                                                            <lable>Everyone</lable>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio />
                                                            <lable>People You Follow</lable>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item" href="#">
                                                        <div className='group-form'>
                                                            <Radio />
                                                            <lable>No One</lable>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div >
        );
    }
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#707070',
        boxSizing: 'border-box',
    },
}));

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);