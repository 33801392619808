import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { styled } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ProgressBar from 'react-bootstrap/ProgressBar';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import { toggleLoader } from "../../store/actions/Auth";
import { HashLink } from 'react-router-hash-link';


class SponsoredDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dob: new Date('1995-09-18T21:11:54'),
        };
    };


    render() {

        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='nft-upload-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sec-title'>
                                    <h2>Sponsor Your NFT</h2>
                                    <p>Please give the description</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                <div className='nft-upload-btn-area style-two'>
                                    <div className='upload-btn'>
                                        <div className='image-area style-two'>
                                            <img src={require('../../static/images/nft-upload.png')} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-12'>
                                <div className='form-area'>
                                    <ValidatorForm className="validatorForm" >
                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <label>Title</label>
                                                <CustomTextField
                                                    fullWidth
                                                    className="MyTextField"
                                                    autoComplete='off'
                                                    placeholder="Certified NFTs Seller #Digital"
                                                    name="Name"
                                                    type="text"
                                                    // value={email}
                                                    variant="outlined"
                                                    margin="dense"
                                                    // onKeyPress={this.keyPressed}
                                                    // onChange={this.handleFormChange}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['Please add Your Certified NFTs Seller', 'Certified NFTs Seller is not valid']}
                                                />
                                                <span className='check'><img src={require('../../static/images/check-nft.png')} alt='' /></span>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <label>Total Budget</label>
                                                <CustomTextField
                                                    fullWidth
                                                    className="MyTextField"
                                                    autoComplete='off'
                                                    placeholder="0.02 ETH"
                                                    name="Name"
                                                    type="text"
                                                    // value={email}
                                                    variant="outlined"
                                                    margin="dense"
                                                    // onKeyPress={this.keyPressed}
                                                    // onChange={this.handleFormChange}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['Please add Your Budget', 'Budget is not valid']}
                                                />
                                                <span className='check'><img src={require('../../static/images/check-nft.png')} alt='' /></span>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <label>Duration</label>
                                                <Select className='custom-menu'
                                                    value="none"
                                                    variant="outlined"
                                                    // onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='custom-menu'>
                                                        3 Days
                                                    </MenuItem>
                                                    <MenuItem className='custom-menu'>
                                                        5 Days
                                                    </MenuItem>
                                                    <MenuItem className='custom-menu'>
                                                        7 Days
                                                    </MenuItem>
                                                    <MenuItem className='custom-menu'>
                                                        14 Days
                                                    </MenuItem>
                                                    <MenuItem className='custom-menu'>
                                                        Custom
                                                    </MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={6}>
                                                <label>Date of Start</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <span className='icon-date'><img src={require('../../static/images/calender_sponsor.png')} alt='' /></span>
                                                    <DatePicker
                                                        fullWidth
                                                        variant="outlined"
                                                        className='MyTextField'
                                                        name="dob"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        placeholder="From"
                                                        format="MM/dd/yyyy"
                                                        // value={dob}
                                                        // onChange={(date) => this.setDateOfBirth(date)}
                                                        KeyboardButtonProps={{ 'aria-label': 'From' }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid className="input-fields" item xs={6}>
                                                <label>Date of End</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <span className='icon-date'><img src={require('../../static/images/calender_sponsor.png')} alt='' /></span>
                                                    <DatePicker
                                                        fullWidth
                                                        variant="outlined"
                                                        className='MyTextField'
                                                        name="dob"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        placeholder="To"
                                                        format="MM/dd/yyyy"
                                                        // value={dob}
                                                        // onChange={(date) => this.setDateOfBirth(date)}
                                                        KeyboardButtonProps={{ 'aria-label': 'To' }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                            <Grid className="input-fields" item xs={12}>
                                                <div className='progress-bar-country'>
                                                    <h5>Estimated People Reached</h5>
                                                    <div className='detail-box'>
                                                        <p>2,400 - 14,800 people per day</p>
                                                        <p>of 50,000,000 </p>
                                                    </div>
                                                    <ProgressBar now={8.43} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className='group-form start-group'>
                                            <button className='btn-style-one'>Start</button>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}
const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const mapDispatchToProps = {  };

const mapStateToProps = ({ Auth }) => {
  let { lightMode } = Auth;
  return { lightMode };
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsoredDetails);