export const uploadPost = ({ data, history }) => ({
    type: 'UPLOAD_POST',
    payload: data,
    history
});

export const uploadPostLoader = (data) => ({
    type: 'UPLOAD_POST_LOADER',
    payload: data
});

export const getAllPosts = () => ({
    type: 'GET_ALL_POSTS',
});

export const allPostsLoader = (data) => ({
    type: 'ALL_POSTS_LOADER',
    payload: data
});

export const setAllPosts = (data) => ({
    type: 'SET_ALL_POSTS',
    payload: data
});

export const getSinglePost = (data) => ({
    type: 'GET_SINGLE_POST',
    payload: data
});

export const singlePostLoader = (data) => ({
    type: 'SINGLE_POST_LOADER',
    payload: data
});

export const setSinglePost = (data) => ({
    type: 'SET_SINGLE_POST',
    payload: data
});

export const getUserPosts = (data) => ({
    type: 'GET_USER_POSTS',
    payload: data
});

export const userPostsLoader = (data) => ({
    type: 'USER_POSTS_LOADER',
    payload: data
});

export const setUserPosts = (data) => ({
    type: 'SET_USER_POSTS',
    payload: data
});

export const postLikeDislike = ({ data, id }) => ({
    type: 'POST_LIKE_DISLIKE',
    payload: data,
    id
});

export const sendPostComment = ({ data, id }) => ({
    type: 'SEND_POST_COMMENT',
    payload: data,
    id
});

export const sendCommentLoader = (data) => ({
    type: 'SEND_COMMENT_LOADER',
    payload: data
});

export const getAllComments = (data) => ({
    type: 'GET_ALL_COMMENTS',
    payload: data
});

export const setAllComments = (data) => ({
    type: 'SET_ALL_COMMENTS',
    payload: data
});

export const setCommentLike = (data) => ({
    type: 'SET_COMMENT_LIKE',
    payload: data
});

export const toggleDeleteCommentModal = (data) => ({
    type: 'TOGGLE_DELETE_COMMENT_MODAL',
    payload: data
});

export const deletePostComment = ({ data, id }) => ({
    type: 'DELETE_POST_COMMENT',
    payload: data,
    id
});

export const editPostComment = ({ data, id }) => ({
    type: 'EDIT_POST_COMMENT',
    payload: data,
    id
});

