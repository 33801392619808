import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { } from "../../store/actions/Auth";


class SponsorNfts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShareModal: false,
            isUploadNftModal: false,
        };
    };

    componentDidMount() {
    };

    toggleShareModal = () => this.setState({ isShareModal: !this.state.isShareModal });
    toggleUploadNftModal = () => this.setState({ isUploadNftModal: !this.state.isUploadNftModal });

    render() {

        let { isShareModal, isUploadNftModal } = this.state;
        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec sponser-nft-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-4 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-8 col-md-12'>
                                {/* empty screen show this */}
                                <div className='sponser-nft-bock'>
                                    <div className='no-items-found'>
                                        <img src={require("../../static/images/no_items_found.png")} alt="" />
                                        <p>No Items Found</p>
                                    </div>
                                    <div className='add-plus'>
                                        <button className='plus-btn' onClick={this.toggleShareModal}><img src={require("../../static/images/plus-bttn.png")} alt="" /></button>
                                    </div>
                                </div>

                                {/* <div className='sponser-nft-show'>
                                    <h3>Sponsored NFTs</h3>
                                    <div className='row'>
                                        <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12'>
                                            <div className='nft-box'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/sponser-nft-1.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='nft-detail-box'>
                                                        <h5>Spent</h5>
                                                        <p>0.0056 ETH</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>Click</h5>
                                                        <p>45</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>CPC</h5>
                                                        <p>0.0001 ETH</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12'>
                                            <div className='nft-box'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/sponser-nft-2.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='nft-detail-box'>
                                                        <h5>Spent</h5>
                                                        <p>0.0056 ETH</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>Click</h5>
                                                        <p>45</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>CPC</h5>
                                                        <p>0.0001 ETH</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12'>
                                            <div className='nft-box'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/sponser-nft-3.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='nft-detail-box'>
                                                        <h5>Spent</h5>
                                                        <p>0.0056 ETH</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>Click</h5>
                                                        <p>45</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>CPC</h5>
                                                        <p>0.0001 ETH</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12'>
                                            <div className='nft-box'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/sponser-nft-4.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='nft-detail-box'>
                                                        <h5>Spent</h5>
                                                        <p>0.0056 ETH</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>Click</h5>
                                                        <p>45</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>CPC</h5>
                                                        <p>0.0001 ETH</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12'>
                                            <div className='nft-box'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/sponser-nft-5.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='nft-detail-box'>
                                                        <h5>Spent</h5>
                                                        <p>0.0056 ETH</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>Click</h5>
                                                        <p>45</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>CPC</h5>
                                                        <p>0.0001 ETH</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12'>
                                            <div className='nft-box'>
                                                <div className='img-box'>
                                                    <img src={require("../../static/images/sponser-nft-6.png")} alt="" />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='nft-detail-box'>
                                                        <h5>Spent</h5>
                                                        <p>0.0056 ETH</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>Click</h5>
                                                        <p>45</p>
                                                    </div>
                                                    <div className='nft-detail-box'>
                                                        <h5>CPC</h5>
                                                        <p>0.0001 ETH</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> */}

                            </div>

                        </div>
                    </div>
                </section>

                {/* --------------- Share Post MODAL--------------- */}
                <Modal isOpen={isShareModal} toggle={this.toggleShareModal} className={`main-modal share-post-modals ${lightMode ? 'dark' : 'light'}`}>
                    {/* <ModalHeader toggle={this.toggleShareModal}></ModalHeader> */}
                    <ModalBody>
                        <div className='content-area'>
                            <h4>Select NFT</h4>
                            <div className='nft-select-area'>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-1.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-2.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-3.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-4.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-5.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-6.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-7.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-8.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-9.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-10.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-11.png')} alt='' />
                                    </div>
                                </div>
                                <div className='nft-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/selec-nft-12.png')} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >


                {/* --------------- Upload Nft MODAL--------------- */}
                {/* <Modal isOpen={isUploadNftModal} toggle={this.toggleUploadNftModal} className={`main-modal share-post-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalHeader toggle={this.toggleUploadNftModal}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className='upload-area'>
                                <div className='inner-area'>
                                    <div className='icon-camera'>
                                        <img src={require("../../static/images/camera.png")} alt="" />
                                    </div>
                                    <p>Drag a file to upload</p>
                                    <div className='upload-btn'>
                                        <button class="btn-style-one">Select From Computer</button>
                                        <input type='file' className='custom-file-input' name='avatar' id='contained-button-file' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ModalBody>
                </Modal > */}
            </div >
        );
    }
}


const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorNfts);