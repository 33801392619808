const { web3 } = require('../web3');
const { env } = require('../config');

const TokenData = require(`./${env}/Token.json`);
const PolygonFactoryData = require(`./${env}/PolygonFactory.json`);
const BSCFactoryData = require(`./${env}/BSCFactory.json`);
const GoerliaFactoryData = require(`./${env}/GoerliaFactory.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const TokenABI = TokenData['abi'];

const PolygonFactoryABI = PolygonFactoryData['abi'];
const PolygonFactoryAddress = PolygonFactoryData['address'];

const BSCFactoryABI = BSCFactoryData['abi'];
const BSCFactoryAddress = BSCFactoryData['address'];

const GoerliaFactoryABI = GoerliaFactoryData['abi'];
const GoerliaFactoryAddress = GoerliaFactoryData['address'];

module.exports = {
  TokenABI,
  PolygonFactoryABI, PolygonFactoryAddress,
  BSCFactoryABI, BSCFactoryAddress,
  GoerliaFactoryABI, GoerliaFactoryAddress
};
