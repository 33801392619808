import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { toggleLoader } from '../actions/Auth';
import { setAllDrops, notableDropLoader, allDropsLoader, setSingleDrop, singleDropLoader } from '../actions/Drop';


/*========== DROP FUNCTIONS =============*/

function* getSingleDrop({ payload }) {
  const { error, response } = yield call(getCall, `/drop/getSingledrop/${payload}`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setSingleDrop(response['data']['body']));
  yield put(singleDropLoader(false));
};

function* getAllDrops() {
  const { error, response } = yield call(getCall, `/drop/getAlldrops`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setAllDrops(response['data']['body']));
  yield put(allDropsLoader(false));
};

function* createNotableDrop({ payload }) {
  const { error, response } = yield call(postCall, { path: '/drop/createdrop', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(notableDropLoader(false));
    EventBus.publish("success", response['data']['message']);
  }
  yield put(toggleLoader({ status: false }));
};

function* actionWatcher() {
  yield takeEvery('GET_SINGLE_DROP', getSingleDrop);
  yield takeEvery('GET_ALL_DROPS', getAllDrops);
  yield takeEvery('CREATE_NOTABLE_DROP', createNotableDrop);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall({ path, payload }) {
  return axios
    .delete(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
