import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { getMyCollections, toggleMyCollections } from "../../store/actions/Collection";
import { Link } from 'react-router-dom';


class MyCollections extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        props.getMyCollections();
        props.toggleMyCollections(true);
    };

    render() {

        let { lightMode, isMyCollection, myCollections } = this.props;

        return (
            <div className={`nfity-page markaetplace-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />

                <section className='home-screen-sec my-collection-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='left-sidebar-area col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='right-content col-xl-9 col-lg-12 col-md-12'>
                                <div className='mycollection-area'>
                                    <div className='top-collection'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='user-accounts'>
                                                    <div className='heading-area'>
                                                        <h3>My Collections</h3>
                                                        <div className='sortby-dropdrown dropdown'>
                                                            {/* <div className="delet-buttons">
                                                                <button className="canecl-btn">Cancel</button>
                                                                <button className="btn-style-two">Select All</button>
                                                                <button className="btn-style-one">Delete</button>
                                                            </div> */}
                                                            {/* <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.893" height="4.723" viewBox="0 0 18.893 4.723">
                                                                    <g id="details_more" transform="translate(-355 -167.599)">
                                                                        <g id="Group_58" data-name="Group 58" transform="translate(373.893 167.599) rotate(90)">
                                                                            <path id="Path_9" data-name="Path 9" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 0)" />
                                                                            <path id="Path_10" data-name="Path 10" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.361,2.361,0,0,1,3.34,0" transform="translate(0 7.085)" />
                                                                            <path id="Path_11" data-name="Path 11" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 14.17)" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <a className="dropdown-item" href="#">
                                                                    <i className='icon'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.833" height="16.5" viewBox="0 0 12.833 16.5">
                                                                            <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.417,19.167A1.839,1.839,0,0,0,10.25,21h7.333a1.839,1.839,0,0,0,1.833-1.833v-11h-11ZM20.333,5.417H17.125L16.208,4.5H11.625l-.917.917H7.5V7.25H20.333Z" transform="translate(-7.5 -4.5)" />
                                                                        </svg>
                                                                    </i>
                                                                    Delete Collections
                                                                </a>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    {isMyCollection
                                                        ? <div className="my-collection-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                                        : <>
                                                            {myCollections && myCollections.length > 0
                                                                ? <div className='row clearfix'>
                                                                    {myCollections.map(collection => {
                                                                        return (
                                                                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                                                                <div className="item">
                                                                                    <div className='user-block'>
                                                                                        <div className='user-detail'>
                                                                                            <Link to={`/collectionDetails/${collection['_id']}`}>
                                                                                                <div className='img-box'>
                                                                                                    <img src={collection['banner']['url']} alt='' />
                                                                                                </div>
                                                                                            </Link>
                                                                                            <div className='content-area'>
                                                                                                <div className='text-box'>
                                                                                                    <Link to={`/collectionDetails/${collection['_id']}`}>
                                                                                                        <div className='img-box'>
                                                                                                            <img src={collection['logo']['url']} alt='' />
                                                                                                        </div>
                                                                                                        <div className='detail-box'>
                                                                                                            <div className='heading-area'>
                                                                                                                <h5>{collection['collectionName']}</h5>
                                                                                                                {collection['isVerified'] &&
                                                                                                                    <span className='check'>
                                                                                                                        <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                                                                    </span>
                                                                                                                }
                                                                                                            </div>
                                                                                                            <p>{collection['category']}</p>
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </div>
                                                                                                <div className='sortby-dropdrown dropdown'>
                                                                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.893" height="4.723" viewBox="0 0 18.893 4.723">
                                                                                                            <g id="details_more" transform="translate(-355 -167.599)">
                                                                                                                <g id="Group_58" data-name="Group 58" transform="translate(373.893 167.599) rotate(90)">
                                                                                                                    <path id="Path_9" data-name="Path 9" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 0)" />
                                                                                                                    <path id="Path_10" data-name="Path 10" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.361,2.361,0,0,1,3.34,0" transform="translate(0 7.085)" />
                                                                                                                    <path id="Path_11" data-name="Path 11" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 14.17)" />
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                                        <Link to={`/editCollection/${collection['_id']}`} className="dropdown-item">
                                                                                                            <i className='icon'>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.992" height="13.992" viewBox="0 0 13.992 13.992">
                                                                                                                    <path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,15.574v2.915H7.415l8.6-8.6L13.1,6.978ZM18.265,7.638a.774.774,0,0,0,0-1.1L16.446,4.724a.774.774,0,0,0-1.1,0L13.928,6.146l2.915,2.915Z" transform="translate(-4.5 -4.496)" />
                                                                                                                </svg>
                                                                                                            </i>
                                                                                                            Edit Collection
                                                                                                        </Link>
                                                                                                        {/* <a className="dropdown-item" href="#">
                                                                                                            <i className='icon'>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.833" height="16.5" viewBox="0 0 12.833 16.5">
                                                                                                                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.417,19.167A1.839,1.839,0,0,0,10.25,21h7.333a1.839,1.839,0,0,0,1.833-1.833v-11h-11ZM20.333,5.417H17.125L16.208,4.5H11.625l-.917.917H7.5V7.25H20.333Z" transform="translate(-7.5 -4.5)" />
                                                                                                                </svg>
                                                                                                            </i>
                                                                                                            Delete Collection
                                                                                                        </a> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }

                                                                </div>
                                                                : <div className='row no-collection'>
                                                                    <h5>No Collection Found!</h5>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div >
        );
    }
};

const mapDispatchToProps = {
    getMyCollections, toggleMyCollections,
};

const mapStateToProps = ({ Auth, Collection }) => {
    let { lightMode } = Auth;
    let { myCollections, isMyCollection } = Collection;
    return { lightMode, myCollections, isMyCollection }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCollections);