import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { styled } from '@material-ui/core/styles';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import { toggleLoader } from "../../store/actions/Auth";
import { getSinglePost, singlePostLoader } from "../../store/actions/Post";


class PostDetail extends Component {

    constructor(props) {
        super(props);
        const query = require('url').parse(document.location.href, true);
        const postId = query.path.split('/')[--query.path.split('/').length];
        this.state = {
            isProfileDoneModal: false,
            isNFTSellModal: false,
        };
        props.getSinglePost(postId);
        props.singlePostLoader(true);
    };

    renderer = ({ hours, minutes, seconds, completed }) => {
        return (
            <div className="countdown-timer-time bis-sale">
                <div className="counter-day counter-hour bid-count">
                    <h5>{hours ? hours : '-'}</h5>
                    <span>Hours</span>
                </div>
                <div className="counter-day counter-minute bid-count">
                    <h5>{minutes ? minutes : '-'}</h5>
                    <span>Minutes</span>
                </div>
                <div className="counter-day counter-seconds bid-count">
                    <h5>{seconds ? seconds : '-'}</h5>
                    <span>Seconds</span>
                </div>
            </div>
        );
    };

    toggleProfileDoneModal = () => this.setState({ isProfileDoneModal: !this.state.isProfileDoneModal });
    toggleNFTSellModal = () => this.setState({ isNFTSellModal: !this.state.isNFTSellModal });

    render() {

        let { isProfileDoneModal, isNFTSellModal } = this.state;
        let { lightMode, publicAddress, isSinglePost, singlePost } = this.props;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='sort-by-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sortby-inner'>
                                    <div className='sortby-dropdrown dropdown'>
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.512" height="16.512" viewBox="0 0 16.512 16.512">
                                                    <g id="vuesax_bold_setting-4" data-name="vuesax/bold/setting-4" opacity="0.4">
                                                        <g id="setting-4">
                                                            <path id="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321A.479.479,0,0,1,4.8.482.479.479,0,0,1,4.321.963Z" transform="translate(10.334 4.252)" fill="#292d32" />
                                                            <path id="Vector-2" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.482.482,0,0,1,0,.963Z" transform="translate(1.376 4.252)" fill="#292d32" />
                                                            <path id="Vector-3" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(4.259 2.016)" fill="#292d32" />
                                                            <path id="Vector-4" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.479.479,0,0,1,.482.482A.479.479,0,0,1,3.041.963Z" transform="translate(11.614 11.29)" fill="#292d32" />
                                                            <path id="Vector-5" data-name="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321a.482.482,0,1,1,0,.963Z" transform="translate(1.376 11.29)" fill="#292d32" />
                                                            <path id="Vector-6" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(6.818 9.061)" fill="#292d32" />
                                                            <path id="Vector-7" data-name="Vector" d="M0,0H16.512V16.512H0Z" transform="translate(16.512 16.512) rotate(180)" fill="none" opacity="0" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </i>
                                            Sort by
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" href="#">Sort by</a>
                                            <a class="dropdown-item" href="#">Sort by</a>
                                            <a class="dropdown-item" href="#">Sort by</a>
                                        </div>
                                    </div>
                                    <div className='right-sort-btn'>
                                        <button className='sort-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="vuesax_bold_grid-2" data-name="vuesax/bold/grid-2" transform="translate(-748 -188)">
                                                    <g id="grid-2">
                                                        <path id="Vector" d="M9.25,0V9.25H5.81C2.17,9.25,0,7.08,0,3.44V0Z" transform="translate(750 200.75)" fill="#292d32" />
                                                        <path id="Vector-2" data-name="Vector" d="M9.25,5.81V9.25H0V0H3.44C7.08,0,9.25,2.17,9.25,5.81Z" transform="translate(760.75 190)" fill="#292d32" />
                                                        <path id="Vector-3" data-name="Vector" d="M9.25,0V9.25H0V5.81C0,2.17,2.17,0,5.81,0Z" transform="translate(750 190)" fill="#292d32" />
                                                        <path id="Vector-4" data-name="Vector" d="M9.25,0V3.44c0,3.64-2.17,5.81-5.81,5.81H0V0Z" transform="translate(760.75 200.75)" fill="#292d32" />
                                                        <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(772 212) rotate(180)" fill="none" opacity="0" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                        <button className='sort-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="vuesax_bold_grid-1" data-name="vuesax/bold/grid-1" transform="translate(-437 -268)" opacity="0.5">
                                                    <g id="grid-1" transform="translate(9 16)">
                                                        <path id="Vector" d="M5.75,0V5.8H0C0,2.19,2.15.02,5.75,0Z" transform="translate(430 254)" fill="#292d32" />
                                                        <path id="Vector-2" data-name="Vector" d="M5.75,5.8H0V0C3.6.02,5.75,2.19,5.75,5.8Z" transform="translate(444.25 254)" fill="#292d32" />
                                                        <path id="Vector-3" data-name="Vector" d="M5.75,0C5.71,3.55,3.57,5.68,0,5.7V0Z" transform="translate(444.25 268.3)" fill="#292d32" />
                                                        <path id="Vector-4" data-name="Vector" d="M5.75,0V5.7C2.18,5.68.04,3.55,0,0Z" transform="translate(430 268.3)" fill="#292d32" />
                                                        <path id="Vector-5" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(430 261.3)" fill="#292d32" />
                                                        <path id="Vector-6" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(444.25 261.3)" fill="#292d32" />
                                                        <path id="Vector-7" data-name="Vector" d="M0,0H5.5V5.5H0Z" transform="translate(437.25 261.3)" fill="#292d32" />
                                                        <path id="Vector-8" data-name="Vector" d="M0,0H5.5V5.8H0Z" transform="translate(437.25 254)" fill="#292d32" />
                                                        <path id="Vector-9" data-name="Vector" d="M0,0H5.5V5.7H0Z" transform="translate(437.25 268.3)" fill="#292d32" />
                                                        <path id="Vector-10" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(452 276) rotate(180)" fill="none" opacity="0" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='profile-detail-page'>
                    <div className='auto-container'>
                        {isSinglePost
                            ? <div className="post-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                            : <div className='row'>
                                <div className='col-lg-5 col-md-12'>
                                    <div className='img-description-area'>
                                        <div className='img-box d-flex justify-content-center'>
                                            {singlePost['data'] &&
                                                <>
                                                    {singlePost['data']['format'] == 'jpg' || singlePost['data']['format'] == 'jpeg' || singlePost['data']['format'] == 'png'
                                                        || singlePost['data']['format'] == 'pnm' || singlePost['data']['format'] == 'bmp' || singlePost['data']['format'] == 'svg'
                                                        ? <img src={singlePost['data']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                        : singlePost['data']['format'] == 'mp4' || singlePost['data']['format'] == 'webm'
                                                            ? <video width="500" autoPlay loop>
                                                                <source src={singlePost['data']['url']} type="video/mp4" />
                                                            </video>
                                                            : singlePost['data']['format'] == 'mp3' || singlePost['data']['format'] == 'mpeg' || singlePost['data']['format'] == 'ogg'
                                                                ? <audio className='audio-file' controls>
                                                                    <source src={singlePost['data']['url']} type="audio/mp3" />
                                                                </audio>
                                                                : <p className="no-preview">No Preview!</p>
                                                    }
                                                </>
                                            }
                                        </div>
                                        {singlePost['description'] &&
                                            <>
                                                <h4>Description</h4>
                                                <p>{singlePost['description']}</p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-7 col-md-12'>
                                    <div className='text-description-area'>
                                        <div className='top-text-box'>
                                            {singlePost['collectionId'] &&
                                                <Link to={`/collectionDetails/${singlePost['collectionId']['_id']}`}>
                                                    <span>{singlePost['collectionId']['collectionName']}</span>
                                                </Link>
                                            }
                                            <ul className='right-button'>
                                                <li>
                                                    <button className='fav-btn'>
                                                        <img src={require('../../static/images/heart-fav.svg')} alt='' />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className='fav-btn'>
                                                        <img src={require('../../static/images/share-btn.svg')} alt='' />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className='fav-btn'>
                                                        <img src={require('../../static/images/details-more.svg')} alt='' />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        {singlePost['name'] &&
                                            <h3>{singlePost['name']}</h3>
                                        }
                                        <div className='owner-suggestion'>
                                            {singlePost['postedBy'] &&
                                                <div className='owner-block'>
                                                    <div className='img-box'>
                                                        <Link to={`/profile/${singlePost['postedBy']['_id']}`}>
                                                            <img src={singlePost['postedBy']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                        </Link>
                                                    </div>
                                                    <div className='text-box'>
                                                        <span>Creator</span>
                                                        <Link to={`/profile/${singlePost['postedBy']['_id']}`}>
                                                            <p>{singlePost['postedBy']['name']}</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            }

                                            {singlePost['owner'] &&
                                                <div className='owner-block'>
                                                    <div className='img-box'>
                                                        <Link to={`/profile/${singlePost['owner']['_id']}`}>
                                                            <img src={singlePost['owner']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                        </Link>
                                                    </div>
                                                    <div className='text-box'>
                                                        <span>Owner</span>
                                                        <Link to={`/profile/${singlePost['owner']['_id']}`}>
                                                            <p>{singlePost['owner']['name']}</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <p className='tokenid'><span>Token ID:</span> {singlePost['tokenId']}</p>
                                        {singlePost['owner'] &&
                                            <>
                                                {publicAddress.toLowerCase() == singlePost['owner']['publicAddress'].toLowerCase()
                                                    ? <>
                                                        <div className='bid-sec text-center'>
                                                            <h5>No events have occurred yet!</h5>
                                                            <p>Check back later.</p>
                                                        </div>
                                                        <div className='bid-btn-area'>
                                                            <div className='bid-btn-sec justify-content-center'>
                                                                <button className='buy-btn' onClick={this.toggleNFTSellModal}>Sell</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <>
                                                        <div className='bid-sec'>
                                                            <h5>Bid End In</h5>
                                                            <div className="countdown-timer timer-coutdwon">
                                                                <div className="timer-inner">
                                                                    <Countdown date={Date.now() + parseInt((9775168972 * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='bid-btn-area'>
                                                            <p>Current Price:</p>
                                                            <h4>15 ETH <span>($16437.22)</span></h4>
                                                            <div className='bid-btn-sec'>
                                                                <button className='buy-btn' onClick={this.toggleProfileDoneModal}>Buy Now</button>
                                                                <button className='bid-btn'>Place a Bid</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section >

                {/* --------------- PROFILE MODAL--------------- */}
                <Modal isOpen={isProfileDoneModal} toggle={this.toggleProfileDoneModal} className={`main-modal profile-done-modal ${lightMode ? 'dark' : 'light'}`}>
                    {/* <ModalHeader toggle={this.toggleProfileDoneModal}></ModalHeader> */}
                    < ModalBody >
                        <div className='content-area'>
                            <div className='check-img'>
                                <img src={require('../../static/images/check.png')} alt='' />
                            </div>
                            <p>Your transaction succeeded</p>
                            <button className='done'>Done</button>
                            <div className='bottom-area'>
                                <p>Display on profile</p>
                                <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                            </div>
                        </div>
                    </ModalBody >
                </Modal >

                {/* --------------- Sell NFT MODAL--------------- */}
                <Modal isOpen={isNFTSellModal} toggle={this.toggleNFTSellModal} className={`main-modal nft-sell-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalHeader toggle={this.toggleNFTSellModal}>
                        <button className='edit-btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="90px" height="90px"><path d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z" /></svg>
                        </button>
                    </ModalHeader>
                    < ModalBody >
                        <div className='content-area'>
                            <h2>List Item</h2>
                            <h4>Choose a type of sale</h4>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="fixed-price-tab" data-toggle="tab" data-target="#fixed-price" type="button" role="tab" aria-controls="fixed-price" aria-selected="true">
                                        Fixed price
                                        <span>The item is listed at the price you set.</span>
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="time-auction-tab" data-toggle="tab" data-target="#time-auction" type="button" role="tab" aria-controls="time-auction" aria-selected="false">
                                        Time auction
                                        <span>The item is listed for auction. <Link className='more' to="#">Learn More</Link></span>
                                    </button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="fixed-price" role="tabpanel" aria-labelledby="fixed-price-tab">
                                    <div className='item-checkout-box'>
                                        <div className='item-box img-item-box'>
                                            <div className='left-area'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/product-checkout-1.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <h4>NFT</h4>
                                                    <p>Untitled Collection #3345369406</p>
                                                </div>
                                            </div>
                                            <div className='right-area'>
                                                <div className='text-box'>
                                                    <p>Listing Price</p>
                                                    <h4>0.24 ETH</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='form-area'>
                                            <ValidatorForm className="validatorForm">
                                                <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields" item xs={9}>
                                                        <label>Set a price</label>
                                                        <CustomTextField
                                                            fullWidth
                                                            className="MyTextField"
                                                            placeholder="Amount"
                                                            name="setaamount"
                                                            type="text"
                                                            // value={username}
                                                            variant="outlined"
                                                            margin="dense"
                                                            // onChange={this.handleFormChange}
                                                            validators={['required']}
                                                            errorMessages={['Please type a price']}
                                                        // helperText={isUsername && "Space can't be added in Amount!" || !usernameAvailable && "Username is already taken!"}
                                                        />
                                                    </Grid>
                                                    <Grid className="input-fields dropdown-coins" item xs={3}>
                                                        <Select
                                                            className='custom-menu'
                                                            // value={gender}
                                                            name="setaamounttype"
                                                            variant="outlined"
                                                            // onChange={this.handleFormChange}
                                                            placeholder='Select your currency'
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem value='Eth' className='custom-menu'>ETH</MenuItem>
                                                            <MenuItem value='BTC' className='custom-menu'>BTC</MenuItem>
                                                            <MenuItem value='BAN' className='custom-menu'>BAN</MenuItem>
                                                            <MenuItem value='MON' className='custom-menu'>MON</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields dropdown-datestime" item xs={12}>
                                                        <label>Duration</label>
                                                        <div className='dropdown-select-date'>
                                                            <div className='sortby-dropdrown dropdown'>
                                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <span className='icon-date'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.353" height="17.546" viewBox="0 0 15.353 17.546">
                                                                            <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,15.9a1.645,1.645,0,0,0,1.645,1.645H13.708A1.645,1.645,0,0,0,15.353,15.9V6.58H0ZM10.967,9.184a.412.412,0,0,1,.411-.411h1.371a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H11.378a.412.412,0,0,1-.411-.411Zm0,4.387a.412.412,0,0,1,.411-.411h1.371a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H11.378a.412.412,0,0,1-.411-.411ZM6.58,9.184a.412.412,0,0,1,.411-.411H8.362a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H6.991a.412.412,0,0,1-.411-.411Zm0,4.387a.412.412,0,0,1,.411-.411H8.362a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H6.991a.412.412,0,0,1-.411-.411ZM2.193,9.184A.412.412,0,0,1,2.6,8.773H3.975a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H2.6a.412.412,0,0,1-.411-.411Zm0,4.387A.412.412,0,0,1,2.6,13.16H3.975a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H2.6a.412.412,0,0,1-.411-.411ZM13.708,2.193H12.063V.548A.55.55,0,0,0,11.515,0h-1.1A.55.55,0,0,0,9.87.548V2.193H5.483V.548A.55.55,0,0,0,4.935,0h-1.1A.55.55,0,0,0,3.29.548V2.193H1.645A1.645,1.645,0,0,0,0,3.838V5.483H15.353V3.838A1.645,1.645,0,0,0,13.708,2.193Z" fill="#9f9f9f" />
                                                                        </svg>
                                                                    </span>
                                                                    1 Month
                                                                    <i className='icon'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="6.021" height="11.181" viewBox="0 0 6.021 11.181">
                                                                            <path id="Settings_arrow" data-name="Settings arrow" d="M13.066,11.783,17.05,7.555a.831.831,0,0,0,0-1.128.725.725,0,0,0-1.066,0l-4.515,4.79a.833.833,0,0,0-.022,1.1l4.533,4.823a.726.726,0,0,0,1.066,0,.831.831,0,0,0,0-1.128Z" transform="translate(17.271 17.375) rotate(180)" fill="#9f9f9f" />
                                                                        </svg>
                                                                    </i>
                                                                </button>
                                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                                        <Grid className="input-fields dropdown-dates" item xs={12}>
                                                                            <label>Date Range</label>
                                                                            <Select
                                                                                className='custom-menu'
                                                                                // value={gender}
                                                                                name="daterange"
                                                                                variant="outlined"
                                                                                // onChange={this.handleFormChange}
                                                                                placeholder='Select your currency'
                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                            >
                                                                                <MenuItem value='1month' className='custom-menu'>1 Month</MenuItem>
                                                                                <MenuItem value='3month' className='custom-menu'>3 Month</MenuItem>
                                                                                <MenuItem value='6month' className='custom-menu'>6 MonthN</MenuItem>
                                                                                <MenuItem value='9month' className='custom-menu'>9 Month</MenuItem>
                                                                            </Select>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                                                        <Grid className="input-fields" item xs={6}>
                                                                            <label>Starting</label>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <DatePicker
                                                                                    fullWidth
                                                                                    className='MyTextField'
                                                                                    name="startingTime"
                                                                                    margin="normal"
                                                                                    id="date-picker-dialog"
                                                                                    format="dd/MM/yyyy"
                                                                                    // value={dob}
                                                                                    // onChange={(date) => this.setDateOfBirth(date)}
                                                                                    KeyboardButtonProps={{ 'aria-label': 'Date of Starting' }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </Grid>
                                                                        <Grid className="input-fields" item xs={6}>
                                                                            <label>Ending</label>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <DatePicker
                                                                                    fullWidth
                                                                                    className='MyTextField'
                                                                                    name="endingTime"
                                                                                    margin="normal"
                                                                                    id="date-picker-dialog"
                                                                                    format="dd/MM/yyyy"
                                                                                    // value={dob}
                                                                                    // onChange={(date) => this.setDateOfBirth(date)}
                                                                                    KeyboardButtonProps={{ 'aria-label': 'Date of ending' }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </Grid>
                                                                    </Grid>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </Grid>
                                                </Grid>
                                            </ValidatorForm>
                                        </div>

                                        <h4>Summary</h4>
                                        <ul className='listing-detail'>
                                            <li>
                                                Listing price
                                                <span>20 ETH</span>
                                            </li>
                                            <li>
                                                Creator earnings
                                                <span>0%</span>
                                            </li>
                                            <li>
                                                Service fee
                                                <span>2.5%</span>
                                            </li>
                                            <li>
                                                <b>Total potential earnings</b>
                                                <span><b>19.5 ETH</b><br />$37,045.71 USD</span>
                                            </li>
                                        </ul>

                                        <div className='btn-box'>
                                            <button className='btn-style-one'>
                                                Complete listing
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="time-auction" role="tabpanel" aria-labelledby="time-auction-tab">
                                    <div className='item-checkout-box'>
                                        <div className='item-box img-item-box'>
                                            <div className='left-area'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/product-checkout-1.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <h4>NFT</h4>
                                                    <p>Untitled Collection #3345369406</p>
                                                </div>
                                            </div>
                                            <div className='right-area'>
                                                <div className='text-box'>
                                                    <p>Listing Price</p>
                                                    <h4>0.12 ETH</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='form-area'>
                                            <ValidatorForm className="validatorForm">
                                                <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields" item xs={9}>
                                                        <label>Set a price</label>
                                                        <CustomTextField
                                                            fullWidth
                                                            className="MyTextField"
                                                            placeholder="Amount"
                                                            name="setaamount"
                                                            type="text"
                                                            // value={username}
                                                            variant="outlined"
                                                            margin="dense"
                                                            // onChange={this.handleFormChange}
                                                            validators={['required']}
                                                            errorMessages={['Please type a price']}
                                                        // helperText={isUsername && "Space can't be added in Amount!" || !usernameAvailable && "Username is already taken!"}
                                                        />
                                                    </Grid>
                                                    <Grid className="input-fields dropdown-coins" item xs={3}>
                                                        <Select
                                                            className='custom-menu'
                                                            // value={gender}
                                                            name="setaamounttype"
                                                            variant="outlined"
                                                            // onChange={this.handleFormChange}
                                                            placeholder='Select your currency'
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem value='Eth' className='custom-menu'>ETH</MenuItem>
                                                            <MenuItem value='BTC' className='custom-menu'>BTC</MenuItem>
                                                            <MenuItem value='BAN' className='custom-menu'>BAN</MenuItem>
                                                            <MenuItem value='MON' className='custom-menu'>MON</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                    <Grid className="input-fields dropdown-datestime" item xs={12}>
                                                        <label>Duration</label>
                                                        <div className='dropdown-select-date'>
                                                            <div className='sortby-dropdrown dropdown'>
                                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <span className='icon-date'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.353" height="17.546" viewBox="0 0 15.353 17.546">
                                                                            <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,15.9a1.645,1.645,0,0,0,1.645,1.645H13.708A1.645,1.645,0,0,0,15.353,15.9V6.58H0ZM10.967,9.184a.412.412,0,0,1,.411-.411h1.371a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H11.378a.412.412,0,0,1-.411-.411Zm0,4.387a.412.412,0,0,1,.411-.411h1.371a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H11.378a.412.412,0,0,1-.411-.411ZM6.58,9.184a.412.412,0,0,1,.411-.411H8.362a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H6.991a.412.412,0,0,1-.411-.411Zm0,4.387a.412.412,0,0,1,.411-.411H8.362a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H6.991a.412.412,0,0,1-.411-.411ZM2.193,9.184A.412.412,0,0,1,2.6,8.773H3.975a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H2.6a.412.412,0,0,1-.411-.411Zm0,4.387A.412.412,0,0,1,2.6,13.16H3.975a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H2.6a.412.412,0,0,1-.411-.411ZM13.708,2.193H12.063V.548A.55.55,0,0,0,11.515,0h-1.1A.55.55,0,0,0,9.87.548V2.193H5.483V.548A.55.55,0,0,0,4.935,0h-1.1A.55.55,0,0,0,3.29.548V2.193H1.645A1.645,1.645,0,0,0,0,3.838V5.483H15.353V3.838A1.645,1.645,0,0,0,13.708,2.193Z" fill="#9f9f9f" />
                                                                        </svg>
                                                                    </span>
                                                                    1 Month
                                                                    <i className='icon'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="6.021" height="11.181" viewBox="0 0 6.021 11.181">
                                                                            <path id="Settings_arrow" data-name="Settings arrow" d="M13.066,11.783,17.05,7.555a.831.831,0,0,0,0-1.128.725.725,0,0,0-1.066,0l-4.515,4.79a.833.833,0,0,0-.022,1.1l4.533,4.823a.726.726,0,0,0,1.066,0,.831.831,0,0,0,0-1.128Z" transform="translate(17.271 17.375) rotate(180)" fill="#9f9f9f" />
                                                                        </svg>
                                                                    </i>
                                                                </button>
                                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                                        <Grid className="input-fields dropdown-dates" item xs={12}>
                                                                            <label>Date Range</label>
                                                                            <Select
                                                                                className='custom-menu'
                                                                                // value={gender}
                                                                                name="daterange"
                                                                                variant="outlined"
                                                                                // onChange={this.handleFormChange}
                                                                                placeholder='Select your currency'
                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                            >
                                                                                <MenuItem value='1month' className='custom-menu'>1 Month</MenuItem>
                                                                                <MenuItem value='3month' className='custom-menu'>3 Month</MenuItem>
                                                                                <MenuItem value='6month' className='custom-menu'>6 MonthN</MenuItem>
                                                                                <MenuItem value='9month' className='custom-menu'>9 Month</MenuItem>
                                                                            </Select>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                                                        <Grid className="input-fields" item xs={6}>
                                                                            <label>Starting</label>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <DatePicker
                                                                                    fullWidth
                                                                                    className='MyTextField'
                                                                                    name="startingTime"
                                                                                    margin="normal"
                                                                                    id="date-picker-dialog"
                                                                                    format="dd/MM/yyyy"
                                                                                    // value={dob}
                                                                                    // onChange={(date) => this.setDateOfBirth(date)}
                                                                                    KeyboardButtonProps={{ 'aria-label': 'Date of Starting' }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </Grid>
                                                                        <Grid className="input-fields" item xs={6}>
                                                                            <label>Ending</label>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <DatePicker
                                                                                    fullWidth
                                                                                    className='MyTextField'
                                                                                    name="endingTime"
                                                                                    margin="normal"
                                                                                    id="date-picker-dialog"
                                                                                    format="dd/MM/yyyy"
                                                                                    // value={dob}
                                                                                    // onChange={(date) => this.setDateOfBirth(date)}
                                                                                    KeyboardButtonProps={{ 'aria-label': 'Date of ending' }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </Grid>
                                                                    </Grid>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </Grid>
                                                </Grid>
                                            </ValidatorForm>
                                        </div>
                                        <h4>Summary</h4>
                                        <ul className='listing-detail'>
                                            <li>
                                                Listing price
                                                <span>20 ETH</span>
                                            </li>
                                            <li>
                                                Creator earnings
                                                <span>0%</span>
                                            </li>
                                            <li>
                                                Service fee
                                                <span>2.5%</span>
                                            </li>
                                            <li>
                                                <b>Total potential earnings</b>
                                                <span><b>19.5 ETH</b><br />$37,045.71 USD</span>
                                            </li>
                                        </ul>

                                        <div className='btn-box'>
                                            <button className='btn-style-one'>
                                                Complete listing
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody >
                </Modal >
            </div >
        );
    }
}


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const mapDispatchToProps = {
    getSinglePost, singlePostLoader,
};

const mapStateToProps = ({ Auth, Post }) => {
    let { lightMode, publicAddress, } = Auth;
    let { isSinglePost, singlePost } = Post;
    return { lightMode, publicAddress, isSinglePost, singlePost }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostDetail);