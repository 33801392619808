import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import VpnKey from '@material-ui/icons/VpnKey';
import Refresh from '@material-ui/icons/Refresh';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';

import { signUpBasic, load } from '../../store/actions/Auth';

import './index.css';
import countries from './country.json'

class SignupBasic extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        name: '',
        uname: '',
        email: '',
        confirm: '',
        password: '',
        account: 'personal',
        terms_cond: true
      },
      showConfirm: false,
      showPassword: false,
      passwordMatch: true,
    };
  }

  submitSignup = () => {
    this.props.load(true);
    this.props.signUpBasic({ data: this.state.formData })
  }

  handleFormChange = ({ target }) => {
    const { formData } = this.state;
    formData[target.name] = target.value;
    this.setState({ formData }, () => {
      if (target.name === 'confirm') this.setState({ passwordMatch: this.state.formData.password === this.state.formData.confirm })
    });
  }

  countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  selectedCountry = (e, value) => {
    let { formData } = this.state;
    formData['country'] = value['label'];
    this.setState({ formData });
  }

  handleMouseDown = (event) => event.preventDefault();
  handleClickShowConfirm = () => this.setState({ showConfirm: !this.state.showConfirm });
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  render() {
    let { loading, lightMode } = this.props;
    let { name, uname, email, password, confirm, account } = this.state.formData;
    let { showPassword, showConfirm, passwordMatch } = this.state;

    return (
      <>
        <section className={`login-page ${lightMode ? 'dark' : 'light'}`}>
          <div className='inner-login'>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-sm-12">
                  <div className="login-form">
                    <div className='icon-splash'>
                      <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                    </div>
                    <div className="form-area">
                      <h3>Sign up</h3>
                      <ValidatorForm className="validatorForm" onSubmit={this.submitSignup}>
                        <Grid container spacing={1} className='group-input' alignItems="flex-end">
                          <Grid className="signup-fields" item xs={12}>
                            <CustomTextField
                              fullWidth
                              className="MyTextField"
                              autoComplete='off'
                              placeholder="Full Name"
                              name="name"
                              type="name"
                              value={name}
                              margin="dense"
                              onChange={this.handleFormChange}
                              validators={['required']}
                              errorMessages={['Full Name can not be empty']}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} className='group-input' alignItems="flex-end">
                          <Grid className="signup-fields" item xs={12}>
                            <CustomTextField
                              fullWidth
                              className="MyTextField"
                              autoComplete='off'
                              placeholder="Username"
                              name="uname"
                              type="name"
                              value={uname}
                              margin="dense"
                              onChange={this.handleFormChange}
                              validators={['required']}
                              errorMessages={['Username can not be empty']}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} className='group-input' alignItems="flex-end">
                          <Grid className="signup-fields" item xs={12}>
                            <CustomTextField
                              fullWidth
                              className="MyTextField"
                              autoComplete='off'
                              placeholder="Email/Phone"
                              name="email"
                              type="email"
                              value={email}
                              margin="dense"
                              onChange={this.handleFormChange}
                              validators={['required', 'isEmail']}
                              errorMessages={['Email/Phone can not be empty', 'Email/Phone is not valid']}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} className='group-input' alignItems="flex-end">
                          <Grid className="signup-fields signup-pass" item xs={12}>
                            <CustomTextField
                              fullWidth
                              name="password"
                              placeholder="Password"
                              value={password}
                              className="MyTextField"
                              validators={['required']}
                              onChange={this.handleFormChange}
                              type={showPassword ? 'text' : 'password'}
                              inputProps={{
                                autocomplete: 'new-password',
                                form: { autoComplete: 'off' }
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">
                                  <IconButton
                                    style={{ color: '#fa6634', marginBottom: '8px' }}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDown}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>,
                              }}
                              errorMessages={['Password can not be empty']}
                            />
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={1} className='group-input' alignItems="flex-end">
                          <Grid className="signup-fields" item xs={12}>
                            <CustomTextField
                              fullWidth
                              name="confirm"
                              label="Confirm Password"
                              value={confirm}
                              className="MyTextField"
                              onChange={this.handleFormChange}
                              type={showConfirm ? 'text' : 'password'}
                              inputProps={{
                                autocomplete: 'new-password',
                                form: { autoComplete: 'off' }
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">
                                  <IconButton
                                    style={{ color: '#fa6634', marginBottom: '8px' }}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowConfirm}
                                    onMouseDown={this.handleMouseDown}
                                  >
                                    {showConfirm ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>,
                              }}
                              error={!passwordMatch}
                              helperText={!passwordMatch && 'Password does not match'}
                              validators={['required']}
                              errorMessages={['Confirm Password cannot be empty']}
                            />
                          </Grid>
                        </Grid> */}
                        {/* <Grid container spacing={1} className='group-input' alignItems="flex-end">
                          <Grid className="signup-fields radio-area" item xs={12}>
                            <FormControl component='fieldset'>
                              <RadioGroup className='radio-group' value={account} onChange={this.handleFormChange}>
                                <FormControlLabel
                                  value="personal"
                                  name="account"
                                  control={<Radio />}
                                  label="Personal Account" />
                                <FormControlLabel
                                  value="corporate"
                                  name="account"
                                  control={<Radio />}
                                  label="Corporate Account" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid> */}

                        <Grid container spacing={1} className='group-input' alignItems="flex-end">
                          <Grid className="signup-fields" item xs={12}>
                            <Select
                              labelId="gender-label"
                              id="gender"
                              placeholder="Gander"
                              // value={age}
                              label="Age"
                            // onChange={handleChange}
                            >
                              <MenuItem>Male</MenuItem>
                              <MenuItem>Female</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>

                        <Grid container spacing={1} className='group-input' alignItems="flex-end">
                          <Grid className="signup-fields" item xs={12}>
                            <Autocomplete
                              fullWidth
                              id="country-select-demo"
                              className="autocomplete-field"
                              options={countries}
                              getOptionLabel={(option) => option.label}
                              onChange={(event, newValue) => this.selectedCountry(event, newValue)}
                              onClick
                              renderOption={(option) => (
                                <React.Fragment>
                                  <span>{this.countryToFlag(option.code)}</span>
                                  &nbsp; {option.label} ({option.code})
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <CustomTextField
                                  {...params}
                                  label='Country'
                                  className='MyTextField'
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <div className="submit-box">
                          <Button
                            type="submit"
                            disabled={loading}
                          >
                            {!loading ? 'Sign up' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                          </Button>
                        </div>
                      </ValidatorForm>
                      <div className='signup-area'>
                        <p>Already have an account? <Link to="/Login" className='signup-link'>Log in</Link></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </>
    )
  }
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fa6634', // Solid underline on focus
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
})(TextValidator);

const mapDispatchToProps = {  };

const mapStateToProps = ({ Auth }) => {
  let { loading, lightMode } = Auth;
  return { loading, lightMode };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupBasic);