import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import VpnKey from '@material-ui/icons/VpnKey';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { load, resetPassword } from '../../store/actions/Auth';

class ResetPass extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        password: '',
        key: '',
      },
      showPassword: false
    };
  }

  componentDidMount() {
    let { location } = this.props;
    if (location.pathname.match('reset_password')) {
      let { formData } = this.state;
      formData['key'] = location.pathname.split('/')[--location.pathname.split('/').length];
      this.setState({ formData })
    }
  }

  submitResetPass = () => {
    this.props.load(true);
    let { history } = this.props;
    this.props.resetPassword({ data: this.state.formData, history })
  }

  handleFormChange = ({ target }) => {
    const { formData } = this.state;
    formData[target.name] = target.value;
    this.setState({ formData });
  }

  handleMouseDownPassword = (event) => event.preventDefault();
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  render() {
    let { loading, lightMode } = this.props;
    let { showPassword } = this.state;
    let { password } = this.state.formData;
    return (
      <>
        <section className={`resetPass-page ${lightMode ? 'dark' : 'light'}`}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="resetPass-form">
                  <div className="row clearfix">
                    <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
                      <div className="form-area">
                        <ValidatorForm className="validatorForm" onSubmit={this.submitResetPass}>
                          <Grid container spacing={1} className="group-input" alignItems="flex-end">
                            <Grid item xs={1}>
                              <VpnKey className='input-icon text-white' />
                            </Grid>
                            <Grid item xs={11}>
                              <CustomTextField
                                fullWidth
                                name="password"
                                placeholder="Password"
                                value={password}
                                className="MyTextField"
                                validators={['required']}
                                onChange={this.handleFormChange}
                                type={showPassword ? 'text' : 'password'}
                                inputProps={{
                                  autocomplete: 'new-password',
                                  form: { autoComplete: 'off' }
                                }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">
                                    <IconButton
                                      style={{ color: '#fa6634', marginBottom: '8px' }}
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      onMouseDown={this.handleMouseDownPassword}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>,
                                }}
                                errorMessages={['Password can not be empty']}
                              />
                            </Grid>
                          </Grid>
                          <div className="group-form submit-box">
                            <Button
                              type="submit"
                              disabled={loading}
                            >
                              {!loading ? 'Set Password' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                            </Button>
                          </div>
                        </ValidatorForm>
                      </div>
                      <div className="already-memmber">
                        <p>Already a member?</p>
                        <Link to='/login' className="login-btn">Login Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fa6634', // Solid underline on focus
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
})(TextValidator);

const mapDispatchToProps = { load, resetPassword };

const mapStateToProps = ({ Auth }) => {
  let { loading, lightMode } = Auth;
  return { loading, lightMode };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPass);