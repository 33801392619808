import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { login, load, verifyEmail, toggleAuthModal, loginSms, verifySmsLogin, loginVerifyGoogle } from '../../store/actions/Auth';

import './index.css';

class CreateNewPassword extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        password: ''
      },
      enabled: '',
      selected: '',
      smsKey: '',
      showPassword: false,
      code: { first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' }
    };
  }

  componentDidMount() {
    let { location } = this.props;
    if (location.pathname.match('verify_email')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.verifyEmail({ data, history: this.props.history })
    }
  }

  submitLogin = () => {
    this.props.load(true);
    this.props.login({ data: this.state.formData, history: this.props.history });
  }

  componentWillReceiveProps({ userAuth }) {
    if (userAuth) {
      let selected = userAuth['enabled'] === 'sms' || userAuth['enabled'] === 'both' ? 'sms' : '2fa';
      this.setState({ enabled: userAuth['enabled'], selected });
    }
  }

  sendSms = () => {
    let { id } = this.props.userAuth;
    this.props.loginSms(id);
  }

  submitSms = () => {
    let { smsKey } = this.state;
    let { history } = this.props;
    let { id } = this.props.userAuth;

    let data = { smsKey };
    this.props.verifySmsLogin({ data, id, history });
  }

  handleFormChange = ({ target }) => {
    const { formData } = this.state;
    formData[target.name] = target.value;
    this.setState({ formData });
  }

  changeTab = (selected) => this.setState({ selected });

  handleMouseDownPassword = (e) => e.preventDefault();
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
  keyPressed = (e) => {
    if (e.key === "Enter") this.submitLogin();
  }

  handleChangedCode = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let { code } = this.state;
    code[name] = value;
    this.setState({ code });

    let input;
    if (name === 'first') input = document.getElementById('second');
    if (name === 'second') input = document.getElementById('third');
    if (name === 'third') input = document.getElementById('fourth');
    if (name === 'fourth') input = document.getElementById('fifth');
    if (name === 'fifth') input = document.getElementById('sixth');
    if (input) {
      input.focus();
      input.select();
    }
    if (name === 'sixth') {
      document.getElementById('sixth').blur();
      let { history } = this.props;
      let { id } = this.props.userAuth;
      let token_code = code.first + code.second + code.third + code.fourth + code.fifth + code.sixth;
      this.props.loginVerifyGoogle({ data: { token_code }, id, history });
      setTimeout(() => {
        if (document.getElementById('first')) document.getElementById('first').focus();
        this.setState({ code: { first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' } })
      }, 1500)
    }
  }

  render() {
    let { loading, lightMode } = this.props;
    let { showPassword } = this.state;
    let { password } = this.state.formData;
    let { } = this.props;
    return (
      <>
        <section className={`login-page creatpass-page ${lightMode ? 'dark' : 'light'}`}>
          <div className='inner-login'>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-sm-12">
                  <div className="login-form">
                    <div className='icon-splash'>
                      <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                    </div>
                    <div className="form-area">
                      <h3>Create New Password</h3>
                      <p>Please set a new password to access<br />your account.</p>
                      <ValidatorForm className="validatorForm" onSubmit={this.submitLogin}>

                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                          <Grid className="input-fields login-pass" item xs={12}>
                            {/* <VpnKey className='input-icon mt-4 mr-2' /> */}
                            <CustomTextField
                              fullWidth
                              className="MyTextField"
                              autoComplete='off'
                              placeholder="Password"
                              name="password"
                              value={password}
                              margin="dense"
                              onKeyPress={this.keyPressed}
                              onChange={this.handleFormChange}
                              type={showPassword ? 'text' : 'password'}
                              validators={['required', 'isPassword']}
                              errorMessages={['Password can not be empty']}
                              inputProps={{
                                autocomplete: 'new-password',
                                form: { autoComplete: 'off' }
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">
                                  <IconButton
                                    style={{ color: '#9F9F9F', marginBottom: '8px' }}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} className="group-input" alignItems="flex-end">
                          <Grid className="input-fields login-pass" item xs={12}>
                            {/* <VpnKey className='input-icon mt-4 mr-2' /> */}
                            <CustomTextField
                              fullWidth
                              className="MyTextField"
                              autoComplete='off'
                              placeholder="Confirm password"
                              name="confirm password"
                              value={password}
                              margin="dense"
                              onKeyPress={this.keyPressed}
                              onChange={this.handleFormChange}
                              type={showPassword ? 'text' : 'password'}
                              validators={['required', 'isPassword']}
                              errorMessages={['Confirm password can not be empty']}
                              inputProps={{
                                autocomplete: 'new-password',
                                form: { autoComplete: 'off' }
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">
                                  <IconButton
                                    style={{ color: '#9F9F9F', marginBottom: '8px' }}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <div className="group-form submit-box">
                          <Button
                            type="Submit"
                            disabled={loading}
                            onClick={this.submitLogin}
                          >
                            {!loading ? 'Reset password' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                          </Button>
                        </div>
                      </ValidatorForm>

                      <div className='forget-pass'>
                        <Link to="/Forget" className='forgot-link'>Forgot Password?</Link>
                      </div>
                      <div className='signup-area'>
                        <p>Don’t have an account? <Link to="/Signup" className='signup-link'>Sign up</Link></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fa6634', // Solid underline on focus
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
})(TextValidator);

const mapDispatchToProps = { load, login, verifyEmail, toggleAuthModal, loginSms, verifySmsLogin, loginVerifyGoogle };

const mapStateToProps = ({ Auth }) => {
  let { loading, lightMode, userAuth, isUserAuthModal } = Auth;
  return { loading, lightMode, userAuth, isUserAuthModal };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewPassword);