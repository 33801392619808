import { PURGE } from "redux-persist";

let initialState = {
  isNFTUplaoded: false,
  isAllPosts: false,
  allPosts: [],
  isSinglePost: false,
  singlePost: {},
  isUserPost: false,
  userPosts: [],
  isCommented: false,
  allComments: [],
  isDeleteCommentModal: false,
}

const Post = (state = initialState, { type, payload }) => {
  switch (type) {

    /*========== POST REDUCERS ============= */

    case 'UPLOAD_POST_LOADER':
      return {
        ...state,
        isNFTUplaoded: payload,
      };

    case 'ALL_POSTS_LOADER':
      return {
        ...state,
        isAllPosts: payload,
      };

    case 'SET_ALL_POSTS':
      return {
        ...state,
        allPosts: payload,
      };

    case 'SINGLE_POST_LOADER':
      return {
        ...state,
        isSinglePost: payload,
      };

    case 'SET_SINGLE_POST':
      return {
        ...state,
        singlePost: payload,
      };

    case 'USER_POSTS_LOADER':
      return {
        ...state,
        isUserPost: payload,
      };

    case 'SET_USER_POSTS':
      return {
        ...state,
        userPosts: payload,
      };

    case 'SEND_COMMENT_LOADER':
      return {
        ...state,
        isCommented: payload,
      };

    case 'SET_ALL_COMMENTS':
      return {
        ...state,
        allComments: payload['comments'],
      };

    case 'TOGGLE_DELETE_COMMENT_MODAL':
      return {
        ...state,
        isDeleteCommentModal: payload,
      };

    default:
      return state;
  }
};

export default Post;