import { PURGE } from "redux-persist";
import { setToken } from '../axios';

let initialState = {
  lightMode: localStorage.getItem('lightMode') == 'true' ? true : false,
  setLoader: { message: 'Please Wait...', status: false },

  userNonce: {},
  auth: localStorage.getItem('token'),
  publicAddress: localStorage.getItem('publicAddress'),

  searchData: [],
  isUserSeach: false,
  isUserData: false,
  userData: {},
  userID: '',
  profileDetails: {},
  usernameAvailable: true,
  isCoverModal: false,
  isProfileModal: false,
  isEditProfile: false,
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'TOGGLE_DARK_MODE':
      localStorage.setItem('lightMode', payload);
      return {
        ...state,
        lightMode: payload,
      };


    /*========== LOGIN REDUCERS ============= */

    case 'SET_NONCE':
      return {
        ...state,
        userNonce: payload,
      };

    case 'SET_LOGIN_DATA':
      setToken(payload['token']);
      localStorage.setItem('token', payload['token']);
      localStorage.setItem('userID', payload['_id']);
      localStorage.setItem('publicAddress', payload['publicAddress']);
      return {
        ...state,
        auth: payload['token'],
        userID: payload['_id'],
        publicAddress: payload['publicAddress'],
      };

    case 'LOGOUT':
      setToken();
      localStorage.removeItem('token');
      localStorage.removeItem('publicAddress');
      localStorage.removeItem('userID');
      return {
        ...state,
        auth: '',
        publicAddress: '',
        setLoader: { message: 'Logout...', status: false },
      };

    /*========== SEARCH REDUCERS =============*/

    case 'SET_SEARCH_USER':
      return {
        ...state,
        searchData: payload,
      };

    case 'SEARCH_USER_LOADER':
      return {
        ...state,
        isUserSeach: payload,
      };

    /*========== USER PROFILE REDUCERS =============*/

    case 'USER_PROFILE_LOADER':
      return {
        ...state,
        isUserData: payload,
      };

    case 'SET_USER_PROFILE':
      return {
        ...state,
        userData: payload,
      };

    /*========== EDIT PROFILE REDUCERS =============*/

    case 'SET_PROFILE_DETAILS':
      localStorage.setItem('userID', payload['_id']);
      return {
        ...state,
        profileDetails: payload,
        userID: payload['_id'],
      };

    case 'SET_CHECK_USERNAME':
      return {
        ...state,
        usernameAvailable: payload,
      };

    case 'TOGGLE_COVER_MODAL':
      return {
        ...state,
        isCoverModal: payload,
      };

    case 'TOGGLE_PROFILE_MODAL':
      return {
        ...state,
        isProfileModal: payload,
      };

    case 'EDIT_PROFILE_LOADER':
      return {
        ...state,
        isEditProfile: payload,
      };

    default:
      return state;
  }
};

export default Auth;