import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import { toggleLoader } from "../../store/actions/Auth";


class Marketplace extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };

    render() {

        const owl_option = {
            margin: 25,
            nav: true,
            items: 4,
            loop: true,
            autoplay: false,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 4,
                }
            },
        };

        let { lightMode } = this.props;
        return (
            <div className={`nfity-page markaetplace-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='sort-by-sec nft-tabs-sec '>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='filter-btn col-lg-6 col-md-4 col'>
                                <Link to="/MarketplaceFilter" className='sort-btn-left'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13">
                                        <g id="Group_2369" data-name="Group 2369" transform="translate(-4780 -1464)">
                                            <rect id="Rectangle_773" data-name="Rectangle 773" width="20" height="3" transform="translate(4780 1464)" />
                                            <rect id="Rectangle_774" data-name="Rectangle 774" width="13" height="3" transform="translate(4784 1469)" />
                                            <rect id="Rectangle_775" data-name="Rectangle 775" width="4" height="3" transform="translate(4788 1474)" />
                                        </g>
                                    </svg>
                                </Link>
                            </div>
                            <div className='sort-area col-lg-6 col-md-8 col'>
                                <div className='sortby-inner'>
                                    <div className='sortby-dropdrown dropdown'>
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.512" height="16.512" viewBox="0 0 16.512 16.512">
                                                    <g id="vuesax_bold_setting-4" data-name="vuesax/bold/setting-4" opacity="0.4">
                                                        <g id="setting-4">
                                                            <path id="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321A.479.479,0,0,1,4.8.482.479.479,0,0,1,4.321.963Z" transform="translate(10.334 4.252)" fill="#292d32" />
                                                            <path id="Vector-2" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.482.482,0,0,1,0,.963Z" transform="translate(1.376 4.252)" fill="#292d32" />
                                                            <path id="Vector-3" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(4.259 2.016)" fill="#292d32" />
                                                            <path id="Vector-4" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.479.479,0,0,1,.482.482A.479.479,0,0,1,3.041.963Z" transform="translate(11.614 11.29)" fill="#292d32" />
                                                            <path id="Vector-5" data-name="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321a.482.482,0,1,1,0,.963Z" transform="translate(1.376 11.29)" fill="#292d32" />
                                                            <path id="Vector-6" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(6.818 9.061)" fill="#292d32" />
                                                            <path id="Vector-7" data-name="Vector" d="M0,0H16.512V16.512H0Z" transform="translate(16.512 16.512) rotate(180)" fill="none" opacity="0" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </i>
                                            Sort by
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" href="#">Sort by</a>
                                            <a class="dropdown-item" href="#">Sort by</a>
                                            <a class="dropdown-item" href="#">Sort by</a>
                                        </div>
                                    </div>
                                    <div className='right-sort-btn'>
                                        <button className='sort-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="vuesax_bold_grid-2" data-name="vuesax/bold/grid-2" transform="translate(-748 -188)">
                                                    <g id="grid-2">
                                                        <path id="Vector" d="M9.25,0V9.25H5.81C2.17,9.25,0,7.08,0,3.44V0Z" transform="translate(750 200.75)" fill="#292d32" />
                                                        <path id="Vector-2" data-name="Vector" d="M9.25,5.81V9.25H0V0H3.44C7.08,0,9.25,2.17,9.25,5.81Z" transform="translate(760.75 190)" fill="#292d32" />
                                                        <path id="Vector-3" data-name="Vector" d="M9.25,0V9.25H0V5.81C0,2.17,2.17,0,5.81,0Z" transform="translate(750 190)" fill="#292d32" />
                                                        <path id="Vector-4" data-name="Vector" d="M9.25,0V3.44c0,3.64-2.17,5.81-5.81,5.81H0V0Z" transform="translate(760.75 200.75)" fill="#292d32" />
                                                        <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(772 212) rotate(180)" fill="none" opacity="0" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                        <button className='sort-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="vuesax_bold_grid-1" data-name="vuesax/bold/grid-1" transform="translate(-437 -268)" opacity="0.5">
                                                    <g id="grid-1" transform="translate(9 16)">
                                                        <path id="Vector" d="M5.75,0V5.8H0C0,2.19,2.15.02,5.75,0Z" transform="translate(430 254)" fill="#292d32" />
                                                        <path id="Vector-2" data-name="Vector" d="M5.75,5.8H0V0C3.6.02,5.75,2.19,5.75,5.8Z" transform="translate(444.25 254)" fill="#292d32" />
                                                        <path id="Vector-3" data-name="Vector" d="M5.75,0C5.71,3.55,3.57,5.68,0,5.7V0Z" transform="translate(444.25 268.3)" fill="#292d32" />
                                                        <path id="Vector-4" data-name="Vector" d="M5.75,0V5.7C2.18,5.68.04,3.55,0,0Z" transform="translate(430 268.3)" fill="#292d32" />
                                                        <path id="Vector-5" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(430 261.3)" fill="#292d32" />
                                                        <path id="Vector-6" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(444.25 261.3)" fill="#292d32" />
                                                        <path id="Vector-7" data-name="Vector" d="M0,0H5.5V5.5H0Z" transform="translate(437.25 261.3)" fill="#292d32" />
                                                        <path id="Vector-8" data-name="Vector" d="M0,0H5.5V5.8H0Z" transform="translate(437.25 254)" fill="#292d32" />
                                                        <path id="Vector-9" data-name="Vector" d="M0,0H5.5V5.7H0Z" transform="translate(437.25 268.3)" fill="#292d32" />
                                                        <path id="Vector-10" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(452 276) rotate(180)" fill="none" opacity="0" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='top-collection'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='user-accounts'>
                                    <div className='heading-area'>
                                        <h3>Top Collections</h3>
                                        <Link className='view-all' to="#">View All</Link>
                                    </div>
                                    <OwlCarousel className="slider-items owl-carousel ltf-owl owl-theme" autoplaySpeed={3000}  {...owl_option} nav>
                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-1.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-1.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>Art Collection</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-2.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-2.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>2D Founders</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-3.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-3.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>Nature Beauty</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-4.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-4.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>3D Founder</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-1.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-1.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>Art Collection</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-2.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-2.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>2D Founders</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-3.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-3.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>Nature Beauty</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-4.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-4.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>3D Founder</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-1.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-1.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>Art Collection</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-2.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-2.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>2D Founders</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-3.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-3.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>Nature Beauty</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-4.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-4.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>3D Founder</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-1.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-1.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>Art Collection</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-2.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-2.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>2D Founders</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-3.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-3.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>Nature Beauty</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className='user-block'>
                                                <div className='user-detail'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-user-banner-4.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <div className='img-box'>
                                                            <img src={require('../../static/images/top-user-4.jpg')} alt='' />
                                                        </div>
                                                        <div className='detail-box'>
                                                            <div className='heading-area'>
                                                                <h5>3D Founder</h5>
                                                                <span className='check'>
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff"></path><path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0"></path></svg>
                                                                </span>
                                                            </div>
                                                            <p>$578.88</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='nfts-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <h4>NFTs</h4>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-1.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-2.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-3.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-4.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-5.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-6.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-7.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-8.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-9.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-10.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-11.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                <div className='nfts-block'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/top-nfts-12.jpg')} alt='' />
                                        <div className='overlabox'>
                                            <button className='fav-heart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                    <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-box'>
                                        <p>Libero #mattis adipiscing inter dum Morbi magna </p>
                                        <div className='user-detail'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/top-nft-user-1.jpg')} alt='' />
                                            </div>
                                            <div className='detail-box'>
                                                <p> @dorisfisher</p>
                                            </div>
                                        </div>
                                        <div className='btn-area'>
                                            <button className='btn-style-one'>15 ETH</button>
                                            <button className='btn-style-two'>Sponsored</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div >
        );
    }
};

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);