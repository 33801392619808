import { combineReducers } from "redux";
import Auth from "./Auth.js";
import Chat from "./Chat.js";
import Collection from "./Collection.js";
import Post from "./Post.js";
import Drop from "./Drop.js";


export default combineReducers(
  {
    Auth: Auth,
    Chat: Chat,
    Collection: Collection,
    Post: Post,
    Drop: Drop,
  });
