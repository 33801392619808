import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { socket } from '../config';
import { sendChatLoader, toggleDeleteChatModal, toggleDeleteMessageModal } from '../actions/Chat';


/*========== CHAT FUNCTIONS =============*/

function* getAllActiveFriends() {
  socket.emit(`active:friends`);
};

function* getAllChatHistory() {
  socket.emit('chat:history:all');
};

function* getSingleChatHistory({ payload }) {
  socket.emit('chat:history:single', payload);
};

function* sendTextMessage({ payload }) {
  socket.emit('chat:send:message', payload);
};

function* chatReadMessage({ payload }) {
  socket.emit('chat:read', { senderId: payload });
};

function* sendChatFile({ payload }) {
  const { error, response } = yield call(postCall, { path: '/chat/uploadFile', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(sendChatLoader(false));
    EventBus.publish("success", response['data']['message']);
  }
};

function* deleteChat({ payload }) {
  const { error, response } = yield call(putCall, { path: '/chat/deleteChat', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(toggleDeleteChatModal(false));
    EventBus.publish("success", response['data']['message']);
  }
};

function* deleteChatMessage({ payload }) {
  const { error, response } = yield call(putCall, { path: '/chat/deleteMessage', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(toggleDeleteMessageModal(false));
    EventBus.publish("success", response['data']['message']);
  }
};


function* actionWatcher() {
  yield takeEvery('GET_ALL_ACTIVE_FRIENDS', getAllActiveFriends);
  yield takeEvery('GET_ALL_CHAT_HISTORY', getAllChatHistory);
  yield takeEvery('GET_SINGLE_CHAT_HISTORY', getSingleChatHistory);
  yield takeEvery('SEND_TEXT_MESSAGE', sendTextMessage);
  yield takeEvery('CHAT_READ_MESSAGE', chatReadMessage);
  yield takeEvery('SEND_CHAT_FILE', sendChatFile);
  yield takeEvery('DELETE_CHAT', deleteChat);
  yield takeEvery('DELETE_CHAT_MESSAGE', deleteChatMessage);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall({ path, payload }) {
  return axios
    .delete(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
