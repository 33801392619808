import EventBus from "eventing-bus";
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './index.css';

import Navbar from '../../components/navbar';
import { getUserPosts, userPostsLoader } from "../../store/actions/Post";
import { getMyCollections, toggleMyCollections } from "../../store/actions/Collection";
import {
    getUserProfile, userProfileLoader, setFollowUnfollow, toggleLoader, toggleCoverModal, sendCoverImage,
    toggleProfileModal, sendProfileImage
} from "../../store/actions/Auth";

const cropper = React.createRef(null);

class Profile extends Component {

    constructor(props) {
        super(props);
        const query = require('url').parse(document.location.href, true);
        const id = query.path.split('/')[--query.path.split('/').length];
        this.state = {
            id,
            type: 'Follow',
            cover: '',
            coverImage: '',
            profilePicture: '',
            profileImage: '',
        };
    };

    componentDidMount() {
        let { id } = this.state;
        this.props.getUserProfile(id);
        this.props.userProfileLoader(true);

        this.props.getUserPosts(id);
        this.props.userPostsLoader(true);

        this.props.getMyCollections();
        this.props.toggleMyCollections(true);
    };

    componentWillReceiveProps({ userData, isCoverModal, isProfileModal }) {
        if (userData['isFollowed']) this.setState({ type: 'UnFollow' });
        else this.setState({ type: 'Follow' });

        if (isCoverModal) this.setState({ cover: '', coverImage: '' });
        if (isProfileModal) this.setState({ profilePicture: '', profileImage: '' });
    };

    handleFollow = () => {
        let { type } = this.state;
        let { userData } = this.props;
        let data = {
            userId: userData['_id'],
            type
        }
        this.props.setFollowUnfollow(data);
    };

    copied = () => EventBus.publish("info", 'Username copied');

    setCoverImage = async (e) => {
        let { coverImage } = this.state;

        coverImage = URL.createObjectURL(e.currentTarget.files[0]);
        this.setState({ coverImage });
    };

    handleCoverImage = async () => {
        let { coverImage, cover } = this.state;
        if (cropper.current) {
            coverImage = cropper.current.getCroppedCanvas().toDataURL();
            let res = await fetch(coverImage);
            let blob = await res.blob();
            cover = new File([blob], "Profile Image", { type: "image/png" });
            if (cover['size'] <= 5000000) {
                this.setState({ coverImage, cover });
                let data = new FormData();
                data.append('cover', cover);
                this.props.sendCoverImage(data);
                this.props.toggleLoader({ message: 'Changing Cover Image...', status: true });
            }
            else EventBus.publish("error", "File Size is more then 5MB");
        }
        else EventBus.publish("info", "Cover is not attached!!!");
    };

    setProfileImage = async (e) => {
        let { profileImage } = this.state;
        profileImage = URL.createObjectURL(e.currentTarget.files[0]);
        this.setState({ profileImage });
    };

    handleProfileImage = async () => {
        let { profileImage, profilePicture } = this.state;
        if (cropper.current) {
            profileImage = cropper.current.getCroppedCanvas().toDataURL();
            let res = await fetch(profileImage);
            let blob = await res.blob();
            profilePicture = new File([blob], "Profile Image", { type: "image/png" });
            if (profilePicture['size'] <= 5000000) {
                this.setState({ profileImage, profilePicture });
                let data = new FormData();
                data.append('profilePicture', profilePicture);
                this.props.sendProfileImage(data);
                this.props.toggleLoader({ message: 'Changing Profile Image...', status: true });
            }
            else EventBus.publish("error", "File Size is more then 5MB");
        }
        else EventBus.publish("info", "Profile Image is not attached!!!");
    };

    render() {
        let { id, coverImage, profileImage, } = this.state;
        let { lightMode, userData, isUserData, userID, isCoverModal, isProfileModal, isUserPost,
            userPosts, isMyCollection, myCollections } = this.props;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                {isUserData
                    ? <div className="profile-page-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                    : <>
                        <Navbar />

                        {/* SORT */}
                        <section className='sort-by-sec'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='sortby-inner'>
                                            <div className='sortby-dropdrown dropdown'>
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className='icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.512" height="16.512" viewBox="0 0 16.512 16.512">
                                                            <g id="vuesax_bold_setting-4" data-name="vuesax/bold/setting-4" opacity="0.4">
                                                                <g id="setting-4">
                                                                    <path id="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321A.479.479,0,0,1,4.8.482.479.479,0,0,1,4.321.963Z" transform="translate(10.334 4.252)" fill="#292d32" />
                                                                    <path id="Vector-2" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.482.482,0,0,1,0,.963Z" transform="translate(1.376 4.252)" fill="#292d32" />
                                                                    <path id="Vector-3" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(4.259 2.016)" fill="#292d32" />
                                                                    <path id="Vector-4" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.479.479,0,0,1,.482.482A.479.479,0,0,1,3.041.963Z" transform="translate(11.614 11.29)" fill="#292d32" />
                                                                    <path id="Vector-5" data-name="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321a.482.482,0,1,1,0,.963Z" transform="translate(1.376 11.29)" fill="#292d32" />
                                                                    <path id="Vector-6" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(6.818 9.061)" fill="#292d32" />
                                                                    <path id="Vector-7" data-name="Vector" d="M0,0H16.512V16.512H0Z" transform="translate(16.512 16.512) rotate(180)" fill="none" opacity="0" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </i>
                                                    Sort by
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" href="#">Sort by</a>
                                                    <a class="dropdown-item" href="#">Sort by</a>
                                                    <a class="dropdown-item" href="#">Sort by</a>
                                                </div>
                                            </div>
                                            <div className='right-sort-btn'>
                                                <button className='sort-btn'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="vuesax_bold_grid-2" data-name="vuesax/bold/grid-2" transform="translate(-748 -188)">
                                                            <g id="grid-2">
                                                                <path id="Vector" d="M9.25,0V9.25H5.81C2.17,9.25,0,7.08,0,3.44V0Z" transform="translate(750 200.75)" fill="#292d32" />
                                                                <path id="Vector-2" data-name="Vector" d="M9.25,5.81V9.25H0V0H3.44C7.08,0,9.25,2.17,9.25,5.81Z" transform="translate(760.75 190)" fill="#292d32" />
                                                                <path id="Vector-3" data-name="Vector" d="M9.25,0V9.25H0V5.81C0,2.17,2.17,0,5.81,0Z" transform="translate(750 190)" fill="#292d32" />
                                                                <path id="Vector-4" data-name="Vector" d="M9.25,0V3.44c0,3.64-2.17,5.81-5.81,5.81H0V0Z" transform="translate(760.75 200.75)" fill="#292d32" />
                                                                <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(772 212) rotate(180)" fill="none" opacity="0" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                                <button className='sort-btn'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="vuesax_bold_grid-1" data-name="vuesax/bold/grid-1" transform="translate(-437 -268)" opacity="0.5">
                                                            <g id="grid-1" transform="translate(9 16)">
                                                                <path id="Vector" d="M5.75,0V5.8H0C0,2.19,2.15.02,5.75,0Z" transform="translate(430 254)" fill="#292d32" />
                                                                <path id="Vector-2" data-name="Vector" d="M5.75,5.8H0V0C3.6.02,5.75,2.19,5.75,5.8Z" transform="translate(444.25 254)" fill="#292d32" />
                                                                <path id="Vector-3" data-name="Vector" d="M5.75,0C5.71,3.55,3.57,5.68,0,5.7V0Z" transform="translate(444.25 268.3)" fill="#292d32" />
                                                                <path id="Vector-4" data-name="Vector" d="M5.75,0V5.7C2.18,5.68.04,3.55,0,0Z" transform="translate(430 268.3)" fill="#292d32" />
                                                                <path id="Vector-5" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(430 261.3)" fill="#292d32" />
                                                                <path id="Vector-6" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(444.25 261.3)" fill="#292d32" />
                                                                <path id="Vector-7" data-name="Vector" d="M0,0H5.5V5.5H0Z" transform="translate(437.25 261.3)" fill="#292d32" />
                                                                <path id="Vector-8" data-name="Vector" d="M0,0H5.5V5.8H0Z" transform="translate(437.25 254)" fill="#292d32" />
                                                                <path id="Vector-9" data-name="Vector" d="M0,0H5.5V5.7H0Z" transform="translate(437.25 268.3)" fill="#292d32" />
                                                                <path id="Vector-10" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(452 276) rotate(180)" fill="none" opacity="0" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* BANNER */}
                        <section className='banner-area'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='banner-img'>
                                            <img src={userData['cover'] || require('../../static/images/manage_account_bg.png')} alt='' />
                                            {userID == id &&
                                                <button onClick={() => this.props.toggleCoverModal(true)} className="change-icon"><img src={require('../../static/images/banner-imf-icon.png')} alt='' /></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* PROFILE IMAGE */}
                        <section className='profile-img-area'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-12'>
                                        <div className='img-proflie'>
                                            <img src={userData['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                            {userID == id &&
                                                <button onClick={() => this.props.toggleProfileModal(true)} className="change-icon"><img src={require('../../static/images/profile-img-icon.png')} alt='' /></button>
                                            }
                                        </div>
                                    </div>

                                    <div className='col-lg-9 col-md-12'>
                                        {userID == id
                                            ? <div className='followers-sec py-4'>
                                                <div className='followers-block'>
                                                    <h5>{userData['followers'] ? userData['followersCount'] : '0'}</h5>
                                                    <p>Followers</p>
                                                </div>
                                                <div className='followers-block'>
                                                    <h5>{userData['followingCount'] ? userData['followingCount'] : '0'}</h5>
                                                    <p>Following</p>
                                                </div>
                                                <div className='followers-block'>
                                                    <Link className="message-link" to={`/uploadPosts`}>Upload NFT</Link>
                                                </div>
                                            </div>
                                            : <div className='followers-sec'>
                                                <div className='followers-block'>
                                                    <h5>0</h5>
                                                    <p>Likes</p>
                                                </div>
                                                <div className='followers-block'>
                                                    <h5>{userData['followers'] ? userData['followersCount'] : '0'}</h5>
                                                    <p>Followers</p>
                                                </div>
                                                <div className='followers-block'>
                                                    <h5>{userData['followingCount'] ? userData['followingCount'] : '0'}</h5>
                                                    <p>Following</p>
                                                </div>
                                                <div className='followers-block'>
                                                    <button onClick={this.handleFollow} className="follow-link" to="#">
                                                        {userData['isFollowed']
                                                            ? 'UnFollow'
                                                            : 'Follow'
                                                        }
                                                    </button>
                                                </div>
                                                {userData['isFollowed'] &&
                                                    <div className='followers-block'>
                                                        <Link className="message-link" to={`/messaging/${userData['_id']}`}>Message</Link>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className='col-lg-5 col-md-12'>
                                        <div className='profile-detail'>
                                            <div className='name-area'>
                                                <h4>{userData['name']}</h4>
                                                {userID !== id &&
                                                    <div className='sortby-dropdrown dropdown'>
                                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.893" height="4.723" viewBox="0 0 18.893 4.723">
                                                                <g id="details_more" transform="translate(-355 -167.599)">
                                                                    <g id="Group_58" data-name="Group 58" transform="translate(373.893 167.599) rotate(90)">
                                                                        <path id="Path_9" data-name="Path 9" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 0)" />
                                                                        <path id="Path_10" data-name="Path 10" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.361,2.361,0,0,1,3.34,0" transform="translate(0 7.085)" />
                                                                        <path id="Path_11" data-name="Path 11" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 14.17)" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <Link class="dropdown-item" to={`/messaging/${userData['_id']}`}>
                                                                <i className='icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.19" height="14.188" viewBox="0 0 14.19 14.188">
                                                                        <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M18.245,4.535,4.679,10.448a.311.311,0,0,0,.011.565l3.67,2.073a.592.592,0,0,0,.676-.067l7.236-6.238c.048-.041.163-.118.207-.074s-.026.159-.067.207l-6.26,7.051a.59.59,0,0,0-.059.706l2.4,3.847a.312.312,0,0,0,.562-.007l5.61-13.567A.311.311,0,0,0,18.245,4.535Z" transform="translate(-4.503 -4.503)" />
                                                                    </svg>
                                                                </i>
                                                                Send Message
                                                            </Link>
                                                            <a class="dropdown-item" href="#">
                                                                <i className='icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.242" height="17.091" viewBox="0 0 14.242 17.091">
                                                                        <path id="Icon_ionic-md-flag" data-name="Icon ionic-md-flag" d="M20.1,5.355a17.519,17.519,0,0,1-2.208.187A17.779,17.779,0,0,1,14.267,5a18.514,18.514,0,0,0-3.743-.5c-2.608,0-3.494.539-3.587.6l-.187.129V21.591H8.886V13.66a13.5,13.5,0,0,1,1.642-.089,18.013,18.013,0,0,1,3.552.69,19.089,19.089,0,0,0,3.868.512,17.664,17.664,0,0,0,2.154-.178c.334-.04.623-.076.89-.12V5.221C20.77,5.266,20.436,5.314,20.1,5.355Z" transform="translate(-6.75 -4.5)" />
                                                                    </svg>
                                                                </i>
                                                                Report
                                                            </a>
                                                            <a class="dropdown-item" href="#">
                                                                <i className='icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.492" height="14.492" viewBox="0 0 14.492 14.492">
                                                                        <path id="Icon_material-block" data-name="Icon material-block" d="M10.246,3a7.246,7.246,0,1,0,7.246,7.246A7.249,7.249,0,0,0,10.246,3Zm-5.8,7.246a5.8,5.8,0,0,1,5.8-5.8A5.726,5.726,0,0,1,13.8,5.674L5.674,13.8A5.726,5.726,0,0,1,4.449,10.246Zm5.8,5.8A5.726,5.726,0,0,1,6.7,14.818L14.818,6.7a5.726,5.726,0,0,1,1.225,3.551A5.8,5.8,0,0,1,10.246,16.043Z" transform="translate(-3 -3)" />
                                                                    </svg>
                                                                </i>
                                                                Block
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {userData['username'] &&
                                                <p className='profile-name'>
                                                    {userData['username']}
                                                    <CopyToClipboard onCopy={this.copied} text={userData['username']}>
                                                        <button className='icon'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <g id="Group_2524" data-name="Group 2524" transform="translate(-710 -1)">
                                                                    <path id="Path_4010" data-name="Path 4010" d="M718.988,9.947a3.635,3.635,0,0,0,5.481.393l2.181-2.181A3.634,3.634,0,0,0,721.51,3.02l-1.25,1.243" transform="translate(-2.669)" fill="none" stroke="#2d85ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                    <path id="Path_4011" data-name="Path 4011" d="M719.681,10.425a3.633,3.633,0,0,0-5.48-.392l-2.181,2.181a3.634,3.634,0,0,0,5.139,5.139l1.242-1.243" transform="translate(0 -2.372)" fill="none" stroke="#2d85ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </CopyToClipboard>
                                                </p>
                                            }
                                            <div className='profile-adress'>
                                                <p>{userData['description']}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* NFT TABS */}
                        <section className='nft-tabs-sec'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="false">Created</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="collected-tab" data-toggle="tab" href="#collected" role="tab" aria-controls="collected" aria-selected="true" disabled>Collected</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="collections-tab" data-toggle="tab" href="#collections" role="tab" aria-controls="collections" aria-selected="false">Collections</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* NFT TABS IMAGES*/}
                        <section className='nft-tabs-img-sec'>
                            <div class="tab-content collection-area" id="myTabContent">
                                {/* CREATED */}
                                <div class="tab-pane fade show active nfts-sec" id="created" role="tabpanel" aria-labelledby="created-tab"><div className='auto-container'>
                                    {isUserPost
                                        ? <div className="post-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                        : <div className='auto-container'>
                                            <div className='row'>
                                                {userPosts && userPosts.length > 0
                                                    ? <div className='col-12'>
                                                        <p className='collection-posts'>
                                                            {userPosts.length}
                                                            {userPosts.length == 1
                                                                ? 'post'
                                                                : 'posts'
                                                            }
                                                        </p>
                                                        <h5>NFTs</h5>
                                                        <div className='row clearfix'>
                                                            {userPosts.map(post => {
                                                                return (
                                                                    <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
                                                                        <div className='nfts-block'>
                                                                            <div className='img-box'>
                                                                                {post['data']['format'] == 'jpg' || post['data']['format'] == 'jpeg' || post['data']['format'] == 'png'
                                                                                    || post['data']['format'] == 'pnm' || post['data']['format'] == 'bmp' || post['data']['format'] == 'svg'
                                                                                    ? <img src={post['data']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                    : post['data']['format'] == 'mp4' || post['data']['format'] == 'webm'
                                                                                        ? <video width="500" autoPlay loop>
                                                                                            <source src={post['data']['url']} type="video/mp4" />
                                                                                        </video>
                                                                                        : post['data']['format'] == 'mp3' || post['data']['format'] == 'mpeg' || post['data']['format'] == 'ogg'
                                                                                            ? <audio controls>
                                                                                                <source src={post['data']['url']} type="audio/mp3" />
                                                                                            </audio>
                                                                                            : <p className="text-center">No Preview!</p>
                                                                                }
                                                                                <div className='overlabox'>
                                                                                    <button className='fav-heart'>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.477" height="13.506" viewBox="0 0 15.477 13.506">
                                                                                            <path id="blue_heart" d="M16.607,5.691a4.071,4.071,0,0,0-5.76,0l-.785.785-.785-.785a4.074,4.074,0,1,0-5.76,5.763l.785.785L10.061,18l5.76-5.764.785-.785A4.076,4.076,0,0,0,16.607,5.691Z" transform="translate(-2.323 -4.497)" fill="#fff" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='text-box'>
                                                                                <p>{post['description']}</p>
                                                                                {post['owner'] &&
                                                                                    <div className='user-detail'>
                                                                                        <div className='img-box'>
                                                                                            <img src={post['owner']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                        </div>
                                                                                        <div className='detail-box'>
                                                                                            <p>{post['owner']['name']}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {(post['forSale'] || post['isSponsored']) &&
                                                                                    <div className='btn-area'>
                                                                                        {post['forSale'] &&
                                                                                            <button className='btn-style-one'>15 ETH</button>
                                                                                        }
                                                                                        {post['isSponsored'] &&
                                                                                            <button className='btn-style-two'>Sponsored</button>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                    : <div className='col-12'>
                                                        <p className='collection-posts'>No posts found!</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                </div>

                                {/* COLLECTED */}
                                {/* <div class="tab-pane fade" id="collected" role="tabpanel" aria-labelledby="collected-tab">
                                    {isUserPost
                                        ? <div className="post-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                        : <div className='auto-container'>
                                            <div className='row'>
                                                {userPosts && userPosts.length > 0
                                                    ? <div className='col-12'>
                                                        <p className='collection-posts'>
                                                        {userPosts.length} 
                                                         {userPosts.length == 1
                                                                ? 'post'
                                                                : 'posts'
                                                            }
                                                        </p>
                                                        <div className='row clearfix'>

                                                            {userPosts.map(post => {
                                                                return (
                                                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                                                        <div className='nft-img'>
                                                                            {post['data']['format'] == 'jpg' || post['data']['format'] == 'jpeg' || post['data']['format'] == 'png'
                                                                                || post['data']['format'] == 'pnm' || post['data']['format'] == 'bmp' || post['data']['format'] == 'svg'
                                                                                ? <img src={post['data']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                : post['data']['format'] == 'mp4' || post['data']['format'] == 'webm'
                                                                                    ? <video width="500" autoPlay loop controls="true">
                                                                                        <source src={post['data']['url']} type="video/mp4" />
                                                                                    </video>
                                                                                    : post['data']['format'] == 'mp3' || post['data']['format'] == 'mpeg' || post['data']['format'] == 'ogg'
                                                                                        ? <audio controls>
                                                                                            <source src={post['data']['url']} type="audio/mp3" />
                                                                                        </audio>
                                                                                        : <p className="text-center">No Preview!</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                    : <div className='col-12'>
                                                        <p className='collection-posts'>No posts found!</p>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    }
                                </div> */}

                                {/* COLLECTIONS */}
                                <div class="tab-pane fade top-collection" id="collections" role="tabpanel" aria-labelledby="collections-tab">
                                    {isMyCollection
                                        ? <div className="post-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                        : <div className='auto-container'>
                                            <div className='row'>
                                                {myCollections && myCollections.length > 0
                                                    ? <div className='col-12'>
                                                        <p className='collection-posts'>
                                                            {myCollections.length} 
                                                            {userPosts.length == 1
                                                                ? 'collection'
                                                                : 'collections'
                                                            }
                                                            </p>
                                                        <h5>Collections</h5>
                                                        <div className='row clearfix user-accounts'>
                                                            {myCollections.map(collection => {
                                                                return (
                                                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                                                        <div className="item mycollection-profile">
                                                                            <div className='user-block'>
                                                                                <div className='user-detail'>
                                                                                    <Link to={`/collectionDetails/${collection['_id']}`}>
                                                                                        <div className='img-box'>
                                                                                            <div className='img-box'>
                                                                                                <img src={collection['banner']['url']} alt='' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='text-box'>
                                                                                            <p>{collection['collectionName']}</p>
                                                                                            <p className="text-secondary">{collection['category']}</p>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                    : <div className='col-12'>
                                                        <p className='collection-posts'>No collections found!</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </section >

                        {/* --------------- COVER IMAGE MODAL--------------- */}
                        < Modal isOpen={isCoverModal} toggle={() => this.props.toggleCoverModal(false)
                        } className={`main-modal image-modal profile-done-modal ${lightMode ? 'dark' : 'light'}`
                        }>
                            <ModalHeader toggle={() => this.props.toggleCoverModal(false)}></ModalHeader>
                            <ModalBody>
                                <div className='content-area'>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='icon-splash'>
                                                <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                            </div>
                                            <h2>Change Cover Image</h2>

                                            {coverImage == ''
                                                ? <>
                                                    <div className='input-file'>
                                                        <input
                                                            className='btn-style-one'
                                                            accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                            type='file'
                                                            id='upload'
                                                            onChange={this.setCoverImage}
                                                        />
                                                        <div className='content-btn'>
                                                            <div className='left-area'>
                                                                <div className='icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
                                                                        <path id="Path_4258" data-name="Path 4258" d="M36.727,46H31a6.892,6.892,0,0,1-4.948-1.969A6.408,6.408,0,0,1,24,39.219a6.848,6.848,0,0,1,1.5-4.344,6.629,6.629,0,0,1,3.914-2.437,8.478,8.478,0,0,1,3.182-4.656A8.812,8.812,0,0,1,38,26a8.676,8.676,0,0,1,6.317,2.547,8.361,8.361,0,0,1,2.593,6.2,5.589,5.589,0,0,1,3.644,1.86A5.446,5.446,0,0,1,52,40.375a5.377,5.377,0,0,1-1.671,3.985A5.569,5.569,0,0,1,46.273,46h-7V37.063L41.309,39l1.782-1.75L38,32.25l-5.091,5L34.691,39l2.036-1.937Z" transform="translate(-24 -26)" fill="#929292" />
                                                                    </svg>
                                                                </div>
                                                                <div className='text-box'>
                                                                    <h5>Select a file or drag and drop here</h5>
                                                                    <p>JPG or PNG, file size no more then 5MB</p>
                                                                </div>
                                                            </div>
                                                            <div className='right-area'>
                                                                <button className='btn-style-two'>Select File</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <Cropper
                                                    ref={cropper}
                                                    dragMode='move'
                                                    cropBoxResizable={false}
                                                    aspectRatio={16 / 9}
                                                    className='cropper-settings'
                                                    src={coverImage}
                                                />
                                            }
                                            <div className='btn-area'>
                                                <button onClick={() => this.props.toggleCoverModal(false)} className='btn-style-two'>Cancel</button>
                                                <button onClick={this.handleCoverImage} className='btn-style-one'>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal >

                        {/* --------------- PROFILE IMAGE MODAL--------------- */}
                        < Modal isOpen={isProfileModal} toggle={() => this.props.toggleProfileModal(false)} className={`main-modal  image-modal profile-done-modal profile-done-modal ${lightMode ? 'dark' : 'light'}`}>
                            <ModalHeader toggle={() => this.props.toggleProfileModal(false)}></ModalHeader>
                            <ModalBody>
                                <div className='content-area'>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='icon-splash'>
                                                <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                            </div>
                                            <h2>Change Profile Image</h2>
                                            {profileImage == ''
                                                ? <>
                                                    <div className='input-file'>
                                                        <input
                                                            className='btn-style-one'
                                                            accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                            type='file'
                                                            id='upload'
                                                            onChange={this.setProfileImage}
                                                        />
                                                        <div className='content-btn'>
                                                            <div className='left-area'>
                                                                <div className='icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
                                                                        <path id="Path_4258" data-name="Path 4258" d="M36.727,46H31a6.892,6.892,0,0,1-4.948-1.969A6.408,6.408,0,0,1,24,39.219a6.848,6.848,0,0,1,1.5-4.344,6.629,6.629,0,0,1,3.914-2.437,8.478,8.478,0,0,1,3.182-4.656A8.812,8.812,0,0,1,38,26a8.676,8.676,0,0,1,6.317,2.547,8.361,8.361,0,0,1,2.593,6.2,5.589,5.589,0,0,1,3.644,1.86A5.446,5.446,0,0,1,52,40.375a5.377,5.377,0,0,1-1.671,3.985A5.569,5.569,0,0,1,46.273,46h-7V37.063L41.309,39l1.782-1.75L38,32.25l-5.091,5L34.691,39l2.036-1.937Z" transform="translate(-24 -26)" fill="#929292" />
                                                                    </svg>
                                                                </div>
                                                                <div className='text-box'>
                                                                    <h5>Select a file or drag and drop here</h5>
                                                                    <p>JPG or PNG, file size no more then 5MB</p>
                                                                </div>
                                                            </div>
                                                            <div className='right-area'>
                                                                <button className='btn-style-two'>Select File</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <Cropper
                                                    ref={cropper}
                                                    dragMode='move'
                                                    cropBoxResizable={false}
                                                    aspectRatio={2 / 2}
                                                    className='cropper-settings'
                                                    src={profileImage}
                                                />
                                            }
                                            <div className='btn-area'>
                                                <button onClick={() => this.props.toggleProfileModal(false)} className='btn-style-two'>Cancel</button>
                                                <button onClick={this.handleProfileImage} className='btn-style-one'>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal >
                    </>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    getUserProfile, userProfileLoader, setFollowUnfollow, toggleLoader, toggleCoverModal, sendCoverImage, toggleProfileModal,
    sendProfileImage, getUserPosts, userPostsLoader, getMyCollections, toggleMyCollections,
};

const mapStateToProps = ({ Auth, Post, Collection }) => {
    let { isUserPost, userPosts, } = Post;
    let { myCollections, isMyCollection } = Collection;
    let { userData, isUserData, userID, isCoverModal, isProfileModal, } = Auth;
    return { userData, isUserData, userID, isCoverModal, isProfileModal, isUserPost, userPosts, myCollections, isMyCollection }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);