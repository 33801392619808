import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import Switch from '@material-ui/core/Switch';
import { styled } from '@material-ui/core/styles';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Link } from 'react-router-dom';

import './index.css';
import '../../static/css/animate.css';
import 'react-circular-progressbar/dist/styles.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";

class MyWallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShareModal: false,
        };
    };

    render() {

        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec profile-edit-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                <div className='my-wallet-box'>
                                    <div className='top-heading'>
                                        <h3>My Wallet</h3>
                                        <Link className='btn-style-one' to="/AddCard">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.331" height="18.331" viewBox="0 0 18.331 18.331">
                                                    <g id="black-and-white-credit-cards" transform="translate(0)">
                                                        <g id="Group_783" data-name="Group 783">
                                                            <path id="Path_283" data-name="Path 283" d="M21.192,8.759,12.634.489a1.768,1.768,0,0,0-2.441,0L5.506,5.016a1.653,1.653,0,0,0,0,2.38l.777.771H8.075L6.4,6.531a.455.455,0,0,1-.135-.325A.443.443,0,0,1,6.4,5.887l.619-.579,2.941,2.86h2.821L8.432,3.942l2.657-2.577a.476.476,0,0,1,.325-.135.462.462,0,0,1,.325.127L20.3,9.625a.433.433,0,0,1,.135.314.46.46,0,0,1-.148.314l-.485.457v1.731l1.382-1.323A1.63,1.63,0,0,0,21.192,8.759Z" transform="translate(-3.361 0)" fill="#fff" />
                                                            <path id="Path_284" data-name="Path 284" d="M14.5,21.328H2.8a1.7,1.7,0,0,0-1.709,1.663v4.424H16.143V22.991A1.647,1.647,0,0,0,14.5,21.328ZM5.178,25.909a1.209,1.209,0,0,1-.84-.34,1.214,1.214,0,1,1,0-1.749,1.214,1.214,0,1,1,.84,2.089Z" transform="translate(-1.091 -12.635)" fill="#fff" />
                                                            <path id="Path_285" data-name="Path 285" d="M1.091,40.43A1.708,1.708,0,0,0,2.8,42.1H14.5a1.655,1.655,0,0,0,1.648-1.673v-.1H1.091Z" transform="translate(-1.091 -23.772)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </i>
                                            Add a Card
                                        </Link>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-12'>
                                            <div className='wallet-block'>
                                                <button className='refresh'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.825" height="18.437" viewBox="0 0 22.825 18.437">
                                                        <g id="refresh_my_wallets" transform="translate(-0.086 -3.464)">
                                                            <path id="Path_3770" data-name="Path 3770" d="M1.5,6v5.454H6.954" transform="translate(0 -0.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                            <path id="Path_3771" data-name="Path 3771" d="M30.954,26.454V21H25.5" transform="translate(-9.456 -6.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                            <path id="Path_3772" data-name="Path 3772" d="M19.216,9.955A8.181,8.181,0,0,0,5.718,6.9L1.5,10.864m20,3.636L17.28,18.464a8.181,8.181,0,0,1-13.5-3.054" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                <p>ETH Balance</p>
                                                <h3>3.023549</h3>
                                                <ul className='card-detail'>
                                                    <li>****</li>
                                                    <li>****</li>
                                                    <li>****</li>
                                                    <li>0907</li>
                                                </ul>
                                                <div className='bottom-footer'>
                                                    <p>Kathy Ross</p>
                                                    <p>05/30</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-12'>
                                            <div className='expense-area'>
                                                <div className='expense-block'>
                                                    <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                        <CircularProgressbar value={25} />
                                                    </div>
                                                    <p>Expenses</p>
                                                    <h4>3.235 ETH</h4>
                                                </div>

                                                <div className='expense-block'>
                                                    <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                        <CircularProgressbar value={45} />
                                                    </div>
                                                    <p>Pending Request</p>
                                                    <h4>3.500 ETH</h4>
                                                </div>

                                                <div className='expense-block'>
                                                    <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                        <CircularProgressbar value={65} />
                                                    </div>
                                                    <p>Transactions</p>
                                                    <h4>20.789 ETH</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='transtion-area'>
                                        <p className='transtion-hist'>Transaction History</p>
                                        <p>Today</p>
                                        <div className='transtion-block'>
                                            <div className='left-area'>
                                                <div className='icon-eth'>
                                                    <img src={require('../../static/images/transaction_history.png')} alt='' />
                                                </div>
                                                <p>Withdrawal</p>
                                            </div>
                                            <div className='right-area'>
                                                <p className='danger'>-1.05821 ETH</p>
                                            </div>
                                        </div>
                                        <div className='transtion-block'>
                                            <div className='left-area'>
                                                <div className='icon-eth'>
                                                    <img src={require('../../static/images/transaction_history.png')} alt='' />
                                                </div>
                                                <p> NFT sales</p>
                                            </div>
                                            <div className='right-area'>
                                                <p className='success'>+2.2482 ETH</p>
                                            </div>
                                        </div>

                                        <p>Yesterday</p>
                                        <div className='transtion-block'>
                                            <div className='left-area'>
                                                <div className='icon-eth'>
                                                    <img src={require('../../static/images/transaction_history.png')} alt='' />
                                                </div>
                                                <p>NFT purchase</p>
                                            </div>
                                            <div className='right-area'>
                                                <p className='danger'>-1.05821 ETH</p>
                                            </div>
                                        </div>
                                        <div className='transtion-block'>
                                            <div className='left-area'>
                                                <div className='icon-eth'>
                                                    <img src={require('../../static/images/transaction_history.png')} alt='' />
                                                </div>
                                                <p> Withdrawal</p>
                                            </div>
                                            <div className='right-area'>
                                                <p className='danger'>-1.05821 ETH</p>
                                            </div>
                                        </div>

                                        <p>26 Aug 2021</p>
                                        <div className='transtion-block'>
                                            <div className='left-area'>
                                                <div className='icon-eth'>
                                                    <img src={require('../../static/images/transaction_history.png')} alt='' />
                                                </div>
                                                <p>Deduct 2% NFT sale</p>
                                            </div>
                                            <div className='right-area'>
                                                <p className='success'>+1.05821 ETH</p>
                                            </div>
                                        </div>
                                        <div className='transtion-block'>
                                            <div className='left-area'>
                                                <div className='icon-eth'>
                                                    <img src={require('../../static/images/transaction_history.png')} alt='' />
                                                </div>
                                                <p> NFT purchase</p>
                                            </div>
                                            <div className='right-area'>
                                                <p className='danger'>-1.05821 ETH</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div >
        );
    }
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#707070',
        boxSizing: 'border-box',
    },
}));

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyWallet);