import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import {
  setNonce, setLoginData, setSearchUser, searchUserLoader, userProfileLoader, setUserProfile, setProfilesDetails, setCheckUsername,
  toggleCoverModal, toggleProfileModal, editProfileLoader, toggleLoader
} from '../actions/Auth';

const userID = localStorage.getItem('userID');

/*========== LOGIN FUNCTIONS =============*/

function* getNonce({ payload }) {
  const { error, response } = yield call(getCall, `/user/getNonce/${payload}`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setNonce(response['data']['body']));
  yield put(toggleLoader({ message: 'Logging In...', status: false }));
};

function* login({ payload }) {
  const { error, response } = yield call(postCall, { path: '/user/loginWithMetamask', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    let data = {
      token: response['data']['body']['token'],
      publicAddress: payload['publicAddress']
    }
    yield put(setLoginData(data));
    EventBus.publish("success", "Wallet login successfully!!!");
    setTimeout(() => window.location.reload(), 500);
  }
  yield put(toggleLoader({ message: 'Signing In...', status: false }));
};

/*========== SEARCH FUNCTIONS =============*/

function* getSearchUser({ payload }) {
  console.log("*********payload::", payload);
  const { error, response } = yield call(getCall, `/user/searchUsers/${payload}`);
  console.log("*********error::", error);
  console.log("*********response::", response);
  // if (error) EventBus.publish("error", error['response']['data']['message']);
  if (response && response['data']['body']) yield put(setSearchUser(response['data']['body']));
  yield put(searchUserLoader(false));
};

/*========== USER PROFILE FUNCTIONS =============*/

function* getUserProfile({ payload }) {
  const { error, response } = yield call(getCall, `/user/getUserProfile/${payload}`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setUserProfile(response['data']['body']));
  yield put(userProfileLoader(false));
};

function* setFollowUnfollow({ payload }) {
  const { error, response } = yield call(postCall, { path: '/user/follow', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_USER_PROFILE", payload: payload['userId'] });
    EventBus.publish("success", response['data']['message']);
  }
};

/*========== EDIT PROFILE FUNCTIONS =============*/

function* getProfilesDetails() {
  const { error, response } = yield call(getCall, `/user/me`);
  // if (error) EventBus.publish("error", error['response']['data']['message']);
  if (response) yield put(setProfilesDetails(response['data']['body']));
};

function* getCheckUsername({ payload }) {
  const { error, response } = yield call(getCall, `/user/checkUsername/${payload}`);
  if (error) yield put(setCheckUsername(false));
  else if (response) yield put(setCheckUsername(true));
};

function* sendCoverImage({ payload }) {
  const { error, response } = yield call(putCall, { path: '/user/updateUserCover', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_PROFILE_DETAILS" });
    if (userID) {
      yield put({ type: "GET_USER_PROFILE", payload: userID });
    }
    yield put(toggleCoverModal(false));
    EventBus.publish("success", response['data']['message']);
  }
  yield put(toggleLoader({ message: 'Cover Image Changed...', status: false }));
};

function* sendProfileImage({ payload }) {
  const { error, response } = yield call(putCall, { path: '/user/editProfilePicture', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_PROFILE_DETAILS" });
    if (userID) {
      yield put({ type: "GET_USER_PROFILE", payload: userID });
    }
    yield put(toggleProfileModal(false));
    EventBus.publish("success", response['data']['message']);
  }
  yield put(toggleLoader({ message: 'Profile Image Changed...', status: false }));
};

function* sendEditProfile({ payload }) {
  const { error, response } = yield call(putCall, { path: '/user/editProfile', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_PROFILE_DETAILS" });
    EventBus.publish("success", response['data']['message']);
  }
  yield put(editProfileLoader(false));
};


function* actionWatcher() {
  yield takeEvery('GET_NONCE', getNonce);
  yield takeEvery('LOGIN', login);
  yield takeEvery('GET_SEARCH_USER', getSearchUser);
  yield takeEvery('GET_USER_PROFILE', getUserProfile);
  yield takeEvery('GET_CHECK_USERNAME', getCheckUsername);
  yield takeEvery('SET_FOLLOW_UNFOLLOW', setFollowUnfollow);
  yield takeEvery('GET_PROFILE_DETAILS', getProfilesDetails);
  yield takeEvery('SEND_COVER_IMAGE', sendCoverImage);
  yield takeEvery('SEND_PROFILE_IMAGE', sendProfileImage);
  yield takeEvery('SEND_EDIT_PROFILE', sendEditProfile);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
