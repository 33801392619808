import EventBus from 'eventing-bus';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MuiPhoneInput from 'material-ui-phone-number';
import { withStyles } from '@material-ui/core/styles';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import {
    sendEditProfile, editProfileLoader, getCheckUsername, toggleCoverModal, sendCoverImage, toggleProfileModal,
    sendProfileImage, toggleLoader
} from "../../store/actions/Auth";

const cropper = React.createRef(null);

class ManageAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cover: '',
            coverImage: '',
            profilePicture: '',
            profileImage: '',

            formData: {
                firstName: '',
                lastName: '',
                username: '',
                phone: '',
                gender: 'Male',
                dob: new Date("2000-01-01T12:00:00.000Z"),
                description: '',
            },
            countryCode: 'us',
            isUsername: false,
        };
    };

    componentWillReceiveProps({ profileDetails, isEditProfile, isCoverModal, isProfileModal }) {
        if (profileDetails && !isEditProfile) {
            let { coverImage, profileImage, formData } = this.state;
            if (profileDetails['cover']) coverImage = profileDetails['cover'];
            if (profileDetails['profilePicture']) profileImage = profileDetails['profilePicture'];

            if (profileDetails['firstName']) formData['firstName'] = profileDetails['firstName'];
            if (profileDetails['lastName']) formData['lastName'] = profileDetails['lastName'];
            if (profileDetails['username']) formData['username'] = profileDetails['username'];
            if (profileDetails['phone']) formData['phone'] = profileDetails['phone'];
            if (profileDetails['gender']) formData['gender'] = profileDetails['gender'];
            if (profileDetails['dob']) formData['dob'] = new Date(profileDetails['dob']);
            if (profileDetails['description']) formData['description'] = profileDetails['description'];
            this.setState({ coverImage, profileImage, formData });
        }

        if (isCoverModal) this.setState({ cover: '', coverImage: '' });
        if (isProfileModal) this.setState({ profilePicture: '', profileImage: '' });
    };

    setCoverImage = async (e) => {
        let { coverImage } = this.state;

        coverImage = URL.createObjectURL(e.currentTarget.files[0]);
        this.setState({ coverImage });
    };

    handleCoverImage = async () => {
        let { coverImage, cover } = this.state;
        if (cropper.current) {
            coverImage = cropper.current.getCroppedCanvas().toDataURL();
            let res = await fetch(coverImage);
            let blob = await res.blob();
            cover = new File([blob], "Profile Image", { type: "image/png" });
            if (cover['size'] <= 5000000) {
                this.setState({ coverImage, cover });
                let data = new FormData();
                data.append('cover', cover);
                this.props.sendCoverImage(data);
                this.props.toggleLoader({ message: 'Changing Cover Image...', status: true });
            }
            else EventBus.publish("error", "File Size is more then 5MB");
        }
        else EventBus.publish("info", "Cover is not attached!!!");
    };

    setProfileImage = async (e) => {
        let { profileImage } = this.state;
        profileImage = URL.createObjectURL(e.currentTarget.files[0]);
        this.setState({ profileImage });
    };

    handleProfileImage = async () => {
        let { profileImage, profilePicture } = this.state;
        if (cropper.current) {
            profileImage = cropper.current.getCroppedCanvas().toDataURL();
            let res = await fetch(profileImage);
            let blob = await res.blob();
            profilePicture = new File([blob], "Profile Image", { type: "image/png" });
            if (profilePicture['size'] <= 5000000) {
                this.setState({ profileImage, profilePicture });
                let data = new FormData();
                data.append('profilePicture', profilePicture);
                this.props.sendProfileImage(data);
                this.props.toggleLoader({ message: 'Changing Profile Image...', status: true });
            }
            else EventBus.publish("error", "File Size is more then 5MB");
        }
        else EventBus.publish("info", "Profile Image is not attached!!!");
    };

    handleFormChange = ({ target }) => {
        let { formData } = this.state;
        if (target.name == 'username' && target.value.indexOf(" ") > -1) this.setState({ isUsername: true });
        else {
            formData[target.name] = target.value;
            this.setState({ formData, isUsername: false },
                () => {
                    if (target.name == 'username') this.props.getCheckUsername(target.value);
                });
        }
    };

    setPhoneNumber = (number) => {
        let { formData } = this.state;
        formData['phone'] = number;
        this.setState({ formData });
    };

    setDateOfBirth = (date) => {
        let { formData } = this.state;
        formData['dob'] = date;
        this.setState({ formData });
    };

    submitEditProfile = () => {
        let { formData } = this.state;
        let { usernameAvailable } = this.props;

        if (formData['firstName'] == '') EventBus.publish("error", "Please type your First Name");
        if (formData['lastName'] == '') EventBus.publish("error", "Please type your Last Name");
        else if (formData['username'] == '') EventBus.publish("error", "Please type your Username");
        else if (!usernameAvailable) EventBus.publish("error", "Username is already taken!");
        else if (formData['phone'] == '') EventBus.publish("error", "Please type your Phone");
        else {
            this.props.editProfileLoader(true);
            this.props.sendEditProfile(formData);
        }
    };

    render() {
        let { countryCode, coverImage, profileImage, isUsername } = this.state;
        let { lightMode, usernameAvailable, isCoverModal, isProfileModal, isEditProfile } = this.props;
        let { firstName, lastName, username, phone, gender, dob, description } = this.state.formData;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec manage-account-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-4 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-8 col-md-12'>
                                <div className='contact-form'>
                                    <h3>Edit Profile</h3>
                                    <div className='form-area'>
                                        <ValidatorForm className="validatorForm">
                                            <div className='change-profile-img'>
                                                <div className='banner-img'>
                                                    <img src={coverImage || require('../../static/images/manage_account_bg.png')} alt='' />
                                                    <div className='icon-change'>
                                                        <a onClick={() => this.props.toggleCoverModal(true)} className='icon-btn'><img src={require('../../static/images/photo-change.png')} alt='' /></a>
                                                    </div>
                                                </div>
                                                <div className='profile-img'>
                                                    <img src={profileImage || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                    <a onClick={() => this.props.toggleProfileModal(true)} className='plus'><img src={require('../../static/images/plus-img.png')} alt='' /></a>
                                                    <p>Change profile photo</p>
                                                </div>
                                            </div>

                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={6}>
                                                    <label>First Name</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        className="MyTextField"
                                                        placeholder="Type your first name here…"
                                                        name="firstName"
                                                        type="text"
                                                        value={firstName}
                                                        variant="outlined"
                                                        margin="dense"
                                                        onChange={this.handleFormChange}
                                                        validators={['required']}
                                                        errorMessages={['Please type your first name']}
                                                    />
                                                </Grid>
                                                <Grid className="input-fields" item xs={6}>
                                                    <label>Last Name</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        className="MyTextField"
                                                        placeholder="Type your last name here…"
                                                        name="lastName"
                                                        type="text"
                                                        value={lastName}
                                                        variant="outlined"
                                                        margin="dense"
                                                        onChange={this.handleFormChange}
                                                        validators={['required']}
                                                        errorMessages={['Please type your last name']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Username</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        className="MyTextField"
                                                        placeholder="Type your username"
                                                        name="username"
                                                        type="text"
                                                        value={username}
                                                        variant="outlined"
                                                        margin="dense"
                                                        onChange={this.handleFormChange}
                                                        validators={['required']}
                                                        errorMessages={['Please type your username']}
                                                        helperText={isUsername && "Space can't be added in username!" || !usernameAvailable && "Username is already taken!"}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Phone</label>
                                                    <MuiPhoneInput
                                                        fullWidth
                                                        className="MyTextField"
                                                        variant="outlined"
                                                        placeholder="Type your phone number"
                                                        name="phone"
                                                        value={phone}
                                                        margin="dense"
                                                        defaultCountry={countryCode}
                                                        id='standard-full-width'
                                                        onChange={(number) => this.setPhoneNumber(number)}
                                                        validators={['required']}
                                                        errorMessages={['Please add Your Phone']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={6}>
                                                    <label>Gender</label>
                                                    <Select
                                                        className='custom-menu'
                                                        value={gender}
                                                        name="gender"
                                                        variant="outlined"
                                                        onChange={this.handleFormChange}
                                                        placeholder='Select your gender'
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value='Male' className='custom-menu'>Male</MenuItem>
                                                        <MenuItem value='Female' className='custom-menu'>Female</MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid className="input-fields" item xs={6}>
                                                    <label>Date of Birth</label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <span className='icon-date'><img src={require('../../static/images/calender_sponsor.png')} alt='' /></span>
                                                        <DatePicker
                                                            fullWidth
                                                            className='MyTextField'
                                                            name="dob"
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            format="dd/MM/yyyy"
                                                            value={dob}
                                                            onChange={(date) => this.setDateOfBirth(date)}
                                                            KeyboardButtonProps={{ 'aria-label': 'Date of Birth' }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Description</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        multiline
                                                        className="MyTextField"
                                                        placeholder="Write about yourself…"
                                                        name="description"
                                                        type="text"
                                                        value={description}
                                                        variant="outlined"
                                                        margin="dense"
                                                        onChange={this.handleFormChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div className='group-form btn-group'>
                                                <button disabled={isEditProfile} onClick={this.submitEditProfile} className='btn-style-one'>
                                                    {isEditProfile
                                                        ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                                                        : 'Send'
                                                    }
                                                </button>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* --------------- COVER IMAGE MODAL--------------- */}
                <Modal isOpen={isCoverModal} toggle={() => this.props.toggleCoverModal(false)} className={`main-modal image-modal profile-done-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalHeader toggle={() => this.props.toggleCoverModal(false)}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="row">
                                <div className="col-12">
                                    <div className='icon-splash'>
                                        <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                    </div>
                                    <h2>Change Cover Image</h2>

                                    {coverImage == ''
                                        ? <>
                                            <div className='input-file'>
                                                <input
                                                    className='btn-style-one'
                                                    accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                    type='file'
                                                    id='upload'
                                                    onChange={this.setCoverImage}
                                                />
                                                <div className='content-btn'>
                                                    <div className='left-area'>
                                                        <div className='icon'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
                                                                <path id="Path_4258" data-name="Path 4258" d="M36.727,46H31a6.892,6.892,0,0,1-4.948-1.969A6.408,6.408,0,0,1,24,39.219a6.848,6.848,0,0,1,1.5-4.344,6.629,6.629,0,0,1,3.914-2.437,8.478,8.478,0,0,1,3.182-4.656A8.812,8.812,0,0,1,38,26a8.676,8.676,0,0,1,6.317,2.547,8.361,8.361,0,0,1,2.593,6.2,5.589,5.589,0,0,1,3.644,1.86A5.446,5.446,0,0,1,52,40.375a5.377,5.377,0,0,1-1.671,3.985A5.569,5.569,0,0,1,46.273,46h-7V37.063L41.309,39l1.782-1.75L38,32.25l-5.091,5L34.691,39l2.036-1.937Z" transform="translate(-24 -26)" fill="#929292" />
                                                            </svg>
                                                        </div>
                                                        <div className='text-box'>
                                                            <h5>Select a file or drag and drop here</h5>
                                                            <p>JPG or PNG, file size no more then 5MB</p>
                                                        </div>
                                                    </div>
                                                    <div className='right-area'>
                                                        <button className='btn-style-two'>Select File</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : <Cropper
                                            ref={cropper}
                                            dragMode='move'
                                            cropBoxResizable={false}
                                            aspectRatio={16 / 9}
                                            className='cropper-settings'
                                            src={coverImage}
                                        />
                                    }
                                    <div className='btn-area'>
                                        <button onClick={() => this.props.toggleCoverModal(false)} className='btn-style-two'>Cancel</button>
                                        <button onClick={this.handleCoverImage} className='btn-style-one'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/* --------------- PROFILE IMAGE MODAL--------------- */}
                <Modal isOpen={isProfileModal} toggle={() => this.props.toggleProfileModal(false)} className={`main-modal  image-modal profile-done-modal profile-done-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalHeader toggle={() => this.props.toggleProfileModal(false)}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="row">
                                <div className="col-12">
                                    <div className='icon-splash'>
                                        <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                    </div>
                                    <h2>Change Profile Image</h2>
                                    {profileImage == ''
                                        ? <>
                                            <div className='input-file'>
                                                <input
                                                    className='btn-style-one'
                                                    accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                    type='file'
                                                    id='upload'
                                                    onChange={this.setProfileImage}
                                                />
                                                <div className='content-btn'>
                                                    <div className='left-area'>
                                                        <div className='icon'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
                                                                <path id="Path_4258" data-name="Path 4258" d="M36.727,46H31a6.892,6.892,0,0,1-4.948-1.969A6.408,6.408,0,0,1,24,39.219a6.848,6.848,0,0,1,1.5-4.344,6.629,6.629,0,0,1,3.914-2.437,8.478,8.478,0,0,1,3.182-4.656A8.812,8.812,0,0,1,38,26a8.676,8.676,0,0,1,6.317,2.547,8.361,8.361,0,0,1,2.593,6.2,5.589,5.589,0,0,1,3.644,1.86A5.446,5.446,0,0,1,52,40.375a5.377,5.377,0,0,1-1.671,3.985A5.569,5.569,0,0,1,46.273,46h-7V37.063L41.309,39l1.782-1.75L38,32.25l-5.091,5L34.691,39l2.036-1.937Z" transform="translate(-24 -26)" fill="#929292" />
                                                            </svg>
                                                        </div>
                                                        <div className='text-box'>
                                                            <h5>Select a file or drag and drop here</h5>
                                                            <p>JPG or PNG, file size no more then 5MB</p>
                                                        </div>
                                                    </div>
                                                    <div className='right-area'>
                                                        <button className='btn-style-two'>Select File</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : <Cropper
                                            ref={cropper}
                                            dragMode='move'
                                            cropBoxResizable={false}
                                            aspectRatio={2 / 2}
                                            className='cropper-settings'
                                            src={profileImage}
                                        />
                                    }
                                    <div className='btn-area'>
                                        <button onClick={() => this.props.toggleProfileModal(false)} className='btn-style-two'>Cancel</button>
                                        <button onClick={this.handleProfileImage} className='btn-style-one'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }
}


const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);


const mapDispatchToProps = {
    sendEditProfile, getCheckUsername, editProfileLoader, toggleCoverModal, sendCoverImage, toggleProfileModal,
    sendProfileImage, toggleLoader
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode, profileDetails, usernameAvailable, isCoverModal, isProfileModal, isEditProfile, } = Auth;
    return { lightMode, profileDetails, usernameAvailable, isCoverModal, isProfileModal, isEditProfile, }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccount);
