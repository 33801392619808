import { connect } from 'react-redux';
import Countdown from 'react-countdown';
import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MuiPhoneInput from 'material-ui-phone-number';
import { withStyles } from '@material-ui/core/styles';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import { toggleLoader } from "../../store/actions/Auth";


class PlaceaBid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMakeAnOfferModal: false,
            isCompleteCheckoutModal: false,
        };
    };

    renderer = ({ hours, minutes, seconds, completed }) => {
        return (
            <div className="countdown-timer-time bis-sale">
                <div className="counter-day counter-hour bid-count">
                    <h5>{hours ? hours : '-'}</h5>
                    <span>Hours</span>
                </div>
                <div className="counter-day counter-minute bid-count">
                    <h5>{minutes ? minutes : '-'}</h5>
                    <span>Minutes</span>
                </div>
                <div className="counter-day counter-seconds bid-count">
                    <h5>{seconds ? seconds : '-'}</h5>
                    <span>Seconds</span>
                </div>
            </div>
        );
    };

    toggleMakeAnOfferModal = () => this.setState({ isMakeAnOfferModal: !this.state.isMakeAnOfferModal });
    toggleCompleteCheckoutModal = () => this.setState({ isCompleteCheckoutModal: !this.state.isCompleteCheckoutModal });

    render() {

        let { isMakeAnOfferModal, isCompleteCheckoutModal } = this.state;
        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='sort-by-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sortby-inner'>
                                    <div className='sortby-dropdrown dropdown'>
                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.512" height="16.512" viewBox="0 0 16.512 16.512">
                                                    <g id="vuesax_bold_setting-4" data-name="vuesax/bold/setting-4" opacity="0.4">
                                                        <g id="setting-4">
                                                            <path id="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321A.479.479,0,0,1,4.8.482.479.479,0,0,1,4.321.963Z" transform="translate(10.334 4.252)" fill="#292d32" />
                                                            <path id="Vector-2" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.482.482,0,0,1,0,.963Z" transform="translate(1.376 4.252)" fill="#292d32" />
                                                            <path id="Vector-3" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(4.259 2.016)" fill="#292d32" />
                                                            <path id="Vector-4" data-name="Vector" d="M3.041.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H3.041a.479.479,0,0,1,.482.482A.479.479,0,0,1,3.041.963Z" transform="translate(11.614 11.29)" fill="#292d32" />
                                                            <path id="Vector-5" data-name="Vector" d="M4.321.963H.482A.479.479,0,0,1,0,.482.479.479,0,0,1,.482,0H4.321a.482.482,0,1,1,0,.963Z" transform="translate(1.376 11.29)" fill="#292d32" />
                                                            <path id="Vector-6" data-name="Vector" d="M5.435,2.718A2.718,2.718,0,1,1,2.718,0,2.718,2.718,0,0,1,5.435,2.718Z" transform="translate(6.818 9.061)" fill="#292d32" />
                                                            <path id="Vector-7" data-name="Vector" d="M0,0H16.512V16.512H0Z" transform="translate(16.512 16.512) rotate(180)" fill="none" opacity="0" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </i>
                                            Sort by
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" href="#">Sort by</a>
                                            <a class="dropdown-item" href="#">Sort by</a>
                                            <a class="dropdown-item" href="#">Sort by</a>
                                        </div>
                                    </div>
                                    <div className='right-sort-btn'>
                                        <button className='sort-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="vuesax_bold_grid-2" data-name="vuesax/bold/grid-2" transform="translate(-748 -188)">
                                                    <g id="grid-2">
                                                        <path id="Vector" d="M9.25,0V9.25H5.81C2.17,9.25,0,7.08,0,3.44V0Z" transform="translate(750 200.75)" fill="#292d32" />
                                                        <path id="Vector-2" data-name="Vector" d="M9.25,5.81V9.25H0V0H3.44C7.08,0,9.25,2.17,9.25,5.81Z" transform="translate(760.75 190)" fill="#292d32" />
                                                        <path id="Vector-3" data-name="Vector" d="M9.25,0V9.25H0V5.81C0,2.17,2.17,0,5.81,0Z" transform="translate(750 190)" fill="#292d32" />
                                                        <path id="Vector-4" data-name="Vector" d="M9.25,0V3.44c0,3.64-2.17,5.81-5.81,5.81H0V0Z" transform="translate(760.75 200.75)" fill="#292d32" />
                                                        <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(772 212) rotate(180)" fill="none" opacity="0" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                        <button className='sort-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="vuesax_bold_grid-1" data-name="vuesax/bold/grid-1" transform="translate(-437 -268)" opacity="0.5">
                                                    <g id="grid-1" transform="translate(9 16)">
                                                        <path id="Vector" d="M5.75,0V5.8H0C0,2.19,2.15.02,5.75,0Z" transform="translate(430 254)" fill="#292d32" />
                                                        <path id="Vector-2" data-name="Vector" d="M5.75,5.8H0V0C3.6.02,5.75,2.19,5.75,5.8Z" transform="translate(444.25 254)" fill="#292d32" />
                                                        <path id="Vector-3" data-name="Vector" d="M5.75,0C5.71,3.55,3.57,5.68,0,5.7V0Z" transform="translate(444.25 268.3)" fill="#292d32" />
                                                        <path id="Vector-4" data-name="Vector" d="M5.75,0V5.7C2.18,5.68.04,3.55,0,0Z" transform="translate(430 268.3)" fill="#292d32" />
                                                        <path id="Vector-5" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(430 261.3)" fill="#292d32" />
                                                        <path id="Vector-6" data-name="Vector" d="M0,0H5.75V5.5H0Z" transform="translate(444.25 261.3)" fill="#292d32" />
                                                        <path id="Vector-7" data-name="Vector" d="M0,0H5.5V5.5H0Z" transform="translate(437.25 261.3)" fill="#292d32" />
                                                        <path id="Vector-8" data-name="Vector" d="M0,0H5.5V5.8H0Z" transform="translate(437.25 254)" fill="#292d32" />
                                                        <path id="Vector-9" data-name="Vector" d="M0,0H5.5V5.7H0Z" transform="translate(437.25 268.3)" fill="#292d32" />
                                                        <path id="Vector-10" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(452 276) rotate(180)" fill="none" opacity="0" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='profile-detail-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-12'>
                                <div className='img-description-area'>
                                    <div className='img-box'>
                                        <img src={require('../../static/images/nft-collection/nft-detail.jpg')} alt='' />
                                    </div>
                                    <h4>Description</h4>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam. voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12'>
                                <div className='text-description-area'>
                                    <div className='top-text-box'>
                                        <span>kathyross</span>
                                        <ul className='right-button'>
                                            <li>
                                                <button className='fav-btn'>
                                                    <img src={require('../../static/images/heart-fav.svg')} alt='' />
                                                </button>
                                            </li>
                                            <li>
                                                <button className='fav-btn'>
                                                    <img src={require('../../static/images/share-btn.svg')} alt='' />
                                                </button>
                                            </li>
                                            <li>
                                                <button className='fav-btn'>
                                                    <img src={require('../../static/images/details-more.svg')} alt='' />
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3>Mad Crocodile Crew NFT</h3>
                                    <div className='owner-suggestion'>
                                        <div className='owner-block'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/owner-img-1.png')} alt='' />
                                            </div>
                                            <div className='text-box'>
                                                <span>Creator</span>
                                                <p>@dorisfisher</p>
                                            </div>
                                        </div>

                                        <div className='owner-block'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/owner-img-2.png')} alt='' />
                                            </div>
                                            <div className='text-box'>
                                                <span>Owner</span>
                                                <p>@jeremypalmer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='tokenid'><span>Token ID:</span> 32120012</p>
                                    <div className='bid-sec'>
                                        <h5>Bid End In</h5>
                                        <div className="countdown-timer timer-coutdwon">
                                            <div className="timer-inner">
                                                <Countdown date={Date.now() + parseInt((9775168972 * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bid-btn-area'>
                                        <p>Current Price:</p>
                                        <h4>15 ETH <span>($16437.22)</span></h4>
                                        <div className='bid-btn-sec'>
                                            <button className='buy-btn' onClick={this.toggleCompleteCheckoutModal}>Buy Now</button>
                                            <button className='bid-btn' onClick={this.toggleMakeAnOfferModal}>Place a Bid</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-12'>
                                <div className='left-details-area'>
                                    <div className='details-panel'>
                                        <div id="accordion">
                                            <div class="card">
                                                <div class="card-header" id="headingDetails">
                                                    <h5 class="mb-0">
                                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseDetails" aria-expanded="true" aria-controls="collapseDetails">
                                                            <i className='icon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.363" height="13.416" viewBox="0 0 16.363 13.416">
                                                                    <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3.25 -7.75)">
                                                                        <path id="Path_4295" data-name="Path 4295" d="M4.5,18H18.363" transform="translate(0 -3.542)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                                                                        <path id="Path_4296" data-name="Path 4296" d="M4.5,9H18.363" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                                                                        <path id="Path_4297" data-name="Path 4297" d="M4.5,27H18.363" transform="translate(0 -7.084)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                                                                    </g>
                                                                </svg>
                                                            </i>
                                                            Details
                                                        </button>
                                                    </h5>
                                                </div>

                                                <div id="collapseDetails" class="collapse show" aria-labelledby="headingDetails" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <ul className='list'>
                                                            <li>Token ID <span className='active'>8987965</span></li>
                                                            <li>Contact Address <span className=''>0cdfhf…657</span></li>
                                                            <li>Chain <span className=''>Ethereum</span></li>
                                                            <li>Last Update <span className=''>4 months ago</span></li>
                                                            <li>Creator Earnings <span className=''>2%</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-7 col-md-12'>
                                <div className='right-details-area'>
                                    <div className='details-panel'>
                                        <div id="accordion">
                                            <div class="card">
                                                <div class="card-header" id="headingTables">
                                                    <h5 class="mb-0">
                                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTables" aria-expanded="true" aria-controls="collapseTables">
                                                            <i className='icon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.363" height="13.416" viewBox="0 0 16.363 13.416">
                                                                    <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3.25 -7.75)">
                                                                        <path id="Path_4295" data-name="Path 4295" d="M4.5,18H18.363" transform="translate(0 -3.542)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                                                                        <path id="Path_4296" data-name="Path 4296" d="M4.5,9H18.363" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                                                                        <path id="Path_4297" data-name="Path 4297" d="M4.5,27H18.363" transform="translate(0 -7.084)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                                                                    </g>
                                                                </svg>
                                                            </i>
                                                            Offers
                                                        </button>
                                                    </h5>
                                                </div>

                                                <div id="collapseTables" class="collapse show" aria-labelledby="headingTables" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <div class="table-responsive">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Price</th>
                                                                        <th scope="col">USD Price</th>
                                                                        <th scope="col">Floor Difference</th>
                                                                        <th scope="col">Expiration</th>
                                                                        <th scope="col">From</th>
                                                                        <th scope="col"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><i className='icon'><img src={require('../../static/images/eth-icon.svg')} alt='' /></i> 0.004ETH</td>
                                                                        <td>$33.50</td>
                                                                        <td>44% below</td>
                                                                        <td>About 6 house</td>
                                                                        <td className='name'>You</td>
                                                                        <td className='close'>
                                                                            <button>
                                                                                <i className='icon'>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                                        <path id="Icon_material-cancel" data-name="Icon material-cancel" d="M11,3a8,8,0,1,0,8,8A7.993,7.993,0,0,0,11,3Zm4,10.872L13.872,15,11,12.128,8.128,15,7,13.872,9.872,11,7,8.128,8.128,7,11,9.872,13.872,7,15,8.128,12.128,11Z" transform="translate(-3 -3)" fill="#9f9f9f" />
                                                                                    </svg>
                                                                                </i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>


                                                                    <tr>
                                                                        <td><i className='icon'><img src={require('../../static/images/eth-icon.svg')} alt='' /></i> 0.004ETH</td>
                                                                        <td>$33.50</td>
                                                                        <td>44% below</td>
                                                                        <td>About 6 house</td>
                                                                        <td className='name'>Seth</td>
                                                                        <td className='close'>

                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td><i className='icon'><img src={require('../../static/images/eth-icon.svg')} alt='' /></i> 0.004ETH</td>
                                                                        <td>$33.50</td>
                                                                        <td>44% below</td>
                                                                        <td>About 6 house</td>
                                                                        <td className='name'>Gilbert</td>
                                                                        <td className='close'>

                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td><i className='icon'><img src={require('../../static/images/eth-icon.svg')} alt='' /></i> 0.004ETH</td>
                                                                        <td>$33.50</td>
                                                                        <td>44% below</td>
                                                                        <td>About 6 house</td>
                                                                        <td className='name'>Jorge</td>
                                                                        <td className='close'>

                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* --------------- Make An Offer MODAL--------------- */}
                <Modal isOpen={isMakeAnOfferModal} toggle={this.toggleMakeAnOfferModal} className={`main-modal profile-done-modal makeanoffer-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalHeader toggle={this.toggleMakeAnOfferModal}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <h2>Make an Offer</h2>
                            <ValidatorForm className="validatorForm">
                                <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                    <Grid className="input-fields" item xs={4}>
                                        <label>Offer amount</label>
                                        <Select
                                            className='custom-menu'
                                            // value={gender}
                                            name="offerAmount"
                                            variant="outlined"
                                            // onChange={this.handleFormChange}
                                            placeholder='Select your Offer amount'
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value='Eth' className='custom-menu'><i className='icon'><img src={require('../../static/images/eth-blue.svg')} alt='' /></i>Etherium</MenuItem>
                                            <MenuItem value='BTC' className='custom-menu'><i className='icon'><img src={require('../../static/images/eth-blue.svg')} alt='' /></i>Etherium</MenuItem>
                                            <MenuItem value='BAN' className='custom-menu'><i className='icon'><img src={require('../../static/images/eth-blue.svg')} alt='' /></i>Etherium</MenuItem>
                                            <MenuItem value='MON' className='custom-menu'><i className='icon'><img src={require('../../static/images/eth-blue.svg')} alt='' /></i>Etherium</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid className="input-fields" item xs={8}>
                                        <label>Offer amount <span>Balance: 0.000ETH</span></label>
                                        <CustomTextField
                                            fullWidth
                                            className="MyTextField"
                                            placeholder="Amount"
                                            name="offerAmount"
                                            type="text"
                                            // value={username}
                                            variant="outlined"
                                            margin="dense"
                                            // onChange={this.handleFormChange}
                                            validators={['required']}
                                            errorMessages={['Please type your Amount']}
                                        // helperText={isUsername && "Space can't be added in Amount!" || !usernameAvailable && "Username is already taken!"}
                                        />
                                        <i className='bottom-text'>Total offer amount: 0ETH</i>
                                    </Grid>
                                </Grid>


                                <Grid container spacing={2} className="group-input" alignItems="flex-end">
                                    <Grid className="input-fields" item xs={4}>
                                        <label>Offer expiration</label>
                                        <Select
                                            className='custom-menu'
                                            // value={gender}
                                            name="offerExpiration"
                                            variant="outlined"
                                            // onChange={this.handleFormChange}
                                            placeholder='Select your Offer expiration'
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value='Days' className='custom-menu'>Days</MenuItem>
                                            <MenuItem value='Month' className='custom-menu'>Month</MenuItem>
                                            <MenuItem value='Year' className='custom-menu'>Year</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid className="input-fields" item xs={8}>
                                        <label>Date time</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <span className='icon-date'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.353" height="17.546" viewBox="0 0 15.353 17.546">
                                                    <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,15.9a1.645,1.645,0,0,0,1.645,1.645H13.708A1.645,1.645,0,0,0,15.353,15.9V6.58H0ZM10.967,9.184a.412.412,0,0,1,.411-.411h1.371a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H11.378a.412.412,0,0,1-.411-.411Zm0,4.387a.412.412,0,0,1,.411-.411h1.371a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H11.378a.412.412,0,0,1-.411-.411ZM6.58,9.184a.412.412,0,0,1,.411-.411H8.362a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H6.991a.412.412,0,0,1-.411-.411Zm0,4.387a.412.412,0,0,1,.411-.411H8.362a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H6.991a.412.412,0,0,1-.411-.411ZM2.193,9.184A.412.412,0,0,1,2.6,8.773H3.975a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H2.6a.412.412,0,0,1-.411-.411Zm0,4.387A.412.412,0,0,1,2.6,13.16H3.975a.412.412,0,0,1,.411.411v1.371a.412.412,0,0,1-.411.411H2.6a.412.412,0,0,1-.411-.411ZM13.708,2.193H12.063V.548A.55.55,0,0,0,11.515,0h-1.1A.55.55,0,0,0,9.87.548V2.193H5.483V.548A.55.55,0,0,0,4.935,0h-1.1A.55.55,0,0,0,3.29.548V2.193H1.645A1.645,1.645,0,0,0,0,3.838V5.483H15.353V3.838A1.645,1.645,0,0,0,13.708,2.193Z" fill="#9f9f9f" />
                                                </svg>
                                            </span>
                                            <DatePicker
                                                fullWidth
                                                className='MyTextField'
                                                name="dateTime"
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="dd/MM/yyyy"
                                                // value={dob}
                                                // onChange={(date) => this.setDateOfBirth(date)}
                                                KeyboardButtonProps={{ 'aria-label': 'Date of Birth' }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <div className='group-form btn-group'>
                                    <button className='btn-style-one'>
                                        Make Offer
                                    </button>
                                </div>
                            </ValidatorForm>
                        </div>
                    </ModalBody>
                </Modal >

                {/* --------------- Complete Checkout MODAL--------------- */}
                <Modal isOpen={isCompleteCheckoutModal} toggle={this.toggleCompleteCheckoutModal} className={`main-modal profile-done-modal makeanoffer-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalHeader toggle={this.toggleCompleteCheckoutModal}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <h2>Complete Checkout</h2>
                            <div className='item-checkout-box'>
                                <div className='item-box'>
                                    <div className='left-area'>
                                        <h4>Iteam</h4>
                                    </div>
                                    <div className='right-area'>
                                        <h4>Subtotal</h4>
                                    </div>
                                </div>

                                <div className='item-box img-item-box'>
                                    <div className='left-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/product-checkout-1.png')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h4>Giraffe Tower</h4>
                                            <p>Giraffe #6498</p>
                                            <span>Royalities: 5%</span>
                                        </div>
                                    </div>
                                    <div className='right-area'>
                                        <div className='text-box'>
                                            <h4>0.24 ETH</h4>
                                            <p>$1134.00</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='item-box bottom-text'>
                                    <div className='left-area'>
                                        <h4>Total</h4>
                                    </div>
                                    <div className='right-area'>
                                        <h4>0.24 ETH</h4>
                                        <p>$1134.00</p>
                                    </div>
                                </div>

                                <div className='btn-box'>
                                    <button className='btn-style-one'>
                                        Confirm Checkout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >
            </div >
        );
    }
}


const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceaBid);