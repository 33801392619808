/*========== CHAT ACTIONS ============= */

export const getAllActiveFriends = () => ({
  type: 'GET_ALL_ACTIVE_FRIENDS',
});

export const setActiveStatus = (data) => ({
  type: 'SET_ACTIVE_STATUS',
  payload: data
});

export const setAllActiveFriends = (data) => ({
  type: 'SET_ALL_ACTIVE_FRIENDS',
  payload: data
});

export const getAllChatHistory = () => ({
  type: 'GET_ALL_CHAT_HISTORY',
});

export const setAllChatHistory = (data) => ({
  type: 'SET_ALL_CHAT_HISTORY',
  payload: data
});

export const allChatHistoryLoader = (data) => ({
  type: 'ALL_CHAT_HISTORY_LOADER',
  payload: data
});

export const getSingleChatHistory = (data) => ({
  type: 'GET_SINGLE_CHAT_HISTORY',
  payload: data
});

export const setSingleChatHistory = (data) => ({
  type: 'SET_SINGLE_CHAT_HISTORY',
  payload: data
});

export const singleChatHistoryLoader = (data) => ({
  type: 'SINGLE_CHAT_HISTORY_LOADER',
  payload: data
});

export const sendTextMessage = (data) => ({
  type: 'SEND_TEXT_MESSAGE',
  payload: data
});

export const chatReadMessage = (data) => ({
  type: 'CHAT_READ_MESSAGE',
  payload: data
});

export const sendChatFile = ({ data }) => ({
  type: 'SEND_CHAT_FILE',
  payload: data,
});

export const sendChatLoader = (data) => ({
  type: 'SEND_CHAT_LOADER',
  payload: data
});

export const chatMessageSent = (data) => ({
  type: 'CHAT_MESSAGE_SENT',
  payload: data
});

export const chatMessageReceived = (data) => ({
  type: 'CHAT_MESSAGE_RECEIVED',
  payload: data
});

export const deleteChat = (data) => ({
  type: 'DELETE_CHAT',
  payload: data
});

export const toggleDeleteChatModal = (data) => ({
  type: 'TOGGLE_DELETE_CHAT_MODAL',
  payload: data
});

export const deleteChatMessage = (data) => ({
  type: 'DELETE_CHAT_MESSAGE',
  payload: data
});

export const toggleDeleteMessageModal = (data) => ({
  type: 'TOGGLE_DELETE_MESSAGE_MODAL',
  payload: data
});

