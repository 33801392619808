import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { toggleLoader } from '../actions/Auth';
import {
  uploadPostLoader, setAllPosts, allPostsLoader, setSinglePost, singlePostLoader, setUserPosts, userPostsLoader,
  sendCommentLoader, setAllComments, toggleDeleteCommentModal
} from '../actions/Post';


/*========== POST FUNCTIONS =============*/

function* uploadPost({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/post/createPost', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    setTimeout(() => history.push(`/postDetail/${response['data']['body']['_id']}`), 500);
  }
  yield put(toggleLoader({ status: false }));
  yield put(uploadPostLoader(false));
};

function* getAllPosts() {
  const { error, response } = yield call(getCall, `/post/getAllPosts`);
  // if (error) EventBus.publish("error", error['response']['data']['message']);
  if (response) yield put(setAllPosts(response['data']['body']));
  yield put(allPostsLoader(false));
};

function* getSinglePost({ payload }) {
  const { error, response } = yield call(getCall, `/post/getSinglePost/${payload}`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setSinglePost(response['data']['body']));
  yield put(singlePostLoader(false));
};

function* getUserPosts({ payload }) {
  const { error, response } = yield call(getCall, `/post/getUserPosts/${payload}`);
  // if (error) EventBus.publish("error", error['response']['data']['message']);
  if (response) yield put(setUserPosts(response['data']['body']));
  yield put(userPostsLoader(false));
};

function* postLikeDislike({ payload, id }) {
  const { error, response } = yield call(postCall, { path: `/post/likePost/${id}`, payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_ALL_POSTS" });
    EventBus.publish("success", response['data']['message']);
  }
};


function* sendPostComment({ payload, id }) {
  console.log("*********payload::", payload);
  const { error, response } = yield call(postCall, { path: `/post/commentPost/${id}`, payload });
  console.log("*********error::", error);
  console.log("*********response::", response);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_ALL_COMMENTS", payload: id });
    EventBus.publish("success", response['data']['message']);
  }
  yield put(sendCommentLoader(false));
};

function* getAllComments({ payload }) {
  const { error, response } = yield call(getCall, `/post/getComments/${payload}`);
  // if (error) EventBus.publish("error", error['response']['data']['message']);
  if (response) yield put(setAllComments(response['data']['body']));
};

function* deletePostComment({ payload, id }) {
  console.log("******payload::", payload);
  const { error, response } = yield call(putCall, { path: `/post/deleteComment/${payload['deleteCommentID']}`, payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put({ type: "GET_ALL_COMMENTS", payload: id });
  }
  yield put(toggleDeleteCommentModal(false));
};

function* editPostComment({ payload, id }) {
  console.log("********id::", id);
  console.log("********payload::", payload);
  const { error, response } = yield call(putCall, { path: `/post/editComment/${payload['commentID']}`, payload });
  console.log("********error::", error);
  console.log("********response::", response);

  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_ALL_COMMENTS", payload: id });
    EventBus.publish("success", response['data']['message']);
  }
  yield put(sendCommentLoader(false));
};


function* actionWatcher() {
  yield takeEvery('UPLOAD_POST', uploadPost);
  yield takeEvery('GET_ALL_POSTS', getAllPosts);
  yield takeEvery('GET_SINGLE_POST', getSinglePost);
  yield takeEvery('GET_USER_POSTS', getUserPosts);
  yield takeEvery('POST_LIKE_DISLIKE', postLikeDislike);
  yield takeEvery('SEND_POST_COMMENT', sendPostComment);
  yield takeEvery('GET_ALL_COMMENTS', getAllComments);
  yield takeEvery('DELETE_POST_COMMENT', deletePostComment);
  yield takeEvery('EDIT_POST_COMMENT', editPostComment);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall({ path, payload }) {
  return axios
    .delete(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
