import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';

import './index.css';
import '../../static/css/animate.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";


class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShareModal: false,
        };
    };

    render() {

        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec contact-us-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                <div className='contact-form'>
                                    <h3>Contact Us</h3>
                                    <div className='form-area'>
                                        <ValidatorForm className="validatorForm" >
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Full Name</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        className="MyTextField"
                                                        autoComplete='off'
                                                        placeholder="Type your name here…"
                                                        name="Name"
                                                        type="text"
                                                        // value={email}
                                                        variant="outlined"
                                                        margin="dense"
                                                        // onKeyPress={this.keyPressed}
                                                        // onChange={this.handleFormChange}
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Please add Your Full Name', 'Full Name is not valid']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Email Address</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        className="MyTextField"
                                                        autoComplete='off'
                                                        placeholder="Type your email address"
                                                        name="Name"
                                                        type="text"
                                                        // value={email}
                                                        variant="outlined"
                                                        margin="dense"
                                                        // onKeyPress={this.keyPressed}
                                                        // onChange={this.handleFormChange}
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Please add Your Email Address', 'Email Address is not valid']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Message</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        multiline
                                                        className="MyTextField"
                                                        autoComplete='off'
                                                        placeholder="Type your message here…"
                                                        name="Message"
                                                        type="text"
                                                        // value={email}
                                                        variant="outlined"
                                                        margin="dense"
                                                        // onKeyPress={this.keyPressed}
                                                        // onChange={this.handleFormChange}
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Please add Your Message', 'Message is not valid']}
                                                    />
                                                </Grid>
                                            </Grid>


                                            <div className='group-form btn-group'>
                                                <button className='btn-style-one'>Send</button>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div >
        );
    }
}
const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);