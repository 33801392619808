import '../axios';
import Auth from './Auth';
import Chat from './Chat';
import Collection from './Collection';
import Post from './Post';
import ChatSockets from './ChatSockets';
import Drop from './Drop';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    Auth(),
    Chat(),
    Collection(),
    Post(),
    ChatSockets(),
    Drop(),
  ]);
}
