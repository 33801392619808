import { PURGE } from "redux-persist";

let initialState = {
  isNotableDrop: false,
  singleDrop: {},
  isSingleDrop: false,
  allDrops: [],
  isAllDrops: false,
}

const Drop = (state = initialState, { type, payload }) => {
  switch (type) {

    /*========== DROP REDUCERS ============= */

    case 'NOTABLE_DROP_LOADER':
      return {
        ...state,
        isNotableDrop: payload,
      };

    case 'SET_SINGLE_DROP':
      return {
        ...state,
        singleDrop: payload,
      };

    case 'SINGLE_DROP_LOADER':
      return {
        ...state,
        isSingleDrop: payload,
      };


    case 'SET_ALL_DROPS':
      return {
        ...state,
        allDrops: payload,
      };

    case 'ALL_DROPS_LOADER':
      return {
        ...state,
        isAllDrops: payload,
      };

    default:
      return state;
  }
};

export default Drop;