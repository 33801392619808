import { connect } from 'react-redux';
import React, { Component } from 'react';

import './index.css';



class SplashScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {
        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <div className='splash-sec'>
                    <div className='cotainer'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='icon-splash'>
                                    <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapDispatchToProps = {  };

const mapStateToProps = ({ Auth }) => {
  let { lightMode } = Auth;
  return { lightMode };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);