import Web3 from "web3";
import Torus from "@toruslabs/torus-embed";
import { chainId, hostName, networkName } from "./config";

let web3;
let torus = new Torus();

const loginTorus = async () => {
    try {
        torus = new Torus();
        await torus.init({
            buildEnv: "production", // default: production
            enableLogging: true, // default: false
            network: {
                host: hostName, // default: mainnet
                chainId: chainId, // default: 1
                networkName: networkName // default: Main Ethereum Network
            },
            // showTorusButton: false // default: true
        });
        await torus.login();
        web3 = new Web3(torus.provider);
        localStorage.setItem("torusProvider", JSON.stringify(torus.provider));
    }
    catch (error) {
        console.log("error::", error);
    }
};

const switchNetwork = async ({ chainId, hostName, networkName }) => {

    try {
        await torus.setProvider({
            host: hostName, // mandatory
            chainId: chainId, // optional
            networkName: networkName, // optional
        });

        web3 = new Web3(torus.provider);
        localStorage.setItem("torusProvider", JSON.stringify(torus.provider));
    }
    catch (error) {
        console.log("error::", error);
    }
};

const logoutTours = async () => {
    await torus.logout();
    localStorage.removeItem('torusProvider');
    localStorage.removeItem('loglevel:torus-embed');
    localStorage.removeItem('loglevel:http-helpers');
    localStorage.removeItem('loglevel:torus.js');
};


export { web3, Web3, loginTorus, switchNetwork, logoutTours };