import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";
import { switchNetwork } from '../../store/web3';
import { chainId, hostName, networkName } from '../../store/config';
import { createCollection, createCollectionLoader } from "../../store/actions/Collection";
import { GoerliaFactoryABI, GoerliaFactoryAddress, BSCFactoryABI, BSCFactoryAddress, } from '../../store/contract/index';

class CreateNewCollection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: '',
            collectionLogo: '',
            banner: '',
            collectionBanner: '',
            collectionName: '',
            slug: '',
            description: '',
            category: '',
            websiteUrl: '',
            blockchain: 'Binance Smart Chain',

            categoryArray: ['Art', 'Gaming', 'Music', 'Photography', 'Sports'],
            // blockchainArray: ['Goerli', 'Ethereum', 'Avalanche', 'Arbitrum', 'Binance Smart Chain', 'Optimism']
            blockchainArray: ['Goerli', 'Binance Smart Chain',]
        };
    };

    componentWillReceiveProps({ isCollectionCreated }) {
        if (!isCollectionCreated) {
            this.setState({ logo: '', collectionLogo: '', banner: '', collectionBanner: '', collectionName: '', slug: '', description: '', category: '', websiteUrl: '', blockchain: 'Binance Smart Chain' });
        }
    };

    setLogoImage = async (e) => {
        let { logo, collectionLogo } = this.state;
        if (e.currentTarget.files[0]) {
            collectionLogo = URL.createObjectURL(e.currentTarget.files[0]);
            let res = await fetch(collectionLogo);
            let blob = await res.blob();
            logo = new File([blob], "Collection Logo", { type: "image/png" });
            if (logo['size'] <= 5000000) this.setState({ logo, collectionLogo });
            else EventBus.publish("error", "File Size is more then 5MB");
        }
    };

    setBannerImage = async (e) => {
        let { banner, collectionBanner } = this.state;
        if (e.currentTarget.files[0]) {
            collectionBanner = URL.createObjectURL(e.currentTarget.files[0]);
            let res = await fetch(collectionBanner);
            let blob = await res.blob();
            banner = new File([blob], "Collection Banner", { type: "image/png" });
            if (banner['size'] <= 5000000) this.setState({ banner, collectionBanner });
            else EventBus.publish("error", "File Size is more then 5MB");
        }
    };

    handleFormChange = async ({ target }) => {
        if (target.name == 'blockchain') {
            if (target.value == 'Goerli') {
                let chainId = '0x5';
                let hostName = 'goerli';
                let networkName = 'Goerli test network';
                await switchNetwork({ chainId, hostName, networkName });
            }
            else await switchNetwork({ chainId, hostName, networkName });
        }

        this.setState({ [target.name]: target.value });
    };

    handlePostCollection = async () => {
        try {
            let { publicAddress } = this.props;
            let { logo, banner, collectionName, description, slug, category, websiteUrl, blockchain } = this.state;

            let Factory = '', ContractAddress = '';

            if (logo == '') {
                EventBus.publish('error', `Please select the collection logo`);
                return;
            };

            if (banner == '') {
                EventBus.publish('error', `Please select the collection banner`);
                return;
            };

            if (collectionName == '') {
                EventBus.publish('error', `Please enter the collection name`);
                return;
            };

            if (slug == '') {
                EventBus.publish('error', `Please enter the collection slug`);
                return;
            };

            if (blockchain == 'Goerli') {
                ContractAddress = GoerliaFactoryAddress;
                Factory = await new web3.eth.Contract(GoerliaFactoryABI, GoerliaFactoryAddress);
            };

            if (blockchain == 'Binance Smart Chain') {
                ContractAddress = BSCFactoryAddress;
                Factory = await new web3.eth.Contract(BSCFactoryABI, BSCFactoryAddress);
            };

            const balance = await web3.eth.getBalance(publicAddress);
            if (balance == 0) {
                EventBus.publish('error', `Your have insufficient balance`);
                return;
            };

            /** Create Collection **/
            this.props.toggleLoader({ message: 'Creating Collection...', status: true });
            await web3.eth.sendTransaction({
                from: publicAddress,
                value: 0,
                to: ContractAddress,
                data: Factory.methods.createCollection(collectionName, slug).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    if (receipt) {
                        let contractAddress = await receipt['logs'][0]['address'];
                        let data = new FormData();
                        data.append('logo', logo);
                        data.append('banner', banner);
                        data.append('collectionName', collectionName);
                        data.append('description', description);
                        data.append('slug', slug);
                        data.append('category', category);
                        data.append('websiteUrl', websiteUrl);
                        data.append('blockchain', blockchain);
                        data.append('payoutAddress', publicAddress);
                        data.append('contractAddress', contractAddress);

                        this.props.createCollection(data);
                        this.props.createCollectionLoader(true);
                    }
                })
        } catch (e) {
            console.log('********Error = ', e);
            this.props.toggleLoader({ message: 'Collection not Created...', status: false });
            EventBus.publish('error', `Unable to Create Collection`);
        };
    };

    render() {

        let { lightMode } = this.props;
        let { collectionLogo, collectionBanner, collectionName, slug, description, category, websiteUrl, blockchain, categoryArray, blockchainArray } = this.state;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='left-sidebar-area col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                <div className='contact-form create-collection-page'>
                                    <h2>Create Collections</h2>
                                    <h4>Upload Logo</h4>
                                    {collectionLogo == ''
                                        ? < div className='img-upload-area'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/place-img.png')} alt='' />
                                            </div>
                                            <div className='text-box'>
                                                <h5>Select a file or drag and drop here</h5>
                                                <p>JPG , PNG or SVG file size no more then 1MB</p>
                                                <div className='input-file'>
                                                    <input
                                                        type='file'
                                                        name='avatar'
                                                        id='contained-button-file'
                                                        className='custom-file-input'
                                                        accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                        onChange={this.setLogoImage}
                                                    />
                                                    <span className='btn-style-two'>Select File</span>
                                                </div>
                                            </div>
                                        </div>
                                        : < div className='img-upload-area'>
                                            <div className='img-box'>
                                                <img className='show-img' src={(collectionLogo)} alt='' />

                                                <div className='ovalybox'>
                                                    <div className='input-file'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                            <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                        </svg>
                                                        <input
                                                            type='file'
                                                            name='avatar'
                                                            id='contained-button-file'
                                                            className='custom-file-input'
                                                            accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                            onChange={this.setLogoImage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <h4>Upload Banner</h4>
                                    <div className='nft-upload-btn-area'>
                                        <div className='upload-btn'>
                                            <div className='image-area'>
                                                <img src={require('../../static/images/nft-upload.png')} alt='' />
                                            </div>
                                            {collectionBanner == ''
                                                ? <div className='input-area'>
                                                    <img src={require('../../static/images/upload-ion.png')} alt='' />
                                                    <div className='content-area'>
                                                        <h5>Or drag and drop a file</h5>
                                                        <p>File types supported: JPG, PNG, GIF<br />
                                                            Max size: 100 MB</p>
                                                        <Link className='ordrag-area' to="#">Or</Link>
                                                    </div>
                                                    <button className="btn-style-one">Select file</button>
                                                    <input
                                                        type='file'
                                                        id='contained-button-file'
                                                        className='custom-file-input'
                                                        accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                        onChange={this.setBannerImage}
                                                    />
                                                </div>
                                                : <div className='input-area input-after-img'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
                                                        <path id="Icon_material-cloud-upload" data-name="Icon material-cloud-upload" d="M37.894,18.08a14.581,14.581,0,0,0-27.417-4A12.011,12.011,0,0,0,11.75,38H37.208a9.98,9.98,0,0,0,.685-19.92ZM27.417,24v8H19.583V24H13.708L23.5,14l9.792,10Z" transform="translate(0 -6)" opacity="1" />
                                                    </svg>
                                                    <img className='banner-img' src={collectionBanner} alt='' />
                                                    {/* <button onClick={() => this.setState({ banner: '', collectionBanner: '' })} className="cancel-btn">
                                                        <img className='icon' src={require('../../static/images/cancel.png')} alt='' />
                                                    </button> */}
                                                    <input
                                                        type='file'
                                                        id='contained-button-file'
                                                        className='custom-file-input'
                                                        accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                        onChange={this.setBannerImage}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='form-area'>
                                        <ValidatorForm className="validatorForm" >
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Name*</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="text"
                                                        margin="dense"
                                                        variant="outlined"
                                                        name="collectionName"
                                                        value={collectionName}
                                                        className="MyTextField"
                                                        placeholder="Treasure of the sea"
                                                        onChange={this.handleFormChange}
                                                        validators={['required']}
                                                        errorMessages={['Please add collection name']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Symbol*</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="text"
                                                        margin="dense"
                                                        name="slug"
                                                        value={slug}
                                                        variant="outlined"
                                                        className="MyTextField"
                                                        placeholder="Symbol of collection"
                                                        onChange={this.handleFormChange}
                                                        validators={['required']}
                                                        errorMessages={['Please add collection slug']}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Description</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        multiline
                                                        type="text"
                                                        margin="dense"
                                                        name="description"
                                                        variant="outlined"
                                                        className="MyTextField"
                                                        value={description}
                                                        onChange={this.handleFormChange}
                                                        placeholder="Here is the description of your collection"
                                                    />
                                                    <span className='crechter-counter'>0/100 Max</span>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Category & Tag</label>
                                                    <Select
                                                        displayEmpty
                                                        name="category"
                                                        value={category}
                                                        variant="outlined"
                                                        onChange={this.handleFormChange}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {categoryArray.map(category => {
                                                            return (
                                                                <MenuItem value={category}>
                                                                    <lable>{category}</lable>
                                                                </MenuItem>
                                                            )
                                                        })
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Social Links</label>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="url"
                                                        margin="dense"
                                                        name="websiteUrl"
                                                        variant="outlined"
                                                        value={websiteUrl}
                                                        className="MyTextField"
                                                        placeholder="http://.yuorsite.io"
                                                        onChange={this.handleFormChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <label>Blockchain</label>
                                                    <Select
                                                        displayEmpty
                                                        name="blockchain"
                                                        value={blockchain}
                                                        variant="outlined"
                                                        onChange={this.handleFormChange}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {blockchainArray.map(blockchain => {
                                                            return (
                                                                <MenuItem value={blockchain}>
                                                                    <lable>{blockchain}</lable>
                                                                    {/* <lable><span className='icon'><img src={require('../../static/images/chains-1.png')} alt='' /></span>Ethereum</lable> */}
                                                                </MenuItem>
                                                            )
                                                        })
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>

                                            {/* <div className='group-form btn-group'>
                                                <button className='btn-style-two'>Draft</button>
                                                <button onClick={this.handlePostCollection} className='btn-style-one'>Post</button>
                                            </div> */}

                                            <div className='group-form btn-group d-flex justify-content-center'>
                                                <button onClick={this.handlePostCollection} className='btn-style-one'>Create</button>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        );
    }
}
const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const mapDispatchToProps = { toggleLoader, createCollection, createCollectionLoader };

const mapStateToProps = ({ Auth, Collection }) => {
    let { isCollectionCreated, } = Collection;
    let { lightMode, publicAddress, } = Auth;
    return { lightMode, publicAddress, isCollectionCreated, };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCollection);