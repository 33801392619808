import moment from "moment";
import Slider from "react-slick";
import EventBus from "eventing-bus";
import { Picker } from "emoji-mart";
import { connect } from 'react-redux';
import Radio from '@mui/material/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import BeatLoader from "react-spinners/BeatLoader";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import './index.css';
import '../../static/css/animate.css';
import "emoji-mart/css/emoji-mart.css";
import 'owl.carousel/dist/assets/owl.carousel.css';

import Navbar from '../../components/navbar';
import { getUserProfile, userProfileLoader } from "../../store/actions/Auth";
import {
    getAllActiveFriends, getSingleChatHistory, chatReadMessage, singleChatHistoryLoader, sendTextMessage, sendChatFile, sendChatLoader,
    toggleDeleteChatModal, deleteChat, toggleDeleteMessageModal, deleteChatMessage
} from "../../store/actions/Chat";


class Messaging extends Component {

    constructor(props) {
        super(props);
        const query = require('url').parse(document.location.href, true);
        const receiverId = query.path.split('/')[--query.path.split('/').length];
        this.state = {
            receiverId,
            message: '',
            file: '',
            isEmoji: false,

            singleChat: [],
            singleChatUser: {},
            chatID: '',
            chatId: '',
        };
    };

    async componentDidMount() {
        let { receiverId } = this.state;

        this.props.getAllActiveFriends();
        this.getChatPageDetails(receiverId);
    };

    getChatPageDetails = (receiverId) => {
        if (receiverId) {
            this.setState({ receiverId });
            this.props.getUserProfile(receiverId);
            this.props.userProfileLoader(true);
            this.props.getSingleChatHistory({ receiverId });
            this.props.chatReadMessage(receiverId);
            this.props.singleChatHistoryLoader(true);
        };
    };

    componentDidUpdate(prevProps, prevState) {
        let { messageSent, messageReceive } = this.props;

        let { singleChat } = this.state;
        if (prevProps['messageSent'] !== messageSent) {

            singleChat.push(messageSent);
            this.setState({ singleChat });
        };

        if (prevProps['messageReceive'] !== messageReceive) {
            let { receiverId } = this.state;

            if (messageReceive['senderId']['_id'] == receiverId) {
                this.props.chatReadMessage(receiverId);

                singleChat.push(messageReceive);
                this.setState({ singleChat });
            }
        };
    };

    componentWillReceiveProps({ singleChatHistory, userData, activeStatus, isDeleteChatModal, isDeleteMessageModal, }) {
        if (userData) this.setState({ singleChatUser: userData });
        if (activeStatus && (userData['_id'] == activeStatus['_id'])) this.setState({ singleChatUser: activeStatus });
        if (singleChatHistory.length > 0) this.setState({ singleChat: singleChatHistory });
        if (!isDeleteChatModal) this.setState({ chatID: '' });
        if (!isDeleteMessageModal) this.setState({ chatId: '' });
    };

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleEmoji = (e) => {
        let { message } = this.state;
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);

        this.setState({ message: message + emoji }, () => this.setState({ isEmoji: false }));
    };

    handleFileUpload = async (e) => {
        let { file } = this.state;
        let upload = URL.createObjectURL(e.currentTarget.files[0]);
        let type = e.currentTarget.files[0]['type'];
        let name = e.currentTarget.files[0]['name'];
        let res = await fetch(upload);
        let blob = await res.blob();
        file = new File([blob], name, { type });

        if (file['size'] <= 5000000) this.setState({ file });
        else EventBus.publish("error", "File Size is more then 5MB");
    };

    handleSendMessage = () => {
        let { message, file, receiverId } = this.state;

        if (message == '' && file == '') {
            EventBus.publish("error", 'Please type the message or attach the file!');
            return;
        };

        if (file !== '') {
            let data = new FormData();
            data.append('file', file);
            data.append('receiverId', receiverId);
            this.setState({ file: '' });
            this.props.sendChatLoader(true);
            this.props.sendChatFile({ data });
        };

        if (message !== '') {
            this.setState({ message: '' });
            this.props.sendChatLoader(true);
            this.props.sendTextMessage({ message, receiverId });
        }
    };

    keyPressed = (e) => {
        if (e.key === "Enter") this.handleSendMessage();
    };

    handleDeleteChat = (chatID) => this.setState({ chatID }, () => this.props.toggleDeleteChatModal(true));
    submitDeleteChat = () => {
        let { chatID } = this.state;
        this.props.deleteChat({ receiverId: chatID });
    };

    handleDeleteMessage = (chatId) => this.setState({ chatId }, () => this.props.toggleDeleteMessageModal(true));
    submitDeleteMessage = () => {
        let { chatId } = this.state;
        this.props.deleteChatMessage({ chatId });
    };

    render() {

        let { message, file, isEmoji, receiverId, singleChat, singleChatUser } = this.state;
        let { lightMode, isUserData, activeFriends, chatHistory, isChatHistory, isSingleChatHistory, isSendChat, isDeleteChatModal,
            isDeleteMessageModal } = this.props;

        const settings = {
            dots: false,
            infinite: false,
            nav: false,
            arrows: false,
            centerPadding: 30,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='chat-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-12'>
                                <div className='left-content-area'>
                                    <div className='massage-box'>
                                        <h3>Messages</h3>
                                        <div className='active-friends-area'>
                                            <h4>Active friends</h4>
                                            {activeFriends && activeFriends.length > 0
                                                ? <Slider className="slider-items" {...settings}>
                                                    {activeFriends.map(friend => {
                                                        return (
                                                            <Link to={`/profile/${friend['_id']}`}>
                                                                <div className="item">
                                                                    <div className='active-friend'>
                                                                        <div className='img-box'>
                                                                            <img src={friend['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                        </div>
                                                                        <p>{friend['firstName']}</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        )
                                                    })
                                                    }
                                                </Slider>
                                                : <p className="text-center">No Active Friends!</p>
                                            }
                                        </div>
                                        <div className='chatbox-friends'>
                                            {isChatHistory
                                                ? <div className="all-chat-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                                : <>
                                                    {chatHistory.length > 0
                                                        ? <>
                                                            {chatHistory.map(chat => {
                                                                let sentTime = new Date(chat['sentAt']).getTime();
                                                                let currentTime = new Date().getTime();
                                                                let milisec = (currentTime - sentTime);

                                                                let days = moment.duration(milisec).days();
                                                                let hours = moment.duration(milisec).hours();
                                                                let minutes = moment.duration(milisec).minutes();
                                                                let seconds = moment.duration(milisec).seconds();

                                                                return (
                                                                    <div className='chatbox-friends-block'>
                                                                        <Link onClick={() => this.getChatPageDetails(chat['friendData']['_id'])} className='text-dark' to={`/messaging/${chat['friendData']['_id']}`}>
                                                                            <div className={`img-box ${chat['friendData']['isOnline'] && 'active'}`}>
                                                                                <img src={(chat['friendData'] && chat['friendData']['profilePicture']) || require('../../static/images/camera.png')} alt='' />
                                                                            </div>
                                                                        </Link>
                                                                        <div className='text-box'>
                                                                            <Link onClick={() => this.getChatPageDetails(chat['friendData']['_id'])} className='text-dark' to={`/messaging/${chat['friendData']['_id']}`}>
                                                                                <div className='left-text'>
                                                                                    {chat['friendData'] &&
                                                                                        <h5>{chat['friendData']['name']}</h5>
                                                                                    }
                                                                                    <div className="msg-time">
                                                                                        {chat['messageType'] == "Text"
                                                                                            ? <p className={`${(chat['unreadCount'] > 0 && chat['friendData']['_id'] !== receiverId) && 'font-weight-bold'}`}>{chat['lastMessage']}</p>
                                                                                            : <p className={`${(chat['unreadCount'] > 0 && chat['friendData']['_id'] !== receiverId) > 0 && 'font-weight-bold'}`}>{chat['lastData']['name'].substring(0, 10) + '...'}</p>
                                                                                        }
                                                                                        {days > 0
                                                                                            ? <span>{days} days ago</span>
                                                                                            : hours > 0
                                                                                                ? <span>{hours} hr ago</span>
                                                                                                : minutes > 0
                                                                                                    ? <span>{minutes} mins ago</span>
                                                                                                    : seconds > 0
                                                                                                        ? <span>{seconds} sec ago</span>
                                                                                                        : 'now'
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                            <div className='right-text'>
                                                                                {(chat['unreadCount'] > 0 && chat['friendData']['_id'] !== receiverId) &&
                                                                                    <span>{chat['unreadCount']}</span>
                                                                                }
                                                                                {/* <div className="message-options">
                                                                                    <div className='sortby-dropdrown dropdown'>
                                                                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        </button>
                                                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                            <button onClick={() => this.handleDeleteChat(chat['friendData']['_id'])} className="delete-btn">
                                                                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                                                                                <span>Delete Message</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </>
                                                        : <div className="d-flex justify-content-center text-secondary  no-message"><p>No Chats!</p></div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-12'>
                                <div className='right-content-area'>
                                    <div className='chat-box'>
                                        <div className="top-frieds-area">
                                            <div className='top-friend-detail'>
                                                {isUserData
                                                    ? <>
                                                        <div className='img-box'><img src={require('../../static/images/profile-img-edit.webp')} alt='' /></div>
                                                        <div className='text-box'>
                                                            <h5>--</h5>
                                                            <p className="text-secondary">Offline</p>
                                                        </div>
                                                    </>
                                                    : <Link to={`/profile/${singleChatUser['_id']}`}>
                                                        {singleChatUser['profilePicture'] &&
                                                            <div className={`img-box ${singleChatUser['isOnline'] && 'active'}`}>
                                                                <img src={singleChatUser['profilePicture']} alt='' />
                                                            </div>
                                                        }
                                                        {singleChatUser['name'] &&
                                                            <div className='text-box'>
                                                                <h5>{singleChatUser['name']}</h5>
                                                                {singleChatUser['isOnline']
                                                                    ? <p className="text-success">Online </p>
                                                                    : <p className="text-secondary">Offline</p>
                                                                }
                                                            </div>
                                                        }
                                                    </Link>

                                                }

                                                <div className='sortby-dropdrown dropdown'>
                                                    {/* <div className="delet-buttons">
                                                        <button className="canecl-btn">Cancel</button>
                                                        <button className="btn-style-two">Select All</button>
                                                        <button className="btn-style-one">Delete</button>
                                                    </div>
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.893" height="4.723" viewBox="0 0 18.893 4.723">
                                                            <g id="details_more" transform="translate(-355 -167.599)">
                                                                <g id="Group_58" data-name="Group 58" transform="translate(373.893 167.599) rotate(90)">
                                                                    <path id="Path_9" data-name="Path 9" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 0)" />
                                                                    <path id="Path_10" data-name="Path 10" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.361,2.361,0,0,1,3.34,0" transform="translate(0 7.085)" />
                                                                    <path id="Path_11" data-name="Path 11" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 14.17)" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <Link class="dropdown-item" to="#">
                                                            <i className='icon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.19" height="14.188" viewBox="0 0 14.19 14.188">
                                                                    <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M18.245,4.535,4.679,10.448a.311.311,0,0,0,.011.565l3.67,2.073a.592.592,0,0,0,.676-.067l7.236-6.238c.048-.041.163-.118.207-.074s-.026.159-.067.207l-6.26,7.051a.59.59,0,0,0-.059.706l2.4,3.847a.312.312,0,0,0,.562-.007l5.61-13.567A.311.311,0,0,0,18.245,4.535Z" transform="translate(-4.503 -4.503)" />
                                                                </svg>
                                                            </i>
                                                            Clear Chat
                                                        </Link>
                                                        <a class="dropdown-item" href="#">
                                                            <i className='icon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.833" height="16.5" viewBox="0 0 12.833 16.5">
                                                                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.417,19.167A1.839,1.839,0,0,0,10.25,21h7.333a1.839,1.839,0,0,0,1.833-1.833v-11h-11ZM20.333,5.417H17.125L16.208,4.5H11.625l-.917.917H7.5V7.25H20.333Z" transform="translate(-7.5 -4.5)" />
                                                                </svg>
                                                            </i>
                                                            Delete Message
                                                        </a>
                                                        <a class="dropdown-item" href="#">
                                                            <i className='icon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.492" height="14.492" viewBox="0 0 14.492 14.492">
                                                                    <path id="Icon_material-block" data-name="Icon material-block" d="M10.246,3a7.246,7.246,0,1,0,7.246,7.246A7.249,7.249,0,0,0,10.246,3Zm-5.8,7.246a5.8,5.8,0,0,1,5.8-5.8A5.726,5.726,0,0,1,13.8,5.674L5.674,13.8A5.726,5.726,0,0,1,4.449,10.246Zm5.8,5.8A5.726,5.726,0,0,1,6.7,14.818L14.818,6.7a5.726,5.726,0,0,1,1.225,3.551A5.8,5.8,0,0,1,10.246,16.043Z" transform="translate(-3 -3)" />
                                                                </svg>
                                                            </i>
                                                            Block
                                                        </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='chatbox-chat-inner'>
                                            <div className="chatbox-body" id="scroller">
                                                {isSingleChatHistory
                                                    ? <div className="single-chat-loader">
                                                        <i className="fa fa-spinner fa-spin fa-fw"></i>
                                                    </div>
                                                    : <div className="message-body">
                                                        {singleChat.length > 0
                                                            ? <ul>
                                                                {singleChat.map(chat => {
                                                                    return (
                                                                        <>
                                                                            {chat['messageType'] == "File"
                                                                                ? <li className={`${chat['type']} my-4`}>
                                                                                    {/* <Checkbox /> */}
                                                                                    {chat['data']['format'] == 'jpg' || chat['data']['format'] == 'jpeg' || chat['data']['format'] == 'png'
                                                                                        || chat['data']['format'] == 'pnm' || chat['data']['format'] == 'bmp' || chat['data']['format'] == 'svg'
                                                                                        ? <>
                                                                                            <a className="img-download" href={chat['data']['location']} download>
                                                                                                <img src={chat['data']['location']} />
                                                                                                <span className="btn-style-one download-btn">
                                                                                                    <i className="fa fa-download mr-2"></i>
                                                                                                    {chat['data']['name']}
                                                                                                </span>
                                                                                            </a>
                                                                                        </>
                                                                                        : <a className="img-download" href={chat['data']['location']} download>
                                                                                            <span className="btn-style-one download-btn">
                                                                                                <i className="fa fa-download mr-2"></i>
                                                                                                {chat['data']['name']}
                                                                                            </span>
                                                                                        </a>

                                                                                    }
                                                                                    {chat['type'] == 'sender' &&
                                                                                        <div className="delete-options">
                                                                                            <div className='sortby-dropdrown dropdown'>
                                                                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                    <i className='icon'>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.893" height="4.723" viewBox="0 0 18.893 4.723"><g id="details_more" transform="translate(-355 -167.599)"><g id="Group_58" data-name="Group 58" transform="translate(373.893 167.599) rotate(90)"><path id="Path_9" data-name="Path 9" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 0)"></path><path id="Path_10" data-name="Path 10" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.361,2.361,0,0,1,3.34,0" transform="translate(0 7.085)"></path><path id="Path_11" data-name="Path 11" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 14.17)"></path></g></g></svg>
                                                                                                    </i>
                                                                                                </button>
                                                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                                    <button onClick={() => this.handleDeleteMessage(chat['_id'])} className="dropdown-item" href="#">Remove</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </li>
                                                                                : <li className={chat['type'] || 'sender'}>
                                                                                    {/* <Checkbox /> */}
                                                                                    <p>{chat['message']}</p>
                                                                                    {chat['type'] == 'sender' &&
                                                                                        <div className="delete-options">
                                                                                            <div className='sortby-dropdrown dropdown'>
                                                                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                    <i className='icon'>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.893" height="4.723" viewBox="0 0 18.893 4.723"><g id="details_more" transform="translate(-355 -167.599)"><g id="Group_58" data-name="Group 58" transform="translate(373.893 167.599) rotate(90)"><path id="Path_9" data-name="Path 9" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 0)"></path><path id="Path_10" data-name="Path 10" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.361,2.361,0,0,1,3.34,0" transform="translate(0 7.085)"></path><path id="Path_11" data-name="Path 11" d="M4.031.692a2.362,2.362,0,1,1-3.34,0,2.362,2.362,0,0,1,3.34,0" transform="translate(0 14.17)"></path></g></g></svg>
                                                                                                    </i>
                                                                                                </button>
                                                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                                    <button onClick={() => this.handleDeleteMessage(chat['_id'])} className="dropdown-item" href="#">Remove</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </li>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                                {isSendChat &&
                                                                    <li className="sender"><BeatLoader color="#2D85FF" /></li>
                                                                }
                                                            </ul>
                                                            : <h5 className="d-flex justify-content-center text-secondary no-message">No Messages!</h5>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className="send-box">
                                                <div className='img-upload-btn'>
                                                    <button className={`upload-btn ${file !== '' && 'active'}`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.997" height="21.873" viewBox="0 0 24.997 21.873">
                                                            <path id="camera_chat" d="M25,7.718V21.779a2.344,2.344,0,0,1-2.343,2.343H2.343A2.344,2.344,0,0,1,0,21.779V7.718A2.344,2.344,0,0,1,2.343,5.375h4.3l.6-1.606A2.34,2.34,0,0,1,9.433,2.25H15.56a2.34,2.34,0,0,1,2.192,1.518l.605,1.606h4.3A2.344,2.344,0,0,1,25,7.718Zm-6.64,7.03A5.859,5.859,0,1,0,12.5,20.607,5.863,5.863,0,0,0,18.357,14.749Zm-1.562,0a4.3,4.3,0,1,1-4.3-4.3A4.3,4.3,0,0,1,16.8,14.749Z" transform="translate(0 -2.25)" />
                                                        </svg>
                                                        <input
                                                            className='image-attach'
                                                            accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                            type='file'
                                                            id='upload'
                                                            onChange={this.handleFileUpload}
                                                        />
                                                    </button>
                                                </div>
                                                <div className='group-form'>
                                                    {file == ''
                                                        ? <input
                                                            value={message}
                                                            name="message"
                                                            onChange={this.handleChange}
                                                            onKeyPress={this.keyPressed}
                                                            type="text"
                                                            className="form-control"
                                                            aria-label="message…"
                                                            placeholder="Type a message…" />
                                                        : <input
                                                            disabled={true}
                                                            aria-label="message…"
                                                            className="form-control file-attach-input"
                                                            placeholder={`File (${file['name'].substring(0, 20) + '.....'}) attached`} />
                                                    }
                                                    {isEmoji &&
                                                        <div className="emoji-box">
                                                            <Picker onSelect={this.handleEmoji} />
                                                        </div>
                                                    }
                                                    <button className={`attached ${file !== '' && 'active'}`}>
                                                        <input
                                                            className='file-attach'
                                                            type='file'
                                                            id='upload'
                                                            onChange={this.handleFileUpload}
                                                        />
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.012" height="26.012" viewBox="0 0 26.012 26.012">
                                                            <path id="Icon_metro-attachment" data-name="Icon metro-attachment" d="M15.679,8.194,14.434,6.95,8.211,13.172a2.64,2.64,0,1,0,3.734,3.733l7.468-7.467A4.4,4.4,0,0,0,13.19,3.217l-7.841,7.84-.017.016a6.136,6.136,0,0,0,8.679,8.677l.016-.017h0l5.352-5.351-1.245-1.244L12.783,18.49l-.016.016a4.376,4.376,0,0,1-6.19-6.188l.017-.016h0l7.842-7.839a2.64,2.64,0,1,1,3.734,3.733L10.7,15.661a.88.88,0,0,1-1.245-1.244l6.223-6.223Z" transform="translate(29.875 12.737) rotate(135)" />
                                                        </svg>
                                                    </button>
                                                    <button className='smiles' onClick={() => this.setState({ isEmoji: !isEmoji })}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <g id="Group_2516" data-name="Group 2516" transform="translate(-5618 -4571)">
                                                                <circle id="Ellipse_421" data-name="Ellipse 421" cx="11" cy="11" r="11" transform="translate(5619 4572)" fill="none" stroke="#000" stroke-width="2" />
                                                                <circle id="Ellipse_422" data-name="Ellipse 422" cx="2" cy="2" r="2" transform="translate(5624 4578)" fill="#fff" />
                                                                <circle id="Ellipse_423" data-name="Ellipse 423" cx="2" cy="2" r="2" transform="translate(5624 4578)" />
                                                                <circle id="Ellipse_424" data-name="Ellipse 424" cx="2" cy="2" r="2" transform="translate(5632 4578)" />
                                                                <path id="Path_4008" data-name="Path 4008" d="M5625.018,4585.256s4.093,7.112,9.438,0" transform="translate(0.482)" fill="none" stroke="#000" stroke-width="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <button onClick={this.handleSendMessage} type="button" className='send-button'>
                                                        <img src={require('../../static/images/send-btn.svg')} alt='' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* --------------- DELETE CHAT MODAL--------------- */}
                < Modal isOpen={isDeleteChatModal} toggle={() => this.props.toggleDeleteChatModal(false)
                }
                    className={`main-modal delete-modal ${lightMode ? 'dark' : 'light'}`
                    }>
                    <ModalHeader toggle={() => this.props.toggleDeleteChatModal(false)}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="row">
                                <div className="col-12">
                                    <div className='icon-splash'>
                                        <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                    </div>
                                    <h2>DELETE CHAT</h2>
                                    <h5>Are you sure want to delete this chat!!</h5>
                                </div>
                                <div className="modalfooter">
                                    <button onClick={() => this.props.toggleDeleteChatModal(false)} className="btn-style-two">Cancel</button>
                                    <button onClick={this.submitDeleteChat} className="btn-style-one">Submit</button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >

                {/* --------------- DELETE MESSAGE MODAL--------------- */}
                < Modal isOpen={isDeleteMessageModal} toggle={() => this.props.toggleDeleteMessageModal(false)} className={`main-modal delete-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalHeader toggle={() => this.props.toggleDeleteMessageModal(false)}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="row">
                                <div className="col-12">
                                    <div className='icon-splash'>
                                        <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                    </div>
                                    <h2>DELETE MESSAGE</h2>
                                    <h5>Are you sure want to delete this message!!</h5>
                                </div>
                                <div className="modalfooter">
                                    <button onClick={() => this.props.toggleDeleteMessageModal(false)} className="btn-style-two">Cancel</button>
                                    <button onClick={this.submitDeleteMessage} className="btn-style-one">Submit</button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >

            </div >
        );
    }
}

const mapDispatchToProps = {
    getAllActiveFriends, getUserProfile, userProfileLoader, getSingleChatHistory, chatReadMessage, singleChatHistoryLoader, sendTextMessage, sendChatFile, sendChatLoader,
    toggleDeleteChatModal, deleteChat, toggleDeleteMessageModal, deleteChatMessage,
};

const mapStateToProps = ({ Auth, Chat }) => {
    let { lightMode, userData, isUserData, } = Auth;
    let { activeFriends, activeStatus, chatHistory, singleChatHistory, isChatHistory, isSingleChatHistory, isSendChat, messageSent,
        messageReceive, isDeleteChatModal, isDeleteMessageModal, } = Chat;
    return {
        lightMode, userData, isUserData, activeFriends, activeStatus, chatHistory, singleChatHistory, isChatHistory, isSingleChatHistory,
        isSendChat, messageSent, messageReceive, isDeleteChatModal, isDeleteMessageModal,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Messaging);