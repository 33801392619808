import Slider from "react-slick";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import React, { Component } from 'react';

import './index.css';
import Navbar from '../../components/navbar';
import { toggleLoader } from "../../store/actions/Auth";

class CollectionDetails extends Component {

    constructor(props) {
        super(props);
        const query = require('url').parse(document.location.href, true);
        const collectionId = query.path.split('/')[--query.path.split('/').length];
        this.state = {
            logo: '',
            collectionLogo: '',
            banner: '',
            collectionBanner: '',
            collectionName: '',
            slug: '',
            description: '',
            category: '',
            websiteUrl: '',
            blockchain: '',
            contractAddress: '',
            nav1: null,
            nav2: null,
        };
    };

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    };

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <div className="countdown-timer-time bis-sale">
                <div className="counter-day counter-hour bid-count">
                    <h5>{days ? days : '-'}</h5>
                    <span>Days</span>
                </div>
                <div className="counter-day counter-hour bid-count">
                    <h5>{hours ? hours : '-'}</h5>
                    <span>Hours</span>
                </div>
                <div className="counter-day counter-minute bid-count">
                    <h5>{minutes ? minutes : '-'}</h5>
                    <span>Minutes</span>
                </div>
                <div className="counter-day counter-seconds bid-count">
                    <h5>{seconds ? seconds : '-'}</h5>
                    <span>Seconds</span>
                </div>
            </div>
        );
    };


    render() {

        const settingsMain = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
        };

        const settingsThumbs = {
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.slider',
            dots: false,
            arrows: false,
            focusOnSelect: true
        };

        let { lightMode, isNotableDrops } = this.props;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />

                {isNotableDrops
                    ? <div className="single-collection-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                    : <>
                        {/* BANNER IMAGE */}
                        <section className='banner-area collection-details-banner'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='banner-img'>
                                            <img src={require('../../static/images/3d-banner.png')} alt='' />
                                            <div className="overlay-box timer-overlay">
                                                <div className="countdown-timer timer-coutdwon">
                                                    <div className="timer-inner">
                                                        <Countdown date={Date.now() + parseInt((1682933090 * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* LOGO IMAGE */}
                        <section className='profile-img-area notable-drops-profile'>
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-12'>
                                        <div className='img-proflie'>
                                            <img src={require('../../static/images/3d-profile.png')} alt='' />
                                        </div>
                                    </div>
                                    <div className="col-lg-9"></div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className='profile-detail'>
                                            <div className='name-area'>
                                                <h4>NFT 3D Image
                                                    <i className="icon">
                                                        <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="22.153" height="23.078" viewBox="0 0 22.153 23.078">
                                                            <path id="Vector" d="M21.653,9.681l-1.506-1.75a2.454,2.454,0,0,1-.52-1.4V4.653A2.146,2.146,0,0,0,17.49,2.516H15.607a2.484,2.484,0,0,1-1.4-.52L12.463.49a2.231,2.231,0,0,0-2.79,0L7.934,2.007a2.554,2.554,0,0,1-1.4.509H4.623A2.146,2.146,0,0,0,2.486,4.653V6.547a2.52,2.52,0,0,1-.509,1.384L.482,9.692a2.23,2.23,0,0,0,0,2.768L1.977,14.22A2.52,2.52,0,0,1,2.486,15.6V17.5a2.146,2.146,0,0,0,2.137,2.137H6.539a2.484,2.484,0,0,1,1.4.52l1.75,1.506a2.231,2.231,0,0,0,2.79,0l1.75-1.506a2.454,2.454,0,0,1,1.4-.52H17.5A2.146,2.146,0,0,0,19.638,17.5V15.616a2.484,2.484,0,0,1,.52-1.4l1.506-1.75A2.246,2.246,0,0,0,21.653,9.681Zm-5.979-.7-5.348,5.348a.829.829,0,0,1-1.174,0l-2.68-2.68a.83.83,0,0,1,1.174-1.174l2.093,2.093L14.5,7.809a.83.83,0,0,1,1.174,1.174Z" transform="translate(0.008 0.926)" fill="#2d85ff" />
                                                            <path id="Vector-2" data-name="Vector" d="M0,0H22.153V22.153H0Z" transform="translate(22.153 22.153) rotate(180)" fill="none" opacity="0" />
                                                        </svg>
                                                    </i>
                                                </h4>
                                            </div>
                                            <p className='profile-name'>
                                                By Smith John
                                                <i className="icon">
                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="22.153" height="23.078" viewBox="0 0 22.153 23.078">
                                                        <path id="Vector" d="M21.653,9.681l-1.506-1.75a2.454,2.454,0,0,1-.52-1.4V4.653A2.146,2.146,0,0,0,17.49,2.516H15.607a2.484,2.484,0,0,1-1.4-.52L12.463.49a2.231,2.231,0,0,0-2.79,0L7.934,2.007a2.554,2.554,0,0,1-1.4.509H4.623A2.146,2.146,0,0,0,2.486,4.653V6.547a2.52,2.52,0,0,1-.509,1.384L.482,9.692a2.23,2.23,0,0,0,0,2.768L1.977,14.22A2.52,2.52,0,0,1,2.486,15.6V17.5a2.146,2.146,0,0,0,2.137,2.137H6.539a2.484,2.484,0,0,1,1.4.52l1.75,1.506a2.231,2.231,0,0,0,2.79,0l1.75-1.506a2.454,2.454,0,0,1,1.4-.52H17.5A2.146,2.146,0,0,0,19.638,17.5V15.616a2.484,2.484,0,0,1,.52-1.4l1.506-1.75A2.246,2.246,0,0,0,21.653,9.681Zm-5.979-.7-5.348,5.348a.829.829,0,0,1-1.174,0l-2.68-2.68a.83.83,0,0,1,1.174-1.174l2.093,2.093L14.5,7.809a.83.83,0,0,1,1.174,1.174Z" transform="translate(0.008 0.926)" fill="#2d85ff" />
                                                        <path id="Vector-2" data-name="Vector" d="M0,0H22.153V22.153H0Z" transform="translate(22.153 22.153) rotate(180)" fill="none" opacity="0" />
                                                    </svg>
                                                </i>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="right-area">
                                            <ul className="social-link">
                                                <li>
                                                    <Link>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9.305" height="17.296" viewBox="0 0 9.305 17.296">
                                                            <path id="Icon_zocial-facebook" data-name="Icon zocial-facebook" d="M13.257,9.288v-3.3h2.664V4.324a4.347,4.347,0,0,1,1.167-3.061A3.712,3.712,0,0,1,19.916,0h2.646V3.3H19.916a.583.583,0,0,0-.466.285,1.159,1.159,0,0,0-.208.7V5.984h3.32v3.3H19.241V17.3H15.921V9.288Z" transform="translate(-13.257)" fill="#707070" />
                                                        </svg>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.2" height="15.594" viewBox="0 0 19.2 15.594">
                                                            <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M17.226,7.267c.012.171.012.341.012.512a11.119,11.119,0,0,1-11.2,11.2A11.12,11.12,0,0,1,0,17.208a8.14,8.14,0,0,0,.95.049,7.881,7.881,0,0,0,4.885-1.681,3.942,3.942,0,0,1-3.679-2.729,4.962,4.962,0,0,0,.743.061,4.162,4.162,0,0,0,1.036-.134A3.935,3.935,0,0,1,.78,8.912V8.863a3.963,3.963,0,0,0,1.779.5A3.941,3.941,0,0,1,1.34,4.1,11.185,11.185,0,0,0,9.454,8.217a4.442,4.442,0,0,1-.1-.9,3.939,3.939,0,0,1,6.81-2.692,7.747,7.747,0,0,0,2.5-.95,3.924,3.924,0,0,1-1.73,2.168A7.888,7.888,0,0,0,19.2,5.233a8.459,8.459,0,0,1-1.974,2.034Z" transform="translate(0 -3.381)" fill="#707070" />
                                                        </svg>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.594" height="15.593" viewBox="0 0 15.594 15.593">
                                                            <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M3.49,15.594H.258V5.183H3.49ZM1.872,3.763a1.881,1.881,0,1,1,1.872-1.89A1.888,1.888,0,0,1,1.872,3.763ZM15.59,15.594H12.364V10.526c0-1.208-.024-2.757-1.681-2.757-1.681,0-1.938,1.312-1.938,2.67v5.155H5.516V5.183h3.1V6.6h.045A3.4,3.4,0,0,1,11.72,4.922c3.272,0,3.873,2.155,3.873,4.953v5.719Z" transform="translate(0 -0.001)" fill="#707070" />
                                                        </svg>
                                                    </Link>
                                                </li>
                                            </ul>
                                            <ul className="fav-area">
                                                <li>
                                                    <Link>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.754" height="17.239" viewBox="0 0 19.754 17.239">
                                                            <path id="blue_heart" d="M20.554,6.021a5.2,5.2,0,0,0-7.352,0l-1,1-1-1a5.2,5.2,0,1,0-7.352,7.356l1,1L12.2,21.736l7.352-7.356,1-1A5.2,5.2,0,0,0,20.554,6.021Z" transform="translate(-2.323 -4.497)" fill="#707070" />
                                                        </svg>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.492" height="16.492" viewBox="0 0 16.492 16.492">
                                                            <path id="Icon_metro-share" data-name="Icon metro-share" d="M16.486,13.267a2.569,2.569,0,0,0-1.852.786L7.692,10.582a2.579,2.579,0,0,0,0-.816L14.634,6.3A2.576,2.576,0,1,0,13.909,4.5a2.6,2.6,0,0,0,.032.408L7,8.384a2.577,2.577,0,1,0,0,3.581l6.942,3.471a2.577,2.577,0,1,0,2.544-2.169Z" transform="translate(-2.571 -1.928)" fill="#707070" />
                                                        </svg>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <div className='sortby-dropdrown dropdown'>
                                                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <svg id="details_more" xmlns="http://www.w3.org/2000/svg" width="20.547" height="5.137" viewBox="0 0 20.547 5.137">
                                                                <g id="Group_58" data-name="Group 58" transform="translate(20.547 0) rotate(90)">
                                                                    <path id="Path_9" data-name="Path 9" d="M4.384.752a2.568,2.568,0,1,1-3.632,0,2.568,2.568,0,0,1,3.632,0" transform="translate(0 0)" />
                                                                    <path id="Path_10" data-name="Path 10" d="M4.384.752a2.568,2.568,0,1,1-3.632,0,2.568,2.568,0,0,1,3.632,0" transform="translate(0 7.705)" />
                                                                    <path id="Path_11" data-name="Path 11" d="M4.384.752a2.568,2.568,0,1,1-3.632,0,2.568,2.568,0,0,1,3.632,0" transform="translate(0 15.411)" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                        {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <Link to="#" className="dropdown-item">
                                                                <i className='icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.992" height="13.992" viewBox="0 0 13.992 13.992">
                                                                        <path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,15.574v2.915H7.415l8.6-8.6L13.1,6.978ZM18.265,7.638a.774.774,0,0,0,0-1.1L16.446,4.724a.774.774,0,0,0-1.1,0L13.928,6.146l2.915,2.915Z" transform="translate(-4.5 -4.496)" />
                                                                    </svg>
                                                                </i>
                                                                Edit Notable Drops
                                                            </Link>
                                                        </div> */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <-- Notable Drops --> */}
                        <section className="notable-drop-details">
                            <div className='auto-container'>
                                <div className='row'>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="left-detail-area">
                                            <h3>About this collection</h3>
                                            <div className="profile-detail">
                                                <div className="img-box">
                                                    <img src={require('../../static/images/3d-profile.png')} alt='' />
                                                </div>
                                                <div className="text-box">
                                                    <h4>NFT 3D Image
                                                        <i className="icon">
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="22.153" height="23.078" viewBox="0 0 22.153 23.078">
                                                                <path id="Vector" d="M21.653,9.681l-1.506-1.75a2.454,2.454,0,0,1-.52-1.4V4.653A2.146,2.146,0,0,0,17.49,2.516H15.607a2.484,2.484,0,0,1-1.4-.52L12.463.49a2.231,2.231,0,0,0-2.79,0L7.934,2.007a2.554,2.554,0,0,1-1.4.509H4.623A2.146,2.146,0,0,0,2.486,4.653V6.547a2.52,2.52,0,0,1-.509,1.384L.482,9.692a2.23,2.23,0,0,0,0,2.768L1.977,14.22A2.52,2.52,0,0,1,2.486,15.6V17.5a2.146,2.146,0,0,0,2.137,2.137H6.539a2.484,2.484,0,0,1,1.4.52l1.75,1.506a2.231,2.231,0,0,0,2.79,0l1.75-1.506a2.454,2.454,0,0,1,1.4-.52H17.5A2.146,2.146,0,0,0,19.638,17.5V15.616a2.484,2.484,0,0,1,.52-1.4l1.506-1.75A2.246,2.246,0,0,0,21.653,9.681Zm-5.979-.7-5.348,5.348a.829.829,0,0,1-1.174,0l-2.68-2.68a.83.83,0,0,1,1.174-1.174l2.093,2.093L14.5,7.809a.83.83,0,0,1,1.174,1.174Z" transform="translate(0.008 0.926)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H22.153V22.153H0Z" transform="translate(22.153 22.153) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </i>
                                                    </h4>
                                                    <p>By Smith John</p>
                                                </div>
                                            </div>
                                            <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or</p>
                                            <div className="reminder-area">
                                                <h5>Public Stage</h5>
                                                <p className="eth-text">0.0003 ETH</p>
                                                <span>April 3 at 05:30PM GMT </span>
                                                <div className="btn-area">
                                                    <Link className="remender-btn btn-style-one">Set Reminder</Link>
                                                    <Link className="remender-btn btn-style-two">Add to Cal</Link>
                                                </div>
                                            </div>

                                            {/* <div className="schedule-area">
                                                <h4>Mint Schedule</h4>
                                                <div id="accordion">
                                                    <div class="card">
                                                        <div class="card-header" id="headingOne">
                                                            <h5 class="mb-0">
                                                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    VIP
                                                                    <span>Start at March 31 at 09:30PM</span>
                                                                </button>
                                                            </h5>
                                                        </div>

                                                        <div id="collapseOne" class="collapse collapase-active-area show" aria-labelledby="headingOne" data-parent="#accordion">
                                                            <div class="card-body">
                                                                Price: 0.34 ETH
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card">
                                                        <div class="card-header" id="headingTwo">
                                                            <h5 class="mb-0">
                                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                    Monster
                                                                    <span>Start at March 31 at 09:30PM</span>
                                                                </button>
                                                            </h5>
                                                        </div>
                                                        <div id="collapseTwo" class="collapse collapase-active-area" aria-labelledby="headingTwo" data-parent="#accordion">
                                                            <div class="card-body">
                                                                Price: 0.34 ETH
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card">
                                                        <div class="card-header" id="headingThree">
                                                            <h5 class="mb-0">
                                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                    Public
                                                                    <span>Start at March 31 at 09:30PM</span>
                                                                </button>
                                                            </h5>
                                                        </div>
                                                        <div id="collapseThree" class="collapse collapase-active-area" aria-labelledby="headingThree" data-parent="#accordion">
                                                            <div class="card-body">
                                                                Price: 0.34 ETH
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header" id="headingFour">
                                                            <h5 class="mb-0">
                                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                    Team Token
                                                                    <span>Start at March 31 at 09:30PM</span>
                                                                </button>
                                                            </h5>
                                                        </div>
                                                        <div id="collapseFour" class="collapse collapase-active-area" aria-labelledby="headingFour" data-parent="#accordion">
                                                            <div class="card-body">
                                                                Price: 0.34 ETH
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="right-area">
                                            <Slider
                                                asNavFor={this.state.nav2}
                                                {...settingsMain}
                                                ref={slider => (this.slider1 = slider)}
                                            >
                                                <div>
                                                    <div className="item">
                                                        <div className="about-game-img">
                                                            <img src={require('../../static/images/drop-slide-5.png')} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="item">
                                                        <div className="about-game-img">
                                                            <img src={require('../../static/images/drop-slide-6.png')} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="item">
                                                        <div className="about-game-img">
                                                            <img src={require('../../static/images/drop-slide-7.png')} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="item">
                                                        <div className="about-game-img">
                                                            <img src={require('../../static/images/drop-slide-8.png')} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                            <Slider className="slider-nav-thumbnails"
                                                {...settingsThumbs}
                                                asNavFor={this.state.nav1}
                                                ref={slider => (this.slider2 = slider)}
                                            >
                                                <div>
                                                    <div className="item-thumbnail">
                                                        <div className="about-game-img">
                                                            <img src={require('../../static/images/drop-slide-thumb-5.png')} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="item-thumbnail">
                                                        <div className="about-game-img">
                                                            <img src={require('../../static/images/drop-slide-thumb-6.png')} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="item-thumbnail">
                                                        <div className="about-game-img">
                                                            <img src={require('../../static/images/drop-slide-thumb-7.png')} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="item-thumbnail">
                                                        <div className="about-game-img">
                                                            <img src={require('../../static/images/drop-slide-thumb-8.png')} alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </>
                }
            </div>
        );
    }
}
const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth, Collection }) => {
    let { lightMode, } = Auth;
    let { singleCollection, isNotableDrops } = Collection;
    return { lightMode, singleCollection, isNotableDrops };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDetails);