import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { toggleLoader } from '../actions/Auth';
import { createCollectionLoader, setMyCollections, toggleMyCollections, setSingleCollection, toggleSingleCollection } from '../actions/Collection';


/*========== COLLECTION FUNCTIONS =============*/

function* createCollection({ payload }) {
  const { error, response } = yield call(postCall, { path: '/collection/createCollection', payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put(toggleLoader({ status: false }));
  yield put(createCollectionLoader(false));
};

function* editCollection({ payload, id }) {
  const { error, response } = yield call(putCall, { path: `/collection/updateCollection/${id}`, payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put(toggleLoader({ status: false }));
  yield put({ type: "GET_SINGLE_COLLECTION", payload: id });
};

function* getMyCollections() {
  const { error, response } = yield call(getCall, `/collection/myCollections`);
  // if (error) EventBus.publish("error", error['response']['data']['message']);
  if (response) yield put(setMyCollections(response['data']['body']));
  yield put(toggleMyCollections(false));
};

function* getSingleCollection({ payload }) {
  const { error, response } = yield call(getCall, `/collection/getCollection/${payload}`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setSingleCollection(response['data']['body']));
  yield put(toggleSingleCollection(false));
};

function* actionWatcher() {
  yield takeEvery('CREATE_COLLECTION', createCollection);
  yield takeEvery('EDIT_COLLECTION', editCollection);
  yield takeEvery('GET_MY_COLLECTIONS', getMyCollections);
  yield takeEvery('GET_SINGLE_COLLECTION', getSingleCollection);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall({ path, payload }) {
  return axios
    .delete(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
