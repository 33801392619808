import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import { toggleLoader } from "../../store/actions/Auth";


class TopPosts extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {

        const owl_option = {
            margin: 25,
            nav: true,
            items: 3,
            loop: true,
            autoplay: false,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1000: {
                    items: 3,
                }
            },
        };

        let { } = this.state;

        return (
            // <div className="nfity-page" onWheel={this.onScroll}>
            <div>
                <div className='auto-container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='user-accounts'>
                                <div className='heading-area'>
                                    <h3>User Accounts</h3>
                                    <Link className='view-all' to="#">View All</Link>
                                </div>
                                <OwlCarousel className="slider-items owl-carousel ltf-owl owl-theme" autoplaySpeed={3000}  {...owl_option} nav>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-1.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-1.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-2.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-3.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-4.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-2.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-9.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-10.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-11.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-12.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-3.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-5.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-6.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-7.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-8.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-4.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-13.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-14.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-15.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-16.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-5.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-17.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-18.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-19.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-20.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-6.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-21.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-22.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-1.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-24.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-7.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-1.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-2.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-3.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-4.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-8.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-9.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-10.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-11.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-12.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-9.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-5.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-6.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-7.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-8.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-10.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-13.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-14.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-15.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-16.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-11.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-17.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-18.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-19.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-20.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='user-block'>
                                            <div className='user-detail'>
                                                <div className='img-box'>
                                                    <img src={require('../../static/images/usr-acount-detail-12.png')} alt='' />
                                                </div>
                                                <div className='text-box'>
                                                    <div className='heading-area'>
                                                        <h5>My best NFTs</h5>
                                                        <span className='icon'>
                                                            <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                <path id="Vector" d="M13.037,5.828,12.13,4.775a1.478,1.478,0,0,1-.313-.84V2.8A1.292,1.292,0,0,0,10.53,1.515H9.4a1.5,1.5,0,0,1-.84-.313L7.5.3a1.343,1.343,0,0,0-1.68,0l-1.047.913a1.538,1.538,0,0,1-.84.307H2.783A1.292,1.292,0,0,0,1.5,2.8v1.14a1.517,1.517,0,0,1-.307.833l-.9,1.06A1.342,1.342,0,0,0,.29,7.5l.9,1.06A1.517,1.517,0,0,1,1.5,9.4v1.14a1.292,1.292,0,0,0,1.287,1.287H3.937a1.5,1.5,0,0,1,.84.313l1.053.907a1.343,1.343,0,0,0,1.68,0l1.053-.907a1.478,1.478,0,0,1,.84-.313h1.133a1.292,1.292,0,0,0,1.287-1.287V9.4a1.5,1.5,0,0,1,.313-.84l.907-1.053A1.352,1.352,0,0,0,13.037,5.828Zm-3.6-.42-3.22,3.22a.5.5,0,0,1-.707,0L3.9,7.015A.5.5,0,1,1,4.6,6.308l1.26,1.26L8.73,4.7a.5.5,0,0,1,.707.707Z" transform="translate(1.337 1.332)" fill="#2d85ff" />
                                                                <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" transform="translate(16 16) rotate(180)" fill="none" opacity="0" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <p>My best NFTs</p>
                                                </div>
                                            </div>
                                            <div className='items-detail'>
                                                <div className='items-block'>
                                                    <p>55</p>
                                                    <span>Items</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Owners</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>3.4K</p>
                                                    <span>Floor price</span>
                                                </div>
                                                <div className='items-block'>
                                                    <p>
                                                        <span className='icon'>
                                                            <img src={require('../../static/images/eth-icon.svg')} alt='' />
                                                        </span>
                                                        56.5K
                                                    </p>
                                                    <span>Items</span>
                                                </div>
                                            </div>
                                            <div className='nft-user-area'>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-21.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-22.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-1.jpg')} alt='' />
                                                    </div>
                                                </div>
                                                <div className='nft-user-block'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/user-nft-24.jpg')} alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                            <h4>Posts</h4>
                            <div className='row clearfix'>
                                <div className='col-lg-8 col-md-12'>
                                    <div className='row clearfix'>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-13.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-14.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-15.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-16.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-18.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-19.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-20.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-21.jpg')} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-12 col-sm-12'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-17.jpg')} alt='' />
                                    </div>
                                </div>
                            </div>
                            <div className='row clearfix'>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-22.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-23.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-24.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-25.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-26.jpg')} alt='' />
                                    </div>
                                </div>

                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-27.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-28.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-29.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-30.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-31.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-32.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-md-4 col-sm-6'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-33.jpg')} alt='' />
                                    </div>
                                </div>
                            </div>

                            <div className='row clearfix'>
                                <div className='col-lg-4 col-md-12 col-sm-12'>
                                    <div className='nft-img'>
                                        <img src={require('../../static/images/nft-collection/nft-34.jpg')} alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-8 col-md-12'>
                                    <div className='row clearfix'>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-35.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-36.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-37.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-38.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-39.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-40.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-41.jpg')} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <div className='nft-img'>
                                                <img src={require('../../static/images/nft-collection/nft-42.jpg')} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}


export default connect()(TopPosts);