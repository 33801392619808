import moment from "moment";
import Slider from "react-slick";
import { connect } from 'react-redux';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import './index.css';
import Navbar from '../../components/navbar';
import { getAllDrops, allDropsLoader, getSingleDrop, singleDropLoader } from "../../store/actions/Drop";

class CollectionDetails extends Component {

    constructor(props) {
        super(props);
        const query = require('url').parse(document.location.href, true);
        const dropId = query.path.split('/')[--query.path.split('/').length];
        this.state = {
            dropId,
            notableDrop: {},
            isMinting: false,
            mintingTime: '',
            selectedDrop: {},

            nav: null,
        };
        props.getAllDrops();
        props.allDropsLoader(true);
        props.getSingleDrop(dropId);
        props.singleDropLoader(true);
    };

    componentDidMount() {
        this.setState({ nav: this.slider });
    };

    componentWillReceiveProps({ singleDrop }) {
        if (singleDrop) this.setState({ notableDrop: singleDrop });
        if (singleDrop['fromDate']) {
            let fromDate = new Date(singleDrop['fromDate']);
            let mintingTime = new Date(singleDrop['fromDate']).getTime();
            if (new Date() > fromDate) this.setState({ isMinting: true });
            else this.setState({ mintingTime })
        }
    };

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            this.setState({ isMinting: true });
            return <h2>Minting is Started</h2>;
        }
        else {
            return (
                <div className="countdown-timer-time bis-sale">
                    <div className="counter-day counter-hour bid-count">
                        <h5>{days ? days : '-'}</h5>
                        <span>Days</span>
                    </div>
                    <div className="counter-day counter-hour bid-count">
                        <h5>{hours ? hours : '-'}</h5>
                        <span>Hours</span>
                    </div>
                    <div className="counter-day counter-minute bid-count">
                        <h5>{minutes ? minutes : '-'}</h5>
                        <span>Minutes</span>
                    </div>
                    <div className="counter-day counter-seconds bid-count">
                        <h5>{seconds ? seconds : '-'}</h5>
                        <span>Seconds</span>
                    </div>
                </div>
            );
        }
    };

    setSliderDrop = (id) => {
        this.props.getSingleDrop(id);
        this.props.singleDropLoader(true);
    };

    render() {

        const settings = {
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.slider',
            dots: false,
            arrows: true,
            focusOnSelect: true,
            infinite: false,
        };

        let { notableDrop, isMinting, mintingTime } = this.state;
        let { lightMode, isSingleDrop, allDrops, isAllDrops } = this.props;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                {isSingleDrop
                    ? <div className="single-collection-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                    : <>
                        {notableDrop == {}
                            ? <div className="no-drops"><h3>Drop data not found!</h3></div>
                            : <>
                                {/* BANNER IMAGE */}
                                <section className='banner-area collection-details-banner'>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='banner-img notable-banner'>
                                                    {notableDrop['file'] &&
                                                        <>
                                                            {notableDrop['file']['format'] == 'jpg' || notableDrop['file']['format'] == 'jpeg' || notableDrop['file']['format'] == 'png'
                                                                || notableDrop['file']['format'] == 'pnm' || notableDrop['file']['format'] == 'bmp' || notableDrop['file']['format'] == 'svg'
                                                                ? <img src={notableDrop['file']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                : notableDrop['file']['format'] == 'mp4' || notableDrop['file']['format'] == 'webm'
                                                                    ? <video width="500" autoPlay loop>
                                                                        <source src={notableDrop['file']['url']} type="video/mp4" />
                                                                    </video>
                                                                    : notableDrop['file']['format'] == 'mp3' || notableDrop['file']['format'] == 'mpeg' || notableDrop['file']['format'] == 'ogg'
                                                                        ? <audio className='audio-file' controls>
                                                                            <source src={notableDrop['file']['url']} type="audio/mp3" />
                                                                        </audio>
                                                                        : <p className="no-preview">No Preview!</p>
                                                            }
                                                        </>
                                                    }
                                                    {isMinting
                                                        ? <div className="overlay-box">
                                                            <div className="minting-box">
                                                                <h4><span className="icon-success"></span>Minting Now</h4>
                                                            </div>
                                                        </div>
                                                        : <div className="overlay-box timer-overlay">
                                                            <div className="countdown-timer timer-coutdwon">
                                                                <div className="timer-inner">
                                                                    {mintingTime &&
                                                                        <Countdown date={Date.now() + parseInt((mintingTime) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                {/* LOGO IMAGE */}
                                <section className='profile-img-area'>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-12'>
                                                <div className='img-proflie'>
                                                    {notableDrop['logo'] &&
                                                        <img src={notableDrop['logo']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-9"></div>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className='profile-detail'>
                                                    <div className='name-area'>
                                                        <h4>
                                                            {notableDrop['projectName']}
                                                            {notableDrop['isVerified'] &&
                                                                <i className="icon">
                                                                    <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="22.153" height="23.078" viewBox="0 0 22.153 23.078">
                                                                        <path id="Vector" d="M21.653,9.681l-1.506-1.75a2.454,2.454,0,0,1-.52-1.4V4.653A2.146,2.146,0,0,0,17.49,2.516H15.607a2.484,2.484,0,0,1-1.4-.52L12.463.49a2.231,2.231,0,0,0-2.79,0L7.934,2.007a2.554,2.554,0,0,1-1.4.509H4.623A2.146,2.146,0,0,0,2.486,4.653V6.547a2.52,2.52,0,0,1-.509,1.384L.482,9.692a2.23,2.23,0,0,0,0,2.768L1.977,14.22A2.52,2.52,0,0,1,2.486,15.6V17.5a2.146,2.146,0,0,0,2.137,2.137H6.539a2.484,2.484,0,0,1,1.4.52l1.75,1.506a2.231,2.231,0,0,0,2.79,0l1.75-1.506a2.454,2.454,0,0,1,1.4-.52H17.5A2.146,2.146,0,0,0,19.638,17.5V15.616a2.484,2.484,0,0,1,.52-1.4l1.506-1.75A2.246,2.246,0,0,0,21.653,9.681Zm-5.979-.7-5.348,5.348a.829.829,0,0,1-1.174,0l-2.68-2.68a.83.83,0,0,1,1.174-1.174l2.093,2.093L14.5,7.809a.83.83,0,0,1,1.174,1.174Z" transform="translate(0.008 0.926)" fill="#2d85ff" />
                                                                        <path id="Vector-2" data-name="Vector" d="M0,0H22.153V22.153H0Z" transform="translate(22.153 22.153) rotate(180)" fill="none" opacity="0" />
                                                                    </svg>
                                                                </i>
                                                            }
                                                        </h4>
                                                    </div>
                                                    {notableDrop['creator'] &&
                                                        <p className='profile-name'>
                                                            By {notableDrop['creator']['name']}
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="right-area">
                                                    <ul className="social-link">
                                                        {notableDrop['websiteUrl'] &&
                                                            <li>
                                                                <a target="_blank" href={notableDrop['websiteUrl']}>
                                                                    <img className="defult" src={require('../../static/images/website.png')} alt='' />
                                                                    <img className="hover" src={require('../../static/images/website-hover.png')} alt='' />
                                                                </a>
                                                            </li>
                                                        }
                                                        {notableDrop['twitterUrl'] &&
                                                            <li>
                                                                <a target="_blank" href={notableDrop['twitterUrl']}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.2" height="15.594" viewBox="0 0 19.2 15.594">
                                                                        <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M17.226,7.267c.012.171.012.341.012.512a11.119,11.119,0,0,1-11.2,11.2A11.12,11.12,0,0,1,0,17.208a8.14,8.14,0,0,0,.95.049,7.881,7.881,0,0,0,4.885-1.681,3.942,3.942,0,0,1-3.679-2.729,4.962,4.962,0,0,0,.743.061,4.162,4.162,0,0,0,1.036-.134A3.935,3.935,0,0,1,.78,8.912V8.863a3.963,3.963,0,0,0,1.779.5A3.941,3.941,0,0,1,1.34,4.1,11.185,11.185,0,0,0,9.454,8.217a4.442,4.442,0,0,1-.1-.9,3.939,3.939,0,0,1,6.81-2.692,7.747,7.747,0,0,0,2.5-.95,3.924,3.924,0,0,1-1.73,2.168A7.888,7.888,0,0,0,19.2,5.233a8.459,8.459,0,0,1-1.974,2.034Z" transform="translate(0 -3.381)" fill="#707070" />
                                                                    </svg>
                                                                </a>
                                                            </li>
                                                        }
                                                        {notableDrop['discordUrl'] &&
                                                            <li>
                                                                <a target="_blank" href={notableDrop['discordUrl']}>
                                                                    <img className="defult" src={require('../../static/images/discord.png')} alt='' />
                                                                    <img className="hover" src={require('../../static/images/discord-hover.png')} alt='' />
                                                                </a>
                                                            </li>
                                                        }
                                                    </ul>
                                                    <ul className="fav-area">
                                                        <li>
                                                            <Link>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19.754" height="17.239" viewBox="0 0 19.754 17.239">
                                                                    <path id="blue_heart" d="M20.554,6.021a5.2,5.2,0,0,0-7.352,0l-1,1-1-1a5.2,5.2,0,1,0-7.352,7.356l1,1L12.2,21.736l7.352-7.356,1-1A5.2,5.2,0,0,0,20.554,6.021Z" transform="translate(-2.323 -4.497)" fill="#707070" />
                                                                </svg>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.492" height="16.492" viewBox="0 0 16.492 16.492">
                                                                    <path id="Icon_metro-share" data-name="Icon metro-share" d="M16.486,13.267a2.569,2.569,0,0,0-1.852.786L7.692,10.582a2.579,2.579,0,0,0,0-.816L14.634,6.3A2.576,2.576,0,1,0,13.909,4.5a2.6,2.6,0,0,0,.032.408L7,8.384a2.577,2.577,0,1,0,0,3.581l6.942,3.471a2.577,2.577,0,1,0,2.544-2.169Z" transform="translate(-2.571 -1.928)" fill="#707070" />
                                                                </svg>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <div className='sortby-dropdrown dropdown'>
                                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <svg id="details_more" xmlns="http://www.w3.org/2000/svg" width="20.547" height="5.137" viewBox="0 0 20.547 5.137">
                                                                        <g id="Group_58" data-name="Group 58" transform="translate(20.547 0) rotate(90)">
                                                                            <path id="Path_9" data-name="Path 9" d="M4.384.752a2.568,2.568,0,1,1-3.632,0,2.568,2.568,0,0,1,3.632,0" transform="translate(0 0)" />
                                                                            <path id="Path_10" data-name="Path 10" d="M4.384.752a2.568,2.568,0,1,1-3.632,0,2.568,2.568,0,0,1,3.632,0" transform="translate(0 7.705)" />
                                                                            <path id="Path_11" data-name="Path 11" d="M4.384.752a2.568,2.568,0,1,1-3.632,0,2.568,2.568,0,0,1,3.632,0" transform="translate(0 15.411)" />
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                                {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <Link to="#" className="dropdown-item">
                                                                <i className='icon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.992" height="13.992" viewBox="0 0 13.992 13.992">
                                                                        <path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,15.574v2.915H7.415l8.6-8.6L13.1,6.978ZM18.265,7.638a.774.774,0,0,0,0-1.1L16.446,4.724a.774.774,0,0,0-1.1,0L13.928,6.146l2.915,2.915Z" transform="translate(-4.5 -4.496)" />
                                                                    </svg>
                                                                </i>
                                                                Edit Notable Drops
                                                            </Link>
                                                        </div> */}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                {/* <-- Notable Drops --> */}
                                <section className="notable-drop-details">
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="left-detail-area">
                                                    <h3>About this collection</h3>
                                                    <div className="profile-detail">
                                                        <div className="img-box">
                                                            {notableDrop['logo'] &&
                                                                <img src={notableDrop['logo']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                            }
                                                        </div>
                                                        <div className="text-box">
                                                            <h4>
                                                                {notableDrop['projectName']}
                                                                {notableDrop['isVerified'] &&
                                                                    <i className="icon">
                                                                        <svg id="verify" xmlns="http://www.w3.org/2000/svg" width="22.153" height="23.078" viewBox="0 0 22.153 23.078">
                                                                            <path id="Vector" d="M21.653,9.681l-1.506-1.75a2.454,2.454,0,0,1-.52-1.4V4.653A2.146,2.146,0,0,0,17.49,2.516H15.607a2.484,2.484,0,0,1-1.4-.52L12.463.49a2.231,2.231,0,0,0-2.79,0L7.934,2.007a2.554,2.554,0,0,1-1.4.509H4.623A2.146,2.146,0,0,0,2.486,4.653V6.547a2.52,2.52,0,0,1-.509,1.384L.482,9.692a2.23,2.23,0,0,0,0,2.768L1.977,14.22A2.52,2.52,0,0,1,2.486,15.6V17.5a2.146,2.146,0,0,0,2.137,2.137H6.539a2.484,2.484,0,0,1,1.4.52l1.75,1.506a2.231,2.231,0,0,0,2.79,0l1.75-1.506a2.454,2.454,0,0,1,1.4-.52H17.5A2.146,2.146,0,0,0,19.638,17.5V15.616a2.484,2.484,0,0,1,.52-1.4l1.506-1.75A2.246,2.246,0,0,0,21.653,9.681Zm-5.979-.7-5.348,5.348a.829.829,0,0,1-1.174,0l-2.68-2.68a.83.83,0,0,1,1.174-1.174l2.093,2.093L14.5,7.809a.83.83,0,0,1,1.174,1.174Z" transform="translate(0.008 0.926)" fill="#2d85ff" />
                                                                            <path id="Vector-2" data-name="Vector" d="M0,0H22.153V22.153H0Z" transform="translate(22.153 22.153) rotate(180)" fill="none" opacity="0" />
                                                                        </svg>
                                                                    </i>
                                                                }
                                                            </h4>
                                                            {notableDrop['creator'] &&
                                                                <p className='profile-name'>
                                                                    By {notableDrop['creator']['name']}
                                                                </p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <p>{notableDrop['description']}</p>

                                                    <div className="reminder-area">
                                                        <h5>Public Stage</h5>
                                                        <p className="eth-text">0.0003 ETH</p>
                                                        <span>
                                                            {moment(notableDrop['fromDate']).format("MMM Do YYYY")} GMT
                                                        </span>
                                                        <div className="btn-area">
                                                            <Link className="remender-btn btn-style-one">Set Reminder</Link>
                                                            <Link className="remender-btn btn-style-two">Add to Cal</Link>
                                                        </div>
                                                    </div>

                                                    {/* <div className="schedule-area">
                                                        <h4>Mint Schedule</h4>
                                                        <div id="accordion">
                                                            <div class="card">
                                                                <div class="card-header" id="headingOne">
                                                                    <h5 class="mb-0">
                                                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            VIP
                                                                            <span>Start at March 31 at 09:30PM</span>
                                                                        </button>
                                                                    </h5>
                                                                </div>

                                                                <div id="collapseOne" class="collapse collapase-active-area show" aria-labelledby="headingOne" data-parent="#accordion">
                                                                    <div class="card-body">
                                                                        Price: 0.34 ETH
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card">
                                                                <div class="card-header" id="headingTwo">
                                                                    <h5 class="mb-0">
                                                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            Monster
                                                                            <span>Start at March 31 at 09:30PM</span>
                                                                        </button>
                                                                    </h5>
                                                                </div>
                                                                <div id="collapseTwo" class="collapse collapase-active-area" aria-labelledby="headingTwo" data-parent="#accordion">
                                                                    <div class="card-body">
                                                                        Price: 0.34 ETH
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card">
                                                                <div class="card-header" id="headingThree">
                                                                    <h5 class="mb-0">
                                                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            Public
                                                                            <span>Start at March 31 at 09:30PM</span>
                                                                        </button>
                                                                    </h5>
                                                                </div>
                                                                <div id="collapseThree" class="collapse collapase-active-area" aria-labelledby="headingThree" data-parent="#accordion">
                                                                    <div class="card-body">
                                                                        Price: 0.34 ETH
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card">
                                                                <div class="card-header" id="headingFour">
                                                                    <h5 class="mb-0">
                                                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            Team Token
                                                                            <span>Start at March 31 at 09:30PM</span>
                                                                        </button>
                                                                    </h5>
                                                                </div>
                                                                <div id="collapseFour" class="collapse collapase-active-area" aria-labelledby="headingFour" data-parent="#accordion">
                                                                    <div class="card-body">
                                                                        Price: 0.34 ETH
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                {isAllDrops
                                                    ? <div className="single-collection-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                                    : <>
                                                        {allDrops.length > 0
                                                            ? <div className="right-area">
                                                                <div className="item">
                                                                    <div className="about-game-img">
                                                                        {notableDrop['file'] &&
                                                                            <>
                                                                                {notableDrop['file']['format'] == 'jpg' || notableDrop['file']['format'] == 'jpeg' || notableDrop['file']['format'] == 'png'
                                                                                    || notableDrop['file']['format'] == 'pnm' || notableDrop['file']['format'] == 'bmp' || notableDrop['file']['format'] == 'svg'
                                                                                    ? <img src={notableDrop['file']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                    : notableDrop['file']['format'] == 'mp4' || notableDrop['file']['format'] == 'webm'
                                                                                        ? <video autoPlay loop>
                                                                                            <source src={notableDrop['file']['url']} type="video/mp4" />
                                                                                        </video>
                                                                                        : notableDrop['file']['format'] == 'mp3' || notableDrop['file']['format'] == 'mpeg' || notableDrop['file']['format'] == 'ogg'
                                                                                            ? <audio className='audio-file' controls>
                                                                                                <source src={notableDrop['file']['url']} type="audio/mp3" />
                                                                                            </audio>
                                                                                            : <h4 className="text-center">No Preview!</h4>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Slider className="slider-nav-thumbnails" {...settings} asNavFor={this.state.nav} ref={slider => (this.slider = slider)}>
                                                                    {allDrops && allDrops.map(drop => {
                                                                        return (
                                                                            <button onClick={() => this.setSliderDrop(drop['_id'])}>
                                                                                <Link to={`/notabledropsdetails/${drop['_id']}`}>
                                                                                    <div className="item-thumbnail">
                                                                                        <div className="about-game-img">
                                                                                            {drop['file'] &&
                                                                                                <>
                                                                                                    {drop['file']['format'] == 'jpg' || drop['file']['format'] == 'jpeg' || drop['file']['format'] == 'png'
                                                                                                        || drop['file']['format'] == 'pnm' || drop['file']['format'] == 'bmp' || drop['file']['format'] == 'svg'
                                                                                                        ? <img src={drop['file']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                                        : drop['file']['format'] == 'mp4' || drop['file']['format'] == 'webm'
                                                                                                            ? <video width="160" height="80px" autoPlay loop>
                                                                                                                <source src={drop['file']['url']} type="video/mp4" />
                                                                                                            </video>
                                                                                                            : drop['file']['format'] == 'mp3' || drop['file']['format'] == 'mpeg' || drop['file']['format'] == 'ogg'
                                                                                                                ? <audio className='audio-file' controls>
                                                                                                                    <source src={drop['file']['url']} type="audio/mp3" />
                                                                                                                </audio>
                                                                                                                : <p className="no-preview">No Preview!</p>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </button>
                                                                        )
                                                                    })
                                                                    }
                                                                </Slider>
                                                            </div>
                                                            : <div className="right-area">
                                                                <div className="item">
                                                                    <div className="about-game-img">
                                                                        <h3>No drops found!</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </section>
                            </>
                        }
                    </>
                }
            </div>
        );
    }
};

const mapDispatchToProps = { getAllDrops, allDropsLoader, getSingleDrop, singleDropLoader };

const mapStateToProps = ({ Auth, Drop }) => {
    let { lightMode, } = Auth;
    let { singleDrop, isSingleDrop, allDrops, isAllDrops } = Drop;
    return { lightMode, singleDrop, isSingleDrop, allDrops, isAllDrops };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDetails);