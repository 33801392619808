import moment from "moment";
import EventBus from "eventing-bus";
import { Picker } from "emoji-mart";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Modal, ModalBody } from "reactstrap";
import BeatLoader from "react-spinners/BeatLoader";
import TextField from '@material-ui/core/TextField';

import './index.css';
import '../../static/css/animate.css';

import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import {
    getAllPosts, allPostsLoader, postLikeDislike, getAllComments, sendPostComment, sendCommentLoader, setCommentLike, toggleDeleteCommentModal,
    deletePostComment, editPostComment
} from "../../store/actions/Post";
import { getAllDrops, allDropsLoader } from "../../store/actions/Drop";


class HomeScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShareModal: false,
            isCommentModal: false,

            postData: {},
            allComments: [],
            file: '',
            comment: '',
            isEmoji: false,
            replyComment: {},
            replyOf: '',
            isReplyTo: false,
            isViewReplies: false,
            deleteCommentID: '',
            editComment: {},
            isEditComment: false,
        };
        props.getAllPosts();
        props.allPostsLoader(true);
        props.getAllDrops();
        props.allDropsLoader(true);
    };

    componentWillReceiveProps({ allComments, isCommented, isDeleteCommentModal }) {
        if (allComments) this.setState({ allComments });
        if (!isCommented) {
            this.hideEditComment();
            this.hideReplyComment();
            this.setState({ file: '', comment: '' });
        }
        if (!isDeleteCommentModal) this.setState({ deleteCommentID: '' });
    }

    toggleShareModal = () => this.setState({ isShareModal: !this.state.isShareModal });

    handlePostLikeDislike = (id) => {
        console.log("*******id::", id);
        let data = {
            reactionType: 'dislike'
        }
        this.props.postLikeDislike({ data, id });
    };

    showCommentModal = (postData) => {
        this.props.getAllComments(postData['_id']);
        this.setState({ postData, allComments: postData['comments'], isCommentModal: true });
    };
    hideCommentModal = () => this.setState({ postData: {}, allComments: [], isCommentModal: false });

    handleFileUpload = async (e) => {
        let { file } = this.state;
        let upload = URL.createObjectURL(e.currentTarget.files[0]);
        let type = e.currentTarget.files[0]['type'];
        let name = e.currentTarget.files[0]['name'];
        let res = await fetch(upload);
        let blob = await res.blob();
        file = new File([blob], name, { type });

        if (file['size'] <= 5000000) this.setState({ file });
        else EventBus.publish("error", "File Size is more then 5MB");
    };

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleEmoji = (e) => {
        let { comment } = this.state;
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);

        this.setState({ comment: comment + emoji }, () => this.setState({ isEmoji: false }));
    };

    setReplyComment = (replyComment) => {
        this.hideEditComment();
        this.setState({ replyComment, replyOf: replyComment['_id'], isReplyTo: true });
    };
    hideReplyComment = () => this.setState({ replyComment: {}, replyOf: '', isReplyTo: false });

    submitComment = () => {
        let { postData, comment, file, replyOf, isEditComment, editComment } = this.state;

        if (isEditComment) {
            let data = {};
            if (replyOf !== '') data = { text: comment, commentID: editComment['_id'], commentType: 'reply' };
            else data = { text: comment, commentID: editComment['_id'], commentType: 'comment' };
            this.props.sendCommentLoader(true);
            this.props.editPostComment({ data, id: postData['_id'] });
            return;
        }

        if (comment == '' && file == '') {
            EventBus.publish("error", 'Please type the comment or attach the file!');
            return;
        };

        if (file !== '') {
            let data = new FormData();
            data.append('image', file);
            if (replyOf !== '') {
                data.append('replyOf', replyOf);
                data.append('commentType', 'reply');
            }
            else data.append('commentType', 'comment');
            this.props.sendCommentLoader(true);
            this.props.sendPostComment({ data, id: postData['_id'] });
        };

        if (comment !== '') {
            let data;
            this.props.sendCommentLoader(true);
            if (replyOf !== '') data = { text: comment, replyOf, commentType: 'reply' };
            else data = { text: comment, commentType: 'comment' };
            this.props.sendPostComment({ data, id: postData['_id'] });
        };
    };

    keyPressed = (e) => {
        if (e.key === "Enter") this.submitComment();
    };

    showDeleteCommentModal = (deleteCommentID) => {
        this.setState({ deleteCommentID });
        this.props.toggleDeleteCommentModal(true);
    };

    showDeleteReplyModal = (deleteCommentID) => {
        this.setState({ deleteCommentID, replyOf: deleteCommentID },
            () => this.props.toggleDeleteCommentModal(true));
    };

    submitDeleteComment = () => {
        let { deleteCommentID, postData, replyOf } = this.state;
        let data = {};
        if (replyOf !== '') data = { deleteCommentID, commentType: 'reply' };
        else data = { deleteCommentID, commentType: 'comment' };
        this.props.deletePostComment({ data, id: postData['_id'] });
    };

    setEditComment = (editComment) => {
        this.hideReplyComment();
        this.setState({ comment: editComment['text'], editComment, isEditComment: true });
    };
    hideEditComment = () => this.setState({ comment: '', editComment: {}, isEditComment: false });


    render() {

        const owl_option = {
            margin: 16,
            nav: true,
            items: 3,
            loop: true,
            autoplay: false,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 2,
                },
                600: {
                    items: 3,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 3,
                }
            },
        };

        const owl_option_2 = {
            margin: 0,
            nav: true,
            items: 1,
            dots: true,
            loop: true,
            autoplay: false,
        };

        let { lightMode, userID, profileDetails, isAllPosts, allPosts, isCommented, isDeleteCommentModal,
            isAllDrops, allDrops } = this.props;
        let { isShareModal, isCommentModal, postData, allComments, file, comment, isEmoji, isReplyTo, isViewReplies,
            replyComment, editComment, isEditComment, } = this.state;

        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='left-sidebar-area col-xl-3 col-lg-6 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>

                            <div className='center-blocks-area col-xl-6 col-lg-12 col-md-12'>
                                <div className='center-content-area'>
                                    <div className='top-seller-area'>
                                        <h3>Top sellers</h3>
                                    </div>
                                    <div className='profile-list-area'>
                                        <OwlCarousel className="slider-items owl-carousel ltf-owl owl-theme" autoplaySpeed={3000}  {...owl_option} nav>
                                            <div className="item">
                                                <div className='profile-inner'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-seller-1.png')} alt='' />
                                                        <div className='top-seller-badge'>
                                                            <img src={require('../../static/images/top_seller_badge.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <p>Josephine</p>
                                                        <span>485 NFTs</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className='profile-inner'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-seller-2.png')} alt='' />
                                                        <div className='top-seller-badge'>
                                                            <img src={require('../../static/images/top_seller_badge.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <p>Devin</p>
                                                        <span>412 NFTs</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className='profile-inner'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-seller-3.png')} alt='' />
                                                        <div className='top-seller-badge'>
                                                            <img src={require('../../static/images/top_seller_badge.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <p>Beatriz</p>
                                                        <span>398 NFTs</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className='profile-inner'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-seller-4.png')} alt='' />
                                                        <div className='top-seller-badge'>
                                                            <img src={require('../../static/images/top_seller_badge.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <p>Janice Fox</p>
                                                        <span>485 NFTs</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className='profile-inner'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-seller-5.png')} alt='' />
                                                        <div className='top-seller-badge'>
                                                            <img src={require('../../static/images/top_seller_badge.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <p>Martha Smith</p>
                                                        <span>412 NFTs</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <div className='profile-inner'>
                                                    <div className='img-box'>
                                                        <img src={require('../../static/images/top-seller-6.png')} alt='' />
                                                        <div className='top-seller-badge'>
                                                            <img src={require('../../static/images/top_seller_badge.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <p>Matthew Rivera</p>
                                                        <span>398 NFTs</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                    {isAllPosts
                                        ? <div className="post-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                        : <div className='nft-blog-card'>
                                            {allPosts && allPosts.length > 0
                                                ? <>
                                                    {allPosts.map(post => {
                                                        let sentTime = new Date(post['createdAt']).getTime();
                                                        let currentTime = new Date().getTime();
                                                        let milisec = (currentTime - sentTime);

                                                        let days = moment.duration(milisec).days();
                                                        let hours = moment.duration(milisec).hours();
                                                        let minutes = moment.duration(milisec).minutes();
                                                        let seconds = moment.duration(milisec).seconds();
                                                        return (
                                                            <div className='nft-blog-block'>
                                                                <div className='top-heading-area'>
                                                                    {post['postedBy'] &&
                                                                        <div className='left-profile-area'>
                                                                            <div className='img-box'>
                                                                                <img src={post['postedBy']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                            </div>
                                                                            <Link to={`/profile/${post['postedBy']['_id']}`}>{post['postedBy']['name'] ? post['postedBy']['name'] : 'User'}</Link>
                                                                        </div>
                                                                    }
                                                                    <div className='right-eth-area'>
                                                                        {post['forSale'] &&
                                                                            <button className='eth-btn'>0 ETH</button>
                                                                        }
                                                                        <button className='detail-btn'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="15.248" viewBox="0 0 4 15.248">
                                                                                <g id="three_dots_home" transform="translate(0 0.248)">
                                                                                    <path id="Path_9" data-name="Path 9" d="M3.414.586a2,2,0,1,1-2.828,0,2,2,0,0,1,2.828,0" transform="translate(4 15) rotate(180)" />
                                                                                    <path id="Path_10" data-name="Path 10" d="M3.414.586a2,2,0,1,1-2.828,0,2,2,0,0,1,2.828,0" transform="translate(4 9.376) rotate(180)" />
                                                                                    <path id="Path_11" data-name="Path 11" d="M3.414.586a2,2,0,1,1-2.828,0,2,2,0,0,1,2.828,0" transform="translate(4 3.752) rotate(180)" />
                                                                                </g>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {post['description'] &&
                                                                    <p>{post['description']}</p>
                                                                }
                                                                <div className='nft-img'>
                                                                    {post['data'] &&
                                                                        <>
                                                                            {post['data']['format'] == 'jpg' || post['data']['format'] == 'jpeg' || post['data']['format'] == 'png'
                                                                                || post['data']['format'] == 'pnm' || post['data']['format'] == 'bmp' || post['data']['format'] == 'svg'
                                                                                ? <img src={post['data']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                : post['data']['format'] == 'mp4' || post['data']['format'] == 'webm'
                                                                                    ? <video width="500" autoPlay loop>
                                                                                        <source src={post['data']['url']} type="video/mp4" />
                                                                                    </video>
                                                                                    : post['data']['format'] == 'mp3' || post['data']['format'] == 'mpeg' || post['data']['format'] == 'ogg'
                                                                                        ? <audio controls>
                                                                                            <source src={post['data']['url']} type="audio/mp3" />
                                                                                        </audio>
                                                                                        : <p className="text-center">No Preview!</p>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className='bottom-area'>
                                                                    <div className='sharea-area'>
                                                                        <div className='left-share-area'>
                                                                            <button className='share-btn' onClick={() => this.handlePostLikeDislike(post['_id'])}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19.988" height="18.573" viewBox="0 0 19.988 18.573">
                                                                                    <path id="like_heart" d="M18.353,18.642a5.462,5.462,0,0,0-8,0L10,19.014l-.353-.373a5.465,5.465,0,0,0-7.724-.284q-.147.137-.284.284a6.13,6.13,0,0,0,0,8.335l7.842,8.27a.714.714,0,0,0,1.01.027l.027-.027,7.839-8.27A6.129,6.129,0,0,0,18.353,18.642ZM17.32,25.993h0L10,33.717,2.671,25.993a4.684,4.684,0,0,1,0-6.368,4.038,4.038,0,0,1,5.705-.23q.119.11.23.23l.871.919a.736.736,0,0,0,1.037,0l.871-.918a4.038,4.038,0,0,1,5.705-.23q.119.11.23.23A4.641,4.641,0,0,1,17.32,25.993Z" transform="translate(0 -16.896)" />
                                                                                </svg>
                                                                            </button>
                                                                            {post['allowComments'] &&
                                                                                <button className='share-btn' onClick={() => this.showCommentModal(post)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.995" height="19.995" viewBox="0 0 19.995 19.995">
                                                                                        <path id="comment" d="M10,0A10,10,0,0,0,1.326,14.975l-1.288,4a.781.781,0,0,0,.983.983l4-1.288A10,10,0,1,0,10,0Zm0,18.433a8.411,8.411,0,0,1-4.465-1.278.782.782,0,0,0-.654-.081L1.991,18l.93-2.888a.782.782,0,0,0-.081-.654A8.436,8.436,0,1,1,10,18.433ZM10.974,10A.976.976,0,1,1,10,9.021.976.976,0,0,1,10.974,10Zm3.905,0a.976.976,0,1,1-.976-.976A.976.976,0,0,1,14.879,10ZM7.069,10a.976.976,0,1,1-.976-.976A.976.976,0,0,1,7.069,10Zm0,0" />
                                                                                    </svg>
                                                                                </button>
                                                                            }
                                                                            {/* <button className='share-btn' onClick={this.toggleShareModal}> */}
                                                                            <button className='share-btn'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.007" height="17.343" viewBox="0 0 20.007 17.343">
                                                                                    <path id="share" d="M-69.555,41.109c0-.066,0-.14,0-.215,0-1.445,0-2.89,0-4.335a.685.685,0,0,1,.411-.706.68.68,0,0,1,.778.182q4.261,3.939,8.525,7.874a.676.676,0,0,1,.006,1.11q-4.27,3.944-8.542,7.887a.669.669,0,0,1-.78.169.683.683,0,0,1-.4-.693q0-2.157,0-4.314v-.226c-.27,0-.53-.012-.79,0a13.872,13.872,0,0,0-3.619.625,6.27,6.27,0,0,0-3.648,3.014c-.219.394-.426.795-.635,1.194a.693.693,0,0,1-.781.444.685.685,0,0,1-.527-.747,24.508,24.508,0,0,1,.334-4.584,7.81,7.81,0,0,1,1.858-4,7.581,7.581,0,0,1,3.576-2.068,16.984,16.984,0,0,1,4.009-.575c.055,0,.111-.005.166-.01A.283.283,0,0,0-69.555,41.109Zm1.335-3.121v.269c0,1.167,0,2.334,0,3.5a.667.667,0,0,1-.7.709c-.479.012-.959.015-1.437.045a12.868,12.868,0,0,0-3.749.707,5.49,5.49,0,0,0-3.383,3.258,10.42,10.42,0,0,0-.6,2.756c-.018.186-.031.373-.05.6a1.062,1.062,0,0,0,.083-.078,7.678,7.678,0,0,1,4.252-2.717,18.465,18.465,0,0,1,4.824-.568.682.682,0,0,1,.766.772q0,1.73,0,3.459c0,.066.005.131.01.236l7.007-6.467Z" transform="translate(79.555 -35.796)" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                        <div className='right-fav-area'>
                                                                            <button className='fav-btn'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                                                                                    <path id="save" d="M79.714,82.578c-.828.919-1.647,1.81-2.449,2.722q-2.427,2.76-4.838,5.537a1.03,1.03,0,0,1-1.181.335A1.188,1.188,0,0,1,70.651,90q0-8.76,0-17.52c0-.765.4-1.226,1.075-1.226q7.937,0,15.874-.008a1.074,1.074,0,0,1,1.05,1.2c-.024,4.978-.012,9.957-.012,14.935q0,1.315,0,2.63a1.074,1.074,0,0,1-.553,1.069.829.829,0,0,1-1.068-.227c-.261-.286-.513-.582-.77-.873l-6.438-7.31C79.771,82.635,79.731,82.6,79.714,82.578ZM71.948,89.2l.057.048a1.71,1.71,0,0,1,.131-.2c2.235-2.563,4.481-5.113,6.7-7.7a1.1,1.1,0,0,1,1.69,0c2.222,2.588,4.473,5.143,6.714,7.71.048.055.1.107.164.177v-16.5H71.948Z" transform="translate(-70.651 -71.249)" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='likes-area'>
                                                                        <button className='like-btn'>{post['totalReactions']} likes</button>
                                                                        {post['allowComments'] &&
                                                                            <button onClick={() => this.showCommentModal(post)} className='like-btn'>View all comments</button>
                                                                        }
                                                                    </div>
                                                                    {post['allowComments'] &&
                                                                        <div className='comment-box'>
                                                                            <div className='group-form'>
                                                                                <div className='icon'>
                                                                                    <img src={profileDetails['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                </div>
                                                                                <button onClick={() => this.showCommentModal(post)} className="add-comment-btn">
                                                                                    <TextField disabled={true} fullWidth placeholder="Add a comment" id="addcomment" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className='time-sponser'>
                                                                        <p>
                                                                            {days > 0
                                                                                ? <span>{days} days ago</span>
                                                                                : hours > 0
                                                                                    ? <span>{hours} hr ago</span>
                                                                                    : minutes > 0
                                                                                        ? <span>{minutes} mins ago</span>
                                                                                        : seconds > 0
                                                                                            ? <span>{seconds} sec ago</span>
                                                                                            : 'now'
                                                                            }
                                                                        </p>
                                                                        {post['isSponsored'] &&
                                                                            <button className='sponser-btn'>Sponsored</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </>
                                                : <div className='no-posts'>
                                                    <h3>No Post Found!</h3>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='right-sidebar-area col-xl-3 col-lg-6 col-md-12'>
                                <div className='right-sidebar'>
                                    <div className='siderbar-inner'>
                                        <div className='collection-box'>
                                            <h4>Top Collections</h4>
                                            <OwlCarousel className="slider-items owl-carousel ltf-owl owl-theme" autoplaySpeed={3000}  {...owl_option_2} nav>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/nft-collection-1.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>Floor Price: 0.032ETH</p>
                                                            <span>+2.2482 ETH</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/nft-collection-1.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>Floor Price: 0.032ETH</p>
                                                            <span>+2.2482 ETH</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/nft-collection-1.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>Floor Price: 0.032ETH</p>
                                                            <span>+2.2482 ETH</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/nft-collection-1.jpg')} alt='' />
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>Floor Price: 0.032ETH</p>
                                                            <span>+2.2482 ETH</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </OwlCarousel>
                                        </div>

                                        <div className='collection-box style-two'>
                                            <h4>Trending NFTs</h4>
                                            <OwlCarousel className="slider-items owl-carousel ltf-owl owl-theme" autoplaySpeed={3000}  {...owl_option_2} nav>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/trading-1.jpg')} alt='' />
                                                        <div className='autoher-img'>
                                                            <img src={require('../../static/images/trading-autohor.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>By Smith John</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/trading-1.jpg')} alt='' />
                                                        <div className='autoher-img'>
                                                            <img src={require('../../static/images/trading-autohor.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>By Smith John</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/trading-1.jpg')} alt='' />
                                                        <div className='autoher-img'>
                                                            <img src={require('../../static/images/trading-autohor.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>By Smith John</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/trading-1.jpg')} alt='' />
                                                        <div className='autoher-img'>
                                                            <img src={require('../../static/images/trading-autohor.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>By Smith John</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className='nft-collection-box'>
                                                        <img src={require('../../static/images/trading-1.jpg')} alt='' />
                                                        <div className='autoher-img'>
                                                            <img src={require('../../static/images/trading-autohor.png')} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='text-box'>
                                                        <h5>Bored Ape Yacht Club</h5>
                                                        <div className='price-area'>
                                                            <p>By Smith John</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </OwlCarousel>
                                        </div>

                                        <div className='collection-box style-three'>
                                            <h4>Notable Drops</h4>
                                            {isAllDrops
                                                ? <div className="notable-loader"><i className="fa fa-spinner fa-spin fa-fw"></i></div>
                                                : <>
                                                    {allDrops.length > 0
                                                        ? <OwlCarousel className="slider-items owl-carousel ltf-owl owl-theme" autoplaySpeed={3000}  {...owl_option_2} nav>
                                                            {allDrops.map(drop => {
                                                                return (
                                                                    <Link to={`/notabledropsdetails/${drop['_id']}`}>
                                                                        <div className="item">
                                                                            <div className='nft-collection-box'>
                                                                                {drop['file']
                                                                                    ? <>
                                                                                        {drop['file']['format'] == 'jpg' || drop['file']['format'] == 'jpeg' || drop['file']['format'] == 'png'
                                                                                            || drop['file']['format'] == 'pnm' || drop['file']['format'] == 'bmp' || drop['file']['format'] == 'svg'
                                                                                            ? <img src={drop['file']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                            : drop['file']['format'] == 'mp4' || drop['file']['format'] == 'webm'
                                                                                                ? <video width="500" autoPlay loop>
                                                                                                    <source src={drop['file']['url']} type="video/mp4" />
                                                                                                </video>
                                                                                                : drop['file']['format'] == 'mp3' || drop['file']['format'] == 'mpeg' || drop['file']['format'] == 'ogg'
                                                                                                    ? <audio controls>
                                                                                                        <source src={drop['file']['url']} type="audio/mp3" />
                                                                                                    </audio>
                                                                                                    : <p>No Preview!</p>
                                                                                        }
                                                                                    </>
                                                                                    : <p>No Preview!</p>
                                                                                }
                                                                            </div>
                                                                            <div className='text-box'>
                                                                                <h5>{drop['projectName']}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                );
                                                            })
                                                            }
                                                        </OwlCarousel>
                                                        : <div className="notable-loader">
                                                            <h5>No Drops Found!</h5>
                                                        </div>

                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* --------------- SHARE POST MODAL--------------- */}
                <Modal isOpen={isShareModal} toggle={this.toggleShareModal} className={`main-modal share-post-modal ${lightMode ? 'dark' : 'light'}`}>
                    {/* <ModalHeader toggle={this.toggleShareModal}></ModalHeader> */}
                    <ModalBody>
                        <div className='content-area'>
                            <h4>Share</h4>
                            <div className='search-box'>
                                <div className='group-form'>
                                    <p>To:</p>
                                    <TextField fullWidth placeholder="Search…" id="search" />
                                </div>
                            </div>
                            <p className='suggest'>Suggested</p>
                            <div className='friends-suggestion'>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-1.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Kevin Gomez</h5>
                                            <p>@kevingomez12</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-2.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Eliza Andrews</h5>
                                            <p>@elizaandrews</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-3.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Timothy Wade</h5>
                                            <p>@timothywade56</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-4.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Megan Oliver</h5>
                                            <p>@meganoliver333</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-5.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Marie Lynch</h5>
                                            <p>@marielynch87</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-6.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Dan Lucas</h5>
                                            <p>@danlucas7</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-7.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Jerry Fox</h5>
                                            <p>@jerryfox77</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-8.jpg')} alt='' />
                                            <div className='add-area'>
                                                <button className='plus'><img src={require('../../static/images/add-follow.png')} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className='text-box'>
                                            <h5>Keanu Hughes</h5>
                                            <p>@keanuhughes1</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-9.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Eliza Andrews</h5>
                                            <p>@elizaandrews</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>
                                <div className='suggestion-block'>
                                    <div className='left-content-area'>
                                        <div className='img-box'>
                                            <img src={require('../../static/images/friend-suggestion-10.jpg')} alt='' />
                                        </div>
                                        <div className='text-box'>
                                            <h5>Dan Lucas</h5>
                                            <p>@danlucas7</p>
                                        </div>
                                    </div>
                                    <div className='right-btn-area'>
                                        <button className='send-btn btn-style-one'>Send</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ModalBody>
                </Modal>


                {/* --------------- COMMENT MODAL--------------- */}
                <Modal isOpen={isCommentModal} toggle={this.hideCommentModal} className={`main-modal comment-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalBody>
                        <div className='content-area'>
                            <div className='comment-block'>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 left-comment-img">
                                        {postData['data'] &&
                                            <>
                                                {postData['data']['format'] == 'jpg' || postData['data']['format'] == 'jpeg' || postData['data']['format'] == 'png'
                                                    || postData['data']['format'] == 'pnm' || postData['data']['format'] == 'bmp' || postData['data']['format'] == 'svg'
                                                    ? <img className="comment-left-img" src={postData['data']['url'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                    : postData['data']['format'] == 'mp4' || postData['data']['format'] == 'webm'
                                                        ? <video width="500" autoPlay loop>
                                                            <source src={postData['data']['url']} type="video/mp4" />
                                                        </video>
                                                        : postData['data']['format'] == 'mp3' || postData['data']['format'] == 'mpeg' || postData['data']['format'] == 'ogg'
                                                            ? <audio controls>
                                                                <source src={postData['data']['url']} type="audio/mp3" />
                                                            </audio>
                                                            : <p className="text-center">No Preview!</p>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="col-md-6 col-sm-12 right-comment-text">
                                        <div className='comment-box'>
                                            {postData['postedBy'] &&
                                                <div className='user-detail'>
                                                    <Link to={`/profile/${postData['postedBy']['_id']}`}>
                                                        <div className='img-box'>
                                                            <img src={postData['postedBy']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h5>{postData['postedBy']['name'] ? postData['postedBy']['name'] : 'User'}</h5>
                                                        </div>
                                                    </Link>
                                                </div>
                                            }
                                            {allComments && allComments.length > 0
                                                ? <div className="comment-section">
                                                    {isCommented &&
                                                        <div className='comment-area text-center'>
                                                            <BeatLoader color="#2D85FF" />
                                                        </div>
                                                    }
                                                    {allComments.map((comment) => {

                                                        let sentTime = new Date(comment['createdAt']).getTime();
                                                        let currentTime = new Date().getTime();
                                                        let milisec = (currentTime - sentTime);

                                                        let days = moment.duration(milisec).days();
                                                        let hours = moment.duration(milisec).hours();
                                                        let minutes = moment.duration(milisec).minutes();
                                                        let seconds = moment.duration(milisec).seconds();

                                                        return (
                                                            <div className='comment-area'>
                                                                <div className="comment">
                                                                    <div className="content">
                                                                        <div className='img-box'>
                                                                            <img src={comment['commentedBy']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                        </div>
                                                                        <div className='text-box'>
                                                                            <h5 className='name'>{comment['commentedBy']['name']}</h5>
                                                                            <span className='time'>
                                                                                {days > 0
                                                                                    ? <span>{days} days ago</span>
                                                                                    : hours > 0
                                                                                        ? <span>{hours} hr ago</span>
                                                                                        : minutes > 0
                                                                                            ? <span>{minutes} mins ago</span>
                                                                                            : seconds > 0
                                                                                                ? <span>{seconds} sec ago</span>
                                                                                                : 'now'
                                                                                }
                                                                            </span>
                                                                            {comment['data'] &&
                                                                                <img src={comment['data']['location']} alt='' />
                                                                            }
                                                                            {comment['text'] &&
                                                                                <p>{comment['text']}</p>
                                                                            }
                                                                            <div className="footer">
                                                                                <div>
                                                                                    <button className="likes-count">
                                                                                        {comment['totalReactions'] > 0 && comment['totalReactions']} Like
                                                                                    </button>
                                                                                    <button onClick={() => this.setReplyComment(comment)} className='reply'>Reply</button>
                                                                                </div>
                                                                                <div>
                                                                                    {comment['commentedBy']['_id'] == userID &&
                                                                                        <>
                                                                                            {comment['text'] &&
                                                                                                <button onClick={() => this.setEditComment(comment)} className="likes-count">Edit</button>
                                                                                            }
                                                                                            <button onClick={() => this.showDeleteCommentModal(comment['_id'])} className="likes-count">Delete</button>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {comment['totalReplies'] > 0 &&
                                                                    <div className="d-flex justify-content-center">
                                                                        <button onClick={() => this.setState({ isViewReplies: !this.state.isViewReplies })} className='more-replies'>
                                                                            {isViewReplies ? 'Hide ' : 'View '}
                                                                            {comment['totalReplies']} Replies
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {isViewReplies &&
                                                                    <div className={`reply comment ${comment['totalReplies'] > 2 && 'scroll'}`}>
                                                                        {comment['replies'] && comment['replies'].length > 0 &&
                                                                            <>
                                                                                {comment['replies'].map(reply => {
                                                                                    let sentTime = new Date(reply['createdAt']).getTime();
                                                                                    let currentTime = new Date().getTime();
                                                                                    let milisec = (currentTime - sentTime);

                                                                                    let days = moment.duration(milisec).days();
                                                                                    let hours = moment.duration(milisec).hours();
                                                                                    let minutes = moment.duration(milisec).minutes();
                                                                                    let seconds = moment.duration(milisec).seconds();
                                                                                    return (
                                                                                        <div className="content">
                                                                                            <div className='img-box'>
                                                                                                <img src={reply['commentedBy']['profilePicture'] || require('../../static/images/profile-img-edit.webp')} alt='' />
                                                                                            </div>
                                                                                            <div className='text-box'>
                                                                                                <h5 className='name'>{reply['commentedBy']['name']}</h5>
                                                                                                <span className='time'>
                                                                                                    {days > 0
                                                                                                        ? <span>{days} days ago</span>
                                                                                                        : hours > 0
                                                                                                            ? <span>{hours} hr ago</span>
                                                                                                            : minutes > 0
                                                                                                                ? <span>{minutes} mins ago</span>
                                                                                                                : seconds > 0
                                                                                                                    ? <span>{seconds} sec ago</span>
                                                                                                                    : 'now'
                                                                                                    }
                                                                                                </span>
                                                                                                {reply['data'] &&
                                                                                                    <img src={reply['data']['location']} alt='' />
                                                                                                }
                                                                                                {reply['text'] &&
                                                                                                    <p>{reply['text']}</p>
                                                                                                }
                                                                                                <div className="footer">
                                                                                                    <div><button className="likes-count">Like</button></div>
                                                                                                    <div>
                                                                                                        {reply['commentedBy']['_id'] == userID &&
                                                                                                            <>
                                                                                                                {reply['text'] &&
                                                                                                                    <button onClick={() => this.setEditReply(reply)} className="likes-count edit-delete-btn">Edit</button>
                                                                                                                }
                                                                                                                <button onClick={() => this.showDeleteReplyModal(reply['_id'])} className="likes-count edit-delete-btn">Delete</button>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                                }
                                                                            </>
                                                                        }

                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                    }

                                                    {/* <div className='comment-area last-comment'>
                                                        <div className="comment">
                                                            <div className="content">
                                                                <div className='img-box'>
                                                                    <img src={require('../../static/images/comment-user-7.png')} alt='' />
                                                                </div>
                                                                <div className='text-box'>
                                                                    <h5 className='name'>Frank Gilbert</h5>
                                                                    <span className='time'>02:25 PM</span>
                                                                    <p>Aenean sed nibh a magna posuere tempor. Nunc
                                                                        faucibus pellentesque nunc in aliquet.</p>

                                                                    <div className="footer">
                                                                        <button className="likes-count">615 Like</button>
                                                                        <button className='reply'>Reply</button>
                                                                        <span className="is-mute">2 hr ago</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                : <div className='comment-area no-comments'>
                                                    {isCommented
                                                        ? <BeatLoader color="#2D85FF" />
                                                        : <div className="comment">
                                                            <h5>No Comments!!!</h5>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {isReplyTo &&
                                                <div className="reply-to">
                                                    <p>Reply to: <span>{replyComment['commentedBy']['name']}</span></p>
                                                    <button onClick={this.hideReplyComment}>
                                                        <i class="fa fa-close"></i>
                                                    </button>
                                                </div>
                                            }
                                            {isEditComment &&
                                                <div className="reply-to">
                                                    <p>Edit Comment: <span>{editComment['text']}</span></p>
                                                    <button onClick={this.hideEditComment}>
                                                        <i class="fa fa-close"></i>
                                                    </button>
                                                </div>
                                            }
                                            <div className="send-box">
                                                <div className='img-upload-btn'>
                                                    <button className={`upload-btn ${file !== '' && 'active'}`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.997" height="21.873" viewBox="0 0 24.997 21.873">
                                                            <path id="camera_chat" d="M25,7.718V21.779a2.344,2.344,0,0,1-2.343,2.343H2.343A2.344,2.344,0,0,1,0,21.779V7.718A2.344,2.344,0,0,1,2.343,5.375h4.3l.6-1.606A2.34,2.34,0,0,1,9.433,2.25H15.56a2.34,2.34,0,0,1,2.192,1.518l.605,1.606h4.3A2.344,2.344,0,0,1,25,7.718Zm-6.64,7.03A5.859,5.859,0,1,0,12.5,20.607,5.863,5.863,0,0,0,18.357,14.749Zm-1.562,0a4.3,4.3,0,1,1-4.3-4.3A4.3,4.3,0,0,1,16.8,14.749Z" transform="translate(0 -2.25)" />
                                                        </svg>
                                                        <input
                                                            className='image-attach'
                                                            accept='.jpg,.jpeg,.png,.pnm,.bmp,.svg'
                                                            type='file'
                                                            id='upload'
                                                            multiple
                                                            onChange={this.handleFileUpload}
                                                        />
                                                    </button>
                                                </div>
                                                <div className='group-form'>
                                                    {file == ''
                                                        ? <input
                                                            value={comment}
                                                            name="comment"
                                                            onChange={this.handleChange}
                                                            onKeyPress={this.keyPressed}
                                                            type="text"
                                                            className="form-control"
                                                            aria-label="comment"
                                                            placeholder="Type a comment" />
                                                        : <input
                                                            disabled={true}
                                                            aria-label="comment"
                                                            className="form-control file-attach-input"
                                                            placeholder={`File (${file['name'].substring(0, 15) + '.....'}) attached`} />
                                                    }
                                                    {isEmoji &&
                                                        <div className="emoji-box">
                                                            <Picker onSelect={this.handleEmoji} />
                                                        </div>
                                                    }
                                                    <button className='attached'>
                                                        <input
                                                            className='file-attach'
                                                            type='file'
                                                            id='upload'
                                                            multiple
                                                            onChange={this.handleFileUpload}
                                                        />
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.012" height="26.012" viewBox="0 0 26.012 26.012">
                                                            <path id="Icon_metro-attachment" data-name="Icon metro-attachment" d="M15.679,8.194,14.434,6.95,8.211,13.172a2.64,2.64,0,1,0,3.734,3.733l7.468-7.467A4.4,4.4,0,0,0,13.19,3.217l-7.841,7.84-.017.016a6.136,6.136,0,0,0,8.679,8.677l.016-.017h0l5.352-5.351-1.245-1.244L12.783,18.49l-.016.016a4.376,4.376,0,0,1-6.19-6.188l.017-.016h0l7.842-7.839a2.64,2.64,0,1,1,3.734,3.733L10.7,15.661a.88.88,0,0,1-1.245-1.244l6.223-6.223Z" transform="translate(29.875 12.737) rotate(135)" />
                                                        </svg>
                                                    </button>
                                                    <button className='smiles' onClick={() => this.setState({ isEmoji: !isEmoji })}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <g id="Group_2516" data-name="Group 2516" transform="translate(-5618 -4571)">
                                                                <circle id="Ellipse_421" data-name="Ellipse 421" cx="11" cy="11" r="11" transform="translate(5619 4572)" fill="none" stroke="#000" stroke-width="2" />
                                                                <circle id="Ellipse_422" data-name="Ellipse 422" cx="2" cy="2" r="2" transform="translate(5624 4578)" fill="#fff" />
                                                                <circle id="Ellipse_423" data-name="Ellipse 423" cx="2" cy="2" r="2" transform="translate(5624 4578)" />
                                                                <circle id="Ellipse_424" data-name="Ellipse 424" cx="2" cy="2" r="2" transform="translate(5632 4578)" />
                                                                <path id="Path_4008" data-name="Path 4008" d="M5625.018,4585.256s4.093,7.112,9.438,0" transform="translate(0.482)" fill="none" stroke="#000" stroke-width="2" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <button onClick={this.submitComment} type="button" className='send-button'>
                                                        <img src={require('../../static/images/send-btn.svg')} alt='' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/* --------------- DELETE COMMENT MODAL--------------- */}
                <Modal isOpen={isDeleteCommentModal} toggle={() => this.props.toggleDeleteCommentModal(false)} className={`main-modal delete-modal ${lightMode ? 'dark' : 'light'}`}>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="row">
                                <div className="col-12">
                                    <div className='icon-splash'>
                                        <figure><img src={require("../../static/images/favicon.png")} alt="" /></figure>
                                    </div>
                                    <h2>DELETE COMMENT</h2>
                                    <h5>Are you sure want to delete this comment!!</h5>
                                </div>
                                <div className="modalfooter">
                                    <button onClick={() => this.props.toggleDeleteCommentModal(false)} className="btn-style-two">Cancel</button>
                                    <button onClick={this.submitDeleteComment} className="btn-style-one">Submit</button>
                                </div>
                            </div>

                        </div>
                    </ModalBody>
                </Modal>

            </div>
        );
    }
};

const mapDispatchToProps = {
    getAllPosts, allPostsLoader, postLikeDislike, getAllComments, sendPostComment, sendCommentLoader, setCommentLike, toggleDeleteCommentModal,
    deletePostComment, editPostComment, getAllDrops, allDropsLoader,
};

const mapStateToProps = ({ Auth, Post, Drop }) => {
    let { isAllDrops, allDrops } = Drop;
    let { lightMode, userID, profileDetails } = Auth;
    let { isAllPosts, allPosts, allComments, isCommented, isDeleteCommentModal } = Post;
    return { lightMode, userID, profileDetails, isAllPosts, allPosts, allComments, isCommented, isDeleteCommentModal, isAllDrops, allDrops }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);