import { PURGE } from "redux-persist";

let initialState = {
  isCollectionCreated: false,
  myCollections: [],
  isMyCollection: false,
  singleCollection: {},
  isSingleCollection: false,
}

const Collection = (state = initialState, { type, payload }) => {
  switch (type) {

    /*========== COLLECTION REDUCERS ============= */

    case 'CREATE_COLLECTION_LOADER':
      return {
        ...state,
        isCollectionCreated: payload,
      };

    case 'SET_MY_COLLECTIONS':
      return {
        ...state,
        myCollections: payload,
      };

    case 'TOGGLE_MY_COLLECTIONS':
      return {
        ...state,
        isMyCollection: payload,
      };

    case 'SET_SINGLE_COLLECTION':
      return {
        ...state,
        singleCollection: payload,
      };

    case 'TOGGLE_SINGLE_COLLECTION':
      return {
        ...state,
        isSingleCollection: payload,
      };

    default:
      return state;
  }
};

export default Collection;