import { PURGE } from "redux-persist";

let initialState = {
  activeFriends: [],
  chatHistory: [],
  totalUnreadCount: 0,
  isChatHistory: false,
  activeStatus: {},
  singleChatHistory: [],
  isSingleChatHistory: false,
  isSendChat: false,
  messageSent: {},
  isDeleteChatModal: false,
  isDeleteMessageModal: false,
}

const Chat = (state = initialState, { type, payload }) => {
  switch (type) {

    /*========== CHAT REDUCERS ============= */

    case 'SET_ALL_ACTIVE_FRIENDS':
      return {
        ...state,
        activeFriends: payload,
      };

    case 'SET_ACTIVE_STATUS':
      return {
        ...state,
        activeStatus: payload['user'],
      };

    case 'SET_ALL_CHAT_HISTORY':
      return {
        ...state,
        chatHistory: payload['allChats'],
        totalUnreadCount: payload['totalUnreadCount'],
      };

    case 'ALL_CHAT_HISTORY_LOADER':
      return {
        ...state,
        isChatHistory: payload,
      };

    case 'SET_SINGLE_CHAT_HISTORY':
      return {
        ...state,
        singleChatHistory: payload,
      };

    case 'SINGLE_CHAT_HISTORY_LOADER':
      return {
        ...state,
        isSingleChatHistory: payload,
      };

    case 'SEND_CHAT_LOADER':
      return {
        ...state,
        isSendChat: payload,
      };

    case 'CHAT_MESSAGE_SENT':
      return {
        ...state,
        messageSent: payload,
      };

    case 'CHAT_MESSAGE_RECEIVED':
      return {
        ...state,
        messageReceive: payload,
      };

    case 'TOGGLE_DELETE_CHAT_MODAL':
      return {
        ...state,
        isDeleteChatModal: payload,
      };

    case 'TOGGLE_DELETE_MESSAGE_MODAL':
      return {
        ...state,
        isDeleteMessageModal: payload,
      };

    default:
      return state;
  }
};

export default Chat;