/*========== COLLECTION ACTIONS ============= */

export const createCollection = (data) => ({
  type: 'CREATE_COLLECTION',
  payload: data
});

export const createCollectionLoader = (data) => ({
  type: 'CREATE_COLLECTION_LOADER',
  payload: data
});

export const editCollection = ({ data, id }) => ({
  type: 'EDIT_COLLECTION',
  payload: data,
  id
});

export const getMyCollections = () => ({
  type: 'GET_MY_COLLECTIONS',
});

export const toggleMyCollections = (data) => ({
  type: 'TOGGLE_MY_COLLECTIONS',
  payload: data
});

export const setMyCollections = (data) => ({
  type: 'SET_MY_COLLECTIONS',
  payload: data
});

export const getSingleCollection = (data) => ({
  type: 'GET_SINGLE_COLLECTION',
  payload: data
});

export const toggleSingleCollection = (data) => ({
  type: 'TOGGLE_SINGLE_COLLECTION',
  payload: data
});

export const setSingleCollection = (data) => ({
  type: 'SET_SINGLE_COLLECTION',
  payload: data
});