import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import './index.css';
import '../../static/css/animate.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import { toggleLoader } from "../../store/actions/Auth";


class TermsandConditions extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };

    render() {

        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec blocked-accounts-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-4 col-md-12'>
                                <div className='left-sidebar style-tow'>
                                    <div className='siderbar-inner'>
                                        <div className='profile-detail'>
                                            <h4>Manage Account</h4>
                                        </div>
                                        <div className='siderbar-menu'>
                                            <ul className='sidebar-list'>
                                                <li>
                                                    <Link className="sidebar-nav-item" to="#">
                                                        <i className='icon'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.212" height="20.188" viewBox="0 0 14.212 20.188">
                                                                <g id="Group_2617" data-name="Group 2617" transform="translate(-144.96 -209)">
                                                                    <path id="Vector" d="M4.795,0a4.79,4.79,0,0,0-.121,9.579.814.814,0,0,1,.222,0h.071A4.791,4.791,0,0,0,4.795,0Z" transform="translate(147.271 209)" fill="#fd5d69" />
                                                                    <path id="Vector-2" data-name="Vector" d="M12.234,1.408a10.022,10.022,0,0,0-10.245,0A3.984,3.984,0,0,0,0,4.668a3.951,3.951,0,0,0,1.978,3.24A9.326,9.326,0,0,0,7.106,9.332a9.326,9.326,0,0,0,5.128-1.423,3.982,3.982,0,0,0,1.978-3.26A3.974,3.974,0,0,0,12.234,1.408ZM9.064,5.557a.762.762,0,0,1,0,1.07.755.755,0,0,1-1.07,0l-.888-.888-.888.888a.755.755,0,0,1-1.07,0,.762.762,0,0,1,0-1.07l.888-.888L5.148,3.78a.757.757,0,0,1,1.07-1.07l.888.888.888-.888a.757.757,0,0,1,1.07,1.07l-.888.888Z" transform="translate(144.96 219.856)" fill="#fd5d69" />
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        Blocked Accounts
                                                    </Link>
                                                </li>

                                                {/* <li>
                                                    <Link className="sidebar-nav-item home-back" to="/">
                                                        <i className='icon'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.996" height="20.998" viewBox="0 0 20.996 20.998">
                                                                <path id="home" d="M20.431,9.133h0L11.864.566a1.933,1.933,0,0,0-2.734,0L.569,9.127l-.009.009a1.932,1.932,0,0,0,1.286,3.293l.06,0h.341v6.3A2.265,2.265,0,0,0,4.511,21H7.862a.615.615,0,0,0,.615-.615V15.441a1.033,1.033,0,0,1,1.032-1.032h1.977a1.033,1.033,0,0,1,1.032,1.032v4.942a.615.615,0,0,0,.615.615h3.351a2.265,2.265,0,0,0,2.263-2.262v-6.3h.317a1.933,1.933,0,0,0,1.368-3.3Zm0,0" transform="translate(0)" fill="#707070" />
                                                            </svg>
                                                        </i>
                                                        Back to Home
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-8 col-md-12'>
                                <div className='blocked-accounts-area'>
                                    <h3>Blocked Accounts</h3>
                                    <div className='blocked-account-block'>
                                        <div className='left-content-area'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/accounts-img-1.png')} alt='' />
                                            </div>
                                            <div className='text-box'>
                                                <h4>Kevin Ortiz</h4>
                                                <p>Posts: 20K </p>
                                                <p>Followers: 12.5K</p>
                                            </div>
                                        </div>
                                        <div className='right-area'>
                                            <Link className='btn-style-one' to="#">Unblock</Link>
                                        </div>
                                    </div>
                                    <div className='blocked-account-block'>
                                        <div className='left-content-area'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/accounts-img-2.png')} alt='' />
                                            </div>
                                            <div className='text-box'>
                                                <h4>Kevin Ortiz</h4>
                                                <p>Posts: 20K </p>
                                                <p>Followers: 12.5K</p>
                                            </div>
                                        </div>
                                        <div className='right-area'>
                                            <Link className='btn-style-one' to="#">Unblock</Link>
                                        </div>
                                    </div>
                                    <div className='blocked-account-block'>
                                        <div className='left-content-area'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/accounts-img-3.png')} alt='' />
                                            </div>
                                            <div className='text-box'>
                                                <h4>Kevin Ortiz</h4>
                                                <p>Posts: 20K </p>
                                                <p>Followers: 12.5K</p>
                                            </div>
                                        </div>
                                        <div className='right-area'>
                                            <Link className='btn-style-one' to="#">Unblock</Link>
                                        </div>
                                    </div>
                                    <div className='blocked-account-block'>
                                        <div className='left-content-area'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/accounts-img-4.png')} alt='' />
                                            </div>
                                            <div className='text-box'>
                                                <h4>Kevin Ortiz</h4>
                                                <p>Posts: 20K </p>
                                                <p>Followers: 12.5K</p>
                                            </div>
                                        </div>
                                        <div className='right-area'>
                                            <Link className='btn-style-one' to="#">Unblock</Link>
                                        </div>
                                    </div>
                                    <div className='blocked-account-block last-block'>
                                        <div className='left-content-area'>
                                            <div className='img-box'>
                                                <img src={require('../../static/images/accounts-img-5.png')} alt='' />
                                            </div>
                                            <div className='text-box'>
                                                <h4>Kevin Ortiz</h4>
                                                <p>Posts: 20K </p>
                                                <p>Followers: 12.5K</p>
                                            </div>
                                        </div>
                                        <div className='right-area'>
                                            <Link className='btn-style-one' to="#">Unblock</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div >
        );
    }
};

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsandConditions);