import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import Footer from '../../components/footer';
import { toggleLoader } from "../../store/actions/Auth";

const UsNow = 78.5;
const NzlNow = 33.8;
const CanNow = 93.3;
const JamNow = 2.43;

class Analytics extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {
        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec profile-edit-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                <div className='anylatics-area'>
                                    <div className='top-seller-area'>
                                        <h3>My Status</h3>
                                        <div className='sortby-dropdrown dropdown'>
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Last 7 days
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="#">
                                                    Last 7 days
                                                </a>
                                                <a class="dropdown-item" href="#">
                                                    Last 28 days
                                                </a>
                                                <a class="dropdown-item" href="#">
                                                    Last 60 days
                                                </a>
                                                <a class="dropdown-item" href="#">
                                                    Last 90 days
                                                </a>
                                                <a class="dropdown-item" href="#">
                                                    Custom
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='status-blocks'>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                                <div className='anayltics-block active-block'>
                                                    <p>Views</p>
                                                    <div className='bottom-area'>
                                                        <h3>756,438</h3>
                                                        <p className='up-arrow'>+436 (85%)
                                                            <span className='icon-arrow'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="6.954" height="14.148" viewBox="0 0 6.954 14.148">
                                                                    <path id="Icon_awesome-long-arrow-alt-up" data-name="Icon awesome-long-arrow-alt-up" d="M3.472,6.483v9.535a.379.379,0,0,0,.379.379H5.619A.379.379,0,0,0,6,16.019V6.483H7.453A.758.758,0,0,0,7.989,5.19L5.271,2.472a.758.758,0,0,0-1.072,0L1.482,5.19a.758.758,0,0,0,.536,1.294Z" transform="translate(-1.258 -2.25)" fill="#fff" />
                                                                </svg>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                                <div className='anayltics-block'>
                                                    <p>Sales</p>
                                                    <div className='bottom-area'>
                                                        <h3>69 Nfts</h3>
                                                        <p className='down-arrow'>-29(35%)
                                                            <span className='icon-arrow'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="6.954" height="14.148" viewBox="0 0 6.954 14.148">
                                                                    <path id="Icon_awesome-long-arrow-alt-up" data-name="Icon awesome-long-arrow-alt-up" d="M3.472,6.483v9.535a.379.379,0,0,0,.379.379H5.619A.379.379,0,0,0,6,16.019V6.483H7.453A.758.758,0,0,0,7.989,5.19L5.271,2.472a.758.758,0,0,0-1.072,0L1.482,5.19a.758.758,0,0,0,.536,1.294Z" transform="translate(-1.258 -2.25)" fill="#fff" />
                                                                </svg>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                                <div className='anayltics-block'>
                                                    <p>Likes</p>
                                                    <div className='bottom-area'>
                                                        <h3>12K</h3>
                                                        <p className='up-arrow'>+342(75%)
                                                            <span className='icon-arrow'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="6.954" height="14.148" viewBox="0 0 6.954 14.148">
                                                                    <path id="Icon_awesome-long-arrow-alt-up" data-name="Icon awesome-long-arrow-alt-up" d="M3.472,6.483v9.535a.379.379,0,0,0,.379.379H5.619A.379.379,0,0,0,6,16.019V6.483H7.453A.758.758,0,0,0,7.989,5.19L5.271,2.472a.758.758,0,0,0-1.072,0L1.482,5.19a.758.758,0,0,0,.536,1.294Z" transform="translate(-1.258 -2.25)" fill="#fff" />
                                                                </svg>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='graph-sec'>
                                    <div className='top-heading-area'>
                                        <div className='left-content'>
                                            <h3>Followers</h3>
                                            <p>12 March - 23April</p>
                                        </div>
                                        <Link className='view-all' to="#">(356,456)</Link>
                                    </div>
                                    <div className='graph-block'>
                                        <img src={require('../../static/images/graph.png')} alt='' />
                                    </div>
                                </div>
                                <div className='gender-sec'>
                                    <h3>Gender</h3>
                                    <ProgressBar now={28.5} />
                                    <div className='bottom-content'>
                                        <div className='genter-detail'>
                                            <h4>28.5%</h4>
                                            <p>Men</p>
                                        </div>
                                        <div className='genter-detail'>
                                            <h4>71.5%</h4>
                                            <p>Woman</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='countries-sec'>
                                    <div className='top-heading'>
                                        <h3>Countries</h3>
                                        <Link className='view-all' to="#">See all</Link>
                                    </div>
                                    <div className='progress-bar-country'>
                                        <i className='img'><img src={require('../../static/images/flag-1.png')} alt='' /><span>USA</span></i>
                                        <ProgressBar now={78.5} />
                                        <p>{`${UsNow}%`}</p>
                                    </div>
                                    <div className='progress-bar-country'>
                                        <i className='img'><img src={require('../../static/images/flag-2.png')} alt='' /><span>NZL</span></i>
                                        <ProgressBar now={33.8} />
                                        <p>{`${NzlNow}%`}</p>
                                    </div>
                                    <div className='progress-bar-country'>
                                        <i className='img'><img src={require('../../static/images/flag-3.png')} alt='' /><span>CAN</span></i>
                                        <ProgressBar now={93.3} />
                                        <p>{`${CanNow}%`}</p>
                                    </div>
                                    <div className='progress-bar-country'>
                                        <i className='img'><img src={require('../../static/images/flag-4.png')} alt='' /><span>JAM</span></i>
                                        <ProgressBar now={2.43} />
                                        <p>{`${JamNow}%`}</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div >
        );
    }
};

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
