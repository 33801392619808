import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, DatePicker, } from '@material-ui/pickers';

import './index.css';
import '../../static/css/animate.css';
import 'react-circular-progressbar/dist/styles.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import SideBar from '../../components/sidebar';
import { toggleLoader } from "../../store/actions/Auth";

class DeleteAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };

    render() {

        let { lightMode } = this.props;
        return (
            <div className={`nfity-page ${lightMode ? 'dark' : 'light'}`}>
                <Navbar />
                <section className='home-screen-sec profile-edit-page'>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-12 col-md-12'>
                                <div className='left-sidebar'>
                                    <SideBar />
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-12 col-md-12'>
                                <div className='my-wallet-box'>
                                    <div className='top-heading'>
                                        <h3>Delete Account</h3>
                                    </div>
                                    <div className='data-area'>
                                        <h4>Delete account @kathyross?</h4>
                                        <p>If you delete your account:</p>
                                        <ol>
                                            <li>You will not be able to log in to Nifty again</li>
                                            <li>Information that is not stored in your account, such as chat messages, may still be visible to others.</li>
                                            <li>All your followers, likes, comments will be deleted accordingly.</li>
                                            <li>You will lose to access to any NFTs you have posted.</li>
                                            <li>You will not be able to receive a refund on any items you have purchased.</li>
                                            <li>Your account will be deactivated for 30 days. If you change your mind and log in again within 30 days, you can choose
                                                to reactivate the account.</li>
                                            <li>If you haven’t reactivated your account within 30 days, your account will be permanently deleted..</li>
                                        </ol>
                                    </div>
                                    <div className='form-area'>
                                        <ValidatorForm className="validatorForm" >


                                            <Grid container spacing={1} className="group-input" alignItems="flex-end">
                                                <Grid className="input-fields" item xs={12}>
                                                    <Checkbox />
                                                    <label>If you are sure to continue, please click on confirm</label>
                                                </Grid>
                                            </Grid>
                                            <div className='group-form start-group'>
                                                <button className='btn-style-one'>Delete Account</button>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div >
        );
    }
}

const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fa6634', // Solid underline on focus
        },
    },
    input: {
        '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
        }
    }
})(TextValidator);

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { lightMode } = Auth;
    return { lightMode }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);